import { generateYearOptions } from '@/utils/date'

import { InputSelectSingle } from '../select'
import { InputYearProps } from './type'

export const InputYear = ({
  value,
  start,
  disabled,
  onChange,
}: InputYearProps) => {
  const options = generateYearOptions(start)
  return (
    <InputSelectSingle
      buttonLabel={`年`}
      options={options}
      height="lg"
      selected={options.find((year) => year.value === value) ?? null}
      handleChange={(selected) => {
        onChange(selected.value)
      }}
      disabled={disabled}
    />
  )
}
