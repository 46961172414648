import clsx from 'clsx'
import * as React from 'react'

import type { ToggleButtonsProps } from './type'

import { ToggleButtonsItem } from './ToggleButtonsItem'

export const ToggleButtons = <
  T extends number | string | (number | string) = number | string
>({
  name,
  items,
  handleClick,
}: ToggleButtonsProps<T>) => {
  return (
    <ul
      className={clsx(
        'tw-grid tw-grid-cols-2',
        'tw-h-8',
        'tw-rounded-lg',
        'tw-overflow-hidden',
        'tw-border-2 tw-border-primary-500'
      )}
    >
      {items.map((item) => {
        return (
          <ToggleButtonsItem
            key={`toggle-buttons-item_${name}_${item.key}`}
            label={item.label}
            selected={item.selected}
            handleClick={() => handleClick(item.key)}
          />
        )
      })}
    </ul>
  )
}

ToggleButtons.displayName = 'ToggleButtons'
