import { useQuery } from 'react-query'

import { contentfulPreviewClient } from '@/lib/contentful'
import { QueryConfig } from '@/lib/react-query'
import type { ITermsOfServiceFields } from '@/types/generated/contentful'

import { contentfulPreviewTermsOfServiceKeyFactory } from './key'

export const getContentfulTermsOfServicePreview = ({
  id,
}: Parameters<
  typeof contentfulPreviewTermsOfServiceKeyFactory.termsOfService
>[0]) => {
  return contentfulPreviewClient.getEntry<ITermsOfServiceFields>(id)
}

export type UseContentfulPreviewTermsOfServiceOptions = Parameters<
  typeof contentfulPreviewTermsOfServiceKeyFactory.termsOfService
>[0] & {
  config?: QueryConfig<typeof getContentfulTermsOfServicePreview>
}

export const useContentfulPreviewTermsOfService = ({
  config,
  id,
}: UseContentfulPreviewTermsOfServiceOptions) => {
  const queryConfig: QueryConfig<typeof getContentfulTermsOfServicePreview> = {
    ...config,
    queryKey: contentfulPreviewTermsOfServiceKeyFactory.termsOfService({
      id,
    }),
    queryFn: () =>
      getContentfulTermsOfServicePreview({
        id,
      }),
  }
  return useQuery(queryConfig)
}
