import { ExpandMore } from '@mui/icons-material'
import clsx from 'clsx'
import React from 'react'

import { DisclosureProps } from './type'

export const Disclosure = ({
  isOpen,
  heading,
  children,
  disabled = false,
  headerClassName = 'tw-bg-white tw-text-xs',
  withSpace = {
    title: false,
    content: false,
  },
  toggle,
}: DisclosureProps) => {
  return (
    <>
      <div
        className={clsx(
          'tw-flex tw-min-h-[44px] tw-w-full tw-cursor-pointer tw-items-center tw-justify-between',
          withSpace.title && 'tw-px-4',
          headerClassName,
          'tw-transition tw-duration-stateChange',
          'hover:tw-opacity-hover',
          'focus:tw-outline-none',
          !disabled && 'tw-cursor-pointer'
        )}
        onClick={disabled ? undefined : toggle}
      >
        {heading}
        <ExpandMore
          className={clsx(
            'tw-h-6 tw-w-6 tw-text-gray-700',
            isOpen && 'tw-rotate-180 tw-transform',
            disabled && 'tw-opacity-30'
          )}
        />
      </div>
      {isOpen && (
        <div
          className={clsx(
            withSpace.content && 'tw-px-4',
            'tw-border-t tw-border-gray-300'
          )}
        >
          {children}
        </div>
      )}
    </>
  )
}
