import type {
  Item,
  ColorChartResponse,
  Favorite,
  Order,
  OrderHistory,
  PurchasesResponse,
} from '@/api/@types'

export const ROUTE = {
  // TODO: エラーコード別にページを用意すると仮定。/errorでまとめる際に見直し
  '401': '/401',
  '403': '/403',
  '404': '/404',
  '500': '/500',
  top: '/',
  login: '/login',
  forgetPassword: '/forget-password',
  resetPassword: '/reset-password',
  account: '/account',
  changeEmailAuth: '/change-email-auth',
  search: '/search',
  searchCategories: '/search/categories',
  searchBrands: '/search/brands',
  items: '/items',
  itemDetail: (itemCode: Item['itemCode']) => `/items/${itemCode}`,
  lineups: (lineupCode: Item['lineupCode']) => `/lineups/${lineupCode}/items`,
  colorCharts: (groupId: ColorChartResponse['groupId']) =>
    `/color-charts/${groupId}`,
  favorites: '/favorites',
  favoriteDetail: (
    setNo: Favorite['setNo'],
    storeCode: Favorite['storeCode']
  ) => `/favorites/detail?setNo=${setNo}&storeCode=${storeCode}`,
  cart: '/cart',
  cartComplete: '/cart/complete',
  orderDetail: ({ orderNo }: { orderNo: Order['orderNo'] }) =>
    `/orders/${orderNo}`,
  orderHistories: '/order-histories',
  orderHistoriesDetailBySalesOrderNo: ({
    salesOrderNo,
  }: {
    salesOrderNo: string
  }) => `/order-histories/detail?salesOrderNo=${salesOrderNo}`,
  orderHistoriesDetailByShippingInfo: ({
    desiredDeliveryDate,
    zipCode,
    address1,
    address2,
    tel,
    deliveryName,
  }: OrderHistory['shippingInfo'] &
    Pick<OrderHistory, 'desiredDeliveryDate'>) =>
    `/order-histories/detail?deliveryDate=${desiredDeliveryDate}&zipCode=${zipCode}&address1=${address1}&address2=${address2}&tel=${tel}&deliveryName=${deliveryName}`,
  orderImport: '/order-import',
  purchases: '/purchases',
  purchaseDetail: ({
    systemType,
    ecSalesOrderNo,
  }: Pick<PurchasesResponse, 'systemType' | 'ecSalesOrderNo'>) =>
    `/purchases/${systemType}?ecSalesOrderNo=${encodeURIComponent(
      ecSalesOrderNo
    )}`,
  purchasedInfo: '/purchased-info',
  purchasedInfoSalesDetail: '/purchased-info/sales-detail',
  purchasedInfoBilledAmounts: '/purchased-info/billed-amounts',
  purchasedInfoMakers: '/purchased-info/makers',
  purchasedInfoMakersStores: '/purchased-info/makers-stores',
  purchasedInfoStores: '/purchased-info/stores',
  purchasedInfoStoresSalesTypes: '/purchased-info/stores-sales-types',
  purchasedInfoStoresMakersSalesTypes:
    '/purchased-info/stores-makers-sales-types',
  guides: '/guides',
  guidesSearch: ({ q }: { q?: string }) =>
    `/guides/search${q ? `?q=${q}` : ''}`,
  guideDetail: (id: string) => `/guides/${id}`,
  contact: '/contact',
  contactComplete: '/contact/complete',
  termsOfService: '/terms-of-service',
  privacyPolicy: '/privacy-policy',
  tokushoho: '/tokushoho',
  invoice: '/invoice',
}

export const EXTERNAL_ROUTE = {
  corporateSite: process.env.NEXT_PUBLIC_CORPORATE_SITE_URL || '',
}
