import { Listbox } from '@headlessui/react'

import { InputSelectOptionContent } from '@/components/ui/input/select/elements'

import { InputSelectSingleOptionProps } from './type'

export const InputSelectSingleOption = ({
  option,
}: InputSelectSingleOptionProps) => {
  return (
    <Listbox.Option value={option}>
      {({ selected, active }) => {
        return (
          <InputSelectOptionContent
            active={active}
            selected={selected}
            option={option}
          />
        )
      }}
    </Listbox.Option>
  )
}

InputSelectSingleOption.displayName = 'InputSelectSingleOption'
