/* eslint-disable */
// prettier-ignore
import type { AspidaClient, BasicHeaders } from 'aspida'
// prettier-ignore
import type { Methods as Methods0 } from '.'

// prettier-ignore
const api = <T>({ baseURL, fetch }: AspidaClient<T>) => {
  const prefix = (baseURL === undefined ? 'http://localhost:18081' : baseURL).replace(/\/$/, '')
  const PATH0 = '/v1/login'
  const POST = 'POST'

  return {
    /**
     * ログイン処理を行い、認証結果を返します。<br/>
     * 正しく認証できた場合は、JWTとアカウント情報を返します。<br/>
     * @returns 正常処理
     */
    post: (option: { body: Methods0['post']['reqBody'], config?: T | undefined }) =>
      fetch<Methods0['post']['resBody'], BasicHeaders, Methods0['post']['status']>(prefix, PATH0, POST, option).json(),
    /**
     * ログイン処理を行い、認証結果を返します。<br/>
     * 正しく認証できた場合は、JWTとアカウント情報を返します。<br/>
     * @returns 正常処理
     */
    $post: (option: { body: Methods0['post']['reqBody'], config?: T | undefined }) =>
      fetch<Methods0['post']['resBody'], BasicHeaders, Methods0['post']['status']>(prefix, PATH0, POST, option).json().then(r => r.body),
    $path: () => `${prefix}${PATH0}`
  }
}

// prettier-ignore
export type ApiInstance = ReturnType<typeof api>
// prettier-ignore
export default api
