import type { UpdateFavoriteListModalProps } from './type'

import { Dialog } from '@/components/ui'

import { UpdateFavoriteListForm } from './Form'

export const UpdateFavoriteListSalesTypeModal = ({
  isOpen,
  favorite,
  onClickConfirm,
  onClickCancel,
}: UpdateFavoriteListModalProps) => {
  return (
    <Dialog title={'種別設定'} isOpen={isOpen}>
      <UpdateFavoriteListForm
        favorite={favorite}
        onClickConfirm={onClickConfirm}
        onClickCancel={onClickCancel}
      />
    </Dialog>
  )
}
