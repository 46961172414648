/* eslint-disable */
// prettier-ignore
import type { AspidaClient, BasicHeaders } from 'aspida'
// prettier-ignore
import type { Methods as Methods0 } from '.'

// prettier-ignore
const api = <T>({ baseURL, fetch }: AspidaClient<T>) => {
  const prefix = (baseURL === undefined ? 'http://localhost:18081' : baseURL).replace(/\/$/, '')
  const PATH0 = '/v1/change-email-auth'
  const POST = 'POST'

  return {
    /**
     * リクエストのトークンを用いて、変更したメールアドレスの認証を行います。<br/>
     * 認証成功しメールアドレス変更した場合、ログイン認証レスポンスを返却します。<br/>
     *
     * 以下のレスポンスはHTTPステータスのみの返却となります。<br/>
     *
     * <ul>
     * <li>401：トークン認証に失敗した場合。</li>
     * </ul>
     * @returns 正常処理
     */
    post: (option: { body: Methods0['post']['reqBody'], config?: T | undefined }) =>
      fetch<Methods0['post']['resBody'], BasicHeaders, Methods0['post']['status']>(prefix, PATH0, POST, option).json(),
    /**
     * リクエストのトークンを用いて、変更したメールアドレスの認証を行います。<br/>
     * 認証成功しメールアドレス変更した場合、ログイン認証レスポンスを返却します。<br/>
     *
     * 以下のレスポンスはHTTPステータスのみの返却となります。<br/>
     *
     * <ul>
     * <li>401：トークン認証に失敗した場合。</li>
     * </ul>
     * @returns 正常処理
     */
    $post: (option: { body: Methods0['post']['reqBody'], config?: T | undefined }) =>
      fetch<Methods0['post']['resBody'], BasicHeaders, Methods0['post']['status']>(prefix, PATH0, POST, option).json().then(r => r.body),
    $path: () => `${prefix}${PATH0}`
  }
}

// prettier-ignore
export type ApiInstance = ReturnType<typeof api>
// prettier-ignore
export default api
