import { ChevronRightOutlined, ChevronLeftOutlined } from '@mui/icons-material'
import clsx from 'clsx'
import React from 'react'

import { range } from '@/utils/array'

import { PaginationItem } from './PaginationItem'
import { PaginationProps } from './type'

export const Pagination = ({
  current,
  total,
  padding = 1,
  onClick,
}: PaginationProps) => {
  if (current < 1 || current > total)
    throw new RangeError(
      `現在のページ位置が1以上${total}以下を満たしていません`
    )

  const displayPages = [
    ...range(current - padding - Number(current === total), current).filter(
      (i) => i >= 1
    ),
    ...range(current, current + padding + 1 + Number(current === 1)).filter(
      (i) => i <= total
    ),
  ]

  const prevPage = (
    <>
      <PaginationItem
        content={<ChevronLeftOutlined sx={{ fontSize: 16 }} />}
        disabled={current === 1}
        onClick={() => onClick(current - 1)}
      />
      {displayPages[0] - 1 > 0 && (
        <PaginationItem content={1} onClick={() => onClick(1)} />
      )}
      {displayPages[0] - 1 > 1 && (
        <PaginationItem content="..." clickable={false} onClick={() => {}} />
      )}
    </>
  )

  const pages = displayPages.map((pageIndex) => {
    return (
      <PaginationItem
        key={`page-${pageIndex}`}
        content={pageIndex}
        isCurrent={pageIndex === current}
        onClick={() => onClick(pageIndex)}
      />
    )
  })

  const nextPage = (
    <>
      {total - displayPages[2 * padding] > 1 && (
        <PaginationItem content="..." clickable={false} onClick={() => {}} />
      )}
      {total - displayPages[2 * padding] > 0 && (
        <PaginationItem content={total} onClick={() => onClick(total)} />
      )}
      <PaginationItem
        content={<ChevronRightOutlined sx={{ fontSize: 16 }} />}
        disabled={current === total}
        onClick={() => onClick(current + 1)}
      />
    </>
  )

  return (
    <ul className={clsx('tw-flex tw-space-x-2')}>
      {prevPage}
      {pages}
      {nextPage}
    </ul>
  )
}
