import { Listbox } from '@headlessui/react'
import { ArrowDropDown } from '@mui/icons-material'
import clsx from 'clsx'
import * as React from 'react'

import type { InputSelectSingleButtonProps } from './type'

import {
  widths,
  heights,
  texts,
  rounds,
  errorClassNames,
} from '@/components/ui/input/const'

export const InputSelectSingleButton = React.forwardRef<
  HTMLButtonElement,
  InputSelectSingleButtonProps
>(
  (
    {
      label,
      height = 'lg',
      rounded = 'all',
      disabled = false,
      isError = false,
      tabIndex,
    },
    ref
  ) => {
    return (
      <Listbox.Button
        className={clsx([
          widths['full'],
          heights[height],
          texts[height],
          rounds[rounded],
          'tw-relative',
          'tw-py-1.5 tw-pl-2 tw-pr-12',
          'tw-border tw-border-gray-300',
          'tw-text-sm',
          'focus:tw-border-accentBlue-500 focus:tw-outline-none',
          disabled
            ? 'tw-cursor-not-allowed tw-bg-gray-100 tw-opacity-70'
            : 'tw-bg-white',
          isError && !disabled && errorClassNames,
        ])}
        ref={ref}
        tabIndex={tabIndex}
      >
        <span className={clsx(['tw-flex tw-items-center'])}>
          <span className={clsx(['tw-block', 'tw-truncate tw-text-xs'])}>
            {label}
          </span>
        </span>
        <span
          className={clsx([
            'tw-absolute',
            'tw-inset-y-0 tw-right-0',
            'tw-pointer-events-none tw-flex tw-items-center tw-px-2',
          ])}
        >
          <ArrowDropDown
            className={clsx(['tw-text-gray-600'])}
            sx={{ fontSize: 20 }}
            aria-hidden="true"
          />
        </span>
      </Listbox.Button>
    )
  }
)

InputSelectSingleButton.displayName = 'InputSelectSingleButton'
