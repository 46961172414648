import clsx from 'clsx'
import { useMemo } from 'react'

import type { BrandSelectProps } from './type'

import { MultiSelectButton, MultiSelectButtonTag } from '@/components/ui'
import { FormFieldWrapper } from '@/components/ui'
import { BRAND_TITLE } from '@/const'

export const BrandSelect = ({
  brandGroups,
  brandCodes,
  errors,
  handleOpenPanel,
  handleUnselect,
}: BrandSelectProps) => {
  const brands = useMemo(
    () => brandGroups.map((brandGroup) => brandGroup.brands).flat(),
    [brandGroups]
  )

  return (
    <FormFieldWrapper id="categories" label={BRAND_TITLE} error={errors}>
      <MultiSelectButton
        placeholder={'未選択'}
        isSelected={!!brandCodes?.length}
        items={
          !!brandCodes?.length && (
            <ul className={clsx('tw-flex tw-flex-1 tw-flex-wrap tw-gap-2')}>
              {brandCodes.map((brandCode) => {
                const brand = brands.find(
                  (brand) => brand?.brandCode === brandCode
                )
                return brand ? (
                  <li key={brandCode}>
                    <MultiSelectButtonTag
                      onUnselect={() => handleUnselect(brandCode)}
                    >
                      {brand.brandName}
                    </MultiSelectButtonTag>
                  </li>
                ) : null
              })}
            </ul>
          )
        }
        onClick={handleOpenPanel}
      />
    </FormFieldWrapper>
  )
}
