import { zodResolver } from '@hookform/resolvers/zod'
import { AddShoppingCart } from '@mui/icons-material'
import clsx from 'clsx'
import { useMemo, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'

import type { AddCartFormProps, AddCartFormVO } from './type'

import type { CartUpdRequest } from '@/api/@types'
import {
  ItemErrorLabel,
  ItemOrderUnitFormField,
  ItemPrice,
  ItemThumbnailBannar,
} from '@/components/features/item'
import { Button, ErrorMessageList } from '@/components/ui'
import { CART_TITLE } from '@/const'
import { useCart, useUpdateCart } from '@/hooks/features/cart'
import { useNotification } from '@/hooks/util'
import { useSalesTypesMaster } from '@/stores'
import { cartItemReducer, extendFieldNames } from '@/utils/features/cart'
import { getErrorMessages } from '@/utils/text'

import { schema } from './const'

export const AddCartForm = ({
  items,
  onSubmit,
  onClickCancel,
}: AddCartFormProps) => {
  const [errorMessages, setErrorMessages] = useState<string[]>([])
  const { notify } = useNotification()

  const { salesTypesMaster } = useSalesTypesMaster()

  const cart = useCart({
    config: {
      enabled: false,
    },
  })
  const updateCart = useUpdateCart({})

  const { register, control, handleSubmit, reset, formState } =
    useForm<AddCartFormVO>({
      resolver: schema && zodResolver(schema),
      mode: 'onBlur',
      defaultValues: {
        items: items.map((item) => ({
          itemCode: item.itemCode,
          salesTypeQuantities: [
            {
              salesType: salesTypesMaster[0].salesTypeCode,
              quantity: 0,
              note: '',
            },
          ],
        })),
      },
    })

  const isLoading = useMemo(() => {
    return cart.isLoading || updateCart.isLoading || formState.isSubmitting
  }, [cart.isLoading, formState.isSubmitting, updateCart.isLoading])

  const inputItems = useWatch({ control, name: 'items' })

  const totalItemCount = useMemo(() => {
    return inputItems.reduce((totalCount, item) => {
      const quantity = item.salesTypeQuantities.reduce(
        (sum, salesTypeQuantity) => (sum += salesTypeQuantity.quantity),
        0
      )
      return totalCount + quantity
    }, 0)
  }, [inputItems])

  const submitHandler = async (input: AddCartFormVO) => {
    if (errorMessages.length > 0) {
      setErrorMessages([])
    }

    const currentCart = await cart.refetch()

    const cartItems: CartUpdRequest['cartItems'] = [
      ...(currentCart.data?.cartItems || []).map((item) => ({
        itemCode: item.item.itemCode,
        salesTypeQuantities: item.salesTypeQuantities,
      })),
      ...input.items.map((item) => {
        return {
          itemCode: item.itemCode,
          salesTypeQuantities: item.salesTypeQuantities.filter(
            ({ quantity }) => quantity > 0
          ),
        }
      }),
    ]

    await updateCart.mutateAsync(
      {
        data: {
          cartItems: cartItems.reduce(cartItemReducer, []),
        },
      },
      {
        onSuccess: () => {
          reset()
          onSubmit && onSubmit()
          notify({
            id: 'add-cart-success',
            type: 'success',
            message: `${CART_TITLE}に追加しました`,
          })
        },
        onError: (error) => {
          if (error.response?.status === 400) {
            const extendedFieldNames = extendFieldNames({
              fields: error.response.data.fields,
            })
            setErrorMessages(
              getErrorMessages({
                fields: error.response.data.fields,
                fieldNames: extendedFieldNames,
              })
            )
          }
        },
      }
    )
  }

  return (
    <div id="item-add-modal-form">
      {errorMessages.length > 0 && (
        <div className={clsx('tw-mb-2 tw-text-center')}>
          <ErrorMessageList errorMessages={errorMessages} />
        </div>
      )}

      <ul
        className={clsx(
          'tw-flex tw-max-h-[60vh] tw-flex-col tw-space-y-2 tw-overflow-y-auto tw-pb-20'
        )}
      >
        {inputItems.map((inputItem, itemIndex) => {
          const item = items.find((i) => i.itemCode === inputItem.itemCode)
          return (
            item && (
              <li
                key={item.itemCode}
                className={clsx(
                  'tw-mb-2',
                  'tw-border-b tw-border-gray-300',
                  'last:tw-border-b-0'
                )}
              >
                <div className={clsx('tw-flex tw-flex-col', 'tw-gap-2')}>
                  <ItemThumbnailBannar
                    item={item}
                    nameFooter={
                      <>
                        <ItemErrorLabel status={item.itemStatus} />
                      </>
                    }
                  />
                  <ItemPrice
                    item={item}
                    cartSalesTypeQuantities={inputItem.salesTypeQuantities}
                    direction={'vertical'}
                  />
                </div>

                <div className={clsx('tw-mt-4')}>
                  <ItemOrderUnitFormField
                    control={control}
                    itemIndex={itemIndex}
                    register={register}
                  />
                </div>
              </li>
            )
          )
        })}
      </ul>
      <div
        className={clsx(
          'tw-absolute tw-inset-x-0 tw-bottom-0 tw-py-4',
          'tw-flex tw-items-center tw-justify-center tw-space-x-2 tw-bg-white tw-px-2 tw-shadow-inner'
        )}
      >
        <div className={clsx('tw-w-full')}>
          <Button
            type="button"
            variant="secondary"
            disabled={isLoading}
            onClick={onClickCancel}
          >
            閉じる
          </Button>
        </div>
        <div className={clsx('tw-w-full tw-min-w-[184px]')}>
          <Button
            disabled={isLoading || totalItemCount <= 0}
            isLoading={isLoading}
            startIcon={
              <AddShoppingCart
                className={clsx('tw-text-white')}
                sx={{ fontSize: 18 }}
              />
            }
            onClick={handleSubmit(submitHandler)}
          >
            {`${CART_TITLE}に追加する`}
          </Button>
        </div>
      </div>
    </div>
  )
}
