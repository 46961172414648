import { RadioGroup } from '@headlessui/react'
import clsx from 'clsx'
import * as React from 'react'

import type { InputRadioChildGroupProps } from './type'

import type { InputValue } from '@/types'

import { GAP_CLASS_NAME } from './const'
import { InputRadioGroupItem } from './InputRadioGroupItem'

export const InputRadioChildGroup = <
  T extends Exclude<InputValue, boolean | undefined> = Exclude<
    InputValue,
    boolean | undefined
  >
>({
  ref,
  id,
  name,
  selected,
  options,
  disabled,
  direction = 'row',
  gap = 'base',
  optionChildren,
  childDirection = 'row',
  childGap = 'base',
  handleChange,
  handleFocus,
  handleBlur,
}: InputRadioChildGroupProps<T>) => {
  return (
    <RadioGroup
      ref={ref}
      id={id}
      as="div"
      value={selected}
      onChange={disabled ? () => {} : handleChange}
      onFocus={disabled ? () => {} : handleFocus}
      onBlur={disabled ? () => {} : handleBlur}
      disabled={disabled}
      className={clsx(disabled && 'tw-opacity-30')}
    >
      <RadioGroup.Label className={clsx(['tw-sr-only tw-col-span-1'])}>
        {name}
      </RadioGroup.Label>
      <div
        className={clsx([
          'tw-flex',
          direction === 'col' && 'tw-flex-col',
          direction === 'row' && 'tw-flex-row tw-items-center',
          direction === 'spCol' &&
            'tw-flex-col lg:tw-flex-row lg:tw-items-center',
          GAP_CLASS_NAME({ direction })[gap],
        ])}
      >
        {options.map((option, index) => (
          <div
            key={`radio-group-item_${name}_${index}_${option.label}`}
            className={clsx(
              'tw-flex',
              childDirection === 'col' && 'tw-flex-col',
              childDirection === 'row' && 'tw-flex-row tw-items-center',
              childDirection === 'spCol' &&
                'tw-flex-col lg:tw-flex-row lg:tw-items-center',
              GAP_CLASS_NAME({ direction: childDirection })[childGap]
            )}
          >
            <InputRadioGroupItem option={option} />
            {optionChildren[option.value](selected === option.value)}
          </div>
        ))}
      </div>
    </RadioGroup>
  )
}

InputRadioChildGroup.displayName = 'InputRadioGroup'
