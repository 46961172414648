import { CartSalesTypeQuantity, SalesType } from '@/api/@types'

export const formatSalesTypeQuantities = ({
  quantities,
  master,
}: {
  quantities: CartSalesTypeQuantity[]
  master: SalesType[]
}) => {
  return quantities
    .filter((q) => {
      return master.find((m) => m.salesTypeCode === q.salesType)
    })
    .map((q) => ({
      ...q,
      price: `¥${q.purchasePrice.toLocaleString()}`,
      summaryPrice: `¥${(
        q.quantity * (q.purchasePrice ?? 0)
      ).toLocaleString()}`,
      salesTypeName:
        master.find((m) => m.salesTypeCode === q.salesType)?.salesTypeName ||
        '',
    }))
}
