import { ITermsOfService } from '@/types/generated/contentful'

export const contentfulTermsOfServiceKeyFactory = {
  all: [{ scope: 'contentfulTermsOfService' }] as const,
  termsOfServices: () =>
    [
      {
        ...contentfulTermsOfServiceKeyFactory.all[0],
        entity: 'termsOfServices',
      },
    ] as const,
}

export const contentfulPreviewTermsOfServiceKeyFactory = {
  all: [{ scope: 'contentfulPreviewTermsOfService' }] as const,
  termsOfService: ({ id }: { id: ITermsOfService['sys']['id'] }) =>
    [
      {
        ...contentfulPreviewTermsOfServiceKeyFactory.all[0],
        entity: 'termsOfService',
        id,
      },
    ] as const,
}
