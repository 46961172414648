import clsx from 'clsx'
import { RefObject, useEffect, useRef, useState } from 'react'

export const FloatingFooterSpacer = ({
  footerElementRef,
  defaultHeight = 0,
}: {
  footerElementRef?: RefObject<HTMLDivElement>
  defaultHeight?: number
}) => {
  const [height, setHeight] = useState(defaultHeight)

  const timerRef = useRef<any | null>(null)

  // on mount
  useEffect(() => {
    timerRef.current = setInterval(() => {
      const newHeight = footerElementRef?.current?.clientHeight || defaultHeight
      setHeight(newHeight)
    }, 500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // on willMount
  useEffect(
    () => () => {
      clearInterval(timerRef.current)
    },
    []
  )

  return (
    <div
      className={clsx('tw-transition-all')}
      style={{ height: `${height}px` }}
    />
  )
}
