import clsx from 'clsx'
import React, { useState, useEffect } from 'react'

import type { InputCurrencyRangeProps } from './type'

import { InputCurrency } from '@/components/ui'

export const InputCurrencyRange = ({
  value,
  height,
  isError,
  handleChange,
  handleFocus,
  handleBlur,
}: InputCurrencyRangeProps) => {
  const onChange = React.useCallback(
    (key: keyof typeof value, input: string) => {
      handleChange({
        ...value,
        [key]: input ? Number(input) : undefined,
      })
    },
    [value, handleChange]
  )

  const [isLowerActive, setIsLowerActive] = useState<boolean>(false)
  const [isUpperActive, setIsUpperActive] = useState<boolean>(false)

  useEffect(() => {
    if (isLowerActive || isUpperActive) {
      handleFocus && handleFocus()
    } else if (!isLowerActive && !isUpperActive) {
      handleBlur && handleBlur()
    }
  }, [isLowerActive, isUpperActive, handleFocus, handleBlur])

  return (
    <div className={clsx('tw-flex tw-items-center tw-space-x-4')}>
      <div className={clsx('tw-flex tw-items-end tw-space-x-2')}>
        <InputCurrency
          value={value.lower}
          height={height}
          isError={isError}
          placeholder="下限なし"
          onChange={(e) => onChange('lower', e.target.value)}
          onFocus={() => setIsLowerActive(true)}
          onBlur={() => setIsLowerActive(false)}
        />
        <span className={clsx('tw-text-xs')}>円</span>
      </div>

      <span className={clsx('tw-text-xs')}>～</span>

      <div className={clsx('tw-flex tw-items-end tw-space-x-2')}>
        <InputCurrency
          value={value.upper}
          height={height}
          isError={isError}
          placeholder="上限なし"
          onChange={(e) => onChange('upper', e.target.value)}
          onFocus={() => setIsUpperActive(true)}
          onBlur={() => setIsUpperActive(false)}
        />
        <span className={clsx('tw-text-xs')}>円</span>
      </div>
    </div>
  )
}
