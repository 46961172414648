import clsx from 'clsx'
import { useRef, useState } from 'react'

import { useInterval } from '@/hooks/util'

export const SlideIn = ({
  isOpen,
  children,
}: {
  isOpen: boolean
  children: React.ReactNode
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const [wrapperHeight, setWrapperHeight] = useState<number | null>(null)

  useInterval(() => {
    setWrapperHeight(ref.current?.clientHeight ?? null)
  }, 500)

  return (
    <div
      className={clsx('tw-overflow-y-hidden', 'tw-transition-all')}
      style={{
        height: isOpen ? wrapperHeight ?? 'auto' : '0px',
        opacity: isOpen ? 1 : 0,
      }}
    >
      <div ref={ref}>{children}</div>
    </div>
  )
}
