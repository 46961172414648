import { ErrorPage } from '@/components/pages/Error'
import { useMe } from '@/hooks/features/user'

import { WithRoleUploadOrderProps } from './type'

/**
 * 「仕入情報の表示権限」があるユーザーのみアクセス許可
 *
 * ※ 以前は「EC注文取込結果」も仕入情報の一部だったが、「注文取込」と同じ扱いに変更になった
 *
 * TODO:仕入情報はこちらの権限で利用制限する
 */
export const withRoleUploadOrder = (
  PageComponent: WithRoleUploadOrderProps
) => {
  const RoleUploadOrderPage = (props: any) => {
    const { data, isSuccess } = useMe({})

    if (!isSuccess) {
      return null
    }

    // 仕入情報の表示権限がない
    if (!data.uploadOrder) {
      return <ErrorPage statusCode={403} />
    }

    return <PageComponent {...props} />
  }

  return RoleUploadOrderPage
}
