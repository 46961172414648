import clsx from 'clsx'

import type { InputRadioGroupProps } from './type'

export const GAP_CLASS_NAME = ({
  direction,
}: Pick<InputRadioGroupProps, 'direction'>) => ({
  base: clsx(
    direction === 'col' && 'tw-space-y-2',
    direction === 'row' && 'tw-space-x-2',
    direction === 'spCol' && 'tw-space-y-2 lg:tw-space-y-0 lg:tw-space-x-2'
  ),
  lg: clsx(
    direction === 'col' && 'tw-space-y-4',
    direction === 'row' && 'tw-space-x-4',
    direction === 'spCol' && 'tw-space-y-4 lg:tw-space-y-0 lg:tw-space-x-4'
  ),
})
