import { Check } from '@mui/icons-material'
import clsx from 'clsx'
import * as React from 'react'

import type { InputSelectOptionContentProps } from './type'

export const InputSelectOptionContent = ({
  active,
  selected,
  option,
}: InputSelectOptionContentProps) => {
  return (
    <div
      className={clsx([
        active ? 'tw-bg-accentBlue-500 tw-text-white' : 'tw-text-black',
        'tw-relative tw-cursor-default tw-select-none tw-py-1 tw-px-2 tw-pr-8',
        'tw-transition-colors tw-duration-100',
        'hover:tw-cursor-pointer',
      ])}
    >
      <div className="tw-flex tw-items-center">
        <span
          className={clsx([
            selected ? 'tw-font-semibold' : 'tw-font-normal',
            'tw-block tw-truncate tw-text-xs',
          ])}
        >
          {option.label}
        </span>
      </div>
      {selected && (
        <span
          className={clsx([
            active ? 'tw-text-white' : 'tw-text-accentBlue-500',
            'tw-absolute',
            'tw-inset-y-0 tw-right-0',
            'tw-flex tw-items-center tw-px-2',
          ])}
        >
          <Check
            className={clsx([
              active ? 'tw-text-white' : 'tw-text-accentBlue-500',
            ])}
            sx={{ fontSize: 16 }}
            aria-hidden="true"
          />
        </span>
      )}
    </div>
  )
}

InputSelectOptionContent.displayName = 'InputSelectOptionContent'
