import type { WithSystemTypesForPurchaseProps } from './type'

import { LoadingOverlay } from '@/components/ui'

import { useCheckSystemTypesForPurchaseMaster } from './util'

export const withSystemTypesForPurchase = (
  PageComponent: WithSystemTypesForPurchaseProps
) => {
  const Enhanced = (props: any) => {
    const { isStored, isLoading } = useCheckSystemTypesForPurchaseMaster()

    if (isStored) {
      return <PageComponent {...props} />
    }

    if (isLoading) {
      return <LoadingOverlay isLoading={true} />
    }

    return null
  }

  return Enhanced
}
