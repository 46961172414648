import clsx from 'clsx'
import { useMemo, useRef, useState } from 'react'

import type { CopyFavoriteItemsModalProps } from './type'

import {
  Button,
  Dialog,
  InputSelectSingle,
  ErrorMessageList,
} from '@/components/ui'
import type { InputOption } from '@/types'

export const CopyFavoriteItemsModal = ({
  isOpen = false,
  currentFavorite,
  favorites = [],
  isMutatingCopy = false,
  isMutatingMove = false,
  errorMessages = [],
  onClose,
  onCopy,
  onMove,
}: CopyFavoriteItemsModalProps) => {
  const addCardButtonRef = useRef(null)
  const [selected, setSelected] = useState<InputOption<number>>({
    value: currentFavorite.setNo,
    label: currentFavorite.name,
  })

  const selectItems = useMemo(() => {
    return favorites
      .filter((favorite) => favorite.setNo !== currentFavorite.setNo)
      .map((favorite) => ({
        value: favorite.setNo,
        label: favorite.name,
      }))
  }, [currentFavorite, favorites])

  return (
    <Dialog
      title="フォルダの移動"
      isOpen={isOpen}
      initialFocus={addCardButtonRef}
    >
      <div className={clsx('tw-mt-2 tw-px-2 tw-pb-4')}>
        {errorMessages.length > 0 && (
          <div className={clsx('tw-mb-2 tw-text-center')}>
            <ErrorMessageList errorMessages={errorMessages} />
          </div>
        )}

        <p className={clsx('tw-text-center tw-text-sm')}>
          選択されている商品を移動またはコピーするフォルダを選択してください。
        </p>

        <div
          className={clsx(
            'tw-mt-4 tw-rounded tw-bg-primary-50 tw-px-4 tw-py-8'
          )}
        >
          <InputSelectSingle<number>
            height="md"
            selected={selected}
            options={selectItems}
            buttonLabel={'111'}
            handleChange={(value) => setSelected(value)}
          />
        </div>

        <div className={clsx('tw-mt-5 tw-w-full')}>
          <div
            className={clsx('tw-flex tw-w-full tw-items-center tw-space-x-2')}
          >
            <Button
              ref={addCardButtonRef}
              variant="secondary"
              isLoading={isMutatingCopy}
              disabled={isMutatingCopy || isMutatingMove}
              onClick={() => onCopy(selected.value)}
            >
              {'コピーする'}
            </Button>
            <Button
              ref={addCardButtonRef}
              isLoading={isMutatingMove}
              disabled={
                isMutatingCopy ||
                isMutatingMove ||
                !currentFavorite.isEditableByMe
              }
              onClick={() => onMove(selected.value)}
            >
              {'移動する'}
            </Button>
          </div>
          <div className={clsx('tw-mt-4 tw-w-full')}>
            <Button
              size="md"
              variant="normal"
              disabled={isMutatingCopy || isMutatingMove}
              onClick={onClose}
            >
              キャンセル
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  )
}
