import clsx from 'clsx'

import type { SectionWrapperProps } from './type'

export const SPACING_SP_X = {
  none: 'none',
  sm: 'sm',
} as const

export const SPACING_PC_X = {
  none: 'none',
} as const

export const SPACING_SP_Y = {
  none: 'none',
  base: 'base',
} as const

export const SPACING_PC_Y = {
  none: 'none',
  base: 'base',
} as const

export const SPACING_CLASS_NAME = ({
  spacing,
}: Pick<SectionWrapperProps, 'spacing'>) => {
  return clsx(
    spacing?.x?.sp === 'sm' && 'tw-px-2',
    'lg:tw-px-0',
    spacing?.y?.sp === 'base' && 'tw-py-4',
    spacing?.y?.pc === 'base' && 'lg:tw-py-4',
    (!spacing?.y?.pc || spacing?.y?.pc === 'none') && 'lg:tw-py-0'
  )
}
