import clsx from 'clsx'
import { useMemo } from 'react'

import type { FormFieldWrapperProps } from './type'

import { layoutClassName } from './const'

export const FormFieldWrapper = ({
  id,
  label,
  error,
  showLabel = true,
  showError = true,
  showErrorOnlyOccered = false,
  required = false,
  children,
}: FormFieldWrapperProps) => {
  const displayError = useMemo(
    () => (Array.isArray(error) ? error[0] : error),
    [error]
  )

  const showErrorMessage = useMemo(() => {
    if (!showError) return false
    if (!displayError && showErrorOnlyOccered) return false

    return true
  }, [displayError, showError, showErrorOnlyOccered])

  return (
    <div>
      {showLabel && (
        <label htmlFor={id} className={clsx(layoutClassName.label)}>
          <span className={clsx('tw-text-xs tw-font-bold tw-text-black')}>
            {label}
          </span>
          {required && (
            <span className={clsx('tw-text-xs tw-font-bold tw-text-alert-500')}>
              ※必須
            </span>
          )}
        </label>
      )}
      <div>{children}</div>
      {showErrorMessage && (
        <p
          role="alert"
          aria-label={displayError?.message}
          className={clsx(
            layoutClassName.error,
            'tw-text-sm tw-font-bold tw-text-alert-500'
          )}
        >
          {displayError?.message}
        </p>
      )}
    </div>
  )
}
