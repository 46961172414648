import clsx from 'clsx'
import { useMemo } from 'react'

import { PriceProps } from './type'

export const ItemPrice = ({
  item,
  cartSalesTypeQuantities,
  direction,
  showCount = true,
}: PriceProps) => {
  const count = useMemo(
    () =>
      cartSalesTypeQuantities
        ? cartSalesTypeQuantities.reduce(
            (sum, current) => (sum += current.quantity),
            0
          )
        : 0,
    [cartSalesTypeQuantities]
  )

  const summarySalonPrice = useMemo(() => {
    if (!item.salonPrice || !cartSalesTypeQuantities) {
      return 0
    }

    return item.salonPrice * count
  }, [item, cartSalesTypeQuantities, count])

  switch (direction) {
    case 'horizontal': {
      return (
        <div
          className={clsx('tw-flex tw-flex-wrap tw-items-center', 'tw-gap-x-1')}
        >
          <div
            className={clsx(
              'tw-flex tw-flex-wrap tw-items-center',
              'tw-gap-x-4'
            )}
          >
            {showCount && (
              <p className={clsx('tw-text-xs tw-text-gray-600')}>
                {`数量：${count}点`}
              </p>
            )}

            <p className={clsx('tw-text-xs tw-text-gray-600')}>
              {`サロン価格：${(item.salonPrice || 0).toLocaleString()}円`}
            </p>
            {cartSalesTypeQuantities && (
              <p className={clsx('tw-text-xs tw-text-gray-600')}>
                {`小計：${summarySalonPrice.toLocaleString()}円`}
              </p>
            )}
          </div>
        </div>
      )
    }
    case 'vertical': {
      return (
        <table className={clsx('tw-text-sm', 'tw-table-fix', 'tw-w-full')}>
          {showCount && (
            <tr>
              <td>数量</td>
              <td className={clsx('tw-min-w-[120px] tw-text-right')}>
                {count}点
              </td>
            </tr>
          )}
          <tr className={clsx('tw-text-gray-600')}>
            <td>サロン価格</td>
            <td className={clsx('tw-min-w-[120px] tw-text-right')}>{`${(
              item.salonPrice || 0
            ).toLocaleString()}円`}</td>
          </tr>
          {cartSalesTypeQuantities && (
            <tr className={clsx('tw-text-gray-600')}>
              <td>小計</td>
              <td
                className={clsx('tw-min-w-[120px] tw-text-right')}
              >{`${summarySalonPrice.toLocaleString()}円`}</td>
            </tr>
          )}
        </table>
      )
    }
    default: {
      return <></>
    }
  }
}
