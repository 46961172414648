import { pascalCase } from 'change-case'

import type { ItemListSortInputOption } from './type'

import { ITEM_FIELDS } from '@/const'

const optionValue = (field: string, sortDirection: string) => {
  return `${field}${pascalCase(sortDirection)}`
}

export const fields = {
  brandNameAsc: {
    field: 'brandName',
    sortDirection: 'asc',
    label: `${ITEM_FIELDS['brandName']}の昇順`,
  },
  brandNameDesc: {
    field: 'brandName',
    sortDirection: 'desc',
    label: `${ITEM_FIELDS['brandName']}の降順`,
  },
  itemNameAsc: {
    field: 'itemName',
    sortDirection: 'asc',
    label: `${ITEM_FIELDS['itemName']}の昇順`,
  },
  itemNameDesc: {
    field: 'itemName',
    sortDirection: 'desc',
    label: `${ITEM_FIELDS['itemName']}の降順`,
  },
  salonPriceAsc: {
    field: 'salonPrice',
    sortDirection: 'asc',
    label: `${ITEM_FIELDS['salonPrice']}が低い順`,
  },
  salonPriceDesc: {
    field: 'salonPrice',
    sortDirection: 'desc',
    label: `${ITEM_FIELDS['salonPrice']}が高い順`,
  },
  salesStartDateAsc: {
    field: 'salesStartDate',
    sortDirection: 'asc',
    label: `${ITEM_FIELDS['salesStartDate']}古い順`,
  },
  brandStartDateDesc: {
    field: 'salesStartDate',
    sortDirection: 'desc',
    label: `${ITEM_FIELDS['salesStartDate']}最新順`,
  },
}

export const options: ItemListSortInputOption[] = [
  {
    label: fields.brandNameAsc.label,
    value: optionValue(
      fields.brandNameAsc.field,
      fields.brandNameAsc.sortDirection
    ),
  },
  {
    label: fields.brandNameDesc.label,
    value: optionValue(
      fields.brandNameDesc.field,
      fields.brandNameDesc.sortDirection
    ),
  },
  {
    label: fields.itemNameAsc.label,
    value: optionValue(
      fields.itemNameAsc.field,
      fields.itemNameAsc.sortDirection
    ),
  },
  {
    label: fields.itemNameDesc.label,
    value: optionValue(
      fields.itemNameDesc.field,
      fields.itemNameDesc.sortDirection
    ),
  },
  {
    label: fields.salonPriceAsc.label,
    value: optionValue(
      fields.salonPriceAsc.field,
      fields.salonPriceAsc.sortDirection
    ),
  },
  {
    label: fields.salonPriceDesc.label,
    value: optionValue(
      fields.salonPriceDesc.field,
      fields.salonPriceDesc.sortDirection
    ),
  },
  {
    label: fields.salesStartDateAsc.label,
    value: optionValue(
      fields.salesStartDateAsc.field,
      fields.salesStartDateAsc.sortDirection
    ),
  },
  {
    label: fields.brandStartDateDesc.label,
    value: optionValue(
      fields.brandStartDateDesc.field,
      fields.brandStartDateDesc.sortDirection
    ),
  },
]
