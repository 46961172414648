import clsx from 'clsx'
import { useMemo } from 'react'

import type { PageInfoProps } from './type'

export const PageInfo = ({ totalCount, perPage, page }: PageInfoProps) => {
  const { from, to } = useMemo(() => {
    const from = perPage * (page - 1) + 1
    const to = Math.min(perPage * page, totalCount)
    return { from, to }
  }, [perPage, page, totalCount])

  if (page < 1 || totalCount < from) return null

  return (
    <p
      className={clsx('tw-w-full tw-text-center tw-text-sm')}
    >{`全${totalCount}件中${from}-${to}件`}</p>
  )
}
