import type { Item } from '@/api/@types'
import type { Methods as ItemsMethods } from '@/api/v1/items'
import type { Methods as ItemsCountMethods } from '@/api/v1/items/count'

export const itemKeyFactory = {
  all: [{ scope: 'item' }] as const,
  items: ({ queryParams }: { queryParams: ItemsMethods['get']['query'] }) =>
    [{ ...itemKeyFactory.all[0], entity: 'items', queryParams }] as const,
  itemsCount: ({
    queryParams,
  }: {
    queryParams: ItemsCountMethods['get']['query']
  }) =>
    [{ ...itemKeyFactory.all[0], entity: 'itemsCount', queryParams }] as const,
  detail: (ItemCode: Item['itemCode']) =>
    [{ ...itemKeyFactory.all[0], entity: 'detail', ItemCode }] as const,
}
