import { useController } from 'react-hook-form'

import type { FormFieldPasswordProps } from './type'

import { FormFieldWrapper, InputPassword } from '@/components/ui'

export const FormFieldPassword = (props: FormFieldPasswordProps) => {
  const {
    label,
    required,
    name,
    control,
    error,
    showLabel,
    showError,
    onFocus,
    ...inputProps
  } = props
  const {
    field: { onChange, onBlur, value },
  } = useController({ name, control })

  const handleChange: React.InputHTMLAttributes<HTMLInputElement>['onChange'] =
    (e) => {
      onChange && onChange(e)
      props.onChange && props.onChange(e)
    }

  const handleFocus: React.InputHTMLAttributes<HTMLInputElement>['onFocus'] = (
    e
  ) => {
    onFocus && onFocus(e)
  }
  const handleBlur: React.InputHTMLAttributes<HTMLInputElement>['onBlur'] = (
    e
  ) => {
    onBlur && onBlur()
    props.onBlur && props.onBlur(e)
  }

  return (
    <FormFieldWrapper
      id={name}
      label={label}
      required={required}
      error={error}
      showLabel={showLabel}
      showError={showError}
    >
      <InputPassword
        data-testid={name}
        {...inputProps}
        value={value}
        id={name}
        isError={error !== undefined}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
    </FormFieldWrapper>
  )
}
