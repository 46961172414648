import clsx from 'clsx'

import type { HeaderColumnProps } from './type'

export const HeaderColumn = ({
  children,
  isFlex = true,
  justify = 'start',
}: HeaderColumnProps) => {
  return (
    <div
      className={clsx(
        'tw-flex tw-items-center tw-gap-4',
        isFlex ? clsx('tw-w-full') : clsx('tw-flex-none'),
        justify === 'center'
          ? 'tw-justify-center'
          : justify === 'end'
          ? 'tw-justify-end'
          : 'tw-justify-start'
      )}
    >
      {children}
    </div>
  )
}
