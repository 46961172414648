import { useCallback, useRef } from 'react'

type Debounce = (callback: () => void) => void

/**
 *
 * @param timeout milliseconds
 */
export const useDebounce = (timeout: number): Debounce => {
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null)
  const debounce: Debounce = useCallback(
    (callback) => {
      if (timer.current) {
        clearTimeout(timer.current)
      }
      timer.current = setTimeout(() => {
        callback()
      }, timeout)
    },
    [timeout]
  )
  return debounce
}
