import clsx from 'clsx'
import { forwardRef } from 'react'

import type { FavoriteButtonProps } from './type'
import type { ReactNode } from 'react'

import { AddFavoriteModal } from '@/components/features/item'
import { FavoriteIcon } from '@/components/icons'
import { Button } from '@/components/ui'
import { useModal } from '@/hooks/util'

const IconWithState = ({
  icon,
  disabled,
}: { icon: ReactNode } & Pick<FavoriteButtonProps, 'disabled'>) => {
  return <span className={clsx(disabled && 'tw-opacity-disabled')}>{icon}</span>
}

export const FavoriteButton = forwardRef<
  HTMLButtonElement,
  FavoriteButtonProps
>(
  (
    {
      type = 'simple',
      isFavorite = false,
      selectedItemCodes,
      disabled = false,
      isSpTypeIcon = false,
      onChange,
    },
    ref
  ) => {
    const { isOpen, open, close } = useModal()
    return (
      <>
        {type === 'textButton' || type === 'iconButton' ? (
          <Button
            type="button"
            variant="secondary"
            disabled={disabled || !selectedItemCodes.length}
            onClick={open}
          >
            {type === 'textButton' ? (
              <>
                <span className={clsx('tw-flex tw-items-center tw-gap-2')}>
                  {isSpTypeIcon && (
                    <IconWithState
                      icon={
                        <FavoriteIcon type={isFavorite ? 'filled' : 'empty'} />
                      }
                      disabled={disabled}
                    />
                  )}
                  <span
                    className={clsx(
                      isSpTypeIcon && 'tw-hidden lg:tw-inline-block'
                    )}
                  >
                    お気に入りに追加する
                  </span>
                </span>
              </>
            ) : (
              <IconWithState
                icon={<FavoriteIcon type={isFavorite ? 'filled' : 'empty'} />}
                disabled={disabled}
              />
            )}
          </Button>
        ) : (
          <button
            type="button"
            className={clsx(
              'tw-flex tw-items-center tw-justify-center',
              'tw-transition-opacity tw-duration-stateChange',
              'hover:tw-opacity-hover'
            )}
            disabled={disabled || !selectedItemCodes.length}
            onClick={open}
            ref={ref}
          >
            <IconWithState
              icon={<FavoriteIcon type={isFavorite ? 'filled' : 'empty'} />}
              disabled={disabled}
            />
          </button>
        )}
        <AddFavoriteModal
          isOpen={isOpen}
          selectedItemCodes={selectedItemCodes}
          onChange={onChange}
          onClose={close}
        />
      </>
    )
  }
)

FavoriteButton.displayName = 'FavoriteButton'
