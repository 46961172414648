import * as z from 'zod'

import { SHIPPING_ADDRESS_TITLE } from '@/const/label'
import { REGEX_PATTERNS } from '@/const/regex'
import { useTextWidthConvertor } from '@/hooks/util'

import { ShippingAddressFormVO } from './type'

const MAX_LENGTH = {
  address: 40,
  tel: 20,
  name: 100,
}

const generateAddressValidateMatchValue = (value: string) => {
  const emojiReg = value.match(REGEX_PATTERNS.emoji)
  const halfWidthReg = value.match(REGEX_PATTERNS.halfWidth.exist)

  if (!!emojiReg) {
    return `絵文字を入力することはできません（検知された文字：${emojiReg[0]}）`
  }

  if (halfWidthReg) {
    return `すべて全角で入力してください（検知された文字：${halfWidthReg[0]}）`
  }

  return '入力できない文字が含まれています'
}

export const schema = z.object({
  zipCode: z
    .string()
    .min(1, '必須項目です')
    .regex(REGEX_PATTERNS.zipCode, '郵便番号が正しくありません'),
  address1: z
    .string()
    .min(1, '必須項目です')
    .max(MAX_LENGTH.address, `最大文字数は${MAX_LENGTH.address}文字です`)
    .refine(
      (v) => {
        const { toFullWidth } = useTextWidthConvertor()
        const fixedValue = toFullWidth(v)
        return (
          !fixedValue.match(REGEX_PATTERNS.emoji) &&
          !fixedValue.match(REGEX_PATTERNS.halfWidth.exist)
        )
      },
      (v) => {
        const { toFullWidth } = useTextWidthConvertor()
        return {
          message: generateAddressValidateMatchValue(toFullWidth(v)),
        }
      }
    ),
  address2: z
    .string()
    .max(MAX_LENGTH.address, `最大文字数は${MAX_LENGTH.address}文字です`)
    .refine(
      (v) => {
        const { toFullWidth } = useTextWidthConvertor()
        const fixedValue = toFullWidth(v)
        return (
          !fixedValue.match(REGEX_PATTERNS.emoji) &&
          !fixedValue.match(REGEX_PATTERNS.halfWidth.exist)
        )
      },
      (v) => {
        const { toFullWidth } = useTextWidthConvertor()
        return {
          message: generateAddressValidateMatchValue(toFullWidth(v)),
        }
      }
    ),
  tel: z
    .string()
    .min(1, '必須項目です')
    .max(MAX_LENGTH.tel, `最大文字数は${MAX_LENGTH.tel}文字です`),
  deliveryName: z
    .string()
    .min(1, '必須項目です')
    .max(MAX_LENGTH.name, `最大文字数は${MAX_LENGTH.name}文字です`)
    .refine(
      (v) => {
        const { toFullWidth } = useTextWidthConvertor()
        const fixedValue = toFullWidth(v)
        return (
          !fixedValue.match(REGEX_PATTERNS.emoji) &&
          !fixedValue.match(REGEX_PATTERNS.halfWidth.exist)
        )
      },
      (v) => {
        const { toFullWidth } = useTextWidthConvertor()
        return {
          message: generateAddressValidateMatchValue(toFullWidth(v)),
        }
      }
    ),
})

export const formLabels: Record<keyof ShippingAddressFormVO, string> = {
  zipCode: '郵便番号',
  address1: '住所１',
  address2: '住所２',
  tel: 'TEL',
  deliveryName: SHIPPING_ADDRESS_TITLE,
}

export const placeholders: Record<keyof ShippingAddressFormVO, string> = {
  zipCode: '123-1234',
  address1: '東京都渋谷区恵比寿西1-13-7',
  address2: 'KB’s ARCH',
  tel: '01212345678',
  deliveryName: 'DEMO SALON',
}
