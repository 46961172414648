import { useQuery } from 'react-query'

import { contentfulClient } from '@/lib/contentful'
import { CONTENT_TYPES, SORT_KEY } from '@/lib/contentful/const'
import { QueryConfig } from '@/lib/react-query'
import type { IMaintenancePageFields } from '@/types/generated/contentful'

import { contentfulMaintenanceKeyFactory } from './key'

// 1件しか取得しないが配列で返ってくるので複数形にする
const getData = () => {
  return contentfulClient.getEntries<IMaintenancePageFields>({
    content_type: CONTENT_TYPES.maintenancePage,
    order: SORT_KEY.maintenancePage,
    limit: 1,
  })
}

type UseContentfulMaintenanceOptions = {
  config?: QueryConfig<typeof getData>
}

export const useContentfulMaintenance = ({
  config,
}: UseContentfulMaintenanceOptions) => {
  const queryConfig: QueryConfig<typeof getData> = {
    ...config,
    queryKey: contentfulMaintenanceKeyFactory.maintenance(),
    queryFn: () => getData(),
  }
  return useQuery(queryConfig)
}
