import clsx from 'clsx'

import { SectionSubTitleProps } from './type'

export const SectionSubTitle = ({ children }: SectionSubTitleProps) => {
  return (
    <h4 className={clsx('tw-text-xs tw-font-bold', 'tw-mb-1 tw-mt-2')}>
      {children}
    </h4>
  )
}
