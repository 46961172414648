import * as z from 'zod'

const MIN_LENGTH = {
  quantity: 0,
}
const MAX_LENGTH = {
  quantity: 999,
  note: 20,
}

export const schema = z.object({
  items: z.array(
    z.object({
      itemCode: z.string(),
      salesTypeQuantities: z.array(
        z.object({
          salesType: z.string().nonempty(),
          quantity: z
            .number({ invalid_type_error: '必須項目です' })
            .min(MIN_LENGTH.quantity, `${MIN_LENGTH.quantity}未満です`)
            .max(MAX_LENGTH.quantity, `${MAX_LENGTH.quantity}を超えています`),
          note: z
            .string()
            .max(MAX_LENGTH.note, `${MAX_LENGTH.note}文字を超えています`)
            .default(''),
        })
      ),
    })
  ),
})

// Note: １商品単体での更新を行うため、インデックスは常に０
export const EDIT_MODAL_FORM_ITEM_INDEX = 0
