import { AddShoppingCart } from '@mui/icons-material'
import clsx from 'clsx'
import { forwardRef } from 'react'

import type { AddCartButtonProps } from './type'

import { Button, Dialog } from '@/components/ui'
import { CART_TITLE } from '@/const'
import { useModal } from '@/hooks/util'

import { AddCartForm } from './AddCartForm'

export const AddCartButton = forwardRef<HTMLButtonElement, AddCartButtonProps>(
  ({ items, disabled = false }, ref) => {
    const { close, open, isOpen } = useModal()

    return (
      <>
        <Button
          ref={ref}
          disabled={disabled}
          isBlock={true}
          startIcon={
            <AddShoppingCart
              className={clsx('tw-text-white')}
              sx={{ fontSize: 24 }}
            />
          }
          onClick={open}
        >
          {`${CART_TITLE}に追加する`}
        </Button>

        <Dialog title="発注内容の編集" isOpen={isOpen}>
          {!!items.length && (
            <AddCartForm items={items} onSubmit={close} onClickCancel={close} />
          )}
        </Dialog>
      </>
    )
  }
)

AddCartButton.displayName = 'AddCartButton'
