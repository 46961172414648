import type { BaseVO } from './type'
import type { UseFormStateReturn } from 'react-hook-form'

export const extractError = (
  errors: UseFormStateReturn<BaseVO>['errors'],
  itemIndex: number,
  unitIndex: number
) => {
  // HACK: itemIndex の存在判定をしているため、！で回避
  return !!errors['items'] &&
    !!errors['items'][itemIndex] &&
    !!errors['items'][itemIndex]!['salesTypeQuantities']
    ? errors['items'][itemIndex]!['salesTypeQuantities']![unitIndex]
    : undefined
}

export const getErrorMessage = (error: ReturnType<typeof extractError>) => {
  if (!error) return undefined

  const { salesType, quantity, note } = error
  let errorMessage = ''

  if (salesType && salesType.message) {
    errorMessage += `売上種別：${salesType.message}\n`
  }
  if (quantity && quantity.message) {
    errorMessage += `数量：${quantity.message}\n`
  }
  if (note && note.message) {
    errorMessage += `メモ：${note.message}`
  }
  return errorMessage
}
