import { useEffect } from 'react'

export const useInterval = (
  callback: Function,
  delay?: number,
  enabled: boolean = true
) => {
  useEffect(() => {
    if (enabled) {
      const interval = setInterval(() => callback(), delay || 1000)
      return () => clearInterval(interval)
    }
  }, [callback, delay, enabled])
}
