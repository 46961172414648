import clsx from 'clsx'
import { useMemo, useRef } from 'react'
import { useOnClickOutside } from 'usehooks-ts'

import type { CartDateInfoProps } from './type'

import { CartDateErrorMessage } from '@/components/features/cart'
import { InputCalendar, TextButton } from '@/components/ui'
import { DATE_FORMAT } from '@/const'
import { useDisclosure } from '@/hooks/util'
import { formatDate as f } from '@/utils/date'

const formatDate = (date?: string) => {
  return date ? f(date, DATE_FORMAT.DISPLAY_YYYYMMDDE) : ''
}

export const CartDateInfo = ({
  regularDeliveryDate,
  desiredDeliveryDate,
  earliestDeliveryDate,
  latestDeliveryDate,
  isDesiredDeliveryDateError,
  updateErrorMessage,
  onChangeDeliveryDate,
}: CartDateInfoProps) => {
  const calendar = useDisclosure()

  const wrapperRef = useRef(null)
  useOnClickOutside(wrapperRef, () => {
    if (calendar.isOpen) {
      calendar.close()
    }
  })

  const handleChangeDesiredDeliveryDate = (date: string | undefined) => {
    if (!date) return
    onChangeDeliveryDate(date)
    calendar.close()
  }

  const errorMessage = useMemo(() => {
    if (isDesiredDeliveryDateError) {
      return <CartDateErrorMessage />
    }
  }, [isDesiredDeliveryDateError])

  return (
    <>
      <div className={clsx('tw-mt-2')}>
        <h4 className={clsx('tw-text-xs tw-font-bold', 'tw-pb-1')}>
          ご希望納品日
        </h4>
        <div
          className={clsx(
            'tw-border tw-border-gray-300',
            'tw-rounded-sm',
            'tw-px-2 tw-py-3',
            'tw-flex tw-items-stretch tw-justify-between'
          )}
        >
          <div className={clsx('tw-space-y-1')}>
            <span className={clsx('tw-text-sm tw-text-alert-500')}>
              {errorMessage || updateErrorMessage}
            </span>
            <p
              className={clsx(
                'tw-text-sm',
                (errorMessage || updateErrorMessage) && 'tw-text-alert-500'
              )}
            >
              {formatDate(desiredDeliveryDate || regularDeliveryDate)}
            </p>
            <p className={clsx('tw-text-xs tw-text-gray-600')}>
              レギュラー納品予定日：{formatDate(regularDeliveryDate)}
            </p>
          </div>
          <div
            className={clsx(
              'tw-ml-2 tw-pl-3',
              'tw-flex-shrink-0',
              'tw-border-l tw-border-gray-300',
              'tw-flex tw-items-center'
            )}
          >
            <TextButton onClick={calendar.toggle} tabIndex={-1}>
              変更
            </TextButton>
            {calendar.isOpen && (
              <div className={clsx('tw-relative', 'tw-z-parts')}>
                <div
                  className={clsx('tw-absolute tw-right-0 tw-top-0 tw-mt-8')}
                >
                  <InputCalendar
                    value={desiredDeliveryDate || regularDeliveryDate}
                    onChange={handleChangeDesiredDeliveryDate}
                    minDate={earliestDeliveryDate}
                    maxDate={latestDeliveryDate}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
