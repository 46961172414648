import { useMemo } from 'react'

import { Dnd, ListOperationTitleSection } from '@/components/ui'

import { SortableItem } from './SortableItem'
import { ListSortableProps } from './type'

export const ListSortable = ({
  control,
  register,
  items,
  onChangeItemSortOrder,
}: ListSortableProps) => {
  const itemsWithId = useMemo(() => {
    return items.map((i, index) => ({ ...i, id: `${i.itemCode}-${index}` }))
  }, [items])

  return (
    <>
      <ListOperationTitleSection>
        <p>並び替え</p>
      </ListOperationTitleSection>
      <Dnd items={itemsWithId} onChange={onChangeItemSortOrder}>
        {(item, index) => (
          <SortableItem
            control={control}
            register={register}
            item={item}
            index={index}
          />
        )}
      </Dnd>
    </>
  )
}
