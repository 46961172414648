export const PURCHASED_INFO_TITLE = '仕入情報'

export const PURCHASED_INFO_SUB_PAGE_TITLES = {
  purchasedInfoSalesDetail: '明細',
  purchasedInfoBilledAmounts: '請求金額一覧',
  purchasedInfoMakers: 'メーカー別',
  purchasedInfoMakersStores: 'メーカー別店舗別',
  purchasedInfoStores: '店舗別',
  purchasedInfoStoresSalesTypes: '店舗別売上種別別',
  purchasedInfoStoresMakersSalesTypes: '店舗別メーカー別売上種別別',
}
