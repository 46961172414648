import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import NextLink from 'next/link'
import React, { useState } from 'react'

import type { Block, Inline } from '@contentful/rich-text-types'

import { ImageModal } from '@/components/ui'
import { ROUTE } from '@/const'
import { CONTENT_TYPES } from '@/lib/contentful/const'

import style from '../style.module.css'

const Link = (node: Block | Inline) => {
  if (!(node.data.target?.sys?.id && node.data.target?.fields?.title)) {
    return null
  }

  if (node.data.target?.sys?.contentType?.sys?.id === CONTENT_TYPES.post) {
    return (
      <NextLink href={ROUTE.guideDetail(node.data.target.sys.id)}>
        <a className={style.a}>{node.data.target.fields.title}</a>
      </NextLink>
    )
  }

  if (
    node.data.target?.sys?.contentType?.sys?.id === CONTENT_TYPES.privacyPolicy
  ) {
    return (
      <NextLink href={ROUTE.privacyPolicy}>
        <a className={style.a}>{node.data.target.fields.title}</a>
      </NextLink>
    )
  }

  if (
    node.data.target?.sys?.contentType?.sys?.id === CONTENT_TYPES.termsOfService
  ) {
    return (
      <NextLink href={ROUTE.termsOfService}>
        <a className={style.a}>{node.data.target.fields.title}</a>
      </NextLink>
    )
  }

  if (node.data.target?.sys?.contentType?.sys?.id === CONTENT_TYPES.tokushoho) {
    return (
      <NextLink href={ROUTE.tokushoho}>
        <a className={style.a}>{node.data.target.fields.title}</a>
      </NextLink>
    )
  }

  return (
    <NextLink href={ROUTE[404]}>
      <a className={style.a}>{node.data.target.fields.title}</a>
    </NextLink>
  )
}

export const renderNode: Options['renderNode'] = {
  [BLOCKS.EMBEDDED_ASSET]: (node, _children) => {
    const [isOpen, setIsOpen] = useState<boolean>(false)

    return (
      <>
        <div className={style.img} onClick={() => setIsOpen(true)}>
          <img
            src={`https://${node.data.target.fields.file.url}`}
            height={node.data.target.fields.file.details.image.height}
            width={node.data.target.fields.file.details.image.width}
            alt={node.data.target.fields.description}
          />
        </div>
        <ImageModal
          isOpen={isOpen}
          image={{
            src: `https://${node.data.target.fields.file.url}`,
            alt: node.data.target.fields.description,
          }}
          onClose={() => setIsOpen(false)}
        />
      </>
    )
  },
  [BLOCKS.HEADING_1]: (_node, children) => {
    return <p className={style.h1}>{children}</p>
  },
  [BLOCKS.HEADING_2]: (_node, children) => {
    return <p className={style.h2}>{children}</p>
  },
  [BLOCKS.HEADING_3]: (_node, children) => {
    return <p className={style.h3}>{children}</p>
  },
  [BLOCKS.HEADING_4]: (_node, children) => {
    return <p className={style.h4}>{children}</p>
  },
  [BLOCKS.HEADING_5]: (_node, children) => {
    return <p className={style.h5}>{children}</p>
  },
  [BLOCKS.HEADING_6]: (_node, children) => {
    return <p className={style.h6}>{children}</p>
  },
  [BLOCKS.PARAGRAPH]: (_node, children) => {
    const convertedChildren = Array.isArray(children)
      ? children.map((child) => {
          return typeof child === 'string'
            ? convertStringToMultiLineNode(child) // \r\n を <br/> に置き換え
            : child
        })
      : children
    return <p className={style.p}>{convertedChildren}</p>
  },
  [BLOCKS.TABLE]: (_node, children) => {
    return (
      <div className={style.tableWrapper}>
        <table>
          <tbody>{children}</tbody>
        </table>
      </div>
    )
  },
  [INLINES.HYPERLINK]: (node, children) => {
    return node.data.uri ? (
      <a href={node.data.uri} className={style.a}>
        {children}
      </a>
    ) : null
  },
  [INLINES.ENTRY_HYPERLINK]: (node) => Link(node),
  [INLINES.EMBEDDED_ENTRY]: (node) => Link(node),
  [BLOCKS.EMBEDDED_ENTRY]: (node) => Link(node),
}

/**
 * 受け取ったテキストの \r\n を <br/> に置き換えたNodeを返す
 */
function convertStringToMultiLineNode(str: string) {
  const lines = str.split('\r\n')
  return (
    <>
      {lines.map((line, i) => {
        return i < lines.length - 1 ? (
          <React.Fragment key={i}>
            {line}
            <br />
          </React.Fragment>
        ) : (
          line
        )
      })}
    </>
  )
}
