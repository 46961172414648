import { ContentCopy, Delete, Edit } from '@mui/icons-material'
import clsx from 'clsx'
import React from 'react'

import type { FavoriteCardOperationProps } from './type'

import { FavoriteCardOperationListItem } from './FavoriteCardOperationListItem'

export const FavoriteCardOperation = ({
  isEditableByMe,
  isDepositStore,
  canDuplicateByMe,
  onClickCopy,
  onClickDelete,
  onClickEdit,
}: FavoriteCardOperationProps) => {
  return (
    <ul
      className={clsx(
        'tw-overflow-hidden tw-rounded',
        'tw-divide-y tw-divide-gray-300'
      )}
    >
      {canDuplicateByMe && (
        <FavoriteCardOperationListItem
          icon={<ContentCopy sx={{ fontSize: 20 }} />}
          text={'複製'}
          onClick={onClickCopy}
        />
      )}
      {isEditableByMe && (
        <>
          <FavoriteCardOperationListItem
            icon={<Delete sx={{ fontSize: 20 }} />}
            text={'削除'}
            onClick={onClickDelete}
          />
          <FavoriteCardOperationListItem
            icon={<Edit sx={{ fontSize: 20 }} />}
            text={isDepositStore ? 'タイトル編集・共有設定' : 'タイトル編集'}
            onClick={onClickEdit}
          />
        </>
      )}
    </ul>
  )
}
