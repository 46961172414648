import { useMutation } from 'react-query'

import type { Favorite } from '@/api/@types'
import { Methods } from '@/api/v1/favorites/_setNo@number/_storeCode@string/items'
import { aspidaClient } from '@/lib/aspida'
import { MutationConfig } from '@/lib/react-query'

export type UpdateFavoriteItemsDTO = {
  setNo: Favorite['setNo']
  storeCode: Favorite['storeCode']
  data: Methods['put']['reqBody']
}

export const updateFavoriteItems = ({
  setNo,
  storeCode,
  data,
}: UpdateFavoriteItemsDTO) => {
  return aspidaClient.v1.favorites
    ._setNo(setNo)
    ._storeCode(storeCode)
    .items.$put({ body: data })
}

type UseUpdateFavoriteItemsOptions = {
  config?: MutationConfig<typeof updateFavoriteItems>
}

export const useUpdateFavoriteItems = ({
  config,
}: UseUpdateFavoriteItemsOptions) => {
  return useMutation({
    ...config,
    mutationFn: updateFavoriteItems,
  })
}
