import clsx from 'clsx'
import { useRef, useState } from 'react'

import { ActionFloatingFooter } from '@/components/layout'
import { FloatingFooterSpacer } from '@/components/layout/FloatingFooterSpacer'
import { Button, InputToggle, SlideIn } from '@/components/ui'

import { CartPaymentTable } from './CartPaymentTable'
import { CartPaymentFooterProps } from './type'

export const CartPaymentFooter = ({
  salonPrice,
  purchasePrice,
  disabledOrder,
  onSubmit,
}: CartPaymentFooterProps) => {
  const [isOpen, setOpen] = useState(true)
  const ref = useRef<HTMLDivElement>(null)

  return (
    <>
      <FloatingFooterSpacer footerElementRef={ref} />
      <ActionFloatingFooter inputRef={ref}>
        <div ref={ref}>
          <div className={clsx('tw-w-full')}>
            <SlideIn isOpen={isOpen}>
              <CartPaymentTable
                salonPrice={salonPrice}
                purchasePrice={purchasePrice}
              />
            </SlideIn>
            <div
              className={clsx(
                'tw-mt-1',
                'tw-flex tw-items-center tw-justify-center'
              )}
            >
              <div
                className={clsx(
                  'tw-flex tw-flex-col tw-items-center',
                  'tw-flex-shrink-0',
                  'tw-px-4'
                )}
              >
                <span className={clsx('tw-block tw-text-sm', 'tw-mb-1')}>
                  合計価格表示
                </span>
                <InputToggle checked={isOpen} onChange={setOpen} />
              </div>
              <Button tabIndex={-1} disabled={disabledOrder} onClick={onSubmit}>
                発注する
              </Button>
            </div>
          </div>
        </div>
      </ActionFloatingFooter>
    </>
  )
}
