import { AxiosError } from 'axios'
import {
  QueryClient,
  UseQueryOptions,
  UseMutationOptions,
  DefaultOptions,
} from 'react-query'
import { PromiseValue } from 'type-fest'

import { isForErrorBoundary, baseErrorHandler } from './util'

const defaultQueryOptions: DefaultOptions = {
  queries: {
    refetchOnWindowFocus: false,
    retry: false,
    cacheTime: 0,
    useErrorBoundary: (error) => isForErrorBoundary(error),
    onError: (error) => baseErrorHandler(error),
  },
  mutations: {
    useErrorBoundary: (error) => isForErrorBoundary(error),
    onError: (error) => baseErrorHandler(error),
  },
}

export const queryClient = new QueryClient({
  defaultOptions: defaultQueryOptions,
})

export type QueryConfig<FetcherFnType extends (...args: any) => any> = Omit<
  UseQueryOptions<PromiseValue<ReturnType<FetcherFnType>>, AxiosError<any>>,
  'initialData'
>

export type MutationConfig<FetcherFnType extends (...args: any) => any> =
  UseMutationOptions<
    PromiseValue<ReturnType<FetcherFnType>>,
    AxiosError<any>,
    Parameters<FetcherFnType>[0]
  >
