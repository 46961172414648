import { useQuery } from 'react-query'

import { aspidaClient } from '@/lib/aspida'
import { QueryConfig } from '@/lib/react-query'

import { favoriteKeyFactory } from './key'

export const getFavorites = () => {
  return aspidaClient.v1.favorites.$get()
}

export type UseFavoritesOptions = {
  config?: QueryConfig<typeof getFavorites>
}

export const useFavorites = ({ config }: UseFavoritesOptions) => {
  const queryConfig: QueryConfig<typeof getFavorites> = {
    ...config,
    queryKey: favoriteKeyFactory.list(),
    queryFn: getFavorites,
  }
  return useQuery(queryConfig)
}
