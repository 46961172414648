import clsx from 'clsx'

import type { ItemCardDetailKvListItemProps } from './type'

export const CLASS_NAME = ({
  pageType,
  isOutsideModal,
  titleWidthClass,
}: Pick<
  ItemCardDetailKvListItemProps,
  'pageType' | 'isOutsideModal' | 'titleWidthClass'
>) => ({
  wrapper:
    pageType === 'list'
      ? clsx(
          'tw-flex tw-items-center',
          'tw-py-0.5',
          'tw-border-b tw-border-gray-300',
          'tw-text-xs'
        )
      : clsx(
          'tw-h-14',
          'tw-flex tw-items-center',
          'tw-border tw-border-primary-100',
          'tw-text-sm'
        ),
  key:
    pageType === 'list'
      ? clsx(
          titleWidthClass || 'tw-w-36',
          isOutsideModal && clsx('tw-flex-none'),
          'tw-text-gray-600'
        )
      : clsx(
          'tw-h-full tw-w-28 lg:tw-w-[232px]',
          'tw-flex tw-items-center tw-justify-center',
          'tw-bg-primary-50',
          'tw-whitespace-pre-wrap tw-text-center'
        ),
  value: clsx(
    'tw-break-all',
    pageType === 'list' ? clsx('lg:tw-pl-1') : clsx('tw-pl-10')
  ),
})
