import { useState, useCallback } from 'react'

import type { ShippingAddressFormVO } from '../CommonShippingAddressModal/type'
import type { CartShippingAddressModalProps } from './type'
import type { SubmitHandler } from 'react-hook-form'

import { NOTIFICATION_MESSAGE } from '@/const'
import { useUpdateShippingInfo } from '@/hooks/features/cart'
import { useNotification } from '@/hooks/util'
import { getErrorMessages } from '@/utils/text'

import { CommonShippingAddressModal } from '../CommonShippingAddressModal/CommonShippingAddressModal'
import { formLabels } from '../CommonShippingAddressModal/const'

export const CartShippingAddressModal = ({
  isOpen,
  defaultValues,
  onClose,
}: CartShippingAddressModalProps) => {
  const { notify } = useNotification()

  const [errorMessages, setErrorMessages] = useState<string[]>([])

  const updateShippingInfoMutation = useUpdateShippingInfo({})

  const submitHandler: SubmitHandler<ShippingAddressFormVO> = useCallback(
    (values) => {
      updateShippingInfoMutation.mutate(
        { data: values },
        {
          onSuccess: () => {
            setErrorMessages([])
            notify({
              id: 'NOTIFICATION_MESSAGE.CART.UPDATE_SHIPPING_INFO',
              type: 'success',
              message: NOTIFICATION_MESSAGE.CART.UPDATE_SHIPPING_INFO,
            })
            onClose()
          },
          onError: (error) => {
            if (error.response?.status === 400) {
              setErrorMessages(
                getErrorMessages({
                  fields: error.response.data.fields,
                  fieldNames: formLabels,
                })
              )
            }
          },
        }
      )
    },
    [notify, onClose, updateShippingInfoMutation]
  )

  return (
    <CommonShippingAddressModal
      isOpen={isOpen}
      isLoading={updateShippingInfoMutation.isLoading}
      defaultValues={defaultValues}
      errorMessages={errorMessages}
      submitHandler={submitHandler}
      onClose={onClose}
    />
  )
}

// /**
//  * お届け先住所の設定
//  */
// const AddressFormModal = ({
//   isOpen,
//   isLoading,
//   defaultValues,
//   errorMessages,
//   submitHandler,
//   onClose,
// }: {
//   isOpen: boolean
//   isLoading: boolean
//   defaultValues: ShippingAddressFormVO
//   errorMessages: string[]
//   submitHandler: SubmitHandler<{
//     zipCode: string
//     address1: string
//     address2: string
//     tel: string
//     deliveryName: string
//   }>
//   onClose: () => void
// }) => {
//   const { notify } = useNotification()

//   const formRef = useRef(null)

//   const { register, control, formState, handleSubmit, setValue, reset } =
//     useForm<ShippingAddressFormVO>({
//       resolver: schema && zodResolver(schema),
//       defaultValues,
//     })

//   const zipCode = useWatch({ control, name: 'zipCode' })

//   const myStore = useMyStore({})

//   const searchAddressQuery = useSearchAddressByZipCode({
//     zipCode: zipCode?.replace('-', ''),
//     config: { enabled: false },
//   })

//   /** 住所検索 */
//   const handleSearchAddress = async () => {
//     const { data, error, isSuccess } = await searchAddressQuery.refetch()
//     if (!isSuccess) {
//       if ((error as AxiosError).response?.status === 400) {
//         notify({
//           id: 'OPERATIONAL_ERROR_MESSAGE.ADDRESS.SEARCH_BY_ZIP_CODE',
//           type: 'error',
//           message: OPERATIONAL_ERROR_MESSAGE.ADDRESS.SEARCH_BY_ZIP_CODE,
//         })
//       }
//       return
//     }
//     setValue(
//       'address1',
//       // NOTE: subLocalityName はstring型になっているが、nullが返ってくることがあるようなのでその場合は空文字になるように暫定処置しています
//       `${data.prefectureName}${data.localityName}${data.subLocalityName || ''}`
//     )
//   }

//   /** 配送情報をストア情報に差し替える処理 */
//   const handleReplaceToStoreInfo = () => {
//     if (!myStore.data) return
//     const { address1, address2, zipCode, tel, storeName } = myStore.data
//     reset(
//       { address1, address2, zipCode, tel, deliveryName: storeName },
//       { keepDefaultValues: true } // NOTE: 更新せずにモーダルを閉じて再度開いた際に、元の配送情報を表示するため
//     )
//   }

//   /** モーダル閉処理 */
//   const handleClose = () => {
//     reset()
//     onClose()
//   }

//   return (
//     <Dialog
//       title={`${SHIPPING_ADDRESS_TITLE}情報`}
//       isOpen={isOpen}
//       initialFocus={formRef}
//     >
//       <div className={clsx('tw-p-2')}>
//         <div
//           className={clsx(
//             'tw-flex tw-items-center tw-justify-between tw-pb-4',
//             'tw-border-b tw-border-gray-300'
//           )}
//         >
//           <Button
//             type="button"
//             variant="secondary"
//             size="md"
//             disabled={!myStore.isFetched}
//             onClick={handleReplaceToStoreInfo}
//           >
//             {`デフォルトの${SHIPPING_ADDRESS_TITLE}を指定する`}
//           </Button>
//         </div>

//         {errorMessages.length > 0 && (
//           <div className={clsx('tw-mt-4')}>
//             <ErrorMessageList errorMessages={errorMessages} />
//           </div>
//         )}

//         <form ref={formRef} id="email-change-form" className={clsx('tw-mt-6')}>
//           <FormInputHorizontalLayout>
//             <div className={clsx('tw-w-32')}>
//               <FormFieldText
//                 label={formLabels.zipCode}
//                 placeholder={placeholders.zipCode}
//                 required
//                 error={formState.errors['zipCode']}
//                 registration={register('zipCode')}
//               />
//             </div>
//             <div className={clsx('tw-mt-7 tw-ml-1')}>
//               <TextButton
//                 onClick={handleSearchAddress}
//                 disabled={!/^[0-9]{3}-?[0-9]{4}$/.test(zipCode)}
//               >
//                 郵便番号から住所検索する
//               </TextButton>
//             </div>
//           </FormInputHorizontalLayout>
//           <FormFieldText
//             label={formLabels.address1}
//             placeholder={placeholders.address1}
//             required
//             error={formState.errors['address1']}
//             registration={register('address1')}
//           />
//           <FormFieldText
//             label={formLabels.address2}
//             placeholder={placeholders.address2}
//             error={formState.errors['address2']}
//             registration={register('address2')}
//           />
//           <FormFieldText
//             label={formLabels.tel}
//             placeholder={placeholders.tel}
//             required
//             error={formState.errors['tel']}
//             registration={register('tel')}
//           />
//           <FormFieldText
//             label={formLabels.deliveryName}
//             placeholder={placeholders.deliveryName}
//             required
//             error={formState.errors['deliveryName']}
//             registration={register('deliveryName')}
//           />

//           <div className={clsx('tw-mt-1 tw-flex tw-items-center tw-space-x-2')}>
//             <Button
//               type="button"
//               variant="normal"
//               disabled={isLoading}
//               onClick={handleClose}
//             >
//               閉じる
//             </Button>
//             <Button
//               type="button"
//               onClick={() => {
//                 handleSubmit(submitHandler)()
//               }}
//               isLoading={isLoading}
//               disabled={searchAddressQuery.isLoading || isLoading}
//             >
//               保存する
//             </Button>
//           </div>
//         </form>
//       </div>
//     </Dialog>
//   )
// }
