import clsx from 'clsx'

import type { MaxWidthLayoutProps } from './type'

export const MaxWidthLayout = ({ children }: MaxWidthLayoutProps) => {
  return (
    <div className={clsx('tw-flex tw-justify-center')}>
      <div className={clsx(['tw-w-full', 'tw-max-w-pcMax'])}>{children}</div>
    </div>
  )
}
