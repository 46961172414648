import clsx from 'clsx'
import React, { useState, useEffect } from 'react'

import type { InputDateRangeProps } from './type'

import { InputDate } from '@/components/ui'

export const InputDateRange = ({
  value,
  height,
  isError,
  placeholder,
  disabled = false,
  popperPlacement = 'auto',
  handleChange,
  handleFocus,
  handleBlur,
}: InputDateRangeProps) => {
  const onChange = React.useCallback(
    (key: keyof typeof value, input: string | Date | undefined) => {
      handleChange({
        ...value,
        [key]: input,
      })
      if (key === 'from') {
        setIsFromActive(false)
      } else {
        setIsToActive(false)
      }
    },
    [value, handleChange]
  )

  const [isFromActive, setIsFromActive] = useState<boolean>(false)
  const [isToActive, setIsToActive] = useState<boolean>(false)

  useEffect(() => {
    if (isFromActive || isToActive) {
      handleFocus && handleFocus()
    } else if (!isFromActive && !isToActive) {
      handleBlur && handleBlur()
    }
  }, [isFromActive, isToActive, handleFocus, handleBlur])

  return (
    <div className={clsx('tw-flex tw-items-center')}>
      <InputDate
        value={value.from}
        height={height}
        isError={isError}
        placeholder={placeholder?.from ?? '開始日'}
        disabled={disabled}
        popperPlacement={popperPlacement}
        onChange={(input) => onChange('from', input)}
        onFocus={() => {
          setIsFromActive(true)
        }}
        onBlur={() => {
          setIsFromActive(false)
        }}
      />

      <span className={clsx('tw-mx-4', 'tw-text-xs', 'tw-outline-none')}>
        ～
      </span>

      <InputDate
        value={value.to}
        height={height}
        isError={isError}
        placeholder={placeholder?.to ?? '終了日'}
        disabled={disabled}
        popperPlacement={popperPlacement}
        onChange={(input) => onChange('to', input)}
        onFocus={() => {
          setIsToActive(true)
        }}
        onBlur={() => {
          setIsToActive(false)
        }}
      />
    </div>
  )
}
