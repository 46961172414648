import { useMutation } from 'react-query'

import { Methods } from '@/api/v1/cart/shipping-information'
import { aspidaClient } from '@/lib/aspida'
import { queryClient } from '@/lib/react-query'
import { MutationConfig } from '@/lib/react-query'

import { cartKeyFactory } from './key'

export type UpdateShippingInfoDTO = {
  data: Methods['put']['reqBody']
}

export const updateShippingInfo = ({ data }: UpdateShippingInfoDTO) => {
  return aspidaClient.v1.cart.shipping_information.$put({ body: data })
}

type UseUpdateShippingInfoOptions = {
  config?: MutationConfig<typeof updateShippingInfo>
}

export const useUpdateShippingInfo = ({
  config,
}: UseUpdateShippingInfoOptions) => {
  return useMutation({
    onSuccess: () => queryClient.invalidateQueries(cartKeyFactory.all),
    ...config,
    mutationFn: updateShippingInfo,
  })
}
