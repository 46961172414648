import { useMutation } from 'react-query'

import type { Favorite } from '@/api/@types'
import { aspidaClient } from '@/lib/aspida'
import { MutationConfig } from '@/lib/react-query'
import { queryClient } from '@/lib/react-query'

import { favoriteKeyFactory } from './key'

export type CopyFavoriteDTO = {
  setNo: Favorite['setNo']
  storeCode: Favorite['storeCode']
}

export const copyFavorite = ({ setNo, storeCode }: CopyFavoriteDTO) => {
  return aspidaClient.v1.favorites
    ._setNo(setNo)
    ._storeCode(storeCode)
    .duplicate.$post()
}

type UseCopyFavoriteOptions = {
  config?: MutationConfig<typeof copyFavorite>
}

export const useCopyFavorite = ({ config }: UseCopyFavoriteOptions) => {
  return useMutation({
    onSuccess: () => queryClient.invalidateQueries(favoriteKeyFactory.list()),
    ...config,
    mutationFn: copyFavorite,
  })
}
