import clsx from 'clsx'
import NextLink from 'next/link'

import type { BreadcrumbItemProps } from './type'

import { BreadcrumbItemContent } from './BreadcrumbItemContent'
import { classNameBase } from './const'

export const BreadcrumbItem = ({ label, href }: BreadcrumbItemProps) => {
  return href ? (
    <NextLink href={href}>
      <a className={clsx(classNameBase)}>
        <BreadcrumbItemContent label={label} isLink={true} />
      </a>
    </NextLink>
  ) : (
    <p className={clsx(classNameBase)}>
      <BreadcrumbItemContent label={label} />
    </p>
  )
}

BreadcrumbItem.displayName = 'BreadcrumbItem'
