import clsx from 'clsx'
import React from 'react'

import type { FormInputHorizontalLayoutProps } from './type'

import { alignHorizontals, alignVerticals } from './const'

export const FormInputHorizontalLayout = ({
  alignHorizontal = 'left',
  alignVertical = 'start',
  children,
}: FormInputHorizontalLayoutProps) => {
  return (
    <div
      className={clsx(
        'tw-w-full',
        'tw-gap-1',
        'tw-flex tw-justify-start',
        alignHorizontals[alignHorizontal],
        alignVerticals[alignVertical]
      )}
    >
      {children}
    </div>
  )
}
