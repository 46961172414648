import { DragHandle } from '@mui/icons-material'
import clsx from 'clsx'

import { FavoriteCard } from '../../FavoriteCard'
import { FavoriteCardListItemProps } from './type'

export const FavoriteCardListItem = ({
  favorite,
  sortable,
  isDepositStore,
  isMyFavorites,
  isOperationDirectionTop,
  onClickCopy,
  onClickDelete,
  onClickEdit,
}: FavoriteCardListItemProps) => {
  return (
    <li
      className={clsx(
        'tw-h-11',
        'tw-flex tw-items-center',
        'tw-pl-2',
        'tw-border-b tw-border-gray-300'
      )}
    >
      {sortable && (
        <div className={clsx('tw-flex-none', 'tw-pl-2', 'tw-touch-none')}>
          <DragHandle
            sx={{ fontSize: 24 }}
            className={clsx('tw-text-primary-500')}
          />
        </div>
      )}
      <div className={clsx('tw-flex-1')}>
        <FavoriteCard
          favorite={favorite}
          showActions={!sortable && isMyFavorites}
          isOperationDirectionTop={isOperationDirectionTop}
          isMyFavorite={isMyFavorites}
          isDepositStore={isDepositStore}
          disabled={sortable}
          onClickCopy={() => onClickCopy && onClickCopy(favorite)}
          onClickDelete={() => onClickDelete && onClickDelete(favorite)}
          onClickEdit={() => onClickEdit && onClickEdit(favorite)}
        />
      </div>
    </li>
  )
}
