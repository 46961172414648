import { Listbox } from '@headlessui/react'

import type { InputSelectMultiOptionProps } from './type'

import { InputSelectMultiOptionContent } from '@/components/ui/input/select/elements'

import { isSelected } from '../util'

export const InputSelectMultiOption = ({
  option,
  selectedOptions,
}: InputSelectMultiOptionProps) => {
  return (
    <Listbox.Option value={option}>
      {({ active }) => {
        return (
          <InputSelectMultiOptionContent
            active={active}
            selected={isSelected(option, selectedOptions)}
            option={option}
          />
        )
      }}
    </Listbox.Option>
  )
}

InputSelectMultiOption.displayName = 'InputSelectMultiOption'
