import { useQuery } from 'react-query'

import { aspidaClient } from '@/lib/aspida'
import { QueryConfig } from '@/lib/react-query'

import { ecSiteOrdersKeyFactory } from './key'

export type TGetEcSiteOrdersQueryParams = Parameters<
  typeof ecSiteOrdersKeyFactory.getEcSiteOrders
>[0]['queryParams']

export type TGetEcSiteOrdersQueryConfig = QueryConfig<typeof getEcSiteOrders>

/**
 * Ec注文アップロード検索
 */
export const getEcSiteOrders = (queryParams: TGetEcSiteOrdersQueryParams) => {
  return aspidaClient.v1.ec_site_orders.$get({
    query: queryParams,
  })
}

export const useGetEcSiteOrders = ({
  config,
  queryParams,
}: {
  config: TGetEcSiteOrdersQueryConfig
  queryParams?: TGetEcSiteOrdersQueryParams
}) => {
  const queryConfig: TGetEcSiteOrdersQueryConfig = {
    ...config,
  }
  if (queryParams) {
    queryConfig.queryKey = ecSiteOrdersKeyFactory.getEcSiteOrders({
      queryParams,
    })
    queryConfig.queryFn = () => getEcSiteOrders(queryParams)
  }
  return useQuery(queryConfig)
}
