import { Close } from '@mui/icons-material'
import clsx from 'clsx'

import type { DialogProps } from './type'

import { DialogBase, TextButton } from '@/components/ui'

import { variants } from './const'

export const Dialog = ({
  isOpen,
  title,
  variant = 'primary',
  initialFocus,
  children,
  size = 'base',
  onClose,
}: DialogProps) => {
  return (
    <DialogBase isOpen={isOpen} onClose={onClose} initialFocus={initialFocus}>
      <div
        className={clsx(
          'tw-mx-4 tw-my-8 tw-w-full tw-max-w-pcMax',
          'tw-rounded tw-bg-white tw-text-left tw-shadow-md',
          'tw-transform tw-overflow-hidden tw-transition-all',
          size === 'base' ? 'lg:tw-max-w-sm' : 'lg:tw-w-pcMax'
        )}
      >
        <div
          className={clsx(
            'tw-w-full tw-px-2',
            'tw-grid tw-grid-cols-8',
            'tw-h-11 tw-items-center tw-justify-end',
            'tw-text-sm tw-font-bold tw-text-white',
            variants[variant]
          )}
          style={{ direction: 'rtl' }}
        >
          <div className={clsx('tw-text-right', 'tw-col-span-1')}>
            {onClose && (
              <TextButton onClick={onClose}>
                <Close fontSize={'small'} sx={{ color: 'white' }} />
              </TextButton>
            )}
          </div>
          <div className={clsx('tw-w-full tw-text-center', 'tw-col-span-6')}>
            {title}
          </div>
        </div>
        <div className={clsx('tw-mt-2 tw-p-2')}>{children}</div>
      </div>
    </DialogBase>
  )
}
