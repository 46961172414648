import { useMutation } from 'react-query'

import { Methods } from '@/api/v1/login'
import { aspidaClient } from '@/lib/aspida'
import { MutationConfig } from '@/lib/react-query'

export type LoginDTO = {
  data: Methods['post']['reqBody']
}

export const login = ({ data }: LoginDTO) => {
  return aspidaClient.v1.login.$post({ body: data })
}

type UseLoginOptions = {
  config?: MutationConfig<typeof login>
}

export const useLogin = ({ config }: UseLoginOptions) => {
  return useMutation({
    ...config,
    mutationFn: login,
  })
}
