import { zodResolver } from '@hookform/resolvers/zod'
import { AddCircleOutlined } from '@mui/icons-material'
import clsx from 'clsx'
import { useCallback, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

import type {
  UpsertFavoriteListFormProps,
  UpsertFavoriteListFormVO,
} from './type'

import {
  Button,
  FormFieldText,
  FormFieldRadioGroup,
  ErrorMessageList,
  TextButton,
  FormFieldWrapper,
} from '@/components/ui'
import { NOTIFICATION_MESSAGE, ERROR_FIELD_NAMES } from '@/const'
import { useCreateFavorite, useUpdateFavorite } from '@/hooks/features/favorite'
import { favoriteKeyFactory } from '@/hooks/features/favorite/key'
import { useNotification } from '@/hooks/util'
import { queryClient } from '@/lib/react-query'
import { getErrorMessages } from '@/utils/text'

import {
  schema,
  formLabels,
  formDefaultValues,
  isSharedOptions,
  DEFAULT_SELECTED_SALES_TYPE,
} from './const'
import { SalesTypeItem } from './SalesTypeItem'

export const UpsertFavoriteListForm = ({
  favorite,
  isDepositStore = false,
  onClickConfirm,
  onClickCancel,
}: UpsertFavoriteListFormProps) => {
  const { notify } = useNotification()

  const [errorMessages, setErrorMessages] = useState<string[]>([])

  const createFavorite = useCreateFavorite({})
  const updateFavorite = useUpdateFavorite({})

  const isLoading = useMemo(() => {
    return createFavorite.isLoading || updateFavorite.isLoading
  }, [createFavorite.isLoading, updateFavorite.isLoading])

  const { control, register, formState, handleSubmit, watch, setValue } =
    useForm<UpsertFavoriteListFormVO>({
      resolver: schema && zodResolver(schema),
      defaultValues: favorite
        ? {
            name: favorite.name,
            isShared: favorite.isShared,
            salesTypeCodes: favorite.salesTypes.map((obj) => obj.salesTypeCode),
          }
        : formDefaultValues,
    })

  const submitHandler = useCallback(
    async ({ name, isShared, salesTypeCodes }: UpsertFavoriteListFormVO) => {
      if (favorite) {
        setErrorMessages([])
        updateFavorite.mutate(
          {
            pathParams: [favorite.setNo, favorite.storeCode],
            data: {
              name,
              isShared,
              salesTypeCodes,
            },
          },
          {
            onSuccess: () => {
              onClickConfirm && onClickConfirm()
              notify({
                id: 'NOTIFICATION_MESSAGE.FAVORITE.UPDATE_SETTING',
                type: 'success',
                message: NOTIFICATION_MESSAGE.FAVORITE.UPDATE_SETTING,
              })
              queryClient.invalidateQueries(favoriteKeyFactory.list())
            },
            onError: (error) => {
              if (error.response?.status === 400) {
                setErrorMessages(
                  getErrorMessages({
                    fields: error.response.data.fields,
                    fieldNames: { ...ERROR_FIELD_NAMES, ...formLabels },
                  })
                )
              }
            },
          }
        )
      } else {
        setErrorMessages([])
        createFavorite.mutate(
          {
            data: {
              name,
              isShared,
              salesTypeCodes,
            },
          },
          {
            onSuccess: () => {
              onClickConfirm && onClickConfirm()
              notify({
                id: 'NOTIFICATION_MESSAGE.FAVORITE.CREATE',
                type: 'success',
                message: NOTIFICATION_MESSAGE.FAVORITE.CREATE,
              })
              queryClient.invalidateQueries(favoriteKeyFactory.list())
            },
            onError: (error) => {
              if (error.response?.status === 400) {
                setErrorMessages(
                  getErrorMessages({
                    fields: error.response.data.fields,
                    fieldNames: formLabels,
                  })
                )
              }
            },
          }
        )
      }
    },
    [favorite, updateFavorite, onClickConfirm, createFavorite, notify]
  )

  const salesTypeCodes = watch('salesTypeCodes')
  const removeField = useCallback(
    (index) => {
      const arr = [...salesTypeCodes]
      arr.splice(index, 1)
      setValue('salesTypeCodes', arr)
    },
    [salesTypeCodes, setValue]
  )
  const appendField = useCallback(() => {
    const arr = [...salesTypeCodes, DEFAULT_SELECTED_SALES_TYPE]
    setValue('salesTypeCodes', arr)
  }, [salesTypeCodes, setValue])

  return (
    <div className={clsx('tw-px-1 tw-pb-4')}>
      {errorMessages.length > 0 && (
        <div className={clsx('tw-mt-4')}>
          <ErrorMessageList errorMessages={errorMessages} />
        </div>
      )}

      <form
        id="favorite-list-upsert-form"
        onSubmit={handleSubmit(submitHandler)}
      >
        <div className={clsx('tw-mt-4')}>
          <FormFieldText
            label={formLabels.name}
            placeholder={formLabels.name}
            disabled={isLoading}
            required
            error={formState.errors['name']}
            registration={register('name')}
          />
        </div>

        <div className={clsx('tw-mt-2')}>
          <div
            className={clsx(
              'tw-w-full',
              'tw-flex tw-flex-col tw-items-center',
              'tw-p-4',
              'tw-rounded',
              'tw-bg-primary-50'
            )}
          >
            <div className={clsx('tw-mb-2 tw-text-sm')}>
              このリストのデフォルトの売上種別を設定
            </div>
            <FormFieldWrapper
              error={formState.errors['salesTypeCodes']}
              showLabel={false}
            >
              <div
                className={clsx(
                  'tw-w-full',
                  'tw-flex tw-flex-col tw-items-center'
                )}
              >
                {salesTypeCodes.map((_salesTypeCode, index) => (
                  <div key={index}>
                    <SalesTypeItem
                      index={index}
                      control={control}
                      showRemove={index > 0}
                      onClickRemove={() => removeField(index)}
                    />
                  </div>
                ))}
              </div>
            </FormFieldWrapper>
            <div
              className={clsx(
                'tw-flex tw-items-center tw-justify-center',
                'hover:tw-opacity-hover',
                'tw-transition tw-duration-stateChange'
              )}
            >
              <TextButton variant="primary" onClick={appendField}>
                <AddCircleOutlined sx={{ fontSize: 24 }} />
                <span className={clsx('tw-ml-1 tw-text-sm')}>
                  売上種別を追加する
                </span>
              </TextButton>
            </div>
          </div>
        </div>

        {isDepositStore && (
          <div className={clsx('tw-mt-2')}>
            <div
              className={clsx(
                'tw-w-full',
                'tw-flex tw-flex-col tw-items-center',
                'tw-p-4',
                'tw-rounded',
                'tw-bg-primary-50'
              )}
            >
              <p className={clsx('tw-text-center tw-text-sm')}>
                他のサロンへの共有
              </p>
              <div className={clsx('tw-mt-1')}>
                <FormFieldRadioGroup
                  showLabel={false}
                  label={formLabels.isShared}
                  error={formState.errors['isShared']}
                  name="isShared"
                  control={control}
                  options={isSharedOptions}
                  formName="isShared"
                  direction={'row'}
                  gap={'lg'}
                  showError={false}
                />
              </div>
            </div>
          </div>
        )}
        <div
          className={clsx(
            isDepositStore ? 'tw-mt-7' : 'tw-mt-4', // バランスを取る
            'tw-flex tw-items-center',
            'tw-gap-4'
          )}
        >
          <Button
            type={'button'}
            disabled={isLoading}
            variant={'normal'}
            onClick={() => onClickCancel()}
          >
            閉じる
          </Button>
          <Button type={'submit'} disabled={isLoading} isLoading={isLoading}>
            保存する
          </Button>
        </div>
      </form>
    </div>
  )
}
