import { useQuery } from 'react-query'

import { aspidaClient } from '@/lib/aspida'
import { QueryConfig } from '@/lib/react-query'

import { categoryKeyFactory } from './key'

export const getCategories = () => {
  return aspidaClient.v1.categories.$get()
}

export type UseCategoriesOptions = {
  config?: QueryConfig<typeof getCategories>
  enable?: boolean
}

/**
 * カテゴリ一覧取得
 */
export const useCategories = ({ config }: UseCategoriesOptions) => {
  const queryConfig: QueryConfig<typeof getCategories> = {
    ...config,
    queryKey: categoryKeyFactory.list(),
    queryFn: () => getCategories(),
  }
  return useQuery(queryConfig)
}
