import mitt from 'mitt'

export const APP_EVENT_NAMES = {
  CHANGE_MENTENANCE_MODE: 'CHANGE_MENTENANCE_MODE',
} as const

type TEvents = {
  [APP_EVENT_NAMES.CHANGE_MENTENANCE_MODE]: boolean
}

export const appEmitter = mitt<TEvents>()
