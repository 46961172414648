import { ArrowDropDown } from '@mui/icons-material'
import clsx from 'clsx'
import * as React from 'react'

import type { InputSelectMultiButtonProps } from './type'

import { widths, heights, texts, rounds } from '@/components/ui/input/const'
import { Spinner } from '@/components/ui/Spinner'

export const InputSelectMultiButton = React.forwardRef<
  HTMLButtonElement,
  InputSelectMultiButtonProps
>(
  (
    {
      label,
      height = 'lg',
      rounded = 'all',
      disabled = false,
      isLoading = false,
      handleClick,
    },
    ref
  ) => {
    return (
      <button
        className={clsx([
          widths['full'],
          heights[height],
          texts[height],
          rounds[rounded],
          'tw-relative',
          'tw-py-1.5 tw-pl-4 tw-pr-12',
          'tw-border tw-border-gray-300',
          'tw-text-sm',
          'focus:tw-border-accentBlue-500 focus:tw-outline-none',
          disabled ? 'tw-cursor-not-allowed tw-opacity-70' : 'tw-bg-white',
        ])}
        onClick={handleClick}
        ref={ref}
      >
        {isLoading && (
          <div className={'tw-w-fit'}>
            <Spinner variant={'normal'} size="sm" />
          </div>
        )}
        {!isLoading && (
          <span className={clsx(['tw-flex tw-items-center'])}>
            <span className={clsx(['tw-block', 'tw-truncate tw-text-xs'])}>
              {label}
            </span>
          </span>
        )}

        <span
          className={clsx([
            'tw-absolute',
            'tw-inset-y-0 tw-right-0',
            'tw-pointer-events-none tw-flex tw-items-center tw-px-2',
          ])}
        >
          <ArrowDropDown
            className={clsx(['tw-text-gray-600'])}
            sx={{ fontSize: 20 }}
            aria-hidden="true"
          />
        </span>
      </button>
    )
  }
)

InputSelectMultiButton.displayName = 'InputSelectMultiButton'
