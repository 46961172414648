import { Close } from '@mui/icons-material'
import clsx from 'clsx'

import type { ImageModalProps } from './type'

import { DialogBase, Image } from '@/components/ui'

export const ImageModal = ({ isOpen, image, onClose }: ImageModalProps) => {
  return (
    <DialogBase isOpen={isOpen} onClose={onClose}>
      <div
        className={clsx(
          'tw-relative',
          'tw-h-screen tw-w-full lg:tw-w-[90vw]',
          'tw-flex tw-items-center tw-justify-center',
          'tw-bg-primary-50 tw-p-2 lg:tw-p-8'
        )}
        onClick={onClose}
      >
        <div
          className={clsx(
            'tw-h-[calc(100%_-_max(15%_,_80px))] tw-w-full',
            'tw-flex tw-items-center tw-justify-center'
          )}
        >
          <Image src={image.src} alt={image.alt} fit={'contain'} />
        </div>
        <div className={clsx('tw-absolute', 'tw-top-0 tw-right-0')}>
          <button
            type={'button'}
            className={clsx('tw-p-2', 'tw-outline-none')}
            onClick={(e) => {
              onClose()
              e.stopPropagation() // 背景のonClickを呼ばない
            }}
          >
            <Close
              sx={{ fontSize: 24 }}
              className={clsx(
                'tw-cursor-pointer hover:tw-opacity-hover',
                'tw-transition tw-duration-stateChange'
              )}
            />
          </button>
        </div>
      </div>
    </DialogBase>
  )
}
