import { useMutation } from 'react-query'

import { aspidaClient } from '@/lib/aspida'
import { queryClient } from '@/lib/react-query'
import { MutationConfig } from '@/lib/react-query'

import { cartKeyFactory } from './key'

export const deleteCartItem = ({ itemCode }: { itemCode: string }) => {
  return aspidaClient.v1.cart.items._itemCode(itemCode).$delete()
}

type UseDeleteCartItemOptions = {
  config?: MutationConfig<typeof deleteCartItem>
}

export const useDeleteCartItem = ({ config }: UseDeleteCartItemOptions) => {
  return useMutation({
    onSuccess: () => queryClient.invalidateQueries(cartKeyFactory.all),
    ...config,
    mutationFn: deleteCartItem,
  })
}
