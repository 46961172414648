import clsx from 'clsx'

import type { MainContentProps } from './type'

import { DocumentationContent } from '@/components/documentation'
import { LoadingOverlay } from '@/components/ui'

import style from './style.module.css'

export const MainContent = ({
  isLoading = false,
  showDefault = false,
  document,
}: MainContentProps) => {
  return (
    <div className={clsx(style.wrapper)}>
      <LoadingOverlay isLoading={isLoading} />

      {/* ヘッダー: ログインユーザーの取得を実行しないようにしたいので共通ヘッダーは使っていない */}
      <div className={clsx(style.header)}>
        <img
          className={clsx(style.logoImage)}
          alt="Altair Logo"
          src="/altair.svg"
        />
      </div>

      {document && (
        <div className={clsx(style.main)}>
          <div className={clsx(style.contents)}>
            <DocumentationContent document={document} />
          </div>
          <div className={clsx(style.illust)}></div>
        </div>
      )}

      {showDefault && (
        <div className={clsx(style.main)}>
          <div className={clsx(style.contents)}>
            <p>ただいまメンテナンス中です</p>
          </div>
          <div className={clsx(style.illust)}></div>
        </div>
      )}
    </div>
  )
}
