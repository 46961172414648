import clsx from 'clsx'

import type { NotificationToastProps } from './type'

import { types } from './const'

export const NotificationToast = ({
  type = 'success',
  message,
  onClick,
}: NotificationToastProps) => {
  return (
    <div className={clsx('tw-w-full lg:tw-w-pcMax', 'lg:tw-py-4 lg:tw-px-8')}>
      <div
        className={clsx(
          'tw-inline-flex tw-items-center tw-justify-center',
          'tw-min-h-[52px] tw-w-full',
          'tw-rounded-lg tw-shadow-md',
          'tw-text-sm',
          types[type],
          onClick !== undefined && 'tw-cursor-pointer'
        )}
        onClick={onClick}
      >
        {message}
      </div>
    </div>
  )
}
