import { Delete } from '@mui/icons-material'
import clsx from 'clsx'
import { useState, useMemo } from 'react'
import { useWatch } from 'react-hook-form'

import type { ItemOrderUnitFormFieldItemProps } from './type'

import {
  FormInputHorizontalLayout,
  FormFieldSelect,
  FormFieldNumber,
  FormFieldText,
} from '@/components/ui'
import { useSalesTypesMaster } from '@/stores'

export const ItemOrderUnitFormFieldItem = ({
  itemIndex,
  unitIndex,
  field,
  showRemove = true,
  error,
  control,
  disabled,
  purchasePrice,
  register,
  onClickRemove,
  onAfterChange,
  onFocusItemCount,
}: ItemOrderUnitFormFieldItemProps) => {
  const { salesTypesMaster } = useSalesTypesMaster()

  const salesTypeQuantity = useWatch({
    control,
    name: `items.${itemIndex}.salesTypeQuantities.${unitIndex}`,
  })

  const salesTypeOptions = useMemo(
    () =>
      salesTypesMaster.map(({ salesTypeCode, salesTypeName }) => ({
        label: salesTypeName,
        value: salesTypeCode,
      })) ?? [],
    [salesTypesMaster]
  )

  const [showNote, setShowNote] = useState(!!field.note)

  return (
    <div
      className={clsx(
        'tw-rounded-lg tw-border tw-border-primary-100 tw-bg-primary-50 tw-p-2'
      )}
    >
      <FormInputHorizontalLayout alignVertical="center">
        <div className={clsx('tw-flex-1')}>
          <div className={clsx('tw-items-center tw-gap-x-2 lg:tw-flex')}>
            <div
              className={clsx(
                'tw-flex tw-items-center tw-gap-x-2',
                'tw-w-full'
              )}
            >
              {showRemove ? (
                <Delete
                  onClick={onClickRemove}
                  sx={{ fontSize: 24 }}
                  className={clsx(
                    'tw-cursor-pointer tw-text-primary-400',
                    'hover:tw-opacity-hover'
                  )}
                />
              ) : (
                <span className={clsx('tw-w-6')} />
              )}
              <div className="tw-flex-1">
                {/* この売上種別選択欄には選択肢「その他A」が省略されずに表示できるだけの横幅が最低限必要 */}
                <FormFieldSelect
                  showLabel={false}
                  height="md"
                  error={error?.salesType}
                  showError={false}
                  name={`items.${itemIndex}.salesTypeQuantities.${unitIndex}.salesType`}
                  control={control}
                  options={salesTypeOptions}
                  buttonLabel="選択してください"
                  disabled={disabled}
                  tabIndex={-1}
                  onAfterChange={onAfterChange}
                />
              </div>

              <div className="tw-flex-1">
                <FormFieldNumber
                  showLabel={false}
                  height="md"
                  error={error?.quantity}
                  showError={false}
                  name={`items.${itemIndex}.salesTypeQuantities.${unitIndex}.quantity`}
                  control={control}
                  min={0}
                  max={999}
                  disabled={disabled}
                  zeroAttention={true}
                  enterIndex
                  onAfterChange={onAfterChange}
                  onFocus={onFocusItemCount}
                />
              </div>
              <span className={clsx('tw-w-6')}>
                <button
                  type={'button'}
                  disabled={disabled}
                  className={clsx(
                    'tw-text-xs tw-text-primary-400',
                    'tw-underline',
                    'tw-cursor-pointer',
                    'hover:tw-opacity-hover'
                  )}
                  tabIndex={-1}
                  onClick={() => setShowNote(!showNote)}
                >
                  <span>メモ</span>
                </button>
              </span>
            </div>
          </div>
          <div
            className={clsx(
              !showNote && 'tw-hidden',
              'tw-w-full',
              'tw-mt-2 tw-gap-x-2',
              'tw-flex tw-items-center',
              'tw-transition tw-duration-stateChange'
            )}
          >
            <span className={clsx('tw-w-6')} />
            <div className={clsx('tw-flex-1')}>
              <FormFieldText
                showLabel={false}
                height="md"
                placeholder="備考"
                disabled={disabled}
                error={error?.note}
                showError={false}
                tabIndex={-1}
                registration={register(
                  `items.${itemIndex}.salesTypeQuantities.${unitIndex}.note`
                )}
                onChange={onAfterChange}
              />
            </div>
            <span className={clsx('tw-w-6')} />
          </div>
          {purchasePrice && (
            <div
              className={clsx(
                'tw-mt-2',
                'tw-mx-8',
                'tw-px-2 tw-py-1',
                'tw-flex tw-flex-col',
                'tw-rounded tw-border tw-border-gray-300'
              )}
            >
              <div
                className={clsx(
                  'tw-flex tw-items-center tw-justify-between',
                  'tw-mb-1 tw-border-b tw-border-gray-300 tw-pb-1'
                )}
              >
                <p className={clsx('tw-text-xs tw-text-gray-600')}>購入価格</p>
                <p className={clsx('tw-text-xs')}>
                  ¥{purchasePrice.toLocaleString()}
                </p>
              </div>
              <div
                className={clsx('tw-flex tw-items-center tw-justify-between')}
              >
                <p className={clsx('tw-text-xs tw-text-gray-600')}>小計</p>
                <p className={clsx('tw-text-xs')}>
                  ¥
                  {(
                    purchasePrice * salesTypeQuantity.quantity
                  ).toLocaleString()}
                </p>
              </div>
            </div>
          )}
        </div>
      </FormInputHorizontalLayout>
    </div>
  )
}
