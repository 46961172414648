import { useRouter } from 'next/router'

import { LoadingOverlay } from '@/components/ui'
import { ROUTE } from '@/const'
import { useSession } from '@/hooks/features/auth'

import { WithAuthProps } from './type'

/**
 * 非ログインユーザーのみアクセスを許可
 * ログインユーザーがアクセスを試みた場合はトップページへリダイレクト
 */
export const withoutAuth = (PageComponent: WithAuthProps) => {
  const AuthenticatedPage = (props: any) => {
    const router = useRouter()
    const { isAuthenticated, isLoading } = useSession()

    if (isLoading) {
      return <LoadingOverlay isLoading={true} />
    }

    if (!isAuthenticated) {
      return <PageComponent {...props} />
    }

    router.push(ROUTE.top)
    return null
  }

  return AuthenticatedPage
}
