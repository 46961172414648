import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  closestCenter,
  useSensor,
} from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { arrayMove, SortableContext } from '@dnd-kit/sortable'

import { useUa } from '@/hooks/util'

import { DndItem } from './DndItem'
import { DndProps } from './type'

export const Dnd = <T,>({
  items,
  disabled,
  children,
  onChange,
}: DndProps<T>) => {
  const { isIOS, isIPadOS, isAndroid } = useUa()
  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 100,
      tolerance: 5,
    },
  })
  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: {
      delay: 100,
      tolerance: 5,
    },
  })
  const keyboardSensor = useSensor(KeyboardSensor)

  // Note: デスクトップの場合、pointerのイベントがないと動かない
  const sensors =
    isIOS || isAndroid || isIPadOS
      ? [touchSensor, keyboardSensor]
      : [touchSensor, pointerSensor, keyboardSensor]

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
      sensors={sensors}
      onDragEnd={(event) => {
        const { active, over } = event
        if (over == null || active.id === over.id) {
          return
        }
        const oldIndex = items.findIndex((f) => f.id === active.id)
        const newIndex = items.findIndex((f) => f.id === over.id)
        const newItems = arrayMove(items, oldIndex, newIndex)
        onChange(newItems)
      }}
    >
      <SortableContext items={items} disabled={disabled}>
        {items.map((item, index) => (
          <DndItem item={item} key={`dnd-sortable-item-${item.id}-${index}`}>
            {children(item, index)}
          </DndItem>
        ))}
      </SortableContext>
    </DndContext>
  )
}
