import type { WithSystemTypesForImportProps } from './type'

import { LoadingOverlay } from '@/components/ui'

import { useCheckSystemTypesForImportMaster } from './util'

export const withSystemTypesForImport = (
  PageComponent: WithSystemTypesForImportProps
) => {
  const Enhanced = (props: any) => {
    const { isStored, isLoading } = useCheckSystemTypesForImportMaster()

    if (isStored) {
      return <PageComponent {...props} />
    }

    if (isLoading) {
      return <LoadingOverlay isLoading={true} />
    }

    return null
  }

  return Enhanced
}
