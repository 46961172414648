import clsx from 'clsx'
import { useMemo } from 'react'

import type { ItemListFloatingFooterProps } from './type'

import { AddCartButton, FavoriteButton } from '@/components/features/item'
import { ActionFloatingFooter } from '@/components/layout'

export const ItemListFloatingFooter = ({
  inputRef,
  selectedItems,
  onChangeFavorite,
}: ItemListFloatingFooterProps) => {
  const selectedItemCodes = useMemo(
    () => selectedItems.map((v) => v.itemCode) as [string, ...string[]],
    [selectedItems]
  )

  return (
    <ActionFloatingFooter inputRef={inputRef}>
      <div
        className={clsx(
          'tw-w-full',
          'tw-flex tw-items-center',
          'tw-gap-2 lg:tw-gap-4'
        )}
      >
        <div className={clsx('tw-w-16 lg:tw-w-full')}>
          <FavoriteButton
            type="textButton"
            isSpTypeIcon={true}
            disabled={!selectedItems.length}
            selectedItemCodes={selectedItemCodes}
            onChange={onChangeFavorite}
          />
        </div>
        <div className={clsx('tw-w-full')}>
          <AddCartButton
            items={selectedItems}
            disabled={!selectedItems.length}
          />
        </div>
      </div>
    </ActionFloatingFooter>
  )
}
