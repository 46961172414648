import { useQuery } from 'react-query'

import { aspidaClient } from '@/lib/aspida'
import { QueryConfig } from '@/lib/react-query'

import { ecSiteOrdersKeyFactory } from './key'

type TParams = Parameters<
  typeof ecSiteOrdersKeyFactory.getEcSiteOrdersFileIdDownload
>[0]

type TConfig = QueryConfig<typeof getEcSiteOrdersFileIdDownload>

/**
 * Ec注文アップロードファイルダウンロード
 */
export const getEcSiteOrdersFileIdDownload = ({ pathParams }: TParams) => {
  return aspidaClient.v1.ec_site_orders
    ._fileId(pathParams.fileId)
    .download.$get()
}

export type TUseGetEcSiteOrdersFileIdDownloadOptions = TParams & {
  config?: TConfig
}

export const useGetEcSiteOrdersFileIdDownload = ({
  config,
  pathParams,
}: TUseGetEcSiteOrdersFileIdDownloadOptions) => {
  const queryConfig: TConfig = {
    ...config,
    queryKey: ecSiteOrdersKeyFactory.getEcSiteOrdersFileIdDownload({
      pathParams,
    }),
    queryFn: () =>
      getEcSiteOrdersFileIdDownload({
        pathParams,
      }),
  }
  return useQuery(queryConfig)
}
