import clsx from 'clsx'
import NextLink from 'next/link'

import type { DetailLinkProps } from './type'

import { ROUTE } from '@/const'

export const DetailLink = ({
  item,
  disabled,
  tabIndex,
  children,
}: DetailLinkProps) => {
  const noLink = item.itemStatus !== 'available' || disabled

  return noLink ? (
    <p
      className={clsx('tw-text-xs tw-font-bold tw-line-clamp-2')}
      tabIndex={tabIndex}
    >
      <span>{children}</span>
    </p>
  ) : (
    <NextLink href={ROUTE.itemDetail(item.itemCode)}>
      <a
        className={clsx(
          'tw-text-xs tw-font-bold tw-line-clamp-2',
          !disabled && 'tw-text-primary-500 tw-underline'
        )}
        tabIndex={tabIndex}
      >
        <span>{children}</span>
      </a>
    </NextLink>
  )
}
