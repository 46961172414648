import { useMutation } from 'react-query'

import { aspidaClient } from '@/lib/aspida'
import { queryClient } from '@/lib/react-query'
import { MutationConfig } from '@/lib/react-query'

import { cartKeyFactory } from './key'

export const clearCart = () => {
  return aspidaClient.v1.cart.$delete()
}

type UseClearCartOptions = {
  config?: MutationConfig<typeof clearCart>
}

export const useClearCart = ({ config }: UseClearCartOptions) => {
  return useMutation({
    onSuccess: () => queryClient.invalidateQueries(cartKeyFactory.all),
    ...config,
    mutationFn: clearCart,
  })
}
