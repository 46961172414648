import { useQuery } from 'react-query'

import { aspidaClient } from '@/lib/aspida'
import { queryClient, QueryConfig } from '@/lib/react-query'

import { userKeyFactory } from './key'

const getEmailSubscription = () => {
  return aspidaClient.v1.receive_email.$get()
}

export type UseEmailSubscriptionOptions = {
  config?: QueryConfig<typeof getEmailSubscription>
}

export const useEmailSubscription = ({
  config,
}: UseEmailSubscriptionOptions) => {
  const queryConfig: QueryConfig<typeof getEmailSubscription> = {
    ...config,
    queryKey: userKeyFactory.emailSubscription(),
    queryFn: () => getEmailSubscription(),
  }
  return useQuery(queryConfig)
}

export const invalidateEmailSubscription = () => {
  const key = userKeyFactory.emailSubscription()
  queryClient.invalidateQueries(key)
}
