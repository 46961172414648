import clsx from 'clsx'
import NextLink from 'next/link'

import { LinkTextProps } from './type'

export const LinkText = ({
  onClick,
  children,
  disabled,
  withUnderline = true,
  ...props
}: LinkTextProps) => {
  return disabled ? (
    <span className={clsx('tw-underline', 'tw-text-primary-200')}>
      {children}
    </span>
  ) : (
    <NextLink {...props} passHref>
      <a
        onClick={onClick}
        className={clsx(
          withUnderline && 'tw-underline',
          'tw-text-primary-500 hover:tw-opacity-hover',
          'tw-transition tw-duration-stateChange'
        )}
      >
        {children}
      </a>
    </NextLink>
  )
}
