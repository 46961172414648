import { Tune } from '@mui/icons-material'
import clsx from 'clsx'
import React, { useEffect, useMemo, useState } from 'react'

import type { FilteredItemListConditionProps } from './type'

import { ItemDetailedSearchModal } from '@/components/features/item'
import { InputSearch, TextButton } from '@/components/ui'
import { KEYWORD_SEARCH_PLACEHOLDER } from '@/const'
import { useModal } from '@/hooks/util'

import { getConditionsTextList } from './util'

export const FilteredItemListCondition = ({
  conditions,
  brandNames,
  categoryNames,
  pushWithSearchCondition,
}: FilteredItemListConditionProps) => {
  const { isOpen, open, close } = useModal()
  const conditionsTextList = useMemo(() => {
    return getConditionsTextList({ conditions, brandNames, categoryNames })
  }, [brandNames, categoryNames, conditions])

  /** キーワード検索処理 */
  const handleKeywordSearch = (keywords: string) => {
    // NOTE: もしkeyword以外のパラメータが詳細検索で指定されていた場合にはここではそれらをクリアせずに維持されるようにしています
    pushWithSearchCondition({
      keywords: keywords ? keywords : undefined,
    })
  }
  const defaultKeywordValue = useMemo(() => {
    return conditions?.keywords
  }, [conditions])

  // モーダルを開くたびに再マウントさせる
  const [openCount, setOpenCount] = useState(0)
  useEffect(() => {
    setOpenCount(openCount + 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  return (
    <div>
      <div className={clsx('tw-flex tw-items-center tw-justify-between')}>
        <p className={clsx('tw-text-sm tw-font-bold')}>検索条件</p>
        <TextButton variant="tertiary" onClick={open}>
          <div
            className={clsx(
              'tw-flex tw-items-center tw-space-x-1 tw-font-bold'
            )}
          >
            <Tune sx={{ fontSize: 16 }} />
            <span>詳細検索</span>
          </div>
        </TextButton>
        <ItemDetailedSearchModal
          key={openCount}
          isOpen={isOpen}
          defaultSearchCondition={conditions}
          onClose={close}
          onSubmit={pushWithSearchCondition}
        />
      </div>

      <div>
        <InputSearch
          name="keyword-only-search"
          placeholder={KEYWORD_SEARCH_PLACEHOLDER}
          defaultValue={defaultKeywordValue}
          onSearch={handleKeywordSearch}
        />
      </div>

      {conditionsTextList.length > 0 && (
        <p
          className={clsx(
            'tw-mt-[6px] tw-p-2',
            'tw-rounded tw-border tw-border-primary-100 tw-bg-primary-50 tw-text-sm'
          )}
        >
          {conditionsTextList.map((condition, i) => (
            <span
              key={`condition-${i}_${condition}`}
              className={clsx(
                i !== conditionsTextList.length - 1 &&
                  "after:tw-mr-1 after:tw-content-[',']"
              )}
            >
              {condition}
            </span>
          ))}
        </p>
      )}
    </div>
  )
}
