import { ErrorPage } from '@/components/pages/Error'
import { useMyStore } from '@/hooks/features/store'

import { WithRoleImportOrderProps } from './type'

/**
 * 「注文取込の表示権限」があるユーザーのみアクセス許可
 *
 * ※「EC注文取込結果」も同様
 */
export const withRoleImportOrder = (
  PageComponent: WithRoleImportOrderProps
) => {
  const RoleImportOrderPage = (props: any) => {
    const { data, isSuccess } = useMyStore({})

    if (!isSuccess) {
      return null
    }

    // 注文取込の表示権限がない
    if (!data.importOrder) {
      return <ErrorPage statusCode={403} />
    }

    return <PageComponent {...props} />
  }

  return RoleImportOrderPage
}
