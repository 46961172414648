import { useMutation } from 'react-query'

import type { Favorite } from '@/api/@types'
import { Methods } from '@/api/v1/favorites/_setNo@number/_storeCode@string/items'
import { aspidaClient } from '@/lib/aspida'
import { MutationConfig } from '@/lib/react-query'

export type CopyFavoriteItemsDTO = {
  setNo: Favorite['setNo']
  storeCode: Favorite['storeCode']
  data: Methods['post']['reqBody']
}

export const copyFavoriteItems = ({
  setNo,
  storeCode,
  data,
}: CopyFavoriteItemsDTO) => {
  return aspidaClient.v1.favorites
    ._setNo(setNo)
    ._storeCode(storeCode)
    .items.$post({
      body: data,
      query: {
        action: 'copy',
      },
    })
}

type UseCopyFavoriteItemsOptions = {
  config?: MutationConfig<typeof copyFavoriteItems>
}

export const useCopyFavoriteItems = ({
  config,
}: UseCopyFavoriteItemsOptions) => {
  return useMutation({
    ...config,
    mutationFn: copyFavoriteItems,
  })
}
