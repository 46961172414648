import clsx from 'clsx'
import React from 'react'

import type { MessagePanelProps } from './type'

export const MessagePanel = ({ children }: MessagePanelProps) => {
  return (
    <div
      className={clsx([
        'tw-w-full',
        'tw-flex tw-justify-center',
        'tw-py-7 tw-px-4',
        'tw-bg-white',
      ])}
    >
      {children}
    </div>
  )
}
