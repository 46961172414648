import clsx from 'clsx'
import React from 'react'

import type { BulkSelectControllerProps } from './type'

import { Button, InputCheckbox } from '@/components/ui'

export const BulkSelectController = ({
  isSelectMode = false,
  selected = 'none',
  activateButtonLabel = '複数選択する',
  deactivateButtonLabel = '完了する',
  handleAllSelect,
  toggleSelectMode,
}: BulkSelectControllerProps) => {
  return (
    <div
      className={clsx(
        'tw-h-12',
        'tw-flex tw-items-center',
        isSelectMode && 'tw-justify-between'
      )}
    >
      {isSelectMode && (
        <div className={clsx('tw-flex tw-items-center', 'tw-gap-2')}>
          <InputCheckbox
            checked={selected === 'all'}
            isIndeterminate={selected === 'indeterminate'}
            label={'複数変更'}
            handleChange={handleAllSelect}
          />
          <p className={clsx('tw-text-sm')}>すべて選択</p>
        </div>
      )}
      <Button
        variant="tertiary"
        size="md"
        onClick={() => toggleSelectMode()}
        isBlock={false}
      >
        {isSelectMode ? deactivateButtonLabel : activateButtonLabel}
      </Button>
    </div>
  )
}
