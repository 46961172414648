import clsx from 'clsx'

import type { ItemCardDetailToggleProps } from './type'

import { ItemCardDetailKvList } from '@/components/features/item'
import { Disclosure } from '@/components/ui'
import { useDisclosure } from '@/hooks/util'

export const ItemCardDetailToggle = ({
  item,
  isOutsideModal = false,
  headerClassName = 'tw-bg-white tw-text-xs',
}: ItemCardDetailToggleProps) => {
  const itemDetailDisclosure = useDisclosure()

  return (
    <div
      className={clsx(
        !itemDetailDisclosure.isOpen && 'tw-border-b tw-border-gray-300'
      )}
    >
      <Disclosure
        isOpen={itemDetailDisclosure.isOpen}
        toggle={itemDetailDisclosure.toggle}
        headerClassName={headerClassName}
        heading={<span className={clsx('tw-text-xs')}>詳細をみる</span>}
      >
        <ItemCardDetailKvList item={item} isOutsideModal={isOutsideModal} />
      </Disclosure>
    </div>
  )
}
