import clsx from 'clsx'
import { useMemo } from 'react'

import { ItemOrderCardPriceProps } from './type'

export const ItemOrderCartPrice = ({
  item,
  cartSalesTypeQuantities,
  direction,
  showCount = true,
  showPurchasePrice = false,
}: ItemOrderCardPriceProps) => {
  const count = useMemo(
    () =>
      cartSalesTypeQuantities
        ? cartSalesTypeQuantities.reduce(
            (sum, current) => (sum += current.quantity),
            0
          )
        : 0,
    [cartSalesTypeQuantities]
  )

  const summarySalonPrice = useMemo(() => {
    if (!item.salonPrice || !cartSalesTypeQuantities) {
      return 0
    }

    return item.salonPrice * count
  }, [item, cartSalesTypeQuantities, count])

  const purchasePriceSummary = useMemo(() => {
    if (!item.salonPrice) {
      return 0
    }
    if (!cartSalesTypeQuantities || cartSalesTypeQuantities.length === 0) {
      return 0
    }

    return cartSalesTypeQuantities.reduce(
      (prev, next) => prev + next.purchasePrice * next.quantity,
      0
    )
  }, [cartSalesTypeQuantities, item.salonPrice])

  // Ntoe: 購入価格の平均値を表示する
  const purchasePriceAverage = useMemo(() => {
    if (!cartSalesTypeQuantities || cartSalesTypeQuantities.length === 0) {
      return 0
    }
    const itemCount = cartSalesTypeQuantities.reduce((prev, next) => {
      return prev + next.quantity
    }, 0)

    //Note: 小数がある場合は第2位まで表示し、ない場合は小数を表示しない
    const average = purchasePriceSummary / itemCount
    if (average - Math.floor(average) === 0) {
      return average
    } else {
      return average.toFixed(2)
    }
  }, [cartSalesTypeQuantities, purchasePriceSummary])

  switch (direction) {
    case 'horizontal': {
      return (
        <div
          className={clsx('tw-flex tw-flex-wrap tw-items-center', 'tw-gap-x-1')}
        >
          <div
            className={clsx(
              'tw-flex tw-flex-wrap tw-items-center',
              'tw-gap-x-4'
            )}
          >
            {showCount && (
              <p className={clsx('tw-text-xs')}>{`数量：${count}点`}</p>
            )}

            <p className={clsx('tw-text-xs tw-text-gray-600')}>
              {`サロン価格：${(item.salonPrice || 0).toLocaleString()}円`}
            </p>
            {cartSalesTypeQuantities && (
              <p className={clsx('tw-text-xs tw-text-gray-600')}>
                {`小計：${summarySalonPrice.toLocaleString()}円`}
              </p>
            )}
            {showPurchasePrice && purchasePriceSummary && (
              <p className={clsx('tw-text-xs', 'tw-bg-gray-100', 'tw-p-2')}>
                {`購入価格小計：${purchasePriceSummary.toLocaleString()}円`}
              </p>
            )}
          </div>
        </div>
      )
    }
    case 'vertical': {
      return (
        <table
          className={clsx(
            'tw-text-sm',
            'tw-table-fix',
            'tw-w-full',
            'tw-border-separate tw-border-spacing-y-1'
          )}
        >
          {showCount && (
            <tr>
              <td>数量</td>
              <td className={clsx('tw-min-w-[120px] tw-text-right')}>
                {count}点
              </td>
            </tr>
          )}
          <tr className={clsx('tw-text-gray-600')}>
            <td>サロン価格</td>
            <td className={clsx('tw-min-w-[120px] tw-text-right')}>{`${(
              item.salonPrice || 0
            ).toLocaleString()}円`}</td>
          </tr>
          {cartSalesTypeQuantities && (
            <tr className={clsx('tw-text-gray-600')}>
              <td>小計</td>
              <td
                className={clsx('tw-min-w-[120px] tw-text-right')}
              >{`${summarySalonPrice.toLocaleString()}円`}</td>
            </tr>
          )}
          {showPurchasePrice && purchasePriceSummary && (
            <tr className={clsx('tw-bg-gray-100')}>
              <td className={clsx('tw-py-2 tw-pl-2', 'tw-space-y-1')}>
                <span className={clsx('tw-block')}>購入価格</span>
                <span className={clsx('tw-block')}>購入価格小計</span>
              </td>
              <td
                className={clsx(
                  'tw-min-w-[120px] tw-text-right',
                  'tw-py-2 tw-pr-2',
                  'tw-space-y-1'
                )}
              >
                <span
                  className={clsx('tw-block')}
                >{`${purchasePriceAverage.toLocaleString()}円`}</span>
                <span
                  className={clsx('tw-block')}
                >{`${purchasePriceSummary.toLocaleString()}円`}</span>
              </td>
            </tr>
          )}
        </table>
      )
    }
    default: {
      return <></>
    }
  }
}
