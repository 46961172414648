import clsx from 'clsx'

import type { ActionFloatingFooterProps } from './type'

import { FloatingFooter, MaxWidthLayout } from '@/components/layout'

export const ActionFloatingFooter = ({
  inputRef,
  children,
}: ActionFloatingFooterProps) => {
  return (
    <FloatingFooter inputRef={inputRef}>
      <div className={clsx('tw-py-4', 'tw-bg-white')}>
        <MaxWidthLayout>
          <div className={clsx('tw-px-2 lg:tw-px-0')}>{children}</div>
        </MaxWidthLayout>
      </div>
    </FloatingFooter>
  )
}
