const LIMITED_MODE_LIST = ['limit-order', 'normal'] as const

export const useLimitedMode = () => {
  const mode = (process.env.NEXT_PUBLIC_LIMITED_MODE ??
    'normal') as (typeof LIMITED_MODE_LIST)[number]
  const limitedModeFrom = process.env.NEXT_PUBLIC_LIMITED_MODE_FROM
    ? new Date(process.env.NEXT_PUBLIC_LIMITED_MODE_FROM)
    : undefined
  const limitedModeTo = process.env.NEXT_PUBLIC_LIMITED_MODE_TO
    ? new Date(process.env.NEXT_PUBLIC_LIMITED_MODE_TO)
    : undefined
  const now = new Date()

  const isLimitedTerm =
    Boolean(LIMITED_MODE_LIST.includes(mode)) &&
    limitedModeFrom &&
    limitedModeTo &&
    limitedModeFrom <= now &&
    now <= limitedModeTo

  return {
    isLimitedTerm,
    mode,
  }
}
