import { Listbox } from '@headlessui/react'
import clsx from 'clsx'
import * as React from 'react'
import { useOnClickOutside } from 'usehooks-ts'

import type { InputSelectMultiProps } from './type'

import { InputOption } from '@/types'

import { InputSelectLabel, InputSelectOptions } from '../elements'
import { INPUT_CHECK_ALL_VALUE } from './const'
import { InputSelectMultiButton } from './InputSelectMultiButton'
import { InputSelectMultiOption } from './InputSelectMultiOption'
import { isSelected } from './util'

export const InputSelectMulti = ({
  ref,
  buttonLabel,
  height = 'lg',
  rounded = 'all',
  selected = [],
  label,
  name,
  options,
  isSelectableAll = true,
  isLoading = false,
  handleFocus,
  handleBlur,
  handleChange,
  ...props
}: InputSelectMultiProps<string>) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const listOptions = React.useMemo(() => {
    return isSelectableAll
      ? [
          {
            label:
              options.length === selected.length
                ? 'すべて外す'
                : 'すべて選択する',
            value: INPUT_CHECK_ALL_VALUE,
          },
          ...options,
        ]
      : options
  }, [isSelectableAll, options, selected])

  const selectedList = React.useMemo(
    () =>
      options.length === selected.length
        ? [...selected, { label: '', value: INPUT_CHECK_ALL_VALUE }]
        : [...selected],
    [options, selected]
  )

  const wrapperRef = React.useRef(null)

  useOnClickOutside(wrapperRef, () => {
    setIsOpen(false)
  })

  const handleClick = () => {
    setIsOpen(!isOpen)
  }

  const handleCheckOption = (option: InputOption<string>) => {
    const isSelectAll = option.value === INPUT_CHECK_ALL_VALUE

    if (isSelectAll) {
      const isSelectedAll = selected.length === options.length
      const newValues = isSelectedAll ? [] : [...options]
      handleChange(newValues)
    } else {
      const newValues = isSelected(option, selected)
        ? selected.filter((o) => o.value !== option.value)
        : [...selected, option]
      handleChange(newValues)
    }
  }

  return (
    <Listbox
      as="div"
      value={selected}
      // FIXME TS
      // @ts-ignore
      onChange={props.disabled ? () => {} : handleCheckOption}
      onFocus={handleFocus}
      onBlur={handleBlur}
      className={clsx('tw-relative')}
      ref={ref}
      disabled={props.disabled}
    >
      {({ disabled }) => (
        <div ref={wrapperRef} className={clsx(['tw-relative tw-z-10'])}>
          {label && <InputSelectLabel label={label} />}
          <InputSelectMultiButton
            label={
              !!selected.length
                ? options.length === selected.length
                  ? options.length === 0
                    ? '-'
                    : 'すべて'
                  : `${selected.length}件選択中`
                : buttonLabel
            }
            height={height}
            rounded={rounded}
            disabled={disabled}
            isLoading={isLoading}
            handleClick={handleClick}
          />
          <InputSelectOptions open={isOpen} width={'full'}>
            {listOptions.map((option, index) => (
              <InputSelectMultiOption
                key={`input-select-option_${name || ''}_${index}_${
                  option.value
                }`}
                option={option}
                selectedOptions={selectedList}
              />
            ))}
          </InputSelectOptions>
        </div>
      )}
    </Listbox>
  )
}
