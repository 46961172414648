import clsx from 'clsx'
import React from 'react'

import type { IconWithLabelProps } from './type'

import { IconWrapper } from '@/components/ui'

export const IconWithLabel = ({
  label,
  children,
  showLabelSp = true,
}: IconWithLabelProps) => {
  return (
    <div
      className={clsx(
        'tw-flex tw-cursor-pointer tw-flex-col tw-items-center tw-justify-center hover:tw-opacity-hover'
      )}
    >
      <IconWrapper>{children}</IconWrapper>
      <span
        className={clsx(
          !showLabelSp && 'tw-hidden lg:tw-inline-block',
          'tw-text-xs tw-text-primary-500'
        )}
      >
        {label}
      </span>
    </div>
  )
}
