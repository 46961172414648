import { useController } from 'react-hook-form'

import type { FormFieldCheckboxProps } from './type'

import { FormFieldHorizontalWrapper, InputCheckbox } from '@/components/ui'

export const FormFieldCheckbox = ({
  disabled,
  label,
  showLabel,
  showError,
  name,
  control,
  error,
}: FormFieldCheckboxProps) => {
  const {
    field: { onChange, value },
  } = useController({ name, control })

  return (
    <FormFieldHorizontalWrapper
      label={label}
      error={error}
      showError={showError}
    >
      <InputCheckbox
        disabled={disabled}
        checked={value}
        label={label}
        showLabel={showLabel}
        handleChange={(e) => onChange(e)}
      />
    </FormFieldHorizontalWrapper>
  )
}
