import { useMemo } from 'react'

import type { UpsertFavoriteListModalProps } from './type'

import { UpsertFavoriteListForm } from '@/components/features/favorite'
import { Dialog } from '@/components/ui'
import { FAVORITE_TITLE_ALIAS } from '@/const'
import { useMyStore } from '@/hooks/features/store'

export const UpsertFavoriteListModal = ({
  isOpen,
  favorite,
  onClickConfirm,
  onClickCancel,
}: UpsertFavoriteListModalProps) => {
  const myStore = useMyStore({})

  const title = useMemo(
    () =>
      favorite === undefined
        ? `新しい${FAVORITE_TITLE_ALIAS}を作成する`
        : `リストの設定`,
    [favorite]
  )

  const isDepositStore = useMemo(() => {
    if (!myStore.data?.isDepositStore) {
      return false
    }
    // create
    if (!favorite) {
      return true
    }
    // update
    return favorite.isEditableByMe
  }, [myStore, favorite])

  return (
    <Dialog title={title} isOpen={isOpen}>
      <UpsertFavoriteListForm
        favorite={favorite}
        isDepositStore={isDepositStore}
        onClickConfirm={onClickConfirm}
        onClickCancel={onClickCancel}
      />
    </Dialog>
  )
}
