import React from 'react'

import type { InputCurrencyProps } from './type'
import type { FocusEvent } from 'react'

import { InputText } from '@/components/ui'

import { formatCurrency } from './util'

export const InputCurrency = React.forwardRef<
  HTMLInputElement,
  InputCurrencyProps
>(
  (
    {
      value,
      defaultValue,
      onChange,
      onFocus,
      onBlur,
      ...props
    }: InputCurrencyProps,
    ref
  ) => {
    const innerRef = React.useRef<HTMLInputElement>(null)
    const [isFocused, setIsFocused] = React.useState(false)

    // refの参照先をinnerRefに差し替え
    React.useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(
      ref,
      () => innerRef.current
    )

    const formatValue = React.useCallback((formatted = '') => {
      if (!innerRef.current || formatted === innerRef.current.value) {
        return
      }
      innerRef.current.value = formatted
    }, [])

    React.useEffect(() => {
      if (value === undefined && defaultValue !== undefined) {
        formatValue(formatCurrency(defaultValue))
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
      if (!isFocused) {
        if (value !== undefined) {
          // for controlled component
          formatValue(formatCurrency(value.toString()))
        } else if (innerRef.current) {
          // for uncontrolled component
          formatValue(formatCurrency(innerRef.current.value))
        }
      } else {
        if (innerRef.current) {
          const commaExcluded = innerRef.current.value.replace(/,/g, '')
          formatValue(commaExcluded)
        }
      }
    }, [isFocused, value, formatValue])

    const handleChange = (e: FocusEvent<HTMLInputElement>) => {
      const formattedValue = formatCurrency(e.target.value)
      onChange &&
        onChange({
          ...e,
          target: {
            ...e.target,
            value: formattedValue.replace(/,/g, ''),
          },
        })
    }

    const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
      setIsFocused(true)
      onFocus && onFocus(e)
    }

    const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
      setIsFocused(false)
      onBlur && onBlur(e)
    }

    return (
      <InputText
        {...props}
        type="text"
        value={isFocused ? value || '' : innerRef.current?.value || ''}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        ref={innerRef}
      />
    )
  }
)

InputCurrency.displayName = 'InputCurrency'
