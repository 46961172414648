import clsx from 'clsx'
import { useMemo } from 'react'

import { ItemPrice, ItemThumbnailBannar } from '@/components/features/item'
import { Button, Dialog } from '@/components/ui'
import { useSalesTypesMaster } from '@/stores'

import { QuantityTable } from './QuantitiyTable'
import { ItemOrderDetailModalProps } from './type'
import { formatSalesTypeQuantities } from './util'

export const ItemOrderDetailModal = ({
  item,
  salesTypeQuantities,
  editable,
  isVisiblePurchasePrice,
  isOpen,
  onClose,
  onOpenEdit,
}: ItemOrderDetailModalProps) => {
  const { salesTypesMaster } = useSalesTypesMaster()
  const formattedCartSalesTypeQuantities = useMemo(() => {
    return formatSalesTypeQuantities({
      quantities: salesTypeQuantities,
      master: salesTypesMaster,
    })
  }, [salesTypeQuantities, salesTypesMaster])

  return (
    <Dialog title={'カート内商品の詳細'} isOpen={isOpen}>
      <ItemThumbnailBannar item={item} />
      <ItemPrice
        item={item}
        cartSalesTypeQuantities={salesTypeQuantities}
        direction={'vertical'}
      />
      <div
        className={clsx('tw-border-t tw-border-gray-300', 'tw-mt-2 tw-py-2')}
      >
        {formattedCartSalesTypeQuantities.length > 0 &&
          formattedCartSalesTypeQuantities.map((quantity, index) => (
            <QuantityTable
              key={`order-item-detail-table-${item.itemCode}-${index}`}
              salesTypeQuantity={quantity}
              isVisiblePurchasePrice={isVisiblePurchasePrice}
            />
          ))}
      </div>
      <div
        className={clsx(
          'tw-mt-1 tw-flex tw-items-center',
          'tw-space-x-2 tw-py-4'
        )}
      >
        <Button type="button" variant="normal" onClick={onClose}>
          閉じる
        </Button>
        <Button type="button" disabled={!editable} onClick={onOpenEdit}>
          編集する
        </Button>
      </div>
    </Dialog>
  )
}
