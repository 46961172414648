import { useQuery } from 'react-query'

import { aspidaClient } from '@/lib/aspida'
import { QueryConfig } from '@/lib/react-query'

import { storeKeyFactory } from './key'

export const getStores = () => {
  return aspidaClient.v1.stores.$get()
}

export type UseStoresOptions = {
  config?: QueryConfig<typeof getStores>
}

export const useStores = ({ config }: UseStoresOptions) => {
  const queryConfig: QueryConfig<typeof getStores> = {
    ...config,
    queryKey: storeKeyFactory.stores(),
    queryFn: () => getStores(),
  }
  return useQuery(queryConfig)
}
