export const objectFit = {
  cover: 'tw-object-cover tw-h-full',
  contain: 'tw-object-contain tw-h-full',
  none: 'tw-object-none',
}

export const objectPosition = {
  leftTop: 'tw-object-left-top',
  top: 'tw-object-top',
  rightTop: 'tw-object-right-top',
  left: 'tw-object-left',
  center: 'tw-object-center',
  right: 'tw-object-right',
  leftBottom: 'tw-object-left-bottom',
  bottom: 'tw-object-bottom',
  rightBottom: 'tw-object-right-bottom',
}

export const defaultNoImageSrc = '/images/kikuya_no_image.png'
