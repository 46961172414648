import clsx from 'clsx'

import type { ListOperationTitleSectionProps } from './type'

export const ListOperationTitleSection = ({
  children,
}: ListOperationTitleSectionProps) => {
  return (
    <div
      className={clsx(
        'tw-h-[3.25rem]',
        'tw-flex tw-items-center',
        'tw-px-4',
        'tw-text-sm tw-font-bold',
        'tw-bg-primary-50'
      )}
    >
      {children}
    </div>
  )
}
