import { useGetEcSiteOrdersSystemTypes } from '@/hooks/features/ecSiteOrders'
import { useSystemTypesMaster } from '@/stores'

export const useCheckSystemTypesForPurchaseMaster = () => {
  const { systemTypesMaster, setSystemTypesMaster } = useSystemTypesMaster()

  const systemTypesQuery = useGetEcSiteOrdersSystemTypes({
    queryParams: {
      yahooType: 'merged',
    },
    config: {
      enabled: systemTypesMaster.forPurchase.length === 0,
      onSuccess: ({ systemTypeList }) => {
        setSystemTypesMaster((currVal) => {
          return {
            ...currVal,
            forPurchase: systemTypeList,
          }
        })
      },
    },
  })

  if (systemTypesMaster.forPurchase.length > 0) {
    return { isStored: true, isLoading: false }
  }

  return systemTypesQuery.isLoading
    ? {
        isStored: false,
        isLoading: true,
      }
    : {
        isStored: systemTypesQuery.isSuccess,
        isLoading: false,
      }
}
