import clsx from 'clsx'
import NextLink from 'next/link'
import { useMemo } from 'react'

import { Image } from '@/components/ui'
import { GUIDE_TITLE, CONTACT_TITLE, EXTERNAL_ROUTE, ROUTE } from '@/const'
import { useSession } from '@/hooks/features/auth'

const Link = ({ href, text }: { href: string; text: string }) => {
  return (
    <NextLink href={href}>
      <a
        className={clsx(
          'tw-px-4',
          'tw-text-xs tw-text-white',
          'tw-transition tw-duration-stateChange hover:tw-opacity-hover'
        )}
      >
        {text}
      </a>
    </NextLink>
  )
}

export const Footer = () => {
  const { isAuthenticated } = useSession()
  const authenticated = useMemo(() => isAuthenticated, [isAuthenticated])

  return (
    <div
      className={clsx(
        'tw-flex tw-flex-col',
        'tw-gap-2',
        'tw-p-4',
        'tw-bg-primary-500'
      )}
    >
      <div className={clsx('tw-flex tw-justify-center')}>
        <div
          className={clsx(
            'tw-w-[320px] lg:tw-w-pcMax',
            'tw-flex tw-justify-between lg:tw-justify-center lg:tw-space-x-8'
          )}
        >
          <div
            className={clsx(
              'tw-flex tw-flex-col tw-space-y-2 lg:tw-flex-row lg:tw-space-y-0 lg:tw-space-x-8'
            )}
          >
            {authenticated && <Link href={ROUTE.guides} text={GUIDE_TITLE} />}
            <Link href={ROUTE.contact} text={CONTACT_TITLE} />
          </div>
          <div
            className={clsx(
              'tw-flex tw-flex-col tw-space-y-2 lg:tw-flex-row lg:tw-space-y-0 lg:tw-space-x-8'
            )}
          >
            <Link href={ROUTE.privacyPolicy} text="プライバシーポリシー" />
            <Link href={ROUTE.termsOfService} text="利用規約" />
            <Link href={ROUTE.tokushoho} text="特定商取引法に基づく表記" />
          </div>
        </div>
      </div>

      <div className={clsx('tw-mt-4 tw-flex tw-justify-center')}>
        <a
          href={EXTERNAL_ROUTE.corporateSite}
          target={'_blank'}
          rel={'noreferrer'}
          className={clsx('tw-block', 'tw-h-6')}
        >
          <Image
            src="/images/kikuya_logo.png"
            alt="kikuya logo"
            fit="contain"
          />
        </a>
      </div>

      <div className={clsx('tw-mt-2 tw-flex tw-justify-center')}>
        <p className={clsx('tw-text-2xs tw-text-white')}>
          {'Copyright KIKUYA-BISYODO Inc. All Rights Reserved.'}
        </p>
      </div>
    </div>
  )
}
