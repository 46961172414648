import { ReactElement, ReactNode } from 'react'

const forEach = (children: ReactNode, elements: ReactNode[]) => {
  if (Array.isArray(children)) {
    for (const c of children) {
      forEach(c, elements)
    }
  } else if (!children || typeof children === 'boolean') {
    // filter out, that is the same as React.Children.toArray
  } else {
    elements.push(children)
  }
}

export const flattenChildren = (children: ReactNode): ReactElement[] => {
  const elements: ReactElement[] = []
  forEach(children, elements)
  return elements
}
