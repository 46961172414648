import { Favorite } from '@/api/@types'

/**
 * `${setNo}__${storeCode}`
 */
export type FavoriteUniqueString =
  `${Favorite['setNo']}${typeof SPLITTER}${Favorite['storeCode']}`

const SPLITTER = '__' as const

export const getFavoriteUniqueString = ({
  setNo,
  storeCode,
}: Pick<Favorite, 'setNo' | 'storeCode'>): FavoriteUniqueString => {
  return `${setNo}__${storeCode}`
}

export const getFavoriteUniqueValues = (
  favoriteUniqueString: FavoriteUniqueString
): Pick<Favorite, 'setNo' | 'storeCode'> => {
  const [setNo, storeCode] = favoriteUniqueString.split(SPLITTER)
  return {
    setNo: Number(setNo),
    storeCode: storeCode,
  }
}
