import clsx from 'clsx'
import { useEffect, useMemo, useRef, useState } from 'react'

import type { InputFileProps } from './type'
import type { ChangeEventHandler, MouseEventHandler } from 'react'

import { DropArea } from './parts/DropArea'
import { FileNameField } from './parts/FileNameField'
import { isAccept } from './util'

// NOTE: inputFileは他のinput系コンポーネントとはだいぶ異なっているのでもしform内で使うときは注意してください。

export const InputFile = ({
  placeholder = 'ファイルが選択されていません',
  disabled = false,
  acceptList,
  fileName,
  postNode,
  onChange,
}: InputFileProps) => {
  const inputFileRef = useRef<HTMLInputElement>(null)
  const [error, setError] = useState<string>('')

  const accept = useMemo(() => {
    return acceptList?.join(',')
  }, [acceptList])

  const reset = useMemo(
    () => () => {
      onChange(null)
      setError('')
    },
    [setError, onChange]
  )

  const trySelect = useMemo(
    () => (files: FileList | null) => {
      setError('')

      if (!files) {
        reset()
        setError('ファイルがありません')
        return
      }

      if (files.length !== 1) {
        reset()
        setError('選択できるファイルは1つです')
        return
      }

      if (!isAccept({ files: files, acceptList })) {
        reset()
        setError('許可されていないファイルフォーマットです')
        return
      }

      onChange(files[0])
    },
    [acceptList, onChange, reset, setError]
  )

  // 「ファイルを選択」ボタンがクリックされた
  const handleClickSelect: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (inputFileRef?.current) {
      inputFileRef.current.click()
    }
    e.stopPropagation()
  }

  // 「ファイルを選択」ボタンからファイルが入力された
  const handleChangeInput: ChangeEventHandler<HTMLInputElement> = (e) => {
    trySelect(e.target.files)
  }

  // ファイルがドロップされた
  const handleDrop = (files: FileList) => {
    trySelect(files)
  }

  // ×ボタン押下
  const handleClickClear = () => {
    reset()
  }

  // on removed fileName
  useEffect(() => {
    if (!fileName && inputFileRef.current?.files?.length) {
      // 選択済みのファイルがあれば削除する
      inputFileRef.current.value = ''
      reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileName])

  return (
    <div className={clsx(disabled && 'tw-cursor-not-allowed')}>
      <div className={clsx('tw-flex tw-flex-col tw-items-center', 'tw-gap-2')}>
        <DropArea
          disabled={disabled}
          onClickSelect={handleClickSelect}
          onDrop={handleDrop}
        />

        <div className={clsx('tw-w-full', 'tw-flex tw-items-start tw-gap-2')}>
          {/* 選択されたファイルの名称表示欄 */}
          <div className={clsx('tw-flex-1', 'tw-w-full tw-overflow-x-hidden')}>
            <FileNameField
              value={fileName}
              placeholder={placeholder}
              error={error}
              onClickClear={handleClickClear}
            />

            {/* このエラーメッセージはファイルの選択失敗のときのみ表示（2つのファイルや拡張子が合わないファイルをドロップした時など） */}
            {error && (
              <div className={clsx(disabled && 'tw-opacity-disabled')}>
                <p
                  role="alert"
                  aria-label={error}
                  className={clsx('tw-text-sm tw-text-alert-500')}
                >
                  {error}
                </p>
              </div>
            )}
          </div>

          {/* NOTE: レイアウトの都合で submit するボタンをファイル名表示欄と横並びにする必要がでてきたため props経由でここに設置できるようにしています */}
          {postNode}
        </div>

        {/* 隠しinput */}
        <input
          type="file"
          ref={inputFileRef}
          onChange={handleChangeInput}
          disabled={disabled}
          accept={accept}
          multiple={false}
          className={clsx('tw-hidden')}
        />
      </div>
    </div>
  )
}
