import { useMemo } from 'react'

export const useMobileChecker = (): {
  device: 'iPhone' | 'Android' | null
  isMobile: boolean
} => {
  const device = useMemo(() => {
    if (!!navigator.userAgent.match(/iPhone/)) {
      return 'iPhone'
    }
    if (!!navigator.userAgent.match(/Android.+Mobile/)) {
      return 'Android'
    }
    return null
  }, [])

  return { device, isMobile: device !== null }
}
