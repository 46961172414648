import { ReactElement, ReactNode, useMemo } from 'react'

import { flattenChildren } from './util'

/**
 * @internal
 */
export const useChildren = (
  children: ReactNode | ((i: number) => ReactElement),
  count: number | undefined
) => {
  return useMemo((): [(i: number) => ReactElement, number] => {
    if (typeof children === 'function') {
      return [children as (i: number) => ReactElement, count || 0]
    }
    // Memoize element array
    const _elements = flattenChildren(children)
    return [(i) => _elements[i]!, _elements.length]
  }, [children, count])
}
