// https://qiita.com/tatsuyankmura/items/9ec3087d9a6e09173a28
// https://github.com/wooorm/gemoji/blob/main/support.md
// https://ja.wikipedia.org/wiki/Unicode%E4%B8%80%E8%A6%A7_0000-0FFF

export const UNICODE_MAP = {
  hiragana: `\u3041-\u309F`,
  katakana: `\u30A1-\u30FF`,
  kanji: `\u4E00-\u9FCC`,
  number: {
    fullWidth: `\uFF10-\uFF19`,
    halfWidth: `\u0030-\u0039`,
  },
  alphabet: {
    fullWidth: `\uFF21-\uFF3A\uFF41-\uFF5A`,
    halfWidth: `\u0041-\u005A\u0061-\u007A`,
  },
  symbol: {
    halfWidth: [
      `\u0020-\u002F`,
      `\u003A-\u003F`,
      `\u005B-\u005F`,
      `\u007B-\u007E`,
    ],
    fullWidth: [
      `\u3000-\u301F`,
      `\u3099-\u309c`,
      `\uFF01-\uFF0F`,
      `\uFF1A-\uFF1F`,
      `\uFF3B-\uFF3F`,
    ],
  },
  emoji: [
    '\ud83c[\udf00-\udfff]',
    '\ud83d[\udc00-\ude4f]',
    '\ud83d[\ude80-\udeff]',
    '\ud7c9[\ude00-\udeff]',
    '[\u2600-\u27BF]',
  ],
}

export const REGEX_PATTERNS = {
  zipCode: /^[0-9]{3}-?[0-9]{4}$/,
  emoji: new RegExp(`(${UNICODE_MAP.emoji.join('|')})+`, 'g'),
  halfWidth: {
    // Note: 基本的な半角文字と半角カタカナ
    exist: /([\x21-\x7E\uFF61-\uFF9F])+/,
  },
}
