import { Dialog as UIDialog, Transition } from '@headlessui/react'
import clsx from 'clsx'
import * as React from 'react'
import 'intersection-observer'

import { DialogBaseProps } from './type'

export const DialogBaseTitle = UIDialog.Title

export const DialogBaseDescription = UIDialog.Description

export const DialogBase = ({
  isOpen,
  onClose,
  children,
  initialFocus,
}: DialogBaseProps) => {
  return (
    <>
      <Transition.Root show={isOpen} as={React.Fragment}>
        <UIDialog
          as="div"
          static
          className="tw-fixed tw-inset-0 tw-z-modal tw-overflow-y-auto"
          open={isOpen}
          onClose={onClose ? onClose : () => {}}
          initialFocus={initialFocus}
        >
          <div
            className={clsx(
              'tw-flex tw-min-h-screen tw-items-center tw-justify-center'
            )}
          >
            <Transition.Child
              as={React.Fragment}
              enter="tw-ease-out tw-duration-300"
              enterFrom="tw-opacity-0"
              enterTo="tw-opacity-100"
              leave="tw-ease-in tw-duration-200"
              leaveFrom="tw-opacity-100"
              leaveTo="tw-opacity-0"
            >
              <UIDialog.Overlay className="tw-fixed tw-inset-0 tw-bg-overlay tw-bg-opacity-75 tw-transition-opacity" />
            </Transition.Child>

            <Transition.Child
              as={React.Fragment}
              enter="tw-ease-out tw-duration-300"
              enterFrom="tw-opacity-0 tw-translate-y-0 tw-scale-95"
              enterTo="tw-opacity-100 tw-translate-y-0 tw-scale-100"
              leave="tw-ease-in tw-duration-200"
              leaveFrom="tw-opacity-100 tw-translate-y-0 tw-scale-100"
              leaveTo="tw-opacity-0 tw-translate-y-0 tw-scale-95"
            >
              {children}
            </Transition.Child>
          </div>
        </UIDialog>
      </Transition.Root>
    </>
  )
}
