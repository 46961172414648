export const ORDER_TITLE = '発注'
export const APPROVE_TITLE = '承認'
export const ORDER_ACTIONS = {
  APPROVE: APPROVE_TITLE,
  REVOKE_APPROVE: `${APPROVE_TITLE}取消`,
  CANCEL: 'キャンセル',
  EDIT: '編集',
}

export const APPROVE_STATUS = (value: boolean) =>
  value ? '承認済み' : '未承認'

export const ORDER_SHIPPING_DATE_TITLE = 'お届け日'
export const ORDER_SHIPPING_INFO_TITLE = 'お届け先情報'
export const ORDER_PAYMENT_INFO_TITLES = {
  subTotal: 'サロン価格',
  total: '購入価格',
} as const

export const ORDER_SHIPPING_DATE_FIELDS = {
  regularDeliveryDate: 'レギュラー納品予定日',
  desiredDeliveryDate: 'ご希望納品日',
}

export const ORDER_SHIPPING_INFO_FIELDS = {
  zipCode: '郵便番号',
  tel: '電話番号',
  deliveryName: 'お届け先名',
}

export const ORDER_PAYMENT_INFO_FIELDS = {
  thisOrder: '今回の注文',
  unshippedOrder: '同日お届け予定の他の発注',
  totalOrder: '合計金額',
  untilFreeShippingPrice: '送料無料まで',
}
