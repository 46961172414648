import clsx from 'clsx'
import { Fragment } from 'react'

import type { ItemCardDetailKvListProps } from './type'

import { ITEM_FIELDS, ITEM_FIELDS_WITH_BREAK, DATE_FORMAT } from '@/const'
import { formatDate } from '@/utils/date'
import { priceToLocalString } from '@/utils/text'

import { ItemCardDetailKvListItem } from './ItemCardDetailKvListItem'
import { ListItemGroup } from './ListItemGroup'

export const ItemCardDetailKvList = ({
  item,
  pageType = 'list',
  isOutsideModal = false,
}: ItemCardDetailKvListProps) => {
  return (
    <ul
      className={clsx(
        isOutsideModal && clsx('lg:tw-grid lg:tw-grid-cols-2', 'lg:tw-gap-8')
      )}
    >
      <li>
        <ListItemGroup>
          <ItemCardDetailKvListItem
            keyLabel={ITEM_FIELDS_WITH_BREAK.retailPrice.map((s, i) => (
              <Fragment key={`retailPrice_${i}`}>
                {s}
                {i !== ITEM_FIELDS_WITH_BREAK.retailPrice.length - 1 && (
                  <br className="lg:tw-hidden" />
                )}
              </Fragment>
            ))}
            valueLabel={priceToLocalString(item.retailPrice || 0)}
            pageType={pageType}
            isOutsideModal={isOutsideModal}
            titleWidthClass={isOutsideModal ? 'tw-w-36 lg:tw-w-32' : undefined}
          />
          <ItemCardDetailKvListItem
            keyLabel={ITEM_FIELDS.salonPrice}
            valueLabel={priceToLocalString(item.salonPrice || 0)}
            pageType={pageType}
            isOutsideModal={isOutsideModal}
            titleWidthClass={isOutsideModal ? 'tw-w-36 lg:tw-w-32' : undefined}
          />
          <ItemCardDetailKvListItem
            keyLabel={ITEM_FIELDS.volume}
            valueLabel={item.volume}
            pageType={pageType}
            isOutsideModal={isOutsideModal}
            titleWidthClass={isOutsideModal ? 'tw-w-36 lg:tw-w-32' : undefined}
          />
        </ListItemGroup>
      </li>
      <li>
        <ListItemGroup>
          <ItemCardDetailKvListItem
            keyLabel={ITEM_FIELDS.janCode}
            valueLabel={item.janCode}
            pageType={pageType}
            isOutsideModal={isOutsideModal}
            titleWidthClass={isOutsideModal ? 'tw-w-36 lg:tw-w-24' : undefined}
          />
          <ItemCardDetailKvListItem
            keyLabel={ITEM_FIELDS.itemCode}
            valueLabel={item.itemCode}
            pageType={pageType}
            isOutsideModal={isOutsideModal}
            titleWidthClass={isOutsideModal ? 'tw-w-36 lg:tw-w-24' : undefined}
          />
          <ItemCardDetailKvListItem
            keyLabel={ITEM_FIELDS.latestOrderDate}
            valueLabel={
              (item.latestOrderDate
                ? formatDate(item.latestOrderDate, DATE_FORMAT.DISPLAY_YYYYMMDD)
                : undefined) || '-'
            }
            pageType={pageType}
            isOutsideModal={isOutsideModal}
            titleWidthClass={isOutsideModal ? 'tw-w-36 lg:tw-w-24' : undefined}
          />
          <ItemCardDetailKvListItem
            keyLabel={ITEM_FIELDS.salesStartDate}
            valueLabel={
              (item.salesStartDate
                ? formatDate(item.salesStartDate, DATE_FORMAT.DISPLAY_YYYYMMDD)
                : undefined) || '-'
            }
            pageType={pageType}
            isOutsideModal={isOutsideModal}
            titleWidthClass={isOutsideModal ? 'tw-w-36 lg:tw-w-24' : undefined}
          />
          {/* 「返品可否」は表示しない
          <ItemCardDetailKvListItem
            keyLabel={ITEM_FIELDS.isNonReturnable}
            valueLabel={
              item.isNonReturnable
                ? NON_RETURNABLE_FLG_VALUE.true
                : NON_RETURNABLE_FLG_VALUE.false
            }
            pageType={pageType}
            isOutsideModal={isOutsideModal}
            titleWidthClass={isOutsideModal ? 'tw-w-36 lg:tw-w-24' : undefined}
          />
          */}
        </ListItemGroup>
      </li>
    </ul>
  )
}
