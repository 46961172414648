import { useMutation } from 'react-query'

import { Methods } from '@/api/v1/change-temp-password'
import { userKeyFactory } from '@/hooks/features/user/key'
import { aspidaClient } from '@/lib/aspida'
import { MutationConfig, queryClient } from '@/lib/react-query'

export type ChangeTempPasswordDTO = {
  data: Methods['put']['reqBody']
}

export const changeTempPassword = ({ data }: ChangeTempPasswordDTO) => {
  return aspidaClient.v1.change_temp_password.$put({ body: data })
}

type UseChangeTempPasswordOptions = {
  config?: MutationConfig<typeof changeTempPassword>
}

export const useChangeTempPassword = ({
  config,
}: UseChangeTempPasswordOptions) => {
  return useMutation({
    ...config,
    mutationFn: changeTempPassword,
    onSuccess: () => {
      queryClient.invalidateQueries(userKeyFactory.me())
    },
  })
}
