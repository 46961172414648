import type { ItemCardWithAddCartFormProps } from './type'

import { OrderUpdItem } from '@/api/@types'
import {
  ItemOrderCard,
  ItemOrderDetailModal,
  ItemOrderEditModal,
} from '@/components/features/item'
import {
  useUpdateCartItem,
  cartKeyFactory,
  useDeleteCartItem,
} from '@/hooks/features/cart'
import { useModal } from '@/hooks/util'
import { queryClient } from '@/lib/react-query'

export const ItemCardWithAddCartForm = ({
  cartItem,
  isVisiblePurchasePrice = false,
}: ItemCardWithAddCartFormProps) => {
  const detailModal = useModal()
  const editModal = useModal()

  const mutator = useUpdateCartItem({
    config: {
      onSuccess: () => {
        // Note: カート内の更新
        queryClient.invalidateQueries(cartKeyFactory.all)

        editModal.close()
      },
    },
  })

  const deleter = useDeleteCartItem({
    config: {
      onSuccess: () => {
        // Note: カート内の更新
        queryClient.invalidateQueries(cartKeyFactory.all)
      },
    },
  })

  const handleOpenEditModal = () => {
    detailModal.close()
    editModal.open()
  }

  const handleDeleteItem = () => {
    deleter.mutateAsync({
      itemCode: cartItem.item.itemCode,
    })
  }

  const handleEditItem = (data: OrderUpdItem[]) => {
    if (data.length === 0) return
    mutator.mutateAsync({
      data: data[0],
    })
  }

  return (
    <div>
      <div>
        <ItemOrderCard
          item={cartItem.item}
          salesTypeQuantities={cartItem.salesTypeQuantities}
          isLoadingDelete={deleter.isLoading}
          isVisiblePurchasePrice={isVisiblePurchasePrice}
          onClickEdit={editModal.open}
          onClickDetail={detailModal.open}
          onClickDelete={handleDeleteItem}
        />
        <ItemOrderDetailModal
          item={cartItem.item}
          salesTypeQuantities={cartItem.salesTypeQuantities}
          editable
          isVisiblePurchasePrice={isVisiblePurchasePrice}
          isOpen={detailModal.isOpen}
          onClose={detailModal.close}
          onOpenEdit={handleOpenEditModal}
        />
        <ItemOrderEditModal
          cartItem={cartItem}
          disabled={cartItem.item.itemStatus !== 'available'}
          isVisiblePurchasePrice={isVisiblePurchasePrice}
          isSubmittingEdit={mutator.isLoading}
          isSubmittingDelete={deleter.isLoading}
          isOpen={editModal.isOpen}
          onDelete={handleDeleteItem}
          onSubmit={handleEditItem}
          onClose={editModal.close}
        />
      </div>
    </div>
  )
}
