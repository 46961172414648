import { useRouter } from 'next/router'

import { LoadingOverlay } from '@/components/ui'
import { ROUTE } from '@/const'
import { useSession } from '@/hooks/features/auth'

import { withLimitedMode } from '../WithLimitedMode'
import { WithForcePasswordChangeProps } from './type'

/**
 * パスワードリセットが必要なユーザーのみアクセスを許可
 * ログイン済みユーザーがアクセスを試みた場合はトップページへリダイレクト
 * ログインしていないユーザーがアクセスを試みた場合はログインページへリダイレクト
 */
export const withForcePasswordChange = (
  PageComponent: WithForcePasswordChangeProps
) => {
  const ForcePasswordChangePage = (props: any) => {
    const router = useRouter()
    const { isAuthenticated, isLoading, isPasswordResetRequired } = useSession()

    if (isAuthenticated) {
      router.push(ROUTE.top)
      return <></>
    }

    if (isLoading) {
      return <LoadingOverlay isLoading={true} />
    }

    if (isPasswordResetRequired) {
      return <PageComponent {...props} />
    }

    router.push(ROUTE.login)
    return null
  }

  return withLimitedMode(ForcePasswordChangePage)
}
