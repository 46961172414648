import { useMemo, useState } from 'react'

import { useContentfulMaintenance } from '@/hooks/features/contentfulMaintenance'

import { MainContent } from './MainContent'

export const MaintenanceScreen = () => {
  const { data, isLoading } = useContentfulMaintenance({
    config: {
      onSuccess: () => {
        setShowDefault(false)
      },
      onError: () => {
        setShowDefault(true)
      },
    },
  })
  const [showDefault, setShowDefault] = useState<boolean>(false)

  const item = useMemo(() => {
    return data && data.items.length >= 1 ? data.items[0] : null
  }, [data])

  const document = item?.fields.content

  return (
    <MainContent
      document={document}
      isLoading={isLoading}
      showDefault={showDefault}
    />
  )
}
