import { Dialog } from '@/components/ui'

import { Form } from './Form'
import { ItemOrderEditModalProps } from './type'

export const ItemOrderEditModal = ({
  cartItem,
  disabled,
  isVisiblePurchasePrice,
  isOpen,
  isSubmittingDelete,
  isSubmittingEdit,
  onSubmit,
  onClose,
  onDelete,
}: ItemOrderEditModalProps) => {
  return (
    <Dialog title={'発注内容の編集'} isOpen={isOpen}>
      <Form
        cartItem={cartItem}
        disabled={disabled}
        isSubmittingEdit={isSubmittingEdit}
        isSubmittingDelete={isSubmittingDelete}
        isVisiblePurchasePrice={isVisiblePurchasePrice}
        onSubmit={onSubmit}
        onClose={onClose}
        onDelete={onDelete}
      />
    </Dialog>
  )
}
