import clsx from 'clsx'

import type { ReadonlyItemOrderUnitFormFieldProps } from './type'

import type { SalesType } from '@/api/@types'
import { useSalesTypesMaster } from '@/stores'

export const ReadonlyItemOrderUnitFormField = ({
  salesTypeQuantities,
  isVisiblePurchasePrice = false,
}: ReadonlyItemOrderUnitFormFieldProps) => {
  const { salesTypesMaster } = useSalesTypesMaster()
  const getSalesTypeLabel = (salesTypeCode: SalesType['salesTypeCode']) => {
    return salesTypesMaster.find(
      (salesType) => salesType.salesTypeCode === salesTypeCode
    )?.salesTypeName
  }

  return (
    <ul className={clsx('tw-flex tw-flex-col', 'tw-gap-2')}>
      {salesTypeQuantities.map((salesTypeQuantity, i) => (
        <li
          key={i}
          className={clsx(
            'tw-rounded-lg',
            'tw-p-2',
            'tw-bg-primary-50',
            'tw-border tw-border-primary-100'
          )}
        >
          <div className={clsx('tw-flex tw-items-center', 'tw-gap-2')}>
            <div className={clsx('tw-flex-none', 'tw-w-[30%] lg:tw-w-[15%]')}>
              <p className={clsx('tw-text-sm')}>{salesTypeQuantity.orderNo}</p>
            </div>
            <div
              className={clsx(
                'tw-flex-none',
                'tw-h-8 tw-w-[40%] lg:tw-w-[20%]',
                'tw-flex tw-items-center',
                'tw-px-2',
                'tw-rounded',
                'tw-bg-gray-100',
                'tw-border tw-border-gray-300'
              )}
            >
              <p className={clsx('tw-text-sm')}>
                {getSalesTypeLabel(salesTypeQuantity.salesType ?? '')}
              </p>
            </div>
            <div
              className={clsx(
                'tw-flex-1 lg:tw-flex-none',
                'tw-h-8 lg:tw-w-[15%]',
                'tw-flex tw-items-center',
                'tw-px-2',
                'tw-rounded',
                'tw-bg-gray-100',
                'tw-border tw-border-gray-300'
              )}
            >
              <p className={clsx('tw-text-sm')}>{salesTypeQuantity.quantity}</p>
            </div>
            <div
              className={clsx(
                'tw-hidden lg:tw-flex lg:tw-items-center',
                'lg:tw-flex-1',
                'lg:tw-h-8',
                'lg:tw-px-2',
                'lg:tw-rounded',
                'lg:tw-bg-gray-100',
                'lg:tw-border lg:tw-border-gray-300'
              )}
            >
              <p className={clsx('tw-text-sm')}>{salesTypeQuantity.note}</p>
            </div>
          </div>

          {isVisiblePurchasePrice && salesTypeQuantity.purchasePrice && (
            <div
              className={clsx('tw-mt-1', 'tw-flex tw-items-center', 'tw-gap-2')}
            >
              <div
                className={clsx('tw-flex-none', 'tw-w-[20%] lg:tw-w-[15%]')}
              />
              <div className={clsx('tw-w-full')}>
                <div
                  className={clsx(
                    'tw-items-center tw-gap-2 lg:tw-flex',
                    'tw-rounded tw-border tw-border-gray-300 lg:tw-border-none'
                  )}
                >
                  <div
                    className={clsx(
                      'tw-w-full',
                      'tw-flex tw-items-center tw-justify-between',
                      'tw-py-1 tw-px-2',
                      'tw-border-gray-300 lg:tw-rounded lg:tw-border'
                    )}
                  >
                    <p className={clsx('tw-text-xs tw-text-gray-600')}>
                      購入価格
                    </p>
                    <p className={clsx('tw-text-xs')}>
                      ¥{salesTypeQuantity.purchasePrice.toLocaleString()}
                    </p>
                  </div>
                  <div
                    className={clsx(
                      'tw-w-full',
                      'tw-flex tw-items-center tw-justify-between',
                      'tw-py-1 tw-px-2',
                      'tw-border-t tw-border-gray-300 lg:tw-rounded lg:tw-border'
                    )}
                  >
                    <p className={clsx('tw-text-xs tw-text-gray-600')}>小計</p>
                    <p className={clsx('tw-text-xs')}>
                      ¥
                      {(
                        salesTypeQuantity.purchasePrice *
                        salesTypeQuantity.quantity
                      ).toLocaleString()}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </li>
      ))}
    </ul>
  )
}
