import { atom, useRecoilState } from 'recoil'

type AuthState = {
  // NOTE: このisLoggedInは現在のログイン状態を表すステータスではない。ログイン状態は useSession から isAuthenticated を参照すること。
  //       こちらの用途としてはログインページでログインした直後にtrueにしたりログアウト時にfalseにしているので、ログイン状態を更新するためのトリガー用のステートになっている。
  isLoggedIn: boolean
  isPasswordResetRequired: boolean
}

export const authState = atom<AuthState>({
  key: 'auth',
  default: {
    isLoggedIn: false,
    isPasswordResetRequired: false,
  },
})

export const useAuth = () => {
  const [auth, setAuth] = useRecoilState(authState)

  return { auth, setAuth }
}
