import clsx from 'clsx'
import ja from 'date-fns/locale/ja'
import { forwardRef } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'

import type { InputDateProps } from './type'

import {
  baseClassNames,
  errorClassNames,
  widths,
  heights,
  texts,
  rounds,
} from '@/components/ui/input/const'
import { formatDate } from '@/utils/date'

import 'react-datepicker/dist/react-datepicker.css'

registerLocale('ja', ja)

export const InputDate = forwardRef<HTMLButtonElement, InputDateProps>(
  (
    {
      value,
      onChange,
      onBlur,
      closeOnScroll = true,
      dateFormat = 'yyyy-MM-dd',
      disabled,
      placeholder,
      popperPlacement = 'auto',
      isError,
      height = 'lg',
      rounded = 'all',
    },
    ref
  ) => {
    return (
      <DatePicker
        selected={value ? new Date(value) : undefined}
        onChange={(date) => onChange(formatDate(date, dateFormat))}
        onBlur={onBlur}
        placeholderText={placeholder}
        isClearable={true}
        disabledKeyboardNavigation={true}
        customInput={
          // software keyboardを表示されないためにbuttonタグを使う
          <button
            ref={ref}
            type={'button'}
            className={clsx(
              baseClassNames,
              isError && !disabled && errorClassNames,
              widths['full'],
              heights[height],
              texts[height],
              rounds[rounded],
              !value && 'tw-text-gray-400',
              'tw-text-left'
            )}
          >
            {value || placeholder}
          </button>
        }
        popperPlacement={popperPlacement}
        closeOnScroll={closeOnScroll}
        disabled={disabled}
        locale="ja"
        dateFormat={dateFormat}
        showPopperArrow={false}
        todayButton="今日"
        calendarClassName="alshain-calendar"
        clearButtonClassName="alshain-calendar-input-field-clear-button"
      />
    )
  }
)

InputDate.displayName = 'InputDate'
