import { useQuery } from 'react-query'

import { aspidaClient } from '@/lib/aspida'
import { QueryConfig } from '@/lib/react-query'

import { favoriteKeyFactory } from './key'

export const getFavoriteDetail = ({
  pathParams,
}: Parameters<typeof favoriteKeyFactory.detail>[0]) => {
  return aspidaClient.v1.favorites
    ._setNo(pathParams.setNo)
    ._storeCode(pathParams.storeCode)
    .$get()
}

export type UseFavoriteDetailOptions = Parameters<
  typeof favoriteKeyFactory.detail
>[0] & {
  config?: QueryConfig<typeof getFavoriteDetail>
}

export const useFavoriteDetail = ({
  pathParams,
  config,
}: UseFavoriteDetailOptions) => {
  const queryConfig: QueryConfig<typeof getFavoriteDetail> = {
    ...config,
    queryKey: favoriteKeyFactory.detail({ pathParams }),
    queryFn: () => getFavoriteDetail({ pathParams }),
  }
  return useQuery(queryConfig)
}
