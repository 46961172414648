import { Edit, Settings, Sort } from '@mui/icons-material'
import clsx from 'clsx'
import { useEffect, useRef } from 'react'
import { WindowVirtualizerProps } from 'virtua'

import { IconWrapper, TextButton } from '@/components/ui'
import { useEnterIndex } from '@/hooks/util'

import { ItemCardWithAddCartForm } from '../ItemCardWithAddCartForm'
import { OperationColumn } from '../OperationColumn'
import { ListProps } from './type'

export const List = ({
  control,
  register,
  itemsArray,
  currentFavorite,
  onClickSortButton,
  onClickEditButton,
  onClickSettingButton,
}: ListProps) => {
  // ESM では React18 が必須のため、 CJS の形式でインポート
  // https://github.com/inokawa/virtua?tab=readme-ov-file#react
  const { WindowVirtualizer } = require('virtua')
  // Note: tabIndex の挙動を enter でも動作するよう　hooksを設定
  const { refresh: refreshElement } = useEnterIndex()
  // １配列あたりの商品数0
  const ref = useRef<HTMLUListElement>(null)

  const items = itemsArray.flatMap((itemsArrayItem) => itemsArrayItem.items)

  /** Note: enterIndex によるイベントを付与をし直す */
  const handleRefreshEnterIndex = () => {
    setTimeout(() => refreshElement(), 1000)
  }
  const virtualizerProps: Omit<WindowVirtualizerProps, 'children'> = {
    onRangeChange: handleRefreshEnterIndex,
  }

  // IOSのChromeのみtabindexが機能しないため、visibilityを変更することで対応
  useEffect(() => {
    if (!ref.current) return

    const virtualizer = ref.current.childNodes[0] as HTMLElement
    virtualizer.style.visibility = 'visible'
  }, [])

  return (
    <>
      <div
        className={clsx(
          'tw-w-full',
          'tw-flex tw-items-center tw-justify-between',
          'tw-px-2 tw-py-2',
          'tw-border-b tw-border-gray-300'
        )}
      >
        <OperationColumn>
          {currentFavorite.isEditableByMe && (
            <>
              <TextButton variant="tertiary" onClick={onClickSortButton}>
                <span
                  className={clsx('tw-inline-flex tw-items-center tw-gap-1')}
                >
                  <IconWrapper>
                    <Sort sx={{ fontSize: 24 }} />
                  </IconWrapper>
                  <span>並び替え</span>
                </span>
              </TextButton>
              <TextButton variant="tertiary" onClick={onClickEditButton}>
                <span
                  className={clsx('tw-inline-flex tw-items-center tw-gap-1')}
                >
                  <IconWrapper>
                    <Edit sx={{ fontSize: 24 }} />
                  </IconWrapper>
                  <span>リストの編集</span>
                </span>
              </TextButton>
              {currentFavorite.isEditableByMe && (
                <TextButton variant="tertiary" onClick={onClickSettingButton}>
                  <span
                    className={clsx('tw-inline-flex tw-items-center tw-gap-1')}
                  >
                    <IconWrapper>
                      <Settings sx={{ fontSize: 24 }} />
                    </IconWrapper>
                    <span>種別設定</span>
                  </span>
                </TextButton>
              )}
            </>
          )}
        </OperationColumn>
      </div>

      <ul className={clsx('tw-px-2')} ref={ref}>
        <WindowVirtualizer {...virtualizerProps}>
          {items.map((item, index) => (
            <li
              key={item.itemCode}
              className={clsx(
                'tw-h-auto',
                'tw-flex tw-items-start',
                'tw-rounded tw-border tw-border-gray-300',
                'tw-mt-2 tw-gap-x-1'
              )}
            >
              <div className={clsx('tw-flex-1 tw-px-2')}>
                <ItemCardWithAddCartForm
                  item={item}
                  itemIndex={index}
                  showPrice={item.itemStatus === 'available'}
                  disabled={item.itemStatus !== 'available'}
                  control={control}
                  register={register}
                  onAfterChange={handleRefreshEnterIndex}
                />
              </div>
            </li>
          ))}
        </WindowVirtualizer>
      </ul>
    </>
  )
}
