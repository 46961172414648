import { ContentPaste, FormatListBulleted, Palette } from '@mui/icons-material'
import clsx from 'clsx'

import type { ItemRelatedLinksProps } from './type'

import { LinkText } from '@/components/ui'
import { ROUTE, LINEUP_TITLE } from '@/const'

export const ItemRelatedLinks = ({ item, disabled }: ItemRelatedLinksProps) => {
  return (
    <div
      className={clsx(
        'lg:tw-w-pcHalf',
        'tw-flex tw-items-center',
        'tw-gap-8',
        'tw-py-2'
      )}
    >
      <div>
        <LinkText href={ROUTE.itemDetail(item.itemCode)} disabled={disabled}>
          <span>
            <ContentPaste
              sx={{ width: 18 }}
              className={clsx('tw-text-primary-500')}
            />
          </span>
          <span className={clsx('tw-ml-1', 'tw-text-sm')}>詳細</span>
        </LinkText>
      </div>

      {item.lineupCode && (
        <LinkText href={ROUTE.lineups(item.lineupCode)} disabled={disabled}>
          <span>
            <FormatListBulleted
              sx={{ width: 18 }}
              className={clsx('tw-text-primary-500')}
            />
          </span>
          <span className={clsx('tw-ml-1', 'tw-text-sm')}>{LINEUP_TITLE}</span>
        </LinkText>
      )}

      {item.hairColorAttribute && (
        <LinkText
          href={ROUTE.colorCharts(item.hairColorAttribute.groupId)}
          disabled={disabled}
        >
          <span>
            <Palette
              sx={{ width: 18 }}
              className={clsx('tw-text-primary-500')}
            />
          </span>
          <span className={clsx('tw-ml-1', 'tw-text-sm')}>すべての色</span>
        </LinkText>
      )}
    </div>
  )
}
