import { useEffect, useRef } from 'react'

import { ListItemProps } from './type'

export const ListItem = ({
  children,
  index,
  resizer,
  top,
  visible,
}: ListItemProps) => {
  const ref = useRef<HTMLDivElement>(null)

  //console.log(top)

  useEffect(() => {
    const el = ref.current
    resizer.subscribe(el as Element, index)

    return () => {
      if (el) {
        resizer.unsubscribe(el as Element)
      }
    }
  }, [index, resizer])

  return (
    <div
      ref={ref}
      style={{
        visibility: visible ? 'visible' : 'hidden',
        position: 'absolute',
        transform: `translateY(${top}px)`,
        width: '100%',
      }}
    >
      {children}
    </div>
  )
}
