import { KeyboardArrowRight } from '@mui/icons-material'
import clsx from 'clsx'
import React from 'react'

import type { BrandDisclosureProps } from './type'

import { Disclosure, IconWrapper } from '@/components/ui'
import { useDisclosure } from '@/hooks/util'

export const BrandDisclosure = ({
  brandGroup,
  onClick,
}: BrandDisclosureProps) => {
  const { isOpen, toggle } = useDisclosure()
  return (
    <Disclosure
      isOpen={isOpen}
      headerClassName={'tw-bg-primary-50 tw-text-sm tw-font-bold'}
      withSpace={{
        title: true,
        content: false,
      }}
      toggle={toggle}
      heading={
        <span className={clsx('text-sm tw-font-bold')}>
          {brandGroup.groupName}
        </span>
      }
    >
      <ul className={clsx('tw-ml-4')}>
        {brandGroup.brands?.map((brand) => (
          <li
            key={brand.brandCode}
            className={clsx('tw-h-11', 'tw-border-b tw-border-gray-300')}
          >
            <button
              className={clsx(
                'tw-h-full tw-w-full',
                'tw-flex tw-items-center tw-justify-between',
                'tw-pr-4',
                'tw-cursor-pointer',
                'tw-transition tw-duration-stateChange',
                'hover:tw-opacity-hover'
              )}
              onClick={() => onClick(brand.brandCode)}
            >
              <span
                className={clsx('tw-text-left tw-text-sm', 'tw-line-clamp-2')}
              >
                {brand.brandName}
              </span>
              <IconWrapper>
                <KeyboardArrowRight
                  sx={{ size: 24 }}
                  className={clsx('tw-text-gray-700')}
                />
              </IconWrapper>
            </button>
          </li>
        ))}
      </ul>
    </Disclosure>
  )
}
