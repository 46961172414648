import type { UpdateFavoriteListTitleModalWithFetchDetailProps } from './type'

import { UpdateFavoriteListTitleModal } from '@/components/features/favorite'
import { LoadingOverlay } from '@/components/ui'
import { ERROR_FIELD_NAMES } from '@/const'
import { useFavoriteDetail } from '@/hooks/features/favorite'
import { useNotification } from '@/hooks/util'
import { getErrorMessages } from '@/utils/text'

export const UpdateFavoriteListTitleModalWithFetchDetail = ({
  isOpen,
  favorite,
  onClickConfirm,
  onClickCancel,
}: UpdateFavoriteListTitleModalWithFetchDetailProps) => {
  const { notify } = useNotification()

  // お気に入り詳細を取得する
  const favoriteDetail = useFavoriteDetail({
    pathParams: {
      setNo: favorite.setNo,
      storeCode: favorite.storeCode,
    },
    config: {
      onError: (error) => {
        if (error.response?.status === 400) {
          const title = 'お気に入りの詳細情報を取得できませんでした'
          const messages = getErrorMessages({
            fields: error.response.data.fields,
            fieldNames: ERROR_FIELD_NAMES,
          })
          notify({
            id: 'OPERATIONAL_ERROR_MESSAGE.UPSERT_FAVORITE_LIST_MODAL_WITH_FETCH_DETAIL',
            type: 'error',
            message: `${title}\n${messages.join('\n')}`,
          })
        }
      },
    },
  })

  const isLoading = !favoriteDetail.data || favoriteDetail.isFetching

  return (
    <>
      <LoadingOverlay isLoading={isLoading} />
      {!isLoading && (
        <UpdateFavoriteListTitleModal
          isOpen={isOpen}
          favorite={favoriteDetail.data}
          onClickConfirm={() => {
            if (onClickConfirm) onClickConfirm()
            favoriteDetail.refetch()
          }}
          onClickCancel={onClickCancel}
        />
      )}
    </>
  )
}
