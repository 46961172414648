import { useMutation } from 'react-query'

import { Methods } from '@/api/v1/cart'
import { aspidaClient } from '@/lib/aspida'
import { queryClient } from '@/lib/react-query'
import { MutationConfig } from '@/lib/react-query'

import { cartKeyFactory } from './key'

export type UpdateCartItemDTO = {
  data: Methods['patch']['reqBody']
}

export const updateCartItem = ({ data }: UpdateCartItemDTO) => {
  return aspidaClient.v1.cart.$patch({ body: data })
}

type UseUpdateCartItemOptions = {
  config?: MutationConfig<typeof updateCartItem>
}

export const useUpdateCartItem = ({ config }: UseUpdateCartItemOptions) => {
  return useMutation({
    onSuccess: () => queryClient.invalidateQueries(cartKeyFactory.itemCount()),
    ...config,
    mutationFn: updateCartItem,
  })
}
