import clsx from 'clsx'
import * as React from 'react'

import type { ToggleButtonsItemProps } from './type'

export const ToggleButtonsItem = ({
  label,
  selected,
  handleClick,
}: ToggleButtonsItemProps) => {
  return (
    <li
      className={clsx(
        'tw-flex tw-items-center tw-justify-center',
        'tw-transition tw-duration-stateChange',
        selected
          ? 'tw-rounded tw-bg-primary-500 tw-text-white'
          : 'tw-text-primary-500'
      )}
    >
      <button
        className={clsx(
          'tw-h-full tw-w-full',
          'tw-flex tw-items-center tw-justify-center',
          'tw-font-bold'
        )}
        onClick={handleClick}
      >
        {label}
      </button>
    </li>
  )
}

ToggleButtonsItem.displayName = 'ToggleButtonsItem'
