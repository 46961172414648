import { CartItem } from '@/api/@types'

import { CartItemFormVO } from './type'

export const convertCartItemToFormValues = (
  cartItem: CartItem
): CartItemFormVO => {
  return {
    items: [
      {
        itemCode: cartItem.item.itemCode,
        salesTypeQuantities: cartItem.salesTypeQuantities.map((s) => ({
          salesType: s.salesType,
          quantity: s.quantity,
          note: s.note,
        })),
      },
    ],
  }
}
