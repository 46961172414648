import { WarningAmber } from '@mui/icons-material'
import clsx from 'clsx'

import type { WarningTextProps } from './type'

import { WARNING_ICON_SIZE, WARNING_ICON_SPACE } from './const'

export const WarningText = ({ children, size = 'large' }: WarningTextProps) => {
  return (
    <div className={clsx('tw-text-center')}>
      <WarningAmber
        sx={{
          fontSize: WARNING_ICON_SIZE[size],
          marginBottom: `${WARNING_ICON_SPACE[size]}px`,
        }}
        className={clsx('tw-text-labelRed-500')}
      />
      {children}
    </div>
  )
}
