import { format, addDays, addMinutes } from 'date-fns'
import ja from 'date-fns/locale/ja'

import { range } from './array'

export const formatDate = (
  date: string | number | Date | null,
  template: string = 'yyyy-MM-dd hh:mm'
) => {
  if (date === null) return undefined
  return format(new Date(date), template, {
    locale: ja,
  })
}

/**
 * dayCount分日数を足したDateを取得する
 */
export const addDaysToDate = (
  date: string | number | Date,
  dayCount: number
) => {
  return addDays(new Date(date), dayCount)
}

/**
 * Contentfulのqueryに用いる時刻形式
 */
export const formatContentfulDate = (
  date: Date,
  template: string = "yyyy-MM-dd'T'HH:mm:ss'Z'"
) => {
  return format(
    addMinutes(date, date.getTimezoneOffset()), // UTC時刻でフォーマットする
    template
  )
}

/**
 * 当月の１日のDateを返す
 */
export const getDateAsFirstDayOfThisMonth = () => {
  const now = new Date()
  return new Date(now.getFullYear(), now.getMonth())
}

/** 現在の年を返す */
export const currentYear = () => {
  const now = new Date()
  return now.getFullYear()
}

/** 現在の月を返す */
export const currentMonth = () => {
  const now = new Date()
  return now.getMonth() + 1
}

/**指定の年から現在の年までの選択肢を生成する */
export const generateYearOptions = (startYear: number) => {
  const now = new Date()
  return range(startYear, now.getFullYear() + 1).map((year) => ({
    label: year,
    value: year,
  }))
}

/**月の選択肢を生成する */
export const generateMonthOptions = () => {
  return [
    { label: '01', value: '01' },
    { label: '02', value: '02' },
    { label: '03', value: '03' },
    { label: '04', value: '04' },
    { label: '05', value: '05' },
    { label: '06', value: '06' },
    { label: '07', value: '07' },
    { label: '08', value: '08' },
    { label: '09', value: '09' },
    { label: '10', value: '10' },
    { label: '11', value: '11' },
    { label: '12', value: '12' },
  ]
}
