import { InfoOutlined } from '@mui/icons-material'
import clsx from 'clsx'

export const CartDateErrorMessage = () => {
  return (
    <div
      className={clsx('tw-flex tw-items-center tw-justify-end tw-space-x-2')}
    >
      <InfoOutlined sx={{ fontSize: 14 }} />
      <div className={clsx('tw-flex tw-text-left')}>
        <span>
          お届け可能な納品日が更新されました。ご希望納品日を再選択してください。
        </span>
      </div>
    </div>
  )
}
