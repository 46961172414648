import { atom, selector, useRecoilValue } from 'recoil'

import type { AtomEffect } from 'recoil'

import { appEmitter, APP_EVENT_NAMES } from '@/utils/appEmitter'

/**
 * イベントがemitされたらストアの値の変更を行う（ eventName: APP_EVENT_NAMES.CHANGE_MENTENANCE_MODE ）
 */
const subscriptionEffect: AtomEffect<TMaintenanceMode> = ({ setSelf }) => {
  const listener = (value: boolean) => {
    setSelf(value)
  }

  // Subscribe to changes and update the atom value
  appEmitter.on(APP_EVENT_NAMES.CHANGE_MENTENANCE_MODE, listener)

  // Cleanup subscription
  return () => {
    appEmitter.off(APP_EVENT_NAMES.CHANGE_MENTENANCE_MODE, listener)
  }
}

// ストア

type TMaintenanceMode = boolean

export const atomMaintenanceMode = atom<TMaintenanceMode>({
  key: 'atomMaintenanceMode',
  default: false,
  effects: [subscriptionEffect],
})

// selector

const selectorMaintenanceMode = selector({
  key: 'selectorMaintenanceMode',
  get: ({ get }) => {
    return get(atomMaintenanceMode)
  },
})

// hooks

export const useSelectorMaintenanceMode = () => {
  return useRecoilValue(selectorMaintenanceMode)
}
