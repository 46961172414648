import aspida from '@aspida/axios'

import type { AxiosExtendError } from './type'
import type { AxiosInstance } from 'axios'

import refreshTokenApi from '@/api/v1/auth/token/$api'
import changeEmailAuthApi from '@/api/v1/change-email-auth/$api'
import loginApi from '@/api/v1/login/$api'

const checkAPI = (error: AxiosExtendError, targetPaths: string[]) => {
  const incomingPath = `${error.config.baseURL}${error.config.url}`.replace(
    /\/$/,
    ''
  )
  return targetPaths.includes(incomingPath)
}

/** トークンリフレッシュAPIかどうかの判定処理 */
export const checkTokenRefreshAPI = (
  axios: AxiosInstance,
  error: AxiosExtendError
) => {
  const notRetryPaths = [refreshTokenApi].map((api) =>
    api(aspida(axios)).$path().replace(/\/$/, '')
  )

  return checkAPI(error, notRetryPaths)
}

/** リトライ対象外APIかどうかの判定処理 */
export const checkNotRetryAPI = (
  axios: AxiosInstance,
  error: AxiosExtendError
) => {
  const notRetryPaths = [loginApi, changeEmailAuthApi].map((api) =>
    api(aspida(axios)).$path().replace(/\/$/, '')
  )

  return checkAPI(error, notRetryPaths)
}

export const isAxiosError = (error: any): error is AxiosExtendError => {
  return !!error.isAxiosError
}

/** errorを返却したAPIのpathがtargetPathsに含まれるか否か */
export const isIncludedPath = (
  error: AxiosExtendError,
  targetPaths: string[]
) => {
  const incomingPath = `${error.config.baseURL}${error.config.url}`.replace(
    /\/$/,
    ''
  )
  const sanitizedTargetPaths = targetPaths.map((path) =>
    path.replace(/\/$/, '')
  )
  return sanitizedTargetPaths.includes(incomingPath)
}
