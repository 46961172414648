import { useMutation } from 'react-query'

import { Methods } from '@/api/v1/change-invoice-email-auth'
import { aspidaClient } from '@/lib/aspida'
import { MutationConfig } from '@/lib/react-query'

export type ChangeInvoiceEmailAuthDTO = Methods['post']['reqBody']
export const changeInvoiceEmailAuth = (data: ChangeInvoiceEmailAuthDTO) => {
  return aspidaClient.v1.change_invoice_email_auth.$post({ body: data })
}

type UseChangeInvoiceEmailAuthOptions = {
  config?: Omit<MutationConfig<typeof changeInvoiceEmailAuth>, 'onSuccess'>
}

export const useChangeInvoiceEmailAuth = ({
  config,
}: UseChangeInvoiceEmailAuthOptions) => {
  return useMutation({
    ...config,
    mutationFn: changeInvoiceEmailAuth,
  })
}
