import { useQuery } from 'react-query'

import { contentfulClient } from '@/lib/contentful'
import { CONTENT_TYPES, SORT_KEY } from '@/lib/contentful/const'
import { QueryConfig } from '@/lib/react-query'
import type { ITermsOfServiceFields } from '@/types/generated/contentful'

import { contentfulTermsOfServiceKeyFactory } from './key'

// 1件しか取得しないが配列で返ってくるので複数形にする
export const getContentfulTermsOfServices = () => {
  return contentfulClient.getEntries<ITermsOfServiceFields>({
    content_type: CONTENT_TYPES.termsOfService,
    order: SORT_KEY.termsOfService,
    limit: 1,
  })
}

export type UseContentfulTermsOfServicesOptions = {
  config?: QueryConfig<typeof getContentfulTermsOfServices>
}

export const useContentfulTermsOfServices = ({
  config,
}: UseContentfulTermsOfServicesOptions) => {
  const queryConfig: QueryConfig<typeof getContentfulTermsOfServices> = {
    ...config,
    queryKey: contentfulTermsOfServiceKeyFactory.termsOfServices(),
    queryFn: () => getContentfulTermsOfServices(),
  }
  return useQuery(queryConfig)
}
