import clsx from 'clsx'
import * as React from 'react'

import type { ImageProps } from './type'

import { objectFit, objectPosition, defaultNoImageSrc } from './const'

export const Image = React.forwardRef<HTMLImageElement, ImageProps>(
  (
    {
      src,
      alt,
      fit = 'cover',
      align = 'center',
      isEventNone = false,
      noImageSrc = defaultNoImageSrc,
    },
    ref
  ) => {
    return (
      <img
        ref={ref}
        src={src}
        alt={alt}
        className={clsx(
          'tw-w-full',
          objectFit[fit],
          objectPosition[align],
          isEventNone && 'tw-pointer-events-none'
        )}
        onError={(e: any) => (e.target.src = noImageSrc)}
      />
    )
  }
)

Image.displayName = 'Image'
