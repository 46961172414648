import { useState, useCallback, useMemo, useEffect } from 'react'

export const useDisclosures = (length: number, initials?: boolean[]) => {
  const [isOpens, setIsOpens] = useState(
    initials ?? [...Array(length)].map((_) => false)
  )

  useEffect(() => {
    setIsOpens([...Array(length)].map((_) => false))
  }, [length])

  const isAllOpen = useMemo(() => isOpens.every((isOpen) => isOpen), [isOpens])

  const open = useCallback(
    (index: number) => {
      const updatedIsOpens = isOpens.map((isOpen, i) =>
        i === index ? true : isOpen
      )
      setIsOpens(updatedIsOpens)
    },
    [isOpens]
  )
  const close = useCallback(
    (index: number) => {
      const updatedIsOpens = isOpens.map((isOpen, i) =>
        i === index ? false : isOpen
      )
      setIsOpens(updatedIsOpens)
    },
    [isOpens]
  )
  const toggle = useCallback(
    (index: number) => {
      const updatedIsOpens = isOpens.map((isOpen, i) =>
        i === index ? !isOpen : isOpen
      )
      setIsOpens(updatedIsOpens)
    },
    [isOpens]
  )
  const openAll = useCallback(() => {
    const updatedIsOpens = isOpens.map((_) => true)
    setIsOpens(updatedIsOpens)
  }, [isOpens])
  const closeAll = useCallback(() => {
    const updatedIsOpens = isOpens.map((_) => false)
    setIsOpens(updatedIsOpens)
  }, [isOpens])

  return { isOpens, isAllOpen, open, close, toggle, openAll, closeAll }
}
