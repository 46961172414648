import clsx from 'clsx'

import type { DeleteFavoriteItemsModalProps } from './type'

import { DialogWarning, ErrorMessageList } from '@/components/ui'

export const DeleteFavoriteItemsModal = ({
  isOpen = false,
  favoriteName,
  isMutatingDelete = false,
  errorMessages = [],
  onClose,
  onDelete,
}: DeleteFavoriteItemsModalProps) => {
  return (
    <DialogWarning
      title="削除"
      isOpen={isOpen}
      bodyText={
        <div
          className={clsx(
            'tw-mt-4',
            'tw-w-full',
            'tw-flex tw-flex-col',
            'tw-gap-4'
          )}
        >
          {errorMessages.length > 0 && (
            <div className={clsx('tw-mb-2 tw-text-center')}>
              <ErrorMessageList errorMessages={errorMessages} />
            </div>
          )}
          <p className={clsx('tw-text-center tw-text-xs')}>
            選択されている商品を
            <br />
            {`お気に入りリスト「${favoriteName}」から削除します。`}
          </p>
          <p className={clsx('tw-text-center tw-text-xs')}>
            この操作は取り消すことができません。
          </p>
        </div>
      }
      isLoading={isMutatingDelete}
      onCancel={onClose}
      onConfirm={onDelete}
    />
  )
}
