import type { FilteredItemListConditionProps } from './type'

import { conditionText } from '@/utils/text'

export const getConditionsTextList = ({
  conditions,
  brandNames,
  categoryNames,
}: Pick<
  FilteredItemListConditionProps,
  'conditions' | 'brandNames' | 'categoryNames'
>): string[] => {
  return conditions
    ? [
        // ...conditionText({}).text(conditions.keywords), // NOTE: キーワード検索窓を設けたためkeywordsの表示は不要となった
        ...conditionText({}).text(categoryNames?.join(', ')),
        ...conditionText({}).text(brandNames?.join(', ')),
        ...conditionText({ title: 'サロン価格' }).currencyRange({
          valueFrom: conditions.salonPrice?.lower,
          valueTo: conditions.salonPrice?.upper,
        }),
        ...conditionText({ title: 'メーカー希望小売価格' }).currencyRange({
          valueFrom: conditions.retailPrice?.lower,
          valueTo: conditions.retailPrice?.upper,
        }),
        ...conditionText({ title: '販売開始日' }).dateRange({
          valueFrom: conditions.salesStart?.from,
          valueTo: conditions.salesStart?.to,
        }),
      ]
    : []
}
