import clsx from 'clsx'

import type { OperationColumnProps } from './type'

export const OperationColumn = ({ children }: OperationColumnProps) => {
  return (
    <div className={clsx('tw-flex tw-items-center', 'tw-gap-4')}>
      {children}
    </div>
  )
}
