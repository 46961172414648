import * as Contentful from 'contentful'

import {
  CONTENTFUL_SPACE,
  CONTENTFUL_ENVIRONMENT,
  CONTENTFUL_ACCESS_TOKEN,
  CONTENTFUL_PREVIEW_ACCESS_TOKEN,
  CONTENTFUL_PREVIEW_HOST,
} from './const'

export const contentfulClient = Contentful.createClient({
  space: CONTENTFUL_SPACE,
  environment: CONTENTFUL_ENVIRONMENT,
  accessToken: CONTENTFUL_ACCESS_TOKEN,
})

export const contentfulPreviewClient = Contentful.createClient({
  space: CONTENTFUL_SPACE,
  environment: CONTENTFUL_ENVIRONMENT,
  accessToken: CONTENTFUL_PREVIEW_ACCESS_TOKEN,
  host: CONTENTFUL_PREVIEW_HOST,
})

export { isContentfulEntryNotFoundError } from './const'
