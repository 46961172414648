import { useCallback, useMemo } from 'react'

import { unsetScrollLock } from '@/utils/dom'

import { useDisclosure } from './useDisclosure'

export const useModal = (initial = false) => {
  const { isOpen, open, close } = useDisclosure(initial)

  const closeModal = useCallback(() => {
    // NOTE: headlessUIバグ対応
    // 本来この処理は必要ないがheadlessUIのバグによりスクロールができなくなるので対応する
    // 通常この処理がなくても問題ないので少し長めの遅延後にスクロールロック解除を試みる
    setTimeout(unsetScrollLock, 1000)
    close()
  }, [close])

  const toggle = useCallback(() => {
    if (isOpen) {
      closeModal()
    } else {
      open()
    }
  }, [closeModal, isOpen, open])

  return useMemo(
    () => ({ isOpen, open, close: closeModal, toggle }),
    [closeModal, isOpen, open, toggle]
  )
}
