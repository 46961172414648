import { RadioGroup } from '@headlessui/react'
import clsx from 'clsx'
import * as React from 'react'

import { InputRadioGroupItemProps } from './type'

export const InputRadioGroupItem = React.forwardRef<
  HTMLDivElement,
  InputRadioGroupItemProps
>(({ option }, ref) => {
  return (
    <RadioGroup.Option
      as="div"
      value={option.value}
      disabled={option.disabled}
      className={clsx([
        'tw-flex tw-items-center tw-justify-start',
        'tw-py-1 tw-px-2',
        'tw-transition-all tw-duration-100',
      ])}
      ref={ref}
    >
      {({ checked }) => (
        <div
          className={clsx([
            'tw-h-4',
            'tw-flex tw-items-center tw-justify-start',
          ])}
        >
          <span
            className={clsx([
              'tw-ring-2 tw-ring-offset-2',
              checked
                ? 'tw-bg-accentBlue-500 tw-ring-accentBlue-500'
                : 'tw-bg-white tw-ring-gray-400',
              'tw-mt-0.5',
              'tw-h-3 tw-w-3',
              'tw-flex tw-items-center tw-justify-center',
              'tw-rounded-full',
              option.disabled ? 'tw-cursor-not-allowed' : 'tw-cursor-pointer',
              'tw-transition-all tw-duration-stateChange',
            ])}
            aria-hidden="true"
          ></span>
          <div className={clsx(['tw-ml-3', 'tw-flex tw-flex-col'])}>
            <RadioGroup.Label
              as="span"
              className={clsx([
                'tw-block',
                'tw-text-xs tw-font-bold',
                checked ? 'tw-text-accentBlue-500' : 'tw-text-gray-400',
                'tw-transition-all tw-duration-stateChange',
                option.disabled ? 'tw-cursor-not-allowed' : 'tw-cursor-pointer',
              ])}
            >
              {option.label}
            </RadioGroup.Label>
          </div>
        </div>
      )}
    </RadioGroup.Option>
  )
})

InputRadioGroupItem.displayName = 'InputRadioGroupItem'
