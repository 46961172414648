import { useRouter } from 'next/router'

import { ErrorPage } from '@/components/pages/Error'
import { useContentfulPreviewTermsOfService } from '@/hooks/features/contentfulTermsOfServices'
import { CONTENTFUL_PREVIEW_SECRET } from '@/lib/contentful/const'

import { MainContent } from '../MainContent'

export const MaintenanceScreenPreview = () => {
  const router = useRouter()

  const { data: maintenance, isLoading } = useContentfulPreviewTermsOfService({
    config: {
      enabled:
        router.isReady &&
        router.query.id !== undefined &&
        router.query.secret === CONTENTFUL_PREVIEW_SECRET,
    },
    id: String(router.query.id),
  })

  if (router.isReady && router.query.secret !== CONTENTFUL_PREVIEW_SECRET) {
    return <ErrorPage statusCode={404} />
  }

  return (
    <MainContent
      document={maintenance?.fields.content}
      isLoading={isLoading}
      showDefault={false}
    />
  )
}
