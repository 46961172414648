import { useMemo } from 'react'
import { useController } from 'react-hook-form'

import { FormFieldWrapper, InputRadioGroup } from '@/components/ui'

import { FormFieldRadioGroupProps } from './type'

export const FormFieldRadioGroup = ({
  options,
  disabled,
  direction,
  gap,
  label,
  showLabel,
  formName,
  control,
  error,
  showError,
}: FormFieldRadioGroupProps) => {
  const {
    field: { onChange, onBlur, value },
  } = useController({ name: formName, control })

  const selectedOption = useMemo(
    () => options.find((option) => option.value === value),
    [options, value]
  )

  const handleBlur = () => {
    onBlur && onBlur()
  }

  return (
    <FormFieldWrapper
      label={label}
      error={error}
      showLabel={showLabel}
      showError={showError}
    >
      <InputRadioGroup
        options={options}
        disabled={disabled}
        selected={selectedOption ? selectedOption.value : null}
        direction={direction}
        gap={gap}
        handleChange={(value) => onChange(value)}
        handleBlur={handleBlur}
      />
    </FormFieldWrapper>
  )
}
