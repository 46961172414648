import clsx from 'clsx'

import type { LoadingOverlayProps } from './type'

import { Spinner } from '@/components/ui'

export const LoadingOverlay = ({ isLoading }: LoadingOverlayProps) => {
  return isLoading ? (
    <div className={clsx('tw-fixed', 'tw-inset-0', 'tw-z-loadingOverlay')}>
      <div
        className={clsx(
          'tw-absolute',
          'tw-inset-0',
          'tw-bg-white tw-opacity-40'
        )}
      />
      <div
        className={clsx(
          'tw-h-full',
          'tw-flex tw-items-center tw-justify-center'
        )}
      >
        <Spinner variant="normal" />
      </div>
    </div>
  ) : null
}
