import { useMutation } from 'react-query'

import { Methods } from '@/api/v1/favorites/_setNo@number/_storeCode@string'
import { aspidaClient } from '@/lib/aspida'
import { MutationConfig } from '@/lib/react-query'

export type UpdateFavoriteDTO = {
  pathParams: [
    Parameters<typeof aspidaClient.v1.favorites._setNo>[0],
    Parameters<
      ReturnType<typeof aspidaClient.v1.favorites._setNo>['_storeCode']
    >[0]
  ]
  data: Methods['put']['reqBody']
}

export const updateFavorite = ({ pathParams, data }: UpdateFavoriteDTO) => {
  return aspidaClient.v1.favorites
    ._setNo(pathParams[0])
    ._storeCode(pathParams[1])
    .$put({ body: data })
}

type UseUpdateFavoriteOptions = {
  config?: MutationConfig<typeof updateFavorite>
}

export const useUpdateFavorite = ({ config }: UseUpdateFavoriteOptions) => {
  return useMutation({
    ...config,
    mutationFn: updateFavorite,
  })
}
