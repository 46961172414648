import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import type { DocumentationContentProps } from './type'

import { OPTIONS } from './const'
import style from './style.module.css'

export const DocumentationContent = ({
  document,
  options = OPTIONS,
}: DocumentationContentProps) => {
  return (
    <div className={style.content}>
      {documentToReactComponents(document, options)}
    </div>
  )
}
