import { useCallback, useEffect } from 'react'

import { DATA_ATTRIBUTES } from '@/const/attribute'

import { useMobileChecker } from './useMobileChecker'

type UseEnterIndexProps = {
  enable?: boolean
}

const ENTER_KEY_VALUES = ['Enter', '\n']

const sortElements = (elements: NodeListOf<HTMLInputElement>) => {
  return Array.from([...elements])
    .filter((elm) => {
      const enterIndex = elm.getAttribute(DATA_ATTRIBUTES.enterIndex)
      return (
        enterIndex !== null &&
        !isNaN(Number(enterIndex)) &&
        Number(enterIndex) >= 0
      )
    })
    .sort((prev, next) => {
      const enterIndex = {
        prev: prev.getAttribute(DATA_ATTRIBUTES.enterIndex) ?? '',
        next: next.getAttribute(DATA_ATTRIBUTES.enterIndex) ?? '',
      }
      if (enterIndex.prev === enterIndex.next) {
        return 0
      }
      return enterIndex.prev > enterIndex.next ? 1 : -1
    })
}

/**
 * enterKeyで tabIndex のような移動をさせる hooks。
 * 移動させるには、対象のエレメントに対して「data-enterindex」というattributeを設定し、優先度を0以上の数値で設定してください。
 * 0以下の値を設定すると、そのエレメントには移動しません。
 * また、エレメントの移動順序は設定された優先度に基づいて決まります
 */
export const useEnterIndex = ({}: UseEnterIndexProps = { enable: true }) => {
  const { device } = useMobileChecker()
  const elements = sortElements(
    document.querySelectorAll<HTMLInputElement>(
      `[${DATA_ATTRIBUTES.enterIndex}]`
    )
  )

  const attachEvent = useCallback(
    (elements: HTMLInputElement[]) => {
      // iOSの場合は、エンターキーで移動しないためイベントを付与しない
      if (device === 'iPhone') {
        return
      }
      const eventListeners = elements.map((element, index) => {
        const moveToNext = () => {
          const nextElement =
            elements.length - 1 == index ? elements[0] : elements[index + 1]
          nextElement.focus()
        }
        const moveToPrev = () => {
          const prevElement =
            index === 0 ? elements[elements.length - 1] : elements[index - 1]
          prevElement.focus()
        }
        const handler = (e: globalThis.KeyboardEvent) => {
          if (ENTER_KEY_VALUES.includes(e.key)) {
            e.shiftKey ? moveToPrev() : moveToNext()
          }
        }
        element.addEventListener('keydown', handler)
        return { element, handler }
      })
      return eventListeners
    },
    [device]
  )

  /**エレメントを取得し直して、イベントを再付与する */
  const refresh = () => {
    const elements = sortElements(
      document.querySelectorAll<HTMLInputElement>(
        `[${DATA_ATTRIBUTES.enterIndex}]`
      )
    )
    attachEvent(elements)
  }

  useEffect(() => {
    const elements = sortElements(
      document.querySelectorAll<HTMLInputElement>(
        `[${DATA_ATTRIBUTES.enterIndex}]`
      )
    )
    attachEvent(elements)
  }, [attachEvent, elements])

  return { refresh }
}
