import clsx from 'clsx'

import type { PerPageSelectProps } from './type'

import { InputSelectSingle } from '@/components/ui'

import { options } from './const'

export const PerPageSelect = (props: PerPageSelectProps) => {
  return (
    <div className={clsx('tw-flex tw-items-center')}>
      <p className={clsx('tw-text-sm')}>表示件数：</p>
      <div className={clsx('tw-w-24')}>
        <InputSelectSingle<number>
          {...props}
          buttonLabel={'表示件数'}
          options={options}
          height="md"
        />
      </div>
    </div>
  )
}
