import { AddShoppingCart } from '@mui/icons-material'
import clsx from 'clsx'
import React from 'react'

import type { FavoriteItemListFloatingFooterProps } from './type'

import { ActionFloatingFooter } from '@/components/layout'
import { Button } from '@/components/ui'
import { CART_TITLE } from '@/const'
import { FAVORITE_TITLE } from '@/const'

export const FavoriteItemListFloatingFooter = ({
  inputRef,
  displayMode,
  isLoading,
  disabledAddCart = false,
  onCancel,
  onClickAddCart,
  onClickUpdate,
}: FavoriteItemListFloatingFooterProps) => {
  return (
    <ActionFloatingFooter inputRef={inputRef}>
      {displayMode === 'main' && (
        <div>
          <Button
            isLoading={isLoading}
            startIcon={
              <AddShoppingCart
                className={clsx('tw-text-white')}
                sx={{ fontSize: 18 }}
              />
            }
            disabled={disabledAddCart}
            onClick={onClickAddCart}
          >
            {`${CART_TITLE}に追加する`}
          </Button>
        </div>
      )}

      {displayMode === 'sort' && (
        <div
          className={clsx(
            'tw-w-full',
            'tw-grid tw-grid-cols-5 tw-items-center lg:tw-grid-cols-2',
            'tw-gap-2 lg:tw-gap-4'
          )}
        >
          <div className={clsx('tw-col-span-2 lg:tw-col-span-1')}>
            <Button variant="normal" disabled={isLoading} onClick={onCancel}>
              閉じる
            </Button>
          </div>
          <div className={clsx('tw-col-span-3 lg:tw-col-span-1')}>
            <Button
              variant="primary"
              isLoading={isLoading}
              onClick={onClickUpdate}
            >
              {`${FAVORITE_TITLE}を更新する`}
            </Button>
          </div>
        </div>
      )}

      {displayMode === 'edit' && (
        <div>
          <Button variant="normal" disabled={isLoading} onClick={onCancel}>
            閉じる
          </Button>
        </div>
      )}
    </ActionFloatingFooter>
  )
}
