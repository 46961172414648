import { Transition } from '@headlessui/react'
import { MoreVert, People } from '@mui/icons-material'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import { Fragment, useState, useRef } from 'react'
import { useOnClickOutside } from 'usehooks-ts'

import type { FavoriteCardProps } from './type'
import type { MouseEvent } from 'react'

import { ROUTE } from '@/const'

import { FavoriteCardOperation } from './FavoriteCardOperation'

export const FavoriteCard = ({
  inputRef,
  favorite,
  disabled = false,
  showActions = true,
  isOperationDirectionTop = false,
  isMyFavorite = false,
  isDepositStore,
  onClickCopy,
  onClickDelete,
  onClickEdit,
}: FavoriteCardProps) => {
  const router = useRouter()

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const wrapperRef = useRef(null)

  const onClickMore = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setIsOpen(!isOpen)
  }

  const handleClickCard = () => {
    if (disabled) return
    router.push(ROUTE.favoriteDetail(favorite.setNo, favorite.storeCode))
  }

  useOnClickOutside(wrapperRef, () => {
    setIsOpen(false)
  })

  return (
    <div ref={wrapperRef} className={clsx('tw-relative')}>
      <div
        className={clsx(
          'tw-flex tw-items-center tw-justify-between',
          'tw-px-2 tw-py-2',
          'tw-cursor-pointer',
          'tw-transition tw-duration-stateChange',
          !disabled && 'hover:tw-opacity-hover'
        )}
        onClick={handleClickCard}
      >
        <div>
          <p
            className={clsx(
              'tw-flex tw-items-center',
              'tw-gap-2',
              'tw-text-sm'
            )}
          >
            <span>{favorite.name}</span>
            <span>{`(${favorite.itemCodes.length})`}</span>
            {isMyFavorite && favorite.isShared && (
              <span>
                <People
                  sx={{
                    fontSize: 24,
                  }}
                  className={clsx('tw-text-gray-400')}
                />
              </span>
            )}
          </p>
        </div>
        {showActions &&
          (favorite.isEditableByMe || favorite.canDuplicateByMe) && (
            <div>
              <button
                ref={inputRef}
                className={clsx(
                  'tw-h-6 tw-w-6',
                  'tw-flex tw-items-center tw-justify-center',
                  'tw-rounded',
                  'disabled:tw-cursor-not-allowed disabled:tw-text-gray-400'
                )}
                disabled={disabled}
                onClick={onClickMore}
              >
                <MoreVert
                  sx={{
                    fontSize: 24,
                  }}
                  className={clsx('tw-text-primary-500')}
                />
              </button>
            </div>
          )}
      </div>
      <Transition
        show={isOpen}
        as={Fragment}
        enter={clsx('tw-transition-opacity tw-ease-in tw-duration-100')}
        enterFrom={clsx(['tw-opacity-0'])}
        enterTo={clsx(['tw-opacity-100'])}
        leave={clsx(['tw-transition-opacity tw-ease-in tw-duration-100'])}
        leaveFrom={clsx(['tw-opacity-100'])}
        leaveTo={clsx(['tw-opacity-0'])}
      >
        <div
          className={clsx(
            'tw-absolute',
            isOperationDirectionTop ? 'tw-bottom-5' : 'tw-top-5',
            'tw-right-4',
            'tw-w-[232px]',
            'tw-drop-shadow-500',
            'tw-z-operationModal'
          )}
        >
          <FavoriteCardOperation
            isEditableByMe={favorite.isEditableByMe}
            isDepositStore={isDepositStore}
            canDuplicateByMe={favorite.canDuplicateByMe}
            onClickCopy={() => {
              setIsOpen(false)
              onClickCopy && onClickCopy()
            }}
            onClickDelete={() => {
              setIsOpen(false)
              onClickDelete && onClickDelete()
            }}
            onClickEdit={() => {
              setIsOpen(false)
              onClickEdit && onClickEdit()
            }}
          />
        </div>
      </Transition>
    </div>
  )
}
