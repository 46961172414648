import type { Favorite } from '@/api/@types'

export const favoriteKeyFactory = {
  all: [{ scope: 'favorite' }] as const,
  list: () => [{ ...favoriteKeyFactory.all[0], entity: 'list' }] as const,
  detail: ({
    pathParams,
  }: {
    pathParams: { setNo: Favorite['setNo']; storeCode: Favorite['storeCode'] }
  }) =>
    [{ ...favoriteKeyFactory.all[0], entity: 'detail', pathParams }] as const,
}
