/* eslint-disable */
// prettier-ignore
import type { AspidaClient, BasicHeaders } from 'aspida'
// prettier-ignore
import { dataToURLString } from 'aspida'
// prettier-ignore
import type { Methods as Methods0 } from './v1/addresses'
// prettier-ignore
import type { Methods as Methods1 } from './v1/aggregation-units-and-stores-for-purchase'
// prettier-ignore
import type { Methods as Methods2 } from './v1/announcements'
// prettier-ignore
import type { Methods as Methods3 } from './v1/auth/token'
// prettier-ignore
import type { Methods as Methods4 } from './v1/available-delivery-period'
// prettier-ignore
import type { Methods as Methods5 } from './v1/billed-amounts'
// prettier-ignore
import type { Methods as Methods6 } from './v1/billed-amounts/download'
// prettier-ignore
import type { Methods as Methods7 } from './v1/brands'
// prettier-ignore
import type { Methods as Methods8 } from './v1/cart'
// prettier-ignore
import type { Methods as Methods9 } from './v1/cart/count'
// prettier-ignore
import type { Methods as Methods10 } from './v1/cart/delivery-date'
// prettier-ignore
import type { Methods as Methods11 } from './v1/cart/items/_itemCode@string'
// prettier-ignore
import type { Methods as Methods12 } from './v1/cart/order'
// prettier-ignore
import type { Methods as Methods13 } from './v1/cart/shipping-information'
// prettier-ignore
import type { Methods as Methods14 } from './v1/categories'
// prettier-ignore
import type { Methods as Methods15 } from './v1/change-email'
// prettier-ignore
import type { Methods as Methods16 } from './v1/change-email-auth'
// prettier-ignore
import type { Methods as Methods17 } from './v1/change-invoice-email'
// prettier-ignore
import type { Methods as Methods18 } from './v1/change-invoice-email-auth'
// prettier-ignore
import type { Methods as Methods19 } from './v1/change-password'
// prettier-ignore
import type { Methods as Methods20 } from './v1/change-temp-password'
// prettier-ignore
import type { Methods as Methods21 } from './v1/color-charts/_groupId@number'
// prettier-ignore
import type { Methods as Methods22 } from './v1/contact'
// prettier-ignore
import type { Methods as Methods23 } from './v1/ec-site-orders'
// prettier-ignore
import type { Methods as Methods24 } from './v1/ec-site-orders/_fileId@string'
// prettier-ignore
import type { Methods as Methods25 } from './v1/ec-site-orders/_fileId@string/download'
// prettier-ignore
import type { Methods as Methods26 } from './v1/ec-site-orders/systemTypes'
// prettier-ignore
import type { Methods as Methods27 } from './v1/favorites'
// prettier-ignore
import type { Methods as Methods28 } from './v1/favorites/_setNo@number/_storeCode@string'
// prettier-ignore
import type { Methods as Methods29 } from './v1/favorites/_setNo@number/_storeCode@string/duplicate'
// prettier-ignore
import type { Methods as Methods30 } from './v1/favorites/_setNo@number/_storeCode@string/items'
// prettier-ignore
import type { Methods as Methods31 } from './v1/favorites/items'
// prettier-ignore
import type { Methods as Methods32 } from './v1/invoices'
// prettier-ignore
import type { Methods as Methods33 } from './v1/invoices/_invoiceId@string/download'
// prettier-ignore
import type { Methods as Methods34 } from './v1/invoices/_invoiceId@string/preview'
// prettier-ignore
import type { Methods as Methods35 } from './v1/invoices/download'
// prettier-ignore
import type { Methods as Methods36 } from './v1/invoices/histories'
// prettier-ignore
import type { Methods as Methods37 } from './v1/invoices/histories/download'
// prettier-ignore
import type { Methods as Methods38 } from './v1/items'
// prettier-ignore
import type { Methods as Methods39 } from './v1/items/_itemCode@string'
// prettier-ignore
import type { Methods as Methods40 } from './v1/items/_itemCode@string/prices'
// prettier-ignore
import type { Methods as Methods41 } from './v1/items/count'
// prettier-ignore
import type { Methods as Methods42 } from './v1/login'
// prettier-ignore
import type { Methods as Methods43 } from './v1/logout'
// prettier-ignore
import type { Methods as Methods44 } from './v1/order-histories'
// prettier-ignore
import type { Methods as Methods45 } from './v1/order-histories/_downloadId/download'
// prettier-ignore
import type { Methods as Methods46 } from './v1/order-histories/_orderNo@string/delivery-date'
// prettier-ignore
import type { Methods as Methods47 } from './v1/order-histories/_orderNo@string/shipping-infomation'
// prettier-ignore
import type { Methods as Methods48 } from './v1/order-histories/_salesOrderNo@string'
// prettier-ignore
import type { Methods as Methods49 } from './v1/order-histories/download'
// prettier-ignore
import type { Methods as Methods50 } from './v1/order-histories/unshipped'
// prettier-ignore
import type { Methods as Methods51 } from './v1/orders'
// prettier-ignore
import type { Methods as Methods52 } from './v1/orders/_orderNo@string'
// prettier-ignore
import type { Methods as Methods53 } from './v1/orders/_orderNo@string/items/_itemCode@string'
// prettier-ignore
import type { Methods as Methods54 } from './v1/orders/approve'
// prettier-ignore
import type { Methods as Methods55 } from './v1/orders/unapproved'
// prettier-ignore
import type { Methods as Methods56 } from './v1/purchases/_downloadId@string/download'
// prettier-ignore
import type { Methods as Methods57 } from './v1/purchases/ec-site-orders'
// prettier-ignore
import type { Methods as Methods58 } from './v1/purchases/ec-site-orders/_systemType'
// prettier-ignore
import type { Methods as Methods59 } from './v1/purchases/ec-site-orders/download'
// prettier-ignore
import type { Methods as Methods60 } from './v1/purchases/makers'
// prettier-ignore
import type { Methods as Methods61 } from './v1/purchases/makers/download'
// prettier-ignore
import type { Methods as Methods62 } from './v1/purchases/makers/stores'
// prettier-ignore
import type { Methods as Methods63 } from './v1/purchases/makers/stores/download'
// prettier-ignore
import type { Methods as Methods64 } from './v1/purchases/stores'
// prettier-ignore
import type { Methods as Methods65 } from './v1/purchases/stores/download'
// prettier-ignore
import type { Methods as Methods66 } from './v1/purchases/stores/makers/sales-types'
// prettier-ignore
import type { Methods as Methods67 } from './v1/purchases/stores/makers/sales-types/download'
// prettier-ignore
import type { Methods as Methods68 } from './v1/purchases/stores/sales-types'
// prettier-ignore
import type { Methods as Methods69 } from './v1/purchases/stores/sales-types/download'
// prettier-ignore
import type { Methods as Methods70 } from './v1/receive-email'
// prettier-ignore
import type { Methods as Methods71 } from './v1/sales-details'
// prettier-ignore
import type { Methods as Methods72 } from './v1/sales-details/download'
// prettier-ignore
import type { Methods as Methods73 } from './v1/sales-types'
// prettier-ignore
import type { Methods as Methods74 } from './v1/stores'
// prettier-ignore
import type { Methods as Methods75 } from './v1/stores/me'
// prettier-ignore
import type { Methods as Methods76 } from './v1/stores-for-invoice'
// prettier-ignore
import type { Methods as Methods77 } from './v1/tests/cart/delivery-date/one-day-ago'
// prettier-ignore
import type { Methods as Methods78 } from './v1/user/me'

// prettier-ignore
const api = <T>({ baseURL, fetch }: AspidaClient<T>) => {
  const prefix = (baseURL === undefined ? 'http://localhost:18081' : baseURL).replace(/\/$/, '')
  const PATH0 = '/v1/addresses'
  const PATH1 = '/v1/aggregation-units-and-stores-for-purchase'
  const PATH2 = '/v1/announcements'
  const PATH3 = '/v1/auth/token'
  const PATH4 = '/v1/available-delivery-period'
  const PATH5 = '/v1/billed-amounts'
  const PATH6 = '/v1/billed-amounts/download'
  const PATH7 = '/v1/brands'
  const PATH8 = '/v1/cart'
  const PATH9 = '/v1/cart/count'
  const PATH10 = '/v1/cart/delivery-date'
  const PATH11 = '/v1/cart/items'
  const PATH12 = '/v1/cart/order'
  const PATH13 = '/v1/cart/shipping-information'
  const PATH14 = '/v1/categories'
  const PATH15 = '/v1/change-email'
  const PATH16 = '/v1/change-email-auth'
  const PATH17 = '/v1/change-invoice-email'
  const PATH18 = '/v1/change-invoice-email-auth'
  const PATH19 = '/v1/change-password'
  const PATH20 = '/v1/change-temp-password'
  const PATH21 = '/v1/color-charts'
  const PATH22 = '/v1/contact'
  const PATH23 = '/v1/ec-site-orders'
  const PATH24 = '/download'
  const PATH25 = '/v1/ec-site-orders/systemTypes'
  const PATH26 = '/v1/favorites'
  const PATH27 = '/duplicate'
  const PATH28 = '/items'
  const PATH29 = '/v1/favorites/items'
  const PATH30 = '/v1/invoices'
  const PATH31 = '/preview'
  const PATH32 = '/v1/invoices/download'
  const PATH33 = '/v1/invoices/histories'
  const PATH34 = '/v1/invoices/histories/download'
  const PATH35 = '/v1/items'
  const PATH36 = '/prices'
  const PATH37 = '/v1/items/count'
  const PATH38 = '/v1/login'
  const PATH39 = '/v1/logout'
  const PATH40 = '/v1/order-histories'
  const PATH41 = '/delivery-date'
  const PATH42 = '/shipping-infomation'
  const PATH43 = '/v1/order-histories/download'
  const PATH44 = '/v1/order-histories/unshipped'
  const PATH45 = '/v1/orders'
  const PATH46 = '/v1/orders/approve'
  const PATH47 = '/v1/orders/unapproved'
  const PATH48 = '/v1/purchases'
  const PATH49 = '/v1/purchases/ec-site-orders'
  const PATH50 = '/v1/purchases/ec-site-orders/download'
  const PATH51 = '/v1/purchases/makers'
  const PATH52 = '/v1/purchases/makers/download'
  const PATH53 = '/v1/purchases/makers/stores'
  const PATH54 = '/v1/purchases/makers/stores/download'
  const PATH55 = '/v1/purchases/stores'
  const PATH56 = '/v1/purchases/stores/download'
  const PATH57 = '/v1/purchases/stores/makers/sales-types'
  const PATH58 = '/v1/purchases/stores/makers/sales-types/download'
  const PATH59 = '/v1/purchases/stores/sales-types'
  const PATH60 = '/v1/purchases/stores/sales-types/download'
  const PATH61 = '/v1/receive-email'
  const PATH62 = '/v1/sales-details'
  const PATH63 = '/v1/sales-details/download'
  const PATH64 = '/v1/sales-types'
  const PATH65 = '/v1/stores'
  const PATH66 = '/v1/stores/me'
  const PATH67 = '/v1/stores-for-invoice'
  const PATH68 = '/v1/tests/cart/delivery-date/one-day-ago'
  const PATH69 = '/v1/user/me'
  const GET = 'GET'
  const POST = 'POST'
  const PUT = 'PUT'
  const DELETE = 'DELETE'
  const PATCH = 'PATCH'

  return {
    v1: {
      addresses: {
        /**
         * 郵便番号から住所情報を検索します。
         * @returns 正常処理
         */
        get: (option: { query: Methods0['get']['query'], config?: T | undefined }) =>
          fetch<Methods0['get']['resBody'], BasicHeaders, Methods0['get']['status']>(prefix, PATH0, GET, option).json(),
        /**
         * 郵便番号から住所情報を検索します。
         * @returns 正常処理
         */
        $get: (option: { query: Methods0['get']['query'], config?: T | undefined }) =>
          fetch<Methods0['get']['resBody'], BasicHeaders, Methods0['get']['status']>(prefix, PATH0, GET, option).json().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods0['get']['query'] } | undefined) =>
          `${prefix}${PATH0}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
      },
      aggregation_units_and_stores_for_purchase: {
        /**
         * ### 概要
         * 仕入情報を検索するときに、ログインユーザーが選択可能な集計単位と店舗を取得する。
         *
         * ### 処理仕様
         *
         * - ログインユーザに設定されている仕入情報の集計範囲を確認する。
         * - 集計範囲が得意先の場合、選択可能な集計単位は得意先とし、選択可能な店舗は自店舗とする。
         * - 集計範囲が請求先の場合、選択可能な集計単位は得意先、請求先とし、選択可能な店舗は自店舗と自店舗の請求先に紐づく店舗とする。
         * - 集計範囲が入金親の場合、選択可能な集計単位は得意先、請求先、入金親とし、選択可能な店舗は自店舗と自店舗の請求先に紐づく店舗、自店舗と自店舗の入金親に紐づく店舗とする。
         * - 結果を返す。
         *
         * ### 並び順
         * - 店舗名の昇順
         *
         * ### HTTPレスポンス
         * - 200 正常
         * - 401 未ログイン
         * @returns 正常処理
         */
        get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods1['get']['resBody'], BasicHeaders, Methods1['get']['status']>(prefix, PATH1, GET, option).json(),
        /**
         * ### 概要
         * 仕入情報を検索するときに、ログインユーザーが選択可能な集計単位と店舗を取得する。
         *
         * ### 処理仕様
         *
         * - ログインユーザに設定されている仕入情報の集計範囲を確認する。
         * - 集計範囲が得意先の場合、選択可能な集計単位は得意先とし、選択可能な店舗は自店舗とする。
         * - 集計範囲が請求先の場合、選択可能な集計単位は得意先、請求先とし、選択可能な店舗は自店舗と自店舗の請求先に紐づく店舗とする。
         * - 集計範囲が入金親の場合、選択可能な集計単位は得意先、請求先、入金親とし、選択可能な店舗は自店舗と自店舗の請求先に紐づく店舗、自店舗と自店舗の入金親に紐づく店舗とする。
         * - 結果を返す。
         *
         * ### 並び順
         * - 店舗名の昇順
         *
         * ### HTTPレスポンス
         * - 200 正常
         * - 401 未ログイン
         * @returns 正常処理
         */
        $get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods1['get']['resBody'], BasicHeaders, Methods1['get']['status']>(prefix, PATH1, GET, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH1}`
      },
      announcements: {
        /**
         * お知らせ一覧を取得する。<br>
         * 順序はソート番号の昇順、表示開始日の昇順で返却される。
         * @returns 正常処理
         */
        get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods2['get']['resBody'], BasicHeaders, Methods2['get']['status']>(prefix, PATH2, GET, option).json(),
        /**
         * お知らせ一覧を取得する。<br>
         * 順序はソート番号の昇順、表示開始日の昇順で返却される。
         * @returns 正常処理
         */
        $get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods2['get']['resBody'], BasicHeaders, Methods2['get']['status']>(prefix, PATH2, GET, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH2}`
      },
      auth: {
        token: {
          /**
           * リフレッシュトークンを渡して、アクセストークンを取得します。<br/>
           * <br/>
           * 以下のレスポンスはHTTPステータスのみの返却となります。<br/>
           *
           * <ul>
           * <li>400：必須項目が無いなどのリクエスト不正。</li>
           * <li>401：対象のリフレッシュトークンが未登録の場合。</li>
           * </ul>
           * @returns 処理成功
           */
          post: (option: { body: Methods3['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods3['post']['resBody'], BasicHeaders, Methods3['post']['status']>(prefix, PATH3, POST, option).json(),
          /**
           * リフレッシュトークンを渡して、アクセストークンを取得します。<br/>
           * <br/>
           * 以下のレスポンスはHTTPステータスのみの返却となります。<br/>
           *
           * <ul>
           * <li>400：必須項目が無いなどのリクエスト不正。</li>
           * <li>401：対象のリフレッシュトークンが未登録の場合。</li>
           * </ul>
           * @returns 処理成功
           */
          $post: (option: { body: Methods3['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods3['post']['resBody'], BasicHeaders, Methods3['post']['status']>(prefix, PATH3, POST, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH3}`
        }
      },
      available_delivery_period: {
        /**
         * ### 概要
         * 店舗コードと郵便番号の組み合わせにおいて、指定可能な納品日の期間を取得する。
         *
         * ### HTTPレスポンス
         * - 200 正常
         * - 400 パラメータ不正
         * - 401 未ログイン
         * - 403 権限のない店舗コード
         * @returns 正常処理
         */
        get: (option: { query: Methods4['get']['query'], config?: T | undefined }) =>
          fetch<Methods4['get']['resBody'], BasicHeaders, Methods4['get']['status']>(prefix, PATH4, GET, option).json(),
        /**
         * ### 概要
         * 店舗コードと郵便番号の組み合わせにおいて、指定可能な納品日の期間を取得する。
         *
         * ### HTTPレスポンス
         * - 200 正常
         * - 400 パラメータ不正
         * - 401 未ログイン
         * - 403 権限のない店舗コード
         * @returns 正常処理
         */
        $get: (option: { query: Methods4['get']['query'], config?: T | undefined }) =>
          fetch<Methods4['get']['resBody'], BasicHeaders, Methods4['get']['status']>(prefix, PATH4, GET, option).json().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods4['get']['query'] } | undefined) =>
          `${prefix}${PATH4}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
      },
      billed_amounts: {
        download: {
          /**
           * ### 概要
           * - 請求金額一覧を取得し、CSVをダウンロード処理受付する。
           * - 検索結果がなかった場合、ヘッダのみ返る。
           * - ダウンロードのための、ダウンロードIDが返却される。
           *
           * ### 処理仕様
           *
           * - 検索条件に合致する請求金額結果を抽出し、抽出結果をCSVファイルとしてS3に保存する。
           * - ただし、大量データの処理を複数同時に行うことでDBへの負荷が大きくなることを避けるため、以下のように処理を行う。
           *     - リクエストを受け付けた後、バリデーションエラーがない場合には、ダウンロードIDを発番し、即座にAPIのレスポンスとして返す。
           *     - ダウンロードIDの単位でリクエストをキューに格納し、キューから取り出して行う処理の同時実行数はシステム全体で1とする。
           *     - キューから取り出した後は、リクエスト時の検索条件に合致するデータを抽出し、抽出結果をCSVファイルとしてS3に保存する。
           * - 処理終了の確認、及びファイルのダウンロードは、仕入情報CSVダウンロードAPIにて行う。
           * - ダウンロードする場合のファイル名は「仕入情報_請求金額_yyyyMMddHHmmssfff.csv」とする。
           *
           * - パラメータから検索条件を作成する。
           * - 集計単位でユーザに対して対応していない集計単位を指定した場合は400を返す。
           * - 店舗コードの指定がない場合、すべて対象の店舗が検索される。請求月の指定がない場合当月とする。
           * - DBから結果を取得する。
           * - UTF-8 BOM形式CSVに整形しbase64でエンコードする。
           * - 上記の内容で処理を開始する。
           *
           * ### 並び順
           * - 請求No
           *
           * ### HTTPレスポンス
           * - 200 正常
           * - 400 パラメータ不正
           * - 401 未ログイン
           * - 403 権限のない集計単位の指定
           *
           * ### CSVカラム
           *
           * 1. 請求日
           * 2. お支払日
           * 3. 請求No
           * 4. 請求先コード
           * 5. 請求先名
           * 6. 売上合計
           * 7. 伝票調整
           * 8. 消費税
           * 9. 今回請求金額
           * @returns 正常処理
           */
          post: (option: { body: Methods6['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods6['post']['resBody'], BasicHeaders, Methods6['post']['status']>(prefix, PATH6, POST, option).json(),
          /**
           * ### 概要
           * - 請求金額一覧を取得し、CSVをダウンロード処理受付する。
           * - 検索結果がなかった場合、ヘッダのみ返る。
           * - ダウンロードのための、ダウンロードIDが返却される。
           *
           * ### 処理仕様
           *
           * - 検索条件に合致する請求金額結果を抽出し、抽出結果をCSVファイルとしてS3に保存する。
           * - ただし、大量データの処理を複数同時に行うことでDBへの負荷が大きくなることを避けるため、以下のように処理を行う。
           *     - リクエストを受け付けた後、バリデーションエラーがない場合には、ダウンロードIDを発番し、即座にAPIのレスポンスとして返す。
           *     - ダウンロードIDの単位でリクエストをキューに格納し、キューから取り出して行う処理の同時実行数はシステム全体で1とする。
           *     - キューから取り出した後は、リクエスト時の検索条件に合致するデータを抽出し、抽出結果をCSVファイルとしてS3に保存する。
           * - 処理終了の確認、及びファイルのダウンロードは、仕入情報CSVダウンロードAPIにて行う。
           * - ダウンロードする場合のファイル名は「仕入情報_請求金額_yyyyMMddHHmmssfff.csv」とする。
           *
           * - パラメータから検索条件を作成する。
           * - 集計単位でユーザに対して対応していない集計単位を指定した場合は400を返す。
           * - 店舗コードの指定がない場合、すべて対象の店舗が検索される。請求月の指定がない場合当月とする。
           * - DBから結果を取得する。
           * - UTF-8 BOM形式CSVに整形しbase64でエンコードする。
           * - 上記の内容で処理を開始する。
           *
           * ### 並び順
           * - 請求No
           *
           * ### HTTPレスポンス
           * - 200 正常
           * - 400 パラメータ不正
           * - 401 未ログイン
           * - 403 権限のない集計単位の指定
           *
           * ### CSVカラム
           *
           * 1. 請求日
           * 2. お支払日
           * 3. 請求No
           * 4. 請求先コード
           * 5. 請求先名
           * 6. 売上合計
           * 7. 伝票調整
           * 8. 消費税
           * 9. 今回請求金額
           * @returns 正常処理
           */
          $post: (option: { body: Methods6['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods6['post']['resBody'], BasicHeaders, Methods6['post']['status']>(prefix, PATH6, POST, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH6}`
        },
        /**
         * ### 概要
         * 請求金額の一覧を取得する
         *
         * ### 処理仕様
         *
         * - パラメータから一覧取得条件を作成する。
         * - 集計単位でユーザに対して対応していない集計単位を指定した場合は400を返す。
         * - 店舗コードの指定がない場合、すべて対象の店舗が検索される。請求月の指定がない場合当月とする。
         * - DBから結果を取得する。
         * - 結果を返す。
         *
         * ### 並び順
         * - 請求No
         *
         * ### HTTPレスポンス
         * - 200 正常
         * - 400 パラメータ不正
         * - 401 未ログイン
         * - 403 権限のない集計単位の指定
         *
         * ### カラム
         * | No. | dataField | headerName | type |
         * |-----|-----------|------------|------|
         * | 1 | billDate | 請求日 | string |
         * | 2 | paymentDate | お支払日 | string |
         * | 3 | billNo | 請求No | string |
         * | 4 | billCode | 請求先コード | string |
         * | 5 | billName | 請求先名 | string |
         * | 6 | total | 売上合計 | integer |
         * | 7 | adjustment | 伝票調整 | integer |
         * | 8 | tax | 消費税 | integer |
         * | 9 | billedAmount | 今回請求金額 | integer |
         * @returns 正常処理
         */
        get: (option: { query: Methods5['get']['query'], config?: T | undefined }) =>
          fetch<Methods5['get']['resBody'], BasicHeaders, Methods5['get']['status']>(prefix, PATH5, GET, option).json(),
        /**
         * ### 概要
         * 請求金額の一覧を取得する
         *
         * ### 処理仕様
         *
         * - パラメータから一覧取得条件を作成する。
         * - 集計単位でユーザに対して対応していない集計単位を指定した場合は400を返す。
         * - 店舗コードの指定がない場合、すべて対象の店舗が検索される。請求月の指定がない場合当月とする。
         * - DBから結果を取得する。
         * - 結果を返す。
         *
         * ### 並び順
         * - 請求No
         *
         * ### HTTPレスポンス
         * - 200 正常
         * - 400 パラメータ不正
         * - 401 未ログイン
         * - 403 権限のない集計単位の指定
         *
         * ### カラム
         * | No. | dataField | headerName | type |
         * |-----|-----------|------------|------|
         * | 1 | billDate | 請求日 | string |
         * | 2 | paymentDate | お支払日 | string |
         * | 3 | billNo | 請求No | string |
         * | 4 | billCode | 請求先コード | string |
         * | 5 | billName | 請求先名 | string |
         * | 6 | total | 売上合計 | integer |
         * | 7 | adjustment | 伝票調整 | integer |
         * | 8 | tax | 消費税 | integer |
         * | 9 | billedAmount | 今回請求金額 | integer |
         * @returns 正常処理
         */
        $get: (option: { query: Methods5['get']['query'], config?: T | undefined }) =>
          fetch<Methods5['get']['resBody'], BasicHeaders, Methods5['get']['status']>(prefix, PATH5, GET, option).json().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods5['get']['query'] } | undefined) =>
          `${prefix}${PATH5}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
      },
      brands: {
        /**
         * ブランドの一覧を取得する。
         * 並び順はgroupName順（アカサタナ）および、brandNameKana順（カナ昇順）とする。
         * @returns 正常処理
         */
        get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods7['get']['resBody'], BasicHeaders, Methods7['get']['status']>(prefix, PATH7, GET, option).json(),
        /**
         * ブランドの一覧を取得する。
         * 並び順はgroupName順（アカサタナ）および、brandNameKana順（カナ昇順）とする。
         * @returns 正常処理
         */
        $get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods7['get']['resBody'], BasicHeaders, Methods7['get']['status']>(prefix, PATH7, GET, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH7}`
      },
      cart: {
        count: {
          /**
           * カート内の商品数を取得します。<br/>
           * 販売中の商品のみをカウントし返却します。
           * @returns 正常処理
           */
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods9['get']['resBody'], BasicHeaders, Methods9['get']['status']>(prefix, PATH9, GET, option).json(),
          /**
           * カート内の商品数を取得します。<br/>
           * 販売中の商品のみをカウントし返却します。
           * @returns 正常処理
           */
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods9['get']['resBody'], BasicHeaders, Methods9['get']['status']>(prefix, PATH9, GET, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH9}`
        },
        delivery_date: {
          /**
           * ログインユーザーのご希望納品日を更新します。<br/>
           * カート用にご希望納品日の設定が出来ます。<br/>
           * ご希望納品日が最短納品日より過去の場合、<br/>
           * ご希望納品日が最終納品日より未来の場合は400を返します。
           * @returns 正常処理
           */
          put: (option: { body: Methods10['put']['reqBody'], config?: T | undefined }) =>
            fetch<Methods10['put']['resBody'], BasicHeaders, Methods10['put']['status']>(prefix, PATH10, PUT, option).json(),
          /**
           * ログインユーザーのご希望納品日を更新します。<br/>
           * カート用にご希望納品日の設定が出来ます。<br/>
           * ご希望納品日が最短納品日より過去の場合、<br/>
           * ご希望納品日が最終納品日より未来の場合は400を返します。
           * @returns 正常処理
           */
          $put: (option: { body: Methods10['put']['reqBody'], config?: T | undefined }) =>
            fetch<Methods10['put']['resBody'], BasicHeaders, Methods10['put']['status']>(prefix, PATH10, PUT, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH10}`
        },
        items: {
          _itemCode: (val3: string) => {
            const prefix3 = `${PATH11}/${val3}`

            return {
              /**
               * ### 概要
               * - カート情報の指定した商品を削除する。
               *
               * ### 処理仕様
               *
               * - パラメータで指定した商品を、カートからすべての売上種別で削除する。
               * - カートに無い商品の場合、404が返る。
               * - 更新された内容で、カート情報の取得と同じ結果を返却する。
               *
               * ### HTTPレスポンス
               * - 200 正常
               * - 400 パラメータ不正
               * - 401 未ログイン
               * - 404 商品なし
               * @returns OK
               */
              delete: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods11['delete']['resBody'], BasicHeaders, Methods11['delete']['status']>(prefix, prefix3, DELETE, option).json(),
              /**
               * ### 概要
               * - カート情報の指定した商品を削除する。
               *
               * ### 処理仕様
               *
               * - パラメータで指定した商品を、カートからすべての売上種別で削除する。
               * - カートに無い商品の場合、404が返る。
               * - 更新された内容で、カート情報の取得と同じ結果を返却する。
               *
               * ### HTTPレスポンス
               * - 200 正常
               * - 400 パラメータ不正
               * - 401 未ログイン
               * - 404 商品なし
               * @returns OK
               */
              $delete: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods11['delete']['resBody'], BasicHeaders, Methods11['delete']['status']>(prefix, prefix3, DELETE, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix3}`
            }
          }
        },
        order: {
          /**
           * カートに対して、発注を確定します。<br/>
           * 料金計算、発注データの登録を行い、最後にカートから全商品の削除を実行します。<br/>
           * 販売不可商品は、料金計算、発注データの登録に含まれずカートから削除されます。<br/>
           * ご希望納品日が最短納品日より過去の場合はエラーになります。<br/>
           * <br/>
           * 販売不可商品のステータスは下記になります。<br/>
           * <ul>
           * <li>unavailable：販売終了商品</li>
           * <li>expired：販売期間外商品</li>
           * <li>forbidden：扱えない商品</li>
           * <li>noItemCode：存在しない商品コード</li>
           * </ul>
           * @returns 正常処理
           */
          patch: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods12['patch']['resBody'], BasicHeaders, Methods12['patch']['status']>(prefix, PATH12, PATCH, option).json(),
          /**
           * カートに対して、発注を確定します。<br/>
           * 料金計算、発注データの登録を行い、最後にカートから全商品の削除を実行します。<br/>
           * 販売不可商品は、料金計算、発注データの登録に含まれずカートから削除されます。<br/>
           * ご希望納品日が最短納品日より過去の場合はエラーになります。<br/>
           * <br/>
           * 販売不可商品のステータスは下記になります。<br/>
           * <ul>
           * <li>unavailable：販売終了商品</li>
           * <li>expired：販売期間外商品</li>
           * <li>forbidden：扱えない商品</li>
           * <li>noItemCode：存在しない商品コード</li>
           * </ul>
           * @returns 正常処理
           */
          $patch: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods12['patch']['resBody'], BasicHeaders, Methods12['patch']['status']>(prefix, PATH12, PATCH, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH12}`
        },
        shipping_information: {
          /**
           * ログインユーザーのカート発送情報を更新します。<br/>
           * カート用に発送予定住所の変更が出来ます。<br/>
           * @returns 正常処理
           */
          put: (option: { body: Methods13['put']['reqBody'], config?: T | undefined }) =>
            fetch<Methods13['put']['resBody'], BasicHeaders, Methods13['put']['status']>(prefix, PATH13, PUT, option).json(),
          /**
           * ログインユーザーのカート発送情報を更新します。<br/>
           * カート用に発送予定住所の変更が出来ます。<br/>
           * @returns 正常処理
           */
          $put: (option: { body: Methods13['put']['reqBody'], config?: T | undefined }) =>
            fetch<Methods13['put']['resBody'], BasicHeaders, Methods13['put']['status']>(prefix, PATH13, PUT, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH13}`
        },
        /**
         * ログインユーザーのカート情報を取得します。<br/>
         * 画面上には、販売中の商品のみが表示されます。<br/>
         * 商品一覧の並び順はカートに追加した順番になります。<br/>
         * 売上種別数量の並び順は商品に追加した順番になります。<br/>
         * <br/>
         * 購入価格表示が無効の場合は値引き費用は通常の費用と同じ値になります。<br/>
         * <br/>
         * レギュラー納品予定日、最短納品予定日、最終納品日はアクセス時に常に再計算されます。<br/>
         * 最終納品日は最短納品日から2週間（13日）後となります。<br/>
         * 保存していた希望納品日が最短の納品日より前になってしまった場合<br/>
         * ご希望納品日エラーとなります。
         * @returns 正常処理
         */
        get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods8['get']['resBody'], BasicHeaders, Methods8['get']['status']>(prefix, PATH8, GET, option).json(),
        /**
         * ログインユーザーのカート情報を取得します。<br/>
         * 画面上には、販売中の商品のみが表示されます。<br/>
         * 商品一覧の並び順はカートに追加した順番になります。<br/>
         * 売上種別数量の並び順は商品に追加した順番になります。<br/>
         * <br/>
         * 購入価格表示が無効の場合は値引き費用は通常の費用と同じ値になります。<br/>
         * <br/>
         * レギュラー納品予定日、最短納品予定日、最終納品日はアクセス時に常に再計算されます。<br/>
         * 最終納品日は最短納品日から2週間（13日）後となります。<br/>
         * 保存していた希望納品日が最短の納品日より前になってしまった場合<br/>
         * ご希望納品日エラーとなります。
         * @returns 正常処理
         */
        $get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods8['get']['resBody'], BasicHeaders, Methods8['get']['status']>(prefix, PATH8, GET, option).json().then(r => r.body),
        /**
         * ログインユーザーのカート情報を削除します。
         * @returns OK
         */
        delete: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods8['delete']['resBody'], BasicHeaders, Methods8['delete']['status']>(prefix, PATH8, DELETE, option).json(),
        /**
         * ログインユーザーのカート情報を削除します。
         * @returns OK
         */
        $delete: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods8['delete']['resBody'], BasicHeaders, Methods8['delete']['status']>(prefix, PATH8, DELETE, option).json().then(r => r.body),
        /**
         * ログインユーザーのカート情報を更新します。<br/>
         * 更新は販売中の商品のみ実行されます。<br/>
         * 対象商品に設定する売上種別数量をすべてリクエストしてください。<br/>
         * 更新に成功した場合は登録したカート情報を返却します。
         * @returns 正常処理
         */
        put: (option: { body: Methods8['put']['reqBody'], config?: T | undefined }) =>
          fetch<Methods8['put']['resBody'], BasicHeaders, Methods8['put']['status']>(prefix, PATH8, PUT, option).json(),
        /**
         * ログインユーザーのカート情報を更新します。<br/>
         * 更新は販売中の商品のみ実行されます。<br/>
         * 対象商品に設定する売上種別数量をすべてリクエストしてください。<br/>
         * 更新に成功した場合は登録したカート情報を返却します。
         * @returns 正常処理
         */
        $put: (option: { body: Methods8['put']['reqBody'], config?: T | undefined }) =>
          fetch<Methods8['put']['resBody'], BasicHeaders, Methods8['put']['status']>(prefix, PATH8, PUT, option).json().then(r => r.body),
        /**
         * ### 概要
         * カート情報の指定した商品の売上種別、数量を更新する。
         *
         * ### 処理仕様
         *
         * - パラメータに指定された商品の数量を更新する。
         * - 商品の数量が0の場合、指定した商品をカートから削除する。
         * - 登録のない商品が指定された場合、新たにカートへ追加する。
         * - 指定された商品に対して指定された売上種別、数量で置き換える。
         * - 更新された内容で、カート情報の取得と同じ結果を返却する。
         *
         * ### HTTPレスポンス
         * - 200 正常
         * - 400 パラメータ不正
         * - 401 未ログイン
         * @returns OK
         */
        patch: (option: { body: Methods8['patch']['reqBody'], config?: T | undefined }) =>
          fetch<Methods8['patch']['resBody'], BasicHeaders, Methods8['patch']['status']>(prefix, PATH8, PATCH, option).json(),
        /**
         * ### 概要
         * カート情報の指定した商品の売上種別、数量を更新する。
         *
         * ### 処理仕様
         *
         * - パラメータに指定された商品の数量を更新する。
         * - 商品の数量が0の場合、指定した商品をカートから削除する。
         * - 登録のない商品が指定された場合、新たにカートへ追加する。
         * - 指定された商品に対して指定された売上種別、数量で置き換える。
         * - 更新された内容で、カート情報の取得と同じ結果を返却する。
         *
         * ### HTTPレスポンス
         * - 200 正常
         * - 400 パラメータ不正
         * - 401 未ログイン
         * @returns OK
         */
        $patch: (option: { body: Methods8['patch']['reqBody'], config?: T | undefined }) =>
          fetch<Methods8['patch']['resBody'], BasicHeaders, Methods8['patch']['status']>(prefix, PATH8, PATCH, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH8}`
      },
      categories: {
        /**
         * カテゴリー一覧を取得します。
         * 取得結果の並び順は、カテゴリーIDの昇順になります。
         * @returns 正常処理
         */
        get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods14['get']['resBody'], BasicHeaders, Methods14['get']['status']>(prefix, PATH14, GET, option).json(),
        /**
         * カテゴリー一覧を取得します。
         * 取得結果の並び順は、カテゴリーIDの昇順になります。
         * @returns 正常処理
         */
        $get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods14['get']['resBody'], BasicHeaders, Methods14['get']['status']>(prefix, PATH14, GET, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH14}`
      },
      change_email: {
        /**
         * メールアドレスを変更します。<br/>
         * リクエストを受付け確認用のメールを送信します。<br/>
         * <br/>
         * 以下のレスポンスはHTTPステータスのみの返却となります。<br/>
         *
         * <ul>
         * <li>200：受付成功し確認用のメールを送信。</li>
         * <li>401：認証に失敗した場合。</li>
         * </ul>
         */
        put: (option: { body: Methods15['put']['reqBody'], config?: T | undefined }) =>
          fetch<void, BasicHeaders, Methods15['put']['status']>(prefix, PATH15, PUT, option).send(),
        /**
         * メールアドレスを変更します。<br/>
         * リクエストを受付け確認用のメールを送信します。<br/>
         * <br/>
         * 以下のレスポンスはHTTPステータスのみの返却となります。<br/>
         *
         * <ul>
         * <li>200：受付成功し確認用のメールを送信。</li>
         * <li>401：認証に失敗した場合。</li>
         * </ul>
         */
        $put: (option: { body: Methods15['put']['reqBody'], config?: T | undefined }) =>
          fetch<void, BasicHeaders, Methods15['put']['status']>(prefix, PATH15, PUT, option).send().then(r => r.body),
        $path: () => `${prefix}${PATH15}`
      },
      change_email_auth: {
        /**
         * リクエストのトークンを用いて、変更したメールアドレスの認証を行います。<br/>
         * 認証成功しメールアドレス変更した場合、ログイン認証レスポンスを返却します。<br/>
         *
         * 以下のレスポンスはHTTPステータスのみの返却となります。<br/>
         *
         * <ul>
         * <li>401：トークン認証に失敗した場合。</li>
         * </ul>
         * @returns 正常処理
         */
        post: (option: { body: Methods16['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods16['post']['resBody'], BasicHeaders, Methods16['post']['status']>(prefix, PATH16, POST, option).json(),
        /**
         * リクエストのトークンを用いて、変更したメールアドレスの認証を行います。<br/>
         * 認証成功しメールアドレス変更した場合、ログイン認証レスポンスを返却します。<br/>
         *
         * 以下のレスポンスはHTTPステータスのみの返却となります。<br/>
         *
         * <ul>
         * <li>401：トークン認証に失敗した場合。</li>
         * </ul>
         * @returns 正常処理
         */
        $post: (option: { body: Methods16['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods16['post']['resBody'], BasicHeaders, Methods16['post']['status']>(prefix, PATH16, POST, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH16}`
      },
      change_invoice_email: {
        /**
         * ### 仕様
         * - 請求書ダウンロードリンクの送信先メールアドレスを更新する。
         * - リクエスト受信後にメールアドレス認証用のメールを送信する。
         * - 全体で重複がないこと。あった場合は400を返す。重複確認の対象は既に設定済みのメールアドレスだけでなく、認証待ちのメールアドレスも対象とする。
         *
         * ### HTTPレスポンス
         * - 200：正常
         * - 400：不正なリクエストを受信した場合
         * - 401：認証エラー
         * @returns 正常処理
         */
        put: (option: { body: Methods17['put']['reqBody'], config?: T | undefined }) =>
          fetch<Methods17['put']['resBody'], BasicHeaders, Methods17['put']['status']>(prefix, PATH17, PUT, option).json(),
        /**
         * ### 仕様
         * - 請求書ダウンロードリンクの送信先メールアドレスを更新する。
         * - リクエスト受信後にメールアドレス認証用のメールを送信する。
         * - 全体で重複がないこと。あった場合は400を返す。重複確認の対象は既に設定済みのメールアドレスだけでなく、認証待ちのメールアドレスも対象とする。
         *
         * ### HTTPレスポンス
         * - 200：正常
         * - 400：不正なリクエストを受信した場合
         * - 401：認証エラー
         * @returns 正常処理
         */
        $put: (option: { body: Methods17['put']['reqBody'], config?: T | undefined }) =>
          fetch<Methods17['put']['resBody'], BasicHeaders, Methods17['put']['status']>(prefix, PATH17, PUT, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH17}`
      },
      change_invoice_email_auth: {
        /**
         * ## 仕様
         * - メールアドレス更新時に送信されるメールから認証を行う。
         * - パラメータで指定されたトークンを保存してあるトークンと比較し、同じ場合認証を行う。
         * - 認証する場合、新しい請求書メールを通常の請求書メールに設定し、新しい請求書メールはnullを設定する。
         *
         * ## レスポンス
         * - 200 正常
         * - 401 トークン不正
         * @returns 正常処理
         */
        post: (option: { body: Methods18['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods18['post']['resBody'], BasicHeaders, Methods18['post']['status']>(prefix, PATH18, POST, option).json(),
        /**
         * ## 仕様
         * - メールアドレス更新時に送信されるメールから認証を行う。
         * - パラメータで指定されたトークンを保存してあるトークンと比較し、同じ場合認証を行う。
         * - 認証する場合、新しい請求書メールを通常の請求書メールに設定し、新しい請求書メールはnullを設定する。
         *
         * ## レスポンス
         * - 200 正常
         * - 401 トークン不正
         * @returns 正常処理
         */
        $post: (option: { body: Methods18['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods18['post']['resBody'], BasicHeaders, Methods18['post']['status']>(prefix, PATH18, POST, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH18}`
      },
      change_password: {
        /**
         * パスワードを変更します。<br/>
         */
        put: (option: { body: Methods19['put']['reqBody'], config?: T | undefined }) =>
          fetch<void, BasicHeaders, Methods19['put']['status']>(prefix, PATH19, PUT, option).send(),
        /**
         * パスワードを変更します。<br/>
         */
        $put: (option: { body: Methods19['put']['reqBody'], config?: T | undefined }) =>
          fetch<void, BasicHeaders, Methods19['put']['status']>(prefix, PATH19, PUT, option).send().then(r => r.body),
        $path: () => `${prefix}${PATH19}`
      },
      change_temp_password: {
        /**
         * 初回ログイン後、パスワードの変更します。<br/>
         */
        put: (option: { body: Methods20['put']['reqBody'], config?: T | undefined }) =>
          fetch<void, BasicHeaders, Methods20['put']['status']>(prefix, PATH20, PUT, option).send(),
        /**
         * 初回ログイン後、パスワードの変更します。<br/>
         */
        $put: (option: { body: Methods20['put']['reqBody'], config?: T | undefined }) =>
          fetch<void, BasicHeaders, Methods20['put']['status']>(prefix, PATH20, PUT, option).send().then(r => r.body),
        $path: () => `${prefix}${PATH20}`
      },
      color_charts: {
        _groupId: (val2: number) => {
          const prefix2 = `${PATH21}/${val2}`

          return {
            /**
             * カラーチャートを取得します。<br/>
             * カラーチャート取得は販売中の商品のみを返却します。<br/>
             * 該当するカラーチャートが1件も存在しない場合、空の配列を返します。<br/>
             * <br/>
             * 商品ステータスは以下になります。<br/>
             * <ul>
             * <li>available：販売中</li>
             * <li>expired：販売終了商品</li>
             * <li>unavailable：販売期間外商品</li>
             * <li>forbidden：扱えない商品</li>
             * </ul>
             *
             * <br/>
             * 並び順は以下になります。<br/>
             * <ul>
             * <li>color_charts.id の昇順</li>
             * <li>ColorChartProduct の brightness の降順</li>
             * <li>ColorChart の colorToneNumber の昇順</li>
             * </ul>
             * @returns 正常処理
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods21['get']['resBody'], BasicHeaders, Methods21['get']['status']>(prefix, prefix2, GET, option).json(),
            /**
             * カラーチャートを取得します。<br/>
             * カラーチャート取得は販売中の商品のみを返却します。<br/>
             * 該当するカラーチャートが1件も存在しない場合、空の配列を返します。<br/>
             * <br/>
             * 商品ステータスは以下になります。<br/>
             * <ul>
             * <li>available：販売中</li>
             * <li>expired：販売終了商品</li>
             * <li>unavailable：販売期間外商品</li>
             * <li>forbidden：扱えない商品</li>
             * </ul>
             *
             * <br/>
             * 並び順は以下になります。<br/>
             * <ul>
             * <li>color_charts.id の昇順</li>
             * <li>ColorChartProduct の brightness の降順</li>
             * <li>ColorChart の colorToneNumber の昇順</li>
             * </ul>
             * @returns 正常処理
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods21['get']['resBody'], BasicHeaders, Methods21['get']['status']>(prefix, prefix2, GET, option).json().then(r => r.body),
            $path: () => `${prefix}${prefix2}`
          }
        }
      },
      contact: {
        /**
         * リクエストパラメータで、お問い合わせメールを送信する。
         */
        post: (option: { body: Methods22['post']['reqBody'], config?: T | undefined }) =>
          fetch<void, BasicHeaders, Methods22['post']['status']>(prefix, PATH22, POST, option).send(),
        /**
         * リクエストパラメータで、お問い合わせメールを送信する。
         */
        $post: (option: { body: Methods22['post']['reqBody'], config?: T | undefined }) =>
          fetch<void, BasicHeaders, Methods22['post']['status']>(prefix, PATH22, POST, option).send().then(r => r.body),
        $path: () => `${prefix}${PATH22}`
      },
      ec_site_orders: {
        _fileId: (val2: string) => {
          const prefix2 = `${PATH23}/${val2}`

          return {
            download: {
              /**
               * CSVデータのダウンロードを行う。
               * データはLibertasから取得する。
               * @returns 正常処理
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods25['get']['resBody'], BasicHeaders, Methods25['get']['status']>(prefix, `${prefix2}${PATH24}`, GET, option).json(),
              /**
               * CSVデータのダウンロードを行う。
               * データはLibertasから取得する。
               * @returns 正常処理
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods25['get']['resBody'], BasicHeaders, Methods25['get']['status']>(prefix, `${prefix2}${PATH24}`, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH24}`
            },
            /**
             * 指定されたアップロードファイルの行詳細を取得する  
             * 詳細は行番号でソートされる
             * データはLibertasから取得する。
             * @returns 正常処理
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods24['get']['resBody'], BasicHeaders, Methods24['get']['status']>(prefix, prefix2, GET, option).json(),
            /**
             * 指定されたアップロードファイルの行詳細を取得する  
             * 詳細は行番号でソートされる
             * データはLibertasから取得する。
             * @returns 正常処理
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods24['get']['resBody'], BasicHeaders, Methods24['get']['status']>(prefix, prefix2, GET, option).json().then(r => r.body),
            $path: () => `${prefix}${prefix2}`
          }
        },
        systemTypes: {
          /**
           * ### 仕様
           * - システム種別一覧を取得する。
           * - クエリパラメータでYahooの形式に ```merged``` を指定した場合、Yahooのシステム種別を ```Yahoo```  として返却する。
           * - クエリパラメータでYahooの形式に ```separated``` を指定した場合、Yahooのシステム種別を注文系と商品系を分けて返却する。
           * - ログインユーザの所属店舗にECAPIの表示権限がある場合、システム種別に ```ECAPI``` を含めて返却する。
           *
           * ### HTTPレスポンス
           * - 200 正常
           * - 400 不正なリクエストを受信した場合
           * - 401 未ログイン
           * @returns 正常処理
           */
          get: (option?: { query?: Methods26['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods26['get']['resBody'], BasicHeaders, Methods26['get']['status']>(prefix, PATH25, GET, option).json(),
          /**
           * ### 仕様
           * - システム種別一覧を取得する。
           * - クエリパラメータでYahooの形式に ```merged``` を指定した場合、Yahooのシステム種別を ```Yahoo```  として返却する。
           * - クエリパラメータでYahooの形式に ```separated``` を指定した場合、Yahooのシステム種別を注文系と商品系を分けて返却する。
           * - ログインユーザの所属店舗にECAPIの表示権限がある場合、システム種別に ```ECAPI``` を含めて返却する。
           *
           * ### HTTPレスポンス
           * - 200 正常
           * - 400 不正なリクエストを受信した場合
           * - 401 未ログイン
           * @returns 正常処理
           */
          $get: (option?: { query?: Methods26['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods26['get']['resBody'], BasicHeaders, Methods26['get']['status']>(prefix, PATH25, GET, option).json().then(r => r.body),
          $path: (option?: { method?: 'get' | undefined; query: Methods26['get']['query'] } | undefined) =>
            `${prefix}${PATH25}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
        },
        /**
         * CSVファイルをアップロードする。 
         * 登録時のエラーなどの結果を返す。
         * 詳細は行番号でソートされる
         *
         * ## 処理仕様 
         * データはLibertasに設定する。
         * - CSVからファイルを解析し文字コードを判別する。
         * - CSVのヘッダ情報から解析しサイトを判定する。 
         * - CSVのサイトごとにデータを解析し取得する。 
         * - CSVからデータをDBに登録し、登録用Functionをコールする。新しいテーブルを作成し、ダウンロードファイルとアップロード結果を紐づけ登録する。 
         * - CSVをAWS上のS3に保存し、後にダウンロードできるようにする。
         * - ファイル内容のサイズがオーバーしていた場合は413でレスポンスが返る。
         *
         * ## 独自エラーコード
         * |エラーコード|内容|
         * |------|------|
         * | -10 | 商品コード桁数不正 |
         * |-11 | JANコード桁数不正 |
         * |-100 | ファイル形式不備 |
         * @returns 正常処理
         */
        post: (option: { body: Methods23['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods23['post']['resBody'], BasicHeaders, Methods23['post']['status']>(prefix, PATH23, POST, option).json(),
        /**
         * CSVファイルをアップロードする。 
         * 登録時のエラーなどの結果を返す。
         * 詳細は行番号でソートされる
         *
         * ## 処理仕様 
         * データはLibertasに設定する。
         * - CSVからファイルを解析し文字コードを判別する。
         * - CSVのヘッダ情報から解析しサイトを判定する。 
         * - CSVのサイトごとにデータを解析し取得する。 
         * - CSVからデータをDBに登録し、登録用Functionをコールする。新しいテーブルを作成し、ダウンロードファイルとアップロード結果を紐づけ登録する。 
         * - CSVをAWS上のS3に保存し、後にダウンロードできるようにする。
         * - ファイル内容のサイズがオーバーしていた場合は413でレスポンスが返る。
         *
         * ## 独自エラーコード
         * |エラーコード|内容|
         * |------|------|
         * | -10 | 商品コード桁数不正 |
         * |-11 | JANコード桁数不正 |
         * |-100 | ファイル形式不備 |
         * @returns 正常処理
         */
        $post: (option: { body: Methods23['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods23['post']['resBody'], BasicHeaders, Methods23['post']['status']>(prefix, PATH23, POST, option).json().then(r => r.body),
        /**
         * Ec注文アップロードされたCSVファイルを入力パラメータから検索し、一覧を返却する。
         * 一覧は取込日時の新しい順でソートされる。
         * データはLibertasから取得する。
         * @returns 正常処理
         */
        get: (option: { query: Methods23['get']['query'], config?: T | undefined }) =>
          fetch<Methods23['get']['resBody'], BasicHeaders, Methods23['get']['status']>(prefix, PATH23, GET, option).json(),
        /**
         * Ec注文アップロードされたCSVファイルを入力パラメータから検索し、一覧を返却する。
         * 一覧は取込日時の新しい順でソートされる。
         * データはLibertasから取得する。
         * @returns 正常処理
         */
        $get: (option: { query: Methods23['get']['query'], config?: T | undefined }) =>
          fetch<Methods23['get']['resBody'], BasicHeaders, Methods23['get']['status']>(prefix, PATH23, GET, option).json().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods23['get']['query'] } | undefined) =>
          `${prefix}${PATH23}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
      },
      favorites: {
        _setNo: (val2: number) => {
          const prefix2 = `${PATH26}/${val2}`

          return {
            _storeCode: (val3: string) => {
              const prefix3 = `${prefix2}/${val3}`

              return {
                duplicate: {
                  /**
                   * ### 概要
                   * 店舗コードを指定して、お気に入りリストを複製します。<br/>
                   * 複製される対象の商品は、指定したお気に入りリストに登録されている販売中の商品になります。<br/>
                   * 複製された名称は「***（コピー）」のように末尾に「（コピー）」が追記されます。<br/>
                   * 複製されたお気に入りリストの並び順は最後尾に追加されます。
                   *
                   * ### HTTPレスポンス
                   * - 200 正常
                   * - 400 パラメータ不正
                   * - 401 未ログイン
                   * - 403 参照権限がないお気に入りリスト
                   * - 404 存在しないお気に入りリスト
                   * @returns 正常処理
                   */
                  post: (option?: { config?: T | undefined } | undefined) =>
                    fetch<Methods29['post']['resBody'], BasicHeaders, Methods29['post']['status']>(prefix, `${prefix3}${PATH27}`, POST, option).json(),
                  /**
                   * ### 概要
                   * 店舗コードを指定して、お気に入りリストを複製します。<br/>
                   * 複製される対象の商品は、指定したお気に入りリストに登録されている販売中の商品になります。<br/>
                   * 複製された名称は「***（コピー）」のように末尾に「（コピー）」が追記されます。<br/>
                   * 複製されたお気に入りリストの並び順は最後尾に追加されます。
                   *
                   * ### HTTPレスポンス
                   * - 200 正常
                   * - 400 パラメータ不正
                   * - 401 未ログイン
                   * - 403 参照権限がないお気に入りリスト
                   * - 404 存在しないお気に入りリスト
                   * @returns 正常処理
                   */
                  $post: (option?: { config?: T | undefined } | undefined) =>
                    fetch<Methods29['post']['resBody'], BasicHeaders, Methods29['post']['status']>(prefix, `${prefix3}${PATH27}`, POST, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix3}${PATH27}`
                },
                items: {
                  /**
                   * ### 概要
                   * 店舗コードを指定して、お気に入りリストの商品一覧を更新します。<br/>
                   * 存在しない商品コードの更新は行われません。<br/>
                   * <br/>
                   * リクエストにお気に入りリストに登録する商品コード一覧を設定します。<br/>
                   * ステータスが販売中以外の商品は、レスポンスの unavailablePurchase として返却されます。<br/>
                   * リクエストの順序がそのまま並び順になります。
                   *
                   * ### 商品ステータス
                   * - available：販売中<
                   * - unavailable：販売終了商品
                   * - expired：販売期間外商品
                   * - forbidden：扱えない商品
                   * - noItemCode：存在しない商品コード
                   *
                   * ### HTTPレスポンス
                   * - 200 正常
                   * - 400 パラメータ不正
                   * - 401 未ログイン
                   * - 403 参照権限がないお気に入りリスト
                   * - 404 存在しないお気に入りリスト
                   * @returns 正常処理
                   */
                  put: (option: { body: Methods30['put']['reqBody'], config?: T | undefined }) =>
                    fetch<Methods30['put']['resBody'], BasicHeaders, Methods30['put']['status']>(prefix, `${prefix3}${PATH28}`, PUT, option).json(),
                  /**
                   * ### 概要
                   * 店舗コードを指定して、お気に入りリストの商品一覧を更新します。<br/>
                   * 存在しない商品コードの更新は行われません。<br/>
                   * <br/>
                   * リクエストにお気に入りリストに登録する商品コード一覧を設定します。<br/>
                   * ステータスが販売中以外の商品は、レスポンスの unavailablePurchase として返却されます。<br/>
                   * リクエストの順序がそのまま並び順になります。
                   *
                   * ### 商品ステータス
                   * - available：販売中<
                   * - unavailable：販売終了商品
                   * - expired：販売期間外商品
                   * - forbidden：扱えない商品
                   * - noItemCode：存在しない商品コード
                   *
                   * ### HTTPレスポンス
                   * - 200 正常
                   * - 400 パラメータ不正
                   * - 401 未ログイン
                   * - 403 参照権限がないお気に入りリスト
                   * - 404 存在しないお気に入りリスト
                   * @returns 正常処理
                   */
                  $put: (option: { body: Methods30['put']['reqBody'], config?: T | undefined }) =>
                    fetch<Methods30['put']['resBody'], BasicHeaders, Methods30['put']['status']>(prefix, `${prefix3}${PATH28}`, PUT, option).json().then(r => r.body),
                  /**
                   * ### 概要
                   * 店舗コードを指定して、お気に入りリスト間での商品移動・複製をします。<br/>
                   * 販売中の商品にのみ移動・複製が行われます。<br/>
                   *
                   * リクエストに移動・複製する商品コード一覧を設定します。<br/>
                   * ステータスが販売中以外の商品は、レスポンスの unavailablePurchase として返却されます。<br/>
                   * リクエストの順序がそのまま並び順になります。
                   *
                   * ### 商品のステータス
                   * - available：販売中
                   * - unavailable：販売終了商品
                   * - expired：販売期間外商品
                   * - forbidden：扱えない商品
                   * - noItemCode：存在しない商品コード
                   *
                   * ### クエリーパラメータ：action
                   * - move：移動
                   * - copy：複製
                   *
                   * ### HTTPレスポンス
                   * - 200 正常
                   * - 400 パラメータ不正
                   * - 401 未ログイン
                   * - 403 参照権限がないお気に入りリスト
                   * - 404 存在しないお気に入りリスト
                   * @returns 正常処理
                   */
                  post: (option: { body: Methods30['post']['reqBody'], query: Methods30['post']['query'], config?: T | undefined }) =>
                    fetch<Methods30['post']['resBody'], BasicHeaders, Methods30['post']['status']>(prefix, `${prefix3}${PATH28}`, POST, option).json(),
                  /**
                   * ### 概要
                   * 店舗コードを指定して、お気に入りリスト間での商品移動・複製をします。<br/>
                   * 販売中の商品にのみ移動・複製が行われます。<br/>
                   *
                   * リクエストに移動・複製する商品コード一覧を設定します。<br/>
                   * ステータスが販売中以外の商品は、レスポンスの unavailablePurchase として返却されます。<br/>
                   * リクエストの順序がそのまま並び順になります。
                   *
                   * ### 商品のステータス
                   * - available：販売中
                   * - unavailable：販売終了商品
                   * - expired：販売期間外商品
                   * - forbidden：扱えない商品
                   * - noItemCode：存在しない商品コード
                   *
                   * ### クエリーパラメータ：action
                   * - move：移動
                   * - copy：複製
                   *
                   * ### HTTPレスポンス
                   * - 200 正常
                   * - 400 パラメータ不正
                   * - 401 未ログイン
                   * - 403 参照権限がないお気に入りリスト
                   * - 404 存在しないお気に入りリスト
                   * @returns 正常処理
                   */
                  $post: (option: { body: Methods30['post']['reqBody'], query: Methods30['post']['query'], config?: T | undefined }) =>
                    fetch<Methods30['post']['resBody'], BasicHeaders, Methods30['post']['status']>(prefix, `${prefix3}${PATH28}`, POST, option).json().then(r => r.body),
                  $path: (option?: { method: 'post'; query: Methods30['post']['query'] } | undefined) =>
                    `${prefix}${prefix3}${PATH28}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                },
                /**
                 * ### 概要
                 * 店舗コードを指定して、お気に入りリストの名称を更新をします。
                 *
                 * ### HTTPレスポンス
                 * - 200 正常
                 * - 400 パラメータ不正
                 * - 401 未ログイン
                 * - 403 参照権限がないお気に入りリスト
                 * - 404 存在しないお気に入りリスト
                 * @returns 正常処理
                 */
                put: (option: { body: Methods28['put']['reqBody'], config?: T | undefined }) =>
                  fetch<Methods28['put']['resBody'], BasicHeaders, Methods28['put']['status']>(prefix, prefix3, PUT, option).json(),
                /**
                 * ### 概要
                 * 店舗コードを指定して、お気に入りリストの名称を更新をします。
                 *
                 * ### HTTPレスポンス
                 * - 200 正常
                 * - 400 パラメータ不正
                 * - 401 未ログイン
                 * - 403 参照権限がないお気に入りリスト
                 * - 404 存在しないお気に入りリスト
                 * @returns 正常処理
                 */
                $put: (option: { body: Methods28['put']['reqBody'], config?: T | undefined }) =>
                  fetch<Methods28['put']['resBody'], BasicHeaders, Methods28['put']['status']>(prefix, prefix3, PUT, option).json().then(r => r.body),
                /**
                 * ### 概要
                 * 店舗コードを指定して、お気に入りリストを削除をします。
                 *
                 * ### HTTPレスポンス
                 * - 200 正常
                 * - 400 パラメータ不正
                 * - 401 未ログイン
                 * - 403 参照権限がないお気に入りリスト
                 * - 404 存在しないお気に入りリスト
                 * @returns 正常処理
                 */
                delete: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods28['delete']['resBody'], BasicHeaders, Methods28['delete']['status']>(prefix, prefix3, DELETE, option).json(),
                /**
                 * ### 概要
                 * 店舗コードを指定して、お気に入りリストを削除をします。
                 *
                 * ### HTTPレスポンス
                 * - 200 正常
                 * - 400 パラメータ不正
                 * - 401 未ログイン
                 * - 403 参照権限がないお気に入りリスト
                 * - 404 存在しないお気に入りリスト
                 * @returns 正常処理
                 */
                $delete: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods28['delete']['resBody'], BasicHeaders, Methods28['delete']['status']>(prefix, prefix3, DELETE, option).json().then(r => r.body),
                /**
                 * ### 概要
                 * 店舗コードを指定して、お気に入りリストの商品一覧を取得します。<br/>
                 * 商品一覧に扱えない商品が含まれる場合、該当の商品情報はnullで返却されます。<br/>
                 * 画面上には販売中の商品のみ表示します。
                 *
                 * ### 商品ステータス
                 * - available：販売中
                 * - unavailable：販売終了商品
                 * - expired：販売期間外商品
                 * - forbidden：扱えない商品
                 *
                 * ### 商品一覧の並び順
                 * - 商品一覧：mm_favm.sort_no の昇順
                 *
                 * ### 売上種別一覧の並び順
                 * - salestypes_defaulted_to_favorites.sort_no の昇順
                 *
                 * ### HTTPレスポンス
                 * - 200 正常
                 * - 400 パラメータ不正
                 * - 401 未ログイン
                 * - 403 参照権限がないお気に入りリスト
                 * - 404 存在しないお気に入りリスト
                 * @returns 正常処理
                 */
                get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods28['get']['resBody'], BasicHeaders, Methods28['get']['status']>(prefix, prefix3, GET, option).json(),
                /**
                 * ### 概要
                 * 店舗コードを指定して、お気に入りリストの商品一覧を取得します。<br/>
                 * 商品一覧に扱えない商品が含まれる場合、該当の商品情報はnullで返却されます。<br/>
                 * 画面上には販売中の商品のみ表示します。
                 *
                 * ### 商品ステータス
                 * - available：販売中
                 * - unavailable：販売終了商品
                 * - expired：販売期間外商品
                 * - forbidden：扱えない商品
                 *
                 * ### 商品一覧の並び順
                 * - 商品一覧：mm_favm.sort_no の昇順
                 *
                 * ### 売上種別一覧の並び順
                 * - salestypes_defaulted_to_favorites.sort_no の昇順
                 *
                 * ### HTTPレスポンス
                 * - 200 正常
                 * - 400 パラメータ不正
                 * - 401 未ログイン
                 * - 403 参照権限がないお気に入りリスト
                 * - 404 存在しないお気に入りリスト
                 * @returns 正常処理
                 */
                $get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods28['get']['resBody'], BasicHeaders, Methods28['get']['status']>(prefix, prefix3, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix3}`
              }
            }
          }
        },
        items: {
          /**
           * ### 概要
           * お気に入りリストの並び順を更新します。<br/>
           * リクエストの順序がそのまま並び順になります。
           *
           * ### HTTPレスポンス
           * - 200 正常
           * - 400 パラメータ不正
           * - 401 未ログイン
           * - 403 参照権限がないお気に入りリスト
           * - 404 存在しないお気に入りリスト
           */
          put: (option: { body: Methods31['put']['reqBody'], config?: T | undefined }) =>
            fetch<void, BasicHeaders, Methods31['put']['status']>(prefix, PATH29, PUT, option).send(),
          /**
           * ### 概要
           * お気に入りリストの並び順を更新します。<br/>
           * リクエストの順序がそのまま並び順になります。
           *
           * ### HTTPレスポンス
           * - 200 正常
           * - 400 パラメータ不正
           * - 401 未ログイン
           * - 403 参照権限がないお気に入りリスト
           * - 404 存在しないお気に入りリスト
           */
          $put: (option: { body: Methods31['put']['reqBody'], config?: T | undefined }) =>
            fetch<void, BasicHeaders, Methods31['put']['status']>(prefix, PATH29, PUT, option).send().then(r => r.body),
          $path: () => `${prefix}${PATH29}`
        },
        /**
         * ### 概要
         * お気に入りリストを作成します。
         *
         * ### HTTPレスポンス
         * - 200 正常
         * - 400 パラメータ不正
         * - 401 未ログイン
         * @returns 正常処理
         */
        post: (option: { body: Methods27['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods27['post']['resBody'], BasicHeaders, Methods27['post']['status']>(prefix, PATH26, POST, option).json(),
        /**
         * ### 概要
         * お気に入りリストを作成します。
         *
         * ### HTTPレスポンス
         * - 200 正常
         * - 400 パラメータ不正
         * - 401 未ログイン
         * @returns 正常処理
         */
        $post: (option: { body: Methods27['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods27['post']['resBody'], BasicHeaders, Methods27['post']['status']>(prefix, PATH26, POST, option).json().then(r => r.body),
        /**
         * ### 概要
         * お気に入りリストの一覧を取得します。<br/>
         * セットNo.及び名前と店舗情報、登録済みの商品コード一覧を返却します。<br/>
         * 返却する店舗コードは、店舗タイプで判別できます。<br/>
         * 編集可能可否と複製可能可否は、自店舗のお気に入りリストの場合、trueを、<br/>
         * 入金親から共有されたお気に入りリストの場合、falseを返却します。<br/>
         * <br/>
         * 編集可能可否は、お気に入りリストの名称変更、削除、商品の並び替え、移動、削除を制限します。<br/>
         * 複製可能可否は、お気に入りリストの複製を制限します。<br/>
         * <br/>
         * 入金親の場合、自店舗のお気に入りリストを取得します。<br/>
         * 子店舗の場合、自店舗のお気に入りリストと入金親から共有されたお気に入りリストを取得します。
         *
         * ### お気に入りリスト一覧の並び順
         * - mm_favh.sort_no の昇順
         *
         * ### 商品コード一覧の並び順
         * - mm_favm.sort_no の昇順
         *
         * ### HTTPレスポンス
         * - 200 正常
         * - 401 未ログイン
         * @returns 正常処理
         */
        get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods27['get']['resBody'], BasicHeaders, Methods27['get']['status']>(prefix, PATH26, GET, option).json(),
        /**
         * ### 概要
         * お気に入りリストの一覧を取得します。<br/>
         * セットNo.及び名前と店舗情報、登録済みの商品コード一覧を返却します。<br/>
         * 返却する店舗コードは、店舗タイプで判別できます。<br/>
         * 編集可能可否と複製可能可否は、自店舗のお気に入りリストの場合、trueを、<br/>
         * 入金親から共有されたお気に入りリストの場合、falseを返却します。<br/>
         * <br/>
         * 編集可能可否は、お気に入りリストの名称変更、削除、商品の並び替え、移動、削除を制限します。<br/>
         * 複製可能可否は、お気に入りリストの複製を制限します。<br/>
         * <br/>
         * 入金親の場合、自店舗のお気に入りリストを取得します。<br/>
         * 子店舗の場合、自店舗のお気に入りリストと入金親から共有されたお気に入りリストを取得します。
         *
         * ### お気に入りリスト一覧の並び順
         * - mm_favh.sort_no の昇順
         *
         * ### 商品コード一覧の並び順
         * - mm_favm.sort_no の昇順
         *
         * ### HTTPレスポンス
         * - 200 正常
         * - 401 未ログイン
         * @returns 正常処理
         */
        $get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods27['get']['resBody'], BasicHeaders, Methods27['get']['status']>(prefix, PATH26, GET, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH26}`
      },
      invoices: {
        _invoiceId: (val2: string) => {
          const prefix2 = `${PATH30}/${val2}`

          return {
            download: {
              /**
               * ## 説明
               * * 請求書PDFをダウンロードします。<br/>
               *   ただし、関連ファイルが存在する場合、請求書のPDFと関連ファイルをzipでダウンロードします。
               * * ダウンロードを行った履歴を登録します。
               *
               * ## Response の補足説明
               * ### 請求書のみをダウンロードする場合
               * * Content-Type に application/pdf を指定する。
               * * Content-Disposition に `attachment; filename*=UTF-8''ファイル名` を指定する。<br/>
               *   ex: `attachment; filename*=UTF-8''20240220_15597_%E3%83%86%E3%82%B9%E3%83%88%E7%92%B0%E5%A2%83%E3%83%87%E3%83%A2%E3%82%B5%E3%83%AD%E3%83%B3_35238_20240201163153.pdf`
               *  
               * ### 請求書と関連ファイルをダウンロードする場合
               * * Content-Type に application/zip を指定する。
               * * Content-Disposition に `attachment; filename*=UTF-8''ファイル名` を指定する。<br/>
               *   ファイル名は `invoice_yyyyMMddhhmmssfff.zip` とする。<br/>
               *   ex: `attachment; filename*=UTF-8''invoice_20240312102300995.zip`
               *
               * ## HTTPレスポンス
               * - 200 正常
               * - 400 パラメータ不正
               * - 401 未ログイン
               * - 403 ログインユーザに権限がない場合
               * - 404 存在しない請求書IDの場合
               * @returns 正常処理
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods33['get']['resBody'], BasicHeaders, Methods33['get']['status']>(prefix, `${prefix2}${PATH24}`, GET, option).blob(),
              /**
               * ## 説明
               * * 請求書PDFをダウンロードします。<br/>
               *   ただし、関連ファイルが存在する場合、請求書のPDFと関連ファイルをzipでダウンロードします。
               * * ダウンロードを行った履歴を登録します。
               *
               * ## Response の補足説明
               * ### 請求書のみをダウンロードする場合
               * * Content-Type に application/pdf を指定する。
               * * Content-Disposition に `attachment; filename*=UTF-8''ファイル名` を指定する。<br/>
               *   ex: `attachment; filename*=UTF-8''20240220_15597_%E3%83%86%E3%82%B9%E3%83%88%E7%92%B0%E5%A2%83%E3%83%87%E3%83%A2%E3%82%B5%E3%83%AD%E3%83%B3_35238_20240201163153.pdf`
               *  
               * ### 請求書と関連ファイルをダウンロードする場合
               * * Content-Type に application/zip を指定する。
               * * Content-Disposition に `attachment; filename*=UTF-8''ファイル名` を指定する。<br/>
               *   ファイル名は `invoice_yyyyMMddhhmmssfff.zip` とする。<br/>
               *   ex: `attachment; filename*=UTF-8''invoice_20240312102300995.zip`
               *
               * ## HTTPレスポンス
               * - 200 正常
               * - 400 パラメータ不正
               * - 401 未ログイン
               * - 403 ログインユーザに権限がない場合
               * - 404 存在しない請求書IDの場合
               * @returns 正常処理
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods33['get']['resBody'], BasicHeaders, Methods33['get']['status']>(prefix, `${prefix2}${PATH24}`, GET, option).blob().then(r => r.body),
              /**
               * ## 説明
               * * 有効期限付きのパスワードと照合し、パスワードが正しければ請求書のPDFをダウンロードします。<br/>
               *   ただし、関連ファイルが存在する場合、請求書のPDFと関連ファイルをzipでダウンロードします。<br/>
               *   また、パスワードの有効期限は、パスワード発行日時から30日間とします。
               * * ダウンロードを行った履歴を登録します。
               *
               * ## Response の補足説明
               * ### 請求書のみをダウンロードする場合
               * * Content-Type に application/pdf を指定する。
               * * Content-Disposition に `attachment; filename*=UTF-8''ファイル名` を指定する。<br/>
               *   ex: `attachment; filename*=UTF-8''20240220_15597_%E3%83%86%E3%82%B9%E3%83%88%E7%92%B0%E5%A2%83%E3%83%87%E3%83%A2%E3%82%B5%E3%83%AD%E3%83%B3_35238_20240201163153.pdf`
               *  
               * ### 請求書と関連ファイルをダウンロードする場合
               * * Content-Type に application/zip を指定する。
               * * Content-Disposition に `attachment; filename*=UTF-8''ファイル名` を指定する。<br/>
               *   ファイル名は `invoice_yyyyMMddhhmmssfff.zip` とする。<br/>
               *   ex: `attachment; filename*=UTF-8''invoice_20240312102300995.zip`
               *
               * ## HTTPレスポンス
               * - 200 正常
               * - 400 パラメータ不正
               * - 403 パスワード不正、パスワードの有効期限切れ
               * - 404 存在しないファイルID
               * @returns 正常処理
               */
              post: (option: { body: Methods33['post']['reqBody'], config?: T | undefined }) =>
                fetch<Methods33['post']['resBody'], BasicHeaders, Methods33['post']['status']>(prefix, `${prefix2}${PATH24}`, POST, option).blob(),
              /**
               * ## 説明
               * * 有効期限付きのパスワードと照合し、パスワードが正しければ請求書のPDFをダウンロードします。<br/>
               *   ただし、関連ファイルが存在する場合、請求書のPDFと関連ファイルをzipでダウンロードします。<br/>
               *   また、パスワードの有効期限は、パスワード発行日時から30日間とします。
               * * ダウンロードを行った履歴を登録します。
               *
               * ## Response の補足説明
               * ### 請求書のみをダウンロードする場合
               * * Content-Type に application/pdf を指定する。
               * * Content-Disposition に `attachment; filename*=UTF-8''ファイル名` を指定する。<br/>
               *   ex: `attachment; filename*=UTF-8''20240220_15597_%E3%83%86%E3%82%B9%E3%83%88%E7%92%B0%E5%A2%83%E3%83%87%E3%83%A2%E3%82%B5%E3%83%AD%E3%83%B3_35238_20240201163153.pdf`
               *  
               * ### 請求書と関連ファイルをダウンロードする場合
               * * Content-Type に application/zip を指定する。
               * * Content-Disposition に `attachment; filename*=UTF-8''ファイル名` を指定する。<br/>
               *   ファイル名は `invoice_yyyyMMddhhmmssfff.zip` とする。<br/>
               *   ex: `attachment; filename*=UTF-8''invoice_20240312102300995.zip`
               *
               * ## HTTPレスポンス
               * - 200 正常
               * - 400 パラメータ不正
               * - 403 パスワード不正、パスワードの有効期限切れ
               * - 404 存在しないファイルID
               * @returns 正常処理
               */
              $post: (option: { body: Methods33['post']['reqBody'], config?: T | undefined }) =>
                fetch<Methods33['post']['resBody'], BasicHeaders, Methods33['post']['status']>(prefix, `${prefix2}${PATH24}`, POST, option).blob().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH24}`
            },
            preview: {
              /**
               * ### 概要
               * 請求書のプレビューを表示する。
               *
               * ### 処理仕様
               *
               * - 請求書IDからS3に格納された請求書の画像(jpg)を取得する。
               * - 画像をbase64でエンコードする。
               * - ファイル内容を返す。
               *
               * ### HTTPレスポンス
               * - 200 正常
               * - 400 パラメータ不正
               * - 401 未ログイン
               * - 403 ログインユーザに権限がない場合
               * - 404 存在しない請求書IDの場合
               * @returns 正常処理
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods34['get']['resBody'], BasicHeaders, Methods34['get']['status']>(prefix, `${prefix2}${PATH31}`, GET, option).json(),
              /**
               * ### 概要
               * 請求書のプレビューを表示する。
               *
               * ### 処理仕様
               *
               * - 請求書IDからS3に格納された請求書の画像(jpg)を取得する。
               * - 画像をbase64でエンコードする。
               * - ファイル内容を返す。
               *
               * ### HTTPレスポンス
               * - 200 正常
               * - 400 パラメータ不正
               * - 401 未ログイン
               * - 403 ログインユーザに権限がない場合
               * - 404 存在しない請求書IDの場合
               * @returns 正常処理
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods34['get']['resBody'], BasicHeaders, Methods34['get']['status']>(prefix, `${prefix2}${PATH31}`, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH31}`
            }
          }
        },
        download: {
          /**
           * ## 説明
           * * 指定された請求書をzip圧縮して、まとめてダウンロードします。<br/>
           *   ファイル名は、「invoice_yyyyMMddHHmmssfff.zip」とします。
           * * 指定した請求書に関連ファイルが存在する場合、関連ファイルもダウンロードします。
           *   関連ファイルが存在する請求書の場合、請求書単位でフォルダーを作成し、フォルダーの中に請求書のPDFと各関連ファイルを格納します。
           *   フォルダー名は 「請求年月_請求先コード_請求先名」とする。（例 : 20240220_15597_テスト環境デモサロン）
           *
           * ### ダウンロードしたファイルの中身のサンプル
           *
           * * 20240220_15597_テスト環境デモサロン（フォルダー）
           *     * 20240220_15597_テスト環境デモサロン_35238_20240201163153.pdf
           *     * 15597_2024年2月分売上データ.xlsx
           * * 20240220_52632_15597子サロンに紐づく52632_100000_20240522000001.pdf
           *
           * ### Response の補足説明
           * * Content-Type に application/zip を指定する。
           * * Content-Disposition に `attachment; filename*=UTF-8''ファイル名` を指定する。<br/>
           *   ファイル名は `invoice_yyyyMMddhhmmssfff.zip` とする。<br/>
           *   ex: `attachment; filename*=UTF-8''invoice_20240312102300995.zip`
           *
           * ## HTTPレスポンス
           * - 200 正常
           * - 400 パラメータ不正
           * - 401 未ログイン
           * - 403 ログインユーザに権限がない場合
           * - 404 存在しない請求書IDの場合
           * @returns 正常処理
           */
          get: (option: { query: Methods35['get']['query'], config?: T | undefined }) =>
            fetch<Methods35['get']['resBody'], BasicHeaders, Methods35['get']['status']>(prefix, PATH32, GET, option).blob(),
          /**
           * ## 説明
           * * 指定された請求書をzip圧縮して、まとめてダウンロードします。<br/>
           *   ファイル名は、「invoice_yyyyMMddHHmmssfff.zip」とします。
           * * 指定した請求書に関連ファイルが存在する場合、関連ファイルもダウンロードします。
           *   関連ファイルが存在する請求書の場合、請求書単位でフォルダーを作成し、フォルダーの中に請求書のPDFと各関連ファイルを格納します。
           *   フォルダー名は 「請求年月_請求先コード_請求先名」とする。（例 : 20240220_15597_テスト環境デモサロン）
           *
           * ### ダウンロードしたファイルの中身のサンプル
           *
           * * 20240220_15597_テスト環境デモサロン（フォルダー）
           *     * 20240220_15597_テスト環境デモサロン_35238_20240201163153.pdf
           *     * 15597_2024年2月分売上データ.xlsx
           * * 20240220_52632_15597子サロンに紐づく52632_100000_20240522000001.pdf
           *
           * ### Response の補足説明
           * * Content-Type に application/zip を指定する。
           * * Content-Disposition に `attachment; filename*=UTF-8''ファイル名` を指定する。<br/>
           *   ファイル名は `invoice_yyyyMMddhhmmssfff.zip` とする。<br/>
           *   ex: `attachment; filename*=UTF-8''invoice_20240312102300995.zip`
           *
           * ## HTTPレスポンス
           * - 200 正常
           * - 400 パラメータ不正
           * - 401 未ログイン
           * - 403 ログインユーザに権限がない場合
           * - 404 存在しない請求書IDの場合
           * @returns 正常処理
           */
          $get: (option: { query: Methods35['get']['query'], config?: T | undefined }) =>
            fetch<Methods35['get']['resBody'], BasicHeaders, Methods35['get']['status']>(prefix, PATH32, GET, option).blob().then(r => r.body),
          $path: (option?: { method?: 'get' | undefined; query: Methods35['get']['query'] } | undefined) =>
            `${prefix}${PATH32}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
        },
        histories: {
          download: {
            /**
             * ### 概要
             * 請求書の操作履歴を取得し、CSVをダウンロードする。<br/>
             * 検索結果が0件の場合、400エラーを返す。<br/>
             * ファイル名は、「請求書履歴_店舗コード_店舗名_yyyyMMddHHmmssSSS.csv」となる。
             *
             * ### 処理仕様
             *
             * - パラメータから検索条件を作成する。
             * - DBから結果を取得し、UTF-8 BOM形式CSVに整形する。
             * - Content-Type に text/csv を指定する。
             * - Content-Disposition に `attachment; filename="請求書履歴_店舗コード_店舗名_yyyyMMddHHmmssSSS.csv" を指定する。
             * - バイナリデータを返却する。
             *
             * ### 並び順
             * - 操作時刻の降順
             *
             * ### HTTPレスポンス
             * - 200 正常
             * - 400 パラメータ不正
             * - 401 未ログイン
             * - 403 ログインユーザに権限がない場合
             *
             * ### CSVカラム
             *
             * 1. 操作時刻
             * 2. 請求先コード
             * 3. 請求先名
             * 4. 請求書ファイル名
             * 5. 操作者種別
             * 6. 操作者名
             * 7. 操作内容
             * @returns 正常処理
             */
            get: (option: { query: Methods37['get']['query'], config?: T | undefined }) =>
              fetch<Methods37['get']['resBody'], BasicHeaders, Methods37['get']['status']>(prefix, PATH34, GET, option).blob(),
            /**
             * ### 概要
             * 請求書の操作履歴を取得し、CSVをダウンロードする。<br/>
             * 検索結果が0件の場合、400エラーを返す。<br/>
             * ファイル名は、「請求書履歴_店舗コード_店舗名_yyyyMMddHHmmssSSS.csv」となる。
             *
             * ### 処理仕様
             *
             * - パラメータから検索条件を作成する。
             * - DBから結果を取得し、UTF-8 BOM形式CSVに整形する。
             * - Content-Type に text/csv を指定する。
             * - Content-Disposition に `attachment; filename="請求書履歴_店舗コード_店舗名_yyyyMMddHHmmssSSS.csv" を指定する。
             * - バイナリデータを返却する。
             *
             * ### 並び順
             * - 操作時刻の降順
             *
             * ### HTTPレスポンス
             * - 200 正常
             * - 400 パラメータ不正
             * - 401 未ログイン
             * - 403 ログインユーザに権限がない場合
             *
             * ### CSVカラム
             *
             * 1. 操作時刻
             * 2. 請求先コード
             * 3. 請求先名
             * 4. 請求書ファイル名
             * 5. 操作者種別
             * 6. 操作者名
             * 7. 操作内容
             * @returns 正常処理
             */
            $get: (option: { query: Methods37['get']['query'], config?: T | undefined }) =>
              fetch<Methods37['get']['resBody'], BasicHeaders, Methods37['get']['status']>(prefix, PATH34, GET, option).blob().then(r => r.body),
            $path: (option?: { method?: 'get' | undefined; query: Methods37['get']['query'] } | undefined) =>
              `${prefix}${PATH34}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
          },
          /**
           * ### 概要
           * 請求書の操作履歴を取得する。
           *
           * ### 処理仕様
           *
           * - パラメータから検索条件を作成する。
           * - DBから結果を取得する。
           * - 結果を返す。
           *
           * ### 並び順
           * - 操作時刻の降順
           *
           * ### HTTPレスポンス
           * - 200 正常
           * - 400 パラメータ不正
           * - 401 未ログイン
           * - 403 ログインユーザに権限がない場合
           * @returns 正常処理
           */
          get: (option: { query: Methods36['get']['query'], config?: T | undefined }) =>
            fetch<Methods36['get']['resBody'], BasicHeaders, Methods36['get']['status']>(prefix, PATH33, GET, option).json(),
          /**
           * ### 概要
           * 請求書の操作履歴を取得する。
           *
           * ### 処理仕様
           *
           * - パラメータから検索条件を作成する。
           * - DBから結果を取得する。
           * - 結果を返す。
           *
           * ### 並び順
           * - 操作時刻の降順
           *
           * ### HTTPレスポンス
           * - 200 正常
           * - 400 パラメータ不正
           * - 401 未ログイン
           * - 403 ログインユーザに権限がない場合
           * @returns 正常処理
           */
          $get: (option: { query: Methods36['get']['query'], config?: T | undefined }) =>
            fetch<Methods36['get']['resBody'], BasicHeaders, Methods36['get']['status']>(prefix, PATH33, GET, option).json().then(r => r.body),
          $path: (option?: { method?: 'get' | undefined; query: Methods36['get']['query'] } | undefined) =>
            `${prefix}${PATH33}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
        },
        /**
         * ### 仕様
         * - 検索条件に一致する請求書を取得する。
         * - ログインユーザーに請求書機能の権限がない場合、403エラーとする。
         * - ログインユーザーに管理者権限がある場合、すべての請求先に紐づく請求書が閲覧可能となる。<br/>管理者権限がない場合、担当支店に紐づく請求先の請求書のみが閲覧可能となる。
         * - 請求月の指定がない場合は、当月の請求書を対象とする。
         * - 取得対象は、請求書ファイルステータスが```published``` ```unPublished```の請求書となる。
         *
         * ### 並び順
         * - 店舗コードの昇順
         * - 請求書公開日の降順
         *
         * ### HTTPレスポンス
         * - 200 正常
         * - 400 パラメータ不正
         * - 401 未ログイン
         * - 403 ログインユーザに権限がない場合
         * @returns 正常処理
         */
        get: (option?: { query?: Methods32['get']['query'] | undefined, config?: T | undefined } | undefined) =>
          fetch<Methods32['get']['resBody'], BasicHeaders, Methods32['get']['status']>(prefix, PATH30, GET, option).json(),
        /**
         * ### 仕様
         * - 検索条件に一致する請求書を取得する。
         * - ログインユーザーに請求書機能の権限がない場合、403エラーとする。
         * - ログインユーザーに管理者権限がある場合、すべての請求先に紐づく請求書が閲覧可能となる。<br/>管理者権限がない場合、担当支店に紐づく請求先の請求書のみが閲覧可能となる。
         * - 請求月の指定がない場合は、当月の請求書を対象とする。
         * - 取得対象は、請求書ファイルステータスが```published``` ```unPublished```の請求書となる。
         *
         * ### 並び順
         * - 店舗コードの昇順
         * - 請求書公開日の降順
         *
         * ### HTTPレスポンス
         * - 200 正常
         * - 400 パラメータ不正
         * - 401 未ログイン
         * - 403 ログインユーザに権限がない場合
         * @returns 正常処理
         */
        $get: (option?: { query?: Methods32['get']['query'] | undefined, config?: T | undefined } | undefined) =>
          fetch<Methods32['get']['resBody'], BasicHeaders, Methods32['get']['status']>(prefix, PATH30, GET, option).json().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods32['get']['query'] } | undefined) =>
          `${prefix}${PATH30}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
      },
      items: {
        _itemCode: (val2: string) => {
          const prefix2 = `${PATH35}/${val2}`

          return {
            prices: {
              /**
               * 商品の売上種別ごとの販売価格一覧を取得する。
               * 購入価格表示権限がない場合403が返却される。
               * @returns 正常処理
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods40['get']['resBody'], BasicHeaders, Methods40['get']['status']>(prefix, `${prefix2}${PATH36}`, GET, option).json(),
              /**
               * 商品の売上種別ごとの販売価格一覧を取得する。
               * 購入価格表示権限がない場合403が返却される。
               * @returns 正常処理
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods40['get']['resBody'], BasicHeaders, Methods40['get']['status']>(prefix, `${prefix2}${PATH36}`, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH36}`
            },
            /**
             * 商品の詳細情報を取得します。<br/>
             * 詳細情報は販売中の商品のみ返却します。<br/>
             * <br/>
             * 商品ステータスは以下になります。<br/>
             * <ul>
             * <li>available：販売中</li>
             * <li>expired：販売終了商品</li>
             * <li>unavailable：販売期間外商品</li>
             * <li>forbidden：扱えない商品</li>
             * </ul>
             * @returns 正常処理
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods39['get']['resBody'], BasicHeaders, Methods39['get']['status']>(prefix, prefix2, GET, option).json(),
            /**
             * 商品の詳細情報を取得します。<br/>
             * 詳細情報は販売中の商品のみ返却します。<br/>
             * <br/>
             * 商品ステータスは以下になります。<br/>
             * <ul>
             * <li>available：販売中</li>
             * <li>expired：販売終了商品</li>
             * <li>unavailable：販売期間外商品</li>
             * <li>forbidden：扱えない商品</li>
             * </ul>
             * @returns 正常処理
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods39['get']['resBody'], BasicHeaders, Methods39['get']['status']>(prefix, prefix2, GET, option).json().then(r => r.body),
            $path: () => `${prefix}${prefix2}`
          }
        },
        count: {
          /**
           * 商品検索件数取得
           * @returns 正常処理
           */
          get: (option?: { query?: Methods41['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods41['get']['resBody'], BasicHeaders, Methods41['get']['status']>(prefix, PATH37, GET, option).json(),
          /**
           * 商品検索件数取得
           * @returns 正常処理
           */
          $get: (option?: { query?: Methods41['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods41['get']['resBody'], BasicHeaders, Methods41['get']['status']>(prefix, PATH37, GET, option).json().then(r => r.body),
          $path: (option?: { method?: 'get' | undefined; query: Methods41['get']['query'] } | undefined) =>
            `${prefix}${PATH37}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
        },
        /**
         * 商品検索を行います。<br>
         * 検索結果は販売中の商品のみを返却します。<br/>
         * <br/>
         * 商品ステータスは以下になります。<br/>
         * <ul>
         * <li>available：販売中</li>
         * <li>expired：販売終了商品</li>
         * <li>unavailable：販売期間外商品</li>
         * <li>forbidden：扱えない商品</li>
         * </ul>
         * @returns 正常処理
         */
        get: (option: { query: Methods38['get']['query'], config?: T | undefined }) =>
          fetch<Methods38['get']['resBody'], BasicHeaders, Methods38['get']['status']>(prefix, PATH35, GET, option).json(),
        /**
         * 商品検索を行います。<br>
         * 検索結果は販売中の商品のみを返却します。<br/>
         * <br/>
         * 商品ステータスは以下になります。<br/>
         * <ul>
         * <li>available：販売中</li>
         * <li>expired：販売終了商品</li>
         * <li>unavailable：販売期間外商品</li>
         * <li>forbidden：扱えない商品</li>
         * </ul>
         * @returns 正常処理
         */
        $get: (option: { query: Methods38['get']['query'], config?: T | undefined }) =>
          fetch<Methods38['get']['resBody'], BasicHeaders, Methods38['get']['status']>(prefix, PATH35, GET, option).json().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods38['get']['query'] } | undefined) =>
          `${prefix}${PATH35}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
      },
      login: {
        /**
         * ログイン処理を行い、認証結果を返します。<br/>
         * 正しく認証できた場合は、JWTとアカウント情報を返します。<br/>
         * @returns 正常処理
         */
        post: (option: { body: Methods42['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods42['post']['resBody'], BasicHeaders, Methods42['post']['status']>(prefix, PATH38, POST, option).json(),
        /**
         * ログイン処理を行い、認証結果を返します。<br/>
         * 正しく認証できた場合は、JWTとアカウント情報を返します。<br/>
         * @returns 正常処理
         */
        $post: (option: { body: Methods42['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods42['post']['resBody'], BasicHeaders, Methods42['post']['status']>(prefix, PATH38, POST, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH38}`
      },
      logout: {
        /**
         * ログアウト処理を行います。<br/>
         * サーバ側で保持しているリフレッシュトークンを削除します。<br/>
         * <br/>
         * 以下のレスポンスはHTTPステータスのみの返却となります。<br/>
         *
         * <ul>
         * <li>200：ログアウト成功。</li>
         * <li>401：認証に失敗した場合。</li>
         * </ul>
         */
        put: (option?: { config?: T | undefined } | undefined) =>
          fetch<void, BasicHeaders, Methods43['put']['status']>(prefix, PATH39, PUT, option).send(),
        /**
         * ログアウト処理を行います。<br/>
         * サーバ側で保持しているリフレッシュトークンを削除します。<br/>
         * <br/>
         * 以下のレスポンスはHTTPステータスのみの返却となります。<br/>
         *
         * <ul>
         * <li>200：ログアウト成功。</li>
         * <li>401：認証に失敗した場合。</li>
         * </ul>
         */
        $put: (option?: { config?: T | undefined } | undefined) =>
          fetch<void, BasicHeaders, Methods43['put']['status']>(prefix, PATH39, PUT, option).send().then(r => r.body),
        $path: () => `${prefix}${PATH39}`
      },
      order_histories: {
        _downloadId: (val2: number | string) => {
          const prefix2 = `${PATH40}/${val2}`

          return {
            download: {
              /**
               * ## 説明
               * * 指定したダウンロードIDのCSVファイルの作成処理終了確認とダウンロードを行います。
               * * ステータスが処理中の場合、ファイル名と内容は空を返します。
               * * ステータスが完了の場合、ファイル名と内容を返します。<br/>
               *   ファイル名は「Altair_注文履歴_yyyyMMddHHmmssfff.csv」とします。
               * * 処理中エラーが発生した場合はエラーが返りファイル内容等は返却しません。
               * @returns 正常終了
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods45['get']['resBody'], Methods45['get']['resHeaders'], Methods45['get']['status']>(prefix, `${prefix2}${PATH24}`, GET, option).json(),
              /**
               * ## 説明
               * * 指定したダウンロードIDのCSVファイルの作成処理終了確認とダウンロードを行います。
               * * ステータスが処理中の場合、ファイル名と内容は空を返します。
               * * ステータスが完了の場合、ファイル名と内容を返します。<br/>
               *   ファイル名は「Altair_注文履歴_yyyyMMddHHmmssfff.csv」とします。
               * * 処理中エラーが発生した場合はエラーが返りファイル内容等は返却しません。
               * @returns 正常終了
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods45['get']['resBody'], Methods45['get']['resHeaders'], Methods45['get']['status']>(prefix, `${prefix2}${PATH24}`, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH24}`
            }
          }
        },
        _orderNo: (val2: string) => {
          const prefix2 = `${PATH40}/${val2}`

          return {
            delivery_date: {
              /**
               * ### 概要
               * パスパラメータで指定した発注番号、および、同一配送になる発注番号の発注に対して、<br/>
               * ご希望納品日を一括更新する。
               *
               * ### 配送単位の条件
               * - 店舗コード
               * - 郵便番号
               * - 住所1
               * - 住所2
               * - お届け先名
               * - ご希望納品日
               * - 未承認 or 未出荷ステータス
               *
               * ### HTTPレスポンス
               * - 200 正常
               * - 400 パラメータ不正
               * - 401 未ログイン
               * - 403 権限なし
               * - 404 存在しない注文詳細
               * @returns 正常処理
               */
              put: (option: { body: Methods46['put']['reqBody'], config?: T | undefined }) =>
                fetch<Methods46['put']['resBody'], BasicHeaders, Methods46['put']['status']>(prefix, `${prefix2}${PATH41}`, PUT, option).json(),
              /**
               * ### 概要
               * パスパラメータで指定した発注番号、および、同一配送になる発注番号の発注に対して、<br/>
               * ご希望納品日を一括更新する。
               *
               * ### 配送単位の条件
               * - 店舗コード
               * - 郵便番号
               * - 住所1
               * - 住所2
               * - お届け先名
               * - ご希望納品日
               * - 未承認 or 未出荷ステータス
               *
               * ### HTTPレスポンス
               * - 200 正常
               * - 400 パラメータ不正
               * - 401 未ログイン
               * - 403 権限なし
               * - 404 存在しない注文詳細
               * @returns 正常処理
               */
              $put: (option: { body: Methods46['put']['reqBody'], config?: T | undefined }) =>
                fetch<Methods46['put']['resBody'], BasicHeaders, Methods46['put']['status']>(prefix, `${prefix2}${PATH41}`, PUT, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH41}`
            },
            shipping_infomation: {
              /**
               * ### 概要
               * パスパラメータで指定した発注番号、および、同一配送になる発注番号の発注に対して、<br/>
               * お届け先情報を一括更新する。
               *
               * ### 配送単位の条件
               * - 店舗コード
               * - 郵便番号
               * - 住所1
               * - 住所2
               * - お届け先名
               * - ご希望納品日
               * - 未承認 or 未出荷ステータス
               *
               * ### HTTPレスポンス
               * - 200 正常
               * - 400 パラメータ不正
               * - 401 未ログイン
               * - 403 権限なし
               * - 404 存在しない注文詳細
               * @returns 正常処理
               */
              put: (option: { body: Methods47['put']['reqBody'], config?: T | undefined }) =>
                fetch<Methods47['put']['resBody'], BasicHeaders, Methods47['put']['status']>(prefix, `${prefix2}${PATH42}`, PUT, option).json(),
              /**
               * ### 概要
               * パスパラメータで指定した発注番号、および、同一配送になる発注番号の発注に対して、<br/>
               * お届け先情報を一括更新する。
               *
               * ### 配送単位の条件
               * - 店舗コード
               * - 郵便番号
               * - 住所1
               * - 住所2
               * - お届け先名
               * - ご希望納品日
               * - 未承認 or 未出荷ステータス
               *
               * ### HTTPレスポンス
               * - 200 正常
               * - 400 パラメータ不正
               * - 401 未ログイン
               * - 403 権限なし
               * - 404 存在しない注文詳細
               * @returns 正常処理
               */
              $put: (option: { body: Methods47['put']['reqBody'], config?: T | undefined }) =>
                fetch<Methods47['put']['resBody'], BasicHeaders, Methods47['put']['status']>(prefix, `${prefix2}${PATH42}`, PUT, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH42}`
            }
          }
        },
        _salesOrderNo: (val2: string) => {
          const prefix2 = `${PATH40}/${val2}`

          return {
            /**
             * ### 概要
             * 指定した受注番号に紐づく出荷済みの発注情報を取得する。<br/>
             * 配送単位のサマリーと、紐づく発注番号の一覧等を取得する。<br/>
             * 商品情報は、商品ステータスによる制限なく返却する。<br/>
             *
             * ### 商品ステータス
             * - available：販売中
             * - unavailable：販売終了商品
             * - expired：販売期間外商品
             * - forbidden：扱えない商品
             *
             * ### HTTPレスポンス
             * - 200 正常
             * - 400 パラメータ不正
             * - 401 未ログイン
             * - 403 権限なし
             * - 404 存在しない受注番号
             * @returns 正常処理
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods48['get']['resBody'], BasicHeaders, Methods48['get']['status']>(prefix, prefix2, GET, option).json(),
            /**
             * ### 概要
             * 指定した受注番号に紐づく出荷済みの発注情報を取得する。<br/>
             * 配送単位のサマリーと、紐づく発注番号の一覧等を取得する。<br/>
             * 商品情報は、商品ステータスによる制限なく返却する。<br/>
             *
             * ### 商品ステータス
             * - available：販売中
             * - unavailable：販売終了商品
             * - expired：販売期間外商品
             * - forbidden：扱えない商品
             *
             * ### HTTPレスポンス
             * - 200 正常
             * - 400 パラメータ不正
             * - 401 未ログイン
             * - 403 権限なし
             * - 404 存在しない受注番号
             * @returns 正常処理
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods48['get']['resBody'], BasicHeaders, Methods48['get']['status']>(prefix, prefix2, GET, option).json().then(r => r.body),
            $path: () => `${prefix}${prefix2}`
          }
        },
        download: {
          /**
           * ## 説明
           * * 指定した抽出条件で抽出する注文履歴をCSVファイルでダウンロードするためのファイル作成を受け付けます。
           * * ファイルの作成を受け付けたら、ダウンロードIDを返します。
           *
           * ## 作成するCSVファイルの説明
           *   | 項目名               | 例                      | 補足説明                                                                     |
           *   |----------------------|-------------------------|------------------------------------------------------------------------------|
           *   | 店舗コード           | 15597                   |                                                                              |
           *   | 店舗名               | デモサロン              |                                                                              |
           *   | ご希望納品日         | 2024-04-01              |                                                                              |
           *   | 取込番号             | 1000000123              | 未連携の場合、空。                                                           |
           *   | お届け先郵便番号     | 1500021                 |                                                                              |
           *   | お届け先住所１       | 東京都渋谷区恵比寿西    |                                                                              |
           *   | お届け先住所２       | アーチビル              |                                                                              |
           *   | お届先電話番号       | 0312345678              |                                                                              |
           *   | お届け先名           | デモサロン              |                                                                              |
           *   | メーカーコード       | 035                     |                                                                              |
           *   | メーカー名           | 日本ロレアル　株式会社  |                                                                              |
           *   | 商品コード           | 0350030170112           |                                                                              |
           *   | JANコード            | 3474636613212           |                                                                              |
           *   | 商品名               | RE ﾌﾟﾛﾄｺﾙ ｴｸｽﾃ ｿﾜﾝ2 500 |                                                                              |
           *   | 売上種別             | 店販                    |                                                                              |
           *   | 単価                 | 3000                    | ログインユーザーが値引き表示ありなら、値引後価格。そうでないならサロン価格。 |
           *   | 数量                 | 2                       |                                                                              |
           *   | 金額                 | 6000                    | 単価 * 数量。                                                                |
           *   | 備考                 | 山田太郎様注文分        |                                                                              |
           *   | サロン価格           | 3285                    |                                                                              |
           *   | メーカー希望小売価格 | 4500                    |                                                                              |
           *
           * ### データの並び順
           * 1. お届け予定日の昇順
           * 2. 店舗コードの昇順
           * 3. 取込番号の昇順。ただし取込番号がない（未連携）のものを後に出力
           * 4. 商品コードの昇順
           * 5. 売上種別コードの昇順
           * 6. 備考の昇順。ただし、備考が空のものを先に出力。
           * @returns 正常処理
           */
          post: (option: { body: Methods49['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods49['post']['resBody'], Methods49['post']['resHeaders'], Methods49['post']['status']>(prefix, PATH43, POST, option).json(),
          /**
           * ## 説明
           * * 指定した抽出条件で抽出する注文履歴をCSVファイルでダウンロードするためのファイル作成を受け付けます。
           * * ファイルの作成を受け付けたら、ダウンロードIDを返します。
           *
           * ## 作成するCSVファイルの説明
           *   | 項目名               | 例                      | 補足説明                                                                     |
           *   |----------------------|-------------------------|------------------------------------------------------------------------------|
           *   | 店舗コード           | 15597                   |                                                                              |
           *   | 店舗名               | デモサロン              |                                                                              |
           *   | ご希望納品日         | 2024-04-01              |                                                                              |
           *   | 取込番号             | 1000000123              | 未連携の場合、空。                                                           |
           *   | お届け先郵便番号     | 1500021                 |                                                                              |
           *   | お届け先住所１       | 東京都渋谷区恵比寿西    |                                                                              |
           *   | お届け先住所２       | アーチビル              |                                                                              |
           *   | お届先電話番号       | 0312345678              |                                                                              |
           *   | お届け先名           | デモサロン              |                                                                              |
           *   | メーカーコード       | 035                     |                                                                              |
           *   | メーカー名           | 日本ロレアル　株式会社  |                                                                              |
           *   | 商品コード           | 0350030170112           |                                                                              |
           *   | JANコード            | 3474636613212           |                                                                              |
           *   | 商品名               | RE ﾌﾟﾛﾄｺﾙ ｴｸｽﾃ ｿﾜﾝ2 500 |                                                                              |
           *   | 売上種別             | 店販                    |                                                                              |
           *   | 単価                 | 3000                    | ログインユーザーが値引き表示ありなら、値引後価格。そうでないならサロン価格。 |
           *   | 数量                 | 2                       |                                                                              |
           *   | 金額                 | 6000                    | 単価 * 数量。                                                                |
           *   | 備考                 | 山田太郎様注文分        |                                                                              |
           *   | サロン価格           | 3285                    |                                                                              |
           *   | メーカー希望小売価格 | 4500                    |                                                                              |
           *
           * ### データの並び順
           * 1. お届け予定日の昇順
           * 2. 店舗コードの昇順
           * 3. 取込番号の昇順。ただし取込番号がない（未連携）のものを後に出力
           * 4. 商品コードの昇順
           * 5. 売上種別コードの昇順
           * 6. 備考の昇順。ただし、備考が空のものを先に出力。
           * @returns 正常処理
           */
          $post: (option: { body: Methods49['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods49['post']['resBody'], Methods49['post']['resHeaders'], Methods49['post']['status']>(prefix, PATH43, POST, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH43}`
        },
        unshipped: {
          /**
           * ### 概要
           * 配送単位を特定するパラメータを指定して、未出荷の発注情報を取得する。<br/>
           * 配送単位のサマリーと、紐づく発注番号の一覧等を取得する。<br/>
           * 商品情報は、商品ステータスによる制限なく返却する。<br/>
           *
           * ### 商品ステータス
           * - available：販売中
           * - unavailable：販売終了商品
           * - expired：販売期間外商品
           * - forbidden：扱えない商品
           *
           * ### HTTPレスポンス
           * - 200 正常
           * - 400 パラメータ不正
           * - 401 未ログイン
           * - 403 権限なし
           * - 404 存在しない受注番号
           * @returns 正常処理
           */
          get: (option: { query: Methods50['get']['query'], config?: T | undefined }) =>
            fetch<Methods50['get']['resBody'], BasicHeaders, Methods50['get']['status']>(prefix, PATH44, GET, option).json(),
          /**
           * ### 概要
           * 配送単位を特定するパラメータを指定して、未出荷の発注情報を取得する。<br/>
           * 配送単位のサマリーと、紐づく発注番号の一覧等を取得する。<br/>
           * 商品情報は、商品ステータスによる制限なく返却する。<br/>
           *
           * ### 商品ステータス
           * - available：販売中
           * - unavailable：販売終了商品
           * - expired：販売期間外商品
           * - forbidden：扱えない商品
           *
           * ### HTTPレスポンス
           * - 200 正常
           * - 400 パラメータ不正
           * - 401 未ログイン
           * - 403 権限なし
           * - 404 存在しない受注番号
           * @returns 正常処理
           */
          $get: (option: { query: Methods50['get']['query'], config?: T | undefined }) =>
            fetch<Methods50['get']['resBody'], BasicHeaders, Methods50['get']['status']>(prefix, PATH44, GET, option).json().then(r => r.body),
          $path: (option?: { method?: 'get' | undefined; query: Methods50['get']['query'] } | undefined) =>
            `${prefix}${PATH44}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
        },
        /**
         * ## 説明
         * * 配送単位の注文履歴一覧を取得する。<br>
         * * 商品一覧は、null で返却する。<br/>
         * * 配送単位の条件
         *   * 店舗コード
         *   * 郵便番号
         *   * 住所1
         *   * 住所2
         *   * お届け先名
         *   * ご希望納品日
         *   * 未承認 or 未出荷ステータス
         *
         * ### 並び順
         * 1. 着荷予定日の昇順
         * 1. 受注番号の昇順、ただし、受注番号が存在しないものを前にする
         * 1. 発注に含まれる一番小さい発注番号の昇順
         *
         * ### HTTPレスポンス
         * - 200 正常
         * - 400 パラメータ不正
         * - 401 未ログイン
         * @returns 正常処理
         */
        get: (option: { query: Methods44['get']['query'], config?: T | undefined }) =>
          fetch<Methods44['get']['resBody'], BasicHeaders, Methods44['get']['status']>(prefix, PATH40, GET, option).json(),
        /**
         * ## 説明
         * * 配送単位の注文履歴一覧を取得する。<br>
         * * 商品一覧は、null で返却する。<br/>
         * * 配送単位の条件
         *   * 店舗コード
         *   * 郵便番号
         *   * 住所1
         *   * 住所2
         *   * お届け先名
         *   * ご希望納品日
         *   * 未承認 or 未出荷ステータス
         *
         * ### 並び順
         * 1. 着荷予定日の昇順
         * 1. 受注番号の昇順、ただし、受注番号が存在しないものを前にする
         * 1. 発注に含まれる一番小さい発注番号の昇順
         *
         * ### HTTPレスポンス
         * - 200 正常
         * - 400 パラメータ不正
         * - 401 未ログイン
         * @returns 正常処理
         */
        $get: (option: { query: Methods44['get']['query'], config?: T | undefined }) =>
          fetch<Methods44['get']['resBody'], BasicHeaders, Methods44['get']['status']>(prefix, PATH40, GET, option).json().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods44['get']['query'] } | undefined) =>
          `${prefix}${PATH40}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
      },
      orders: {
        _orderNo: (val2: string) => {
          const prefix2 = `${PATH45}/${val2}`

          return {
            items: {
              _itemCode: (val4: string) => {
                const prefix4 = `${prefix2}${PATH28}/${val4}`

                return {
                  /**
                   * ### 概要
                   * - 発注の指定した商品を削除する。
                   *
                   * ### 処理仕様
                   *
                   * - パラメータで指定した商品を、発注からすべての売上種別で削除する。
                   * - 発注内にすべての商品が無くなる場合、400で返り、削除できない。
                   * - 出荷済み、出荷準備中の場合400が返る。
                   * - 承認権限がない場合、承認済みは削除できず、400が返る。
                   * - 発注に無い商品の場合、404が返る。
                   *
                   *
                   * ### HTTPレスポンス
                   * - 200 正常
                   * - 400 パラメータ不正
                   * - 401 未ログイン
                   * - 404 商品なし
                   * @returns OK
                   */
                  delete: (option?: { config?: T | undefined } | undefined) =>
                    fetch<Methods53['delete']['resBody'], BasicHeaders, Methods53['delete']['status']>(prefix, prefix4, DELETE, option).json(),
                  /**
                   * ### 概要
                   * - 発注の指定した商品を削除する。
                   *
                   * ### 処理仕様
                   *
                   * - パラメータで指定した商品を、発注からすべての売上種別で削除する。
                   * - 発注内にすべての商品が無くなる場合、400で返り、削除できない。
                   * - 出荷済み、出荷準備中の場合400が返る。
                   * - 承認権限がない場合、承認済みは削除できず、400が返る。
                   * - 発注に無い商品の場合、404が返る。
                   *
                   *
                   * ### HTTPレスポンス
                   * - 200 正常
                   * - 400 パラメータ不正
                   * - 401 未ログイン
                   * - 404 商品なし
                   * @returns OK
                   */
                  $delete: (option?: { config?: T | undefined } | undefined) =>
                    fetch<Methods53['delete']['resBody'], BasicHeaders, Methods53['delete']['status']>(prefix, prefix4, DELETE, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix4}`
                }
              }
            },
            /**
             * ### 概要
             * 指定した発注番号に紐づく発注をキャンセルする。
             *
             * ### HTTPレスポンス
             * - 200 正常
             * - 400 パラメータ不正
             * - 401 未ログイン
             * - 403 権限なし
             * - 404 存在しない発注番号
             */
            delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<void, BasicHeaders, Methods52['delete']['status']>(prefix, prefix2, DELETE, option).send(),
            /**
             * ### 概要
             * 指定した発注番号に紐づく発注をキャンセルする。
             *
             * ### HTTPレスポンス
             * - 200 正常
             * - 400 パラメータ不正
             * - 401 未ログイン
             * - 403 権限なし
             * - 404 存在しない発注番号
             */
            $delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<void, BasicHeaders, Methods52['delete']['status']>(prefix, prefix2, DELETE, option).send().then(r => r.body),
            /**
             * ### 概要
             * 指定した発注番号にて発注詳細を取得する。<br/>
             * 商品情報は、商品ステータスによる制限なく返却する。<br/>
             *
             * ### 商品ステータス
             * - available：販売中
             * - unavailable：販売終了商品
             * - expired：販売期間外商品
             * - forbidden：扱えない商品
             *
             * ### HTTPレスポンス
             * - 200 正常
             * - 400 パラメータ不正
             * - 401 未ログイン
             * - 403 権限なし
             * - 404 存在しない発注番号
             * @returns 正常処理
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods52['get']['resBody'], BasicHeaders, Methods52['get']['status']>(prefix, prefix2, GET, option).json(),
            /**
             * ### 概要
             * 指定した発注番号にて発注詳細を取得する。<br/>
             * 商品情報は、商品ステータスによる制限なく返却する。<br/>
             *
             * ### 商品ステータス
             * - available：販売中
             * - unavailable：販売終了商品
             * - expired：販売期間外商品
             * - forbidden：扱えない商品
             *
             * ### HTTPレスポンス
             * - 200 正常
             * - 400 パラメータ不正
             * - 401 未ログイン
             * - 403 権限なし
             * - 404 存在しない発注番号
             * @returns 正常処理
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods52['get']['resBody'], BasicHeaders, Methods52['get']['status']>(prefix, prefix2, GET, option).json().then(r => r.body),
            /**
             * ### 概要
             * 指定した発注番号に紐づく発注内容を変更する。
             *
             * - 発注内にすべての商品が無くなる場合、400で返り、更新できない。
             * - 出荷済み、出荷準備中の場合400が返る。
             * - 承認権限がない場合、承認済みは更新できず、400が返る。
             *
             * ### HTTPレスポンス
             * - 200 正常
             * - 400 パラメータ不正
             * - 401 未ログイン
             * - 403 権限なし
             * - 404 存在しない発注番号
             */
            put: (option: { body: Methods52['put']['reqBody'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods52['put']['status']>(prefix, prefix2, PUT, option).send(),
            /**
             * ### 概要
             * 指定した発注番号に紐づく発注内容を変更する。
             *
             * - 発注内にすべての商品が無くなる場合、400で返り、更新できない。
             * - 出荷済み、出荷準備中の場合400が返る。
             * - 承認権限がない場合、承認済みは更新できず、400が返る。
             *
             * ### HTTPレスポンス
             * - 200 正常
             * - 400 パラメータ不正
             * - 401 未ログイン
             * - 403 権限なし
             * - 404 存在しない発注番号
             */
            $put: (option: { body: Methods52['put']['reqBody'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods52['put']['status']>(prefix, prefix2, PUT, option).send().then(r => r.body),
            /**
             * ### 概要
             * 指定した発注番号に紐づく発注の商品の売上種別、数量を変更する。
             *
             * ### 処理仕様
             *
             * - パラメータに指定された商品の数量を更新する。
             * - 商品の数量が0の場合、指定の商品は発注から削除する。
             * - 登録のない商品が指定された場合は新たに追加する。
             * - 指定された商品に対して指定された売上種別、数量で置き換える。
             * - 発注内にすべての商品が無くなる場合、400で返り、削除できない。
             * - 出荷済み、出荷準備中の場合400が返る。
             * - 承認権限がない場合、承認済みは更新できず、400が返る。
             *
             *
             * ### HTTPレスポンス
             * - 200 正常
             * - 400 パラメータ不正
             * - 401 未ログイン
             * - 403 権限なし
             * - 404 存在しない発注番号
             */
            patch: (option: { body: Methods52['patch']['reqBody'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods52['patch']['status']>(prefix, prefix2, PATCH, option).send(),
            /**
             * ### 概要
             * 指定した発注番号に紐づく発注の商品の売上種別、数量を変更する。
             *
             * ### 処理仕様
             *
             * - パラメータに指定された商品の数量を更新する。
             * - 商品の数量が0の場合、指定の商品は発注から削除する。
             * - 登録のない商品が指定された場合は新たに追加する。
             * - 指定された商品に対して指定された売上種別、数量で置き換える。
             * - 発注内にすべての商品が無くなる場合、400で返り、削除できない。
             * - 出荷済み、出荷準備中の場合400が返る。
             * - 承認権限がない場合、承認済みは更新できず、400が返る。
             *
             *
             * ### HTTPレスポンス
             * - 200 正常
             * - 400 パラメータ不正
             * - 401 未ログイン
             * - 403 権限なし
             * - 404 存在しない発注番号
             */
            $patch: (option: { body: Methods52['patch']['reqBody'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods52['patch']['status']>(prefix, prefix2, PATCH, option).send().then(r => r.body),
            $path: () => `${prefix}${prefix2}`
          }
        },
        approve: {
          /**
           * ### 概要
           * 発注を承認する。<br/>
           * 承認は処理ステータスが正常である発注に対して発注単位または一括で承認が可能となる。<br/>
           * 指定した発注番号に紐づく発注情報に対して権限がない場合、ステータス：noAuthority を返す。<br/>
           * なお、入金親店舗に紐づく承認ユーザーの場合、自店舗と紐づく子店舗の発注を承認可能。<br/>
           * 子店舗に紐づく承認ユーザーの場合、自店舗の発注のみ承認可能。<br/>
           * 一般ユーザーは承認不可。<br/>
           *
           * ### 処理ステータス
           * - ok：正常
           * - noOrder：存在しない発注番号
           * - noAuthority：権限がない発注番号
           * - deliveryDateError：ご希望納品日エラー
           * - orderStatusApproved：承認済み
           * - orderStatusShipped：出荷済み
           * - unavailableItems：販売不可商品を含む
           *
           * ### HTTPレスポンス
           * - 200 正常
           * - 400 パラメータ不正
           * - 401 未ログイン
           * @param option.body - 発注番号を指定する
           * @returns 正常処理
           */
          patch: (option: { body: Methods54['patch']['reqBody'], config?: T | undefined }) =>
            fetch<Methods54['patch']['resBody'], BasicHeaders, Methods54['patch']['status']>(prefix, PATH46, PATCH, option).json(),
          /**
           * ### 概要
           * 発注を承認する。<br/>
           * 承認は処理ステータスが正常である発注に対して発注単位または一括で承認が可能となる。<br/>
           * 指定した発注番号に紐づく発注情報に対して権限がない場合、ステータス：noAuthority を返す。<br/>
           * なお、入金親店舗に紐づく承認ユーザーの場合、自店舗と紐づく子店舗の発注を承認可能。<br/>
           * 子店舗に紐づく承認ユーザーの場合、自店舗の発注のみ承認可能。<br/>
           * 一般ユーザーは承認不可。<br/>
           *
           * ### 処理ステータス
           * - ok：正常
           * - noOrder：存在しない発注番号
           * - noAuthority：権限がない発注番号
           * - deliveryDateError：ご希望納品日エラー
           * - orderStatusApproved：承認済み
           * - orderStatusShipped：出荷済み
           * - unavailableItems：販売不可商品を含む
           *
           * ### HTTPレスポンス
           * - 200 正常
           * - 400 パラメータ不正
           * - 401 未ログイン
           * @param option.body - 発注番号を指定する
           * @returns 正常処理
           */
          $patch: (option: { body: Methods54['patch']['reqBody'], config?: T | undefined }) =>
            fetch<Methods54['patch']['resBody'], BasicHeaders, Methods54['patch']['status']>(prefix, PATH46, PATCH, option).json().then(r => r.body),
          /**
           * ### 概要
           * 発注承認をキャンセルする。<br/>
           * 発注承認キャンセルは処理ステータスが正常である発注に対して発注単位または一括でキャンセルが可能となる。<br/>
           * 指定した発注番号に紐づく発注情報に対して権限がない場合、ステータス：noAuthority を返す。<br/>
           * なお、入金親店舗に紐づく承認ユーザーの場合、自店舗と紐づく子店舗の発注承認をキャンセル可能。<br/>
           * 子店舗に紐づく承認ユーザーの場合、自店舗の発注承認のみキャンセル可能。<br/>
           * 一般ユーザーは発注承認キャンセル不可。<br/>
           *
           * ### 処理ステータス
           * - ok：正常
           * - noOrder：存在しない発注番号
           * - noAuthority：権限がない発注番号
           * - orderStatusUnApproved：承認キャンセル済み
           * - orderStatusShipped：出荷済み
           *
           * ### HTTPレスポンス
           * - 200 正常
           * - 400 パラメータ不正
           * - 401 未ログイン
           * - 403 権限なし
           * - 404 存在しない発注番号
           * @param option.body - 発注番号を指定する
           * @returns 正常処理
           */
          delete: (option: { body: Methods54['delete']['reqBody'], config?: T | undefined }) =>
            fetch<Methods54['delete']['resBody'], BasicHeaders, Methods54['delete']['status']>(prefix, PATH46, DELETE, option).json(),
          /**
           * ### 概要
           * 発注承認をキャンセルする。<br/>
           * 発注承認キャンセルは処理ステータスが正常である発注に対して発注単位または一括でキャンセルが可能となる。<br/>
           * 指定した発注番号に紐づく発注情報に対して権限がない場合、ステータス：noAuthority を返す。<br/>
           * なお、入金親店舗に紐づく承認ユーザーの場合、自店舗と紐づく子店舗の発注承認をキャンセル可能。<br/>
           * 子店舗に紐づく承認ユーザーの場合、自店舗の発注承認のみキャンセル可能。<br/>
           * 一般ユーザーは発注承認キャンセル不可。<br/>
           *
           * ### 処理ステータス
           * - ok：正常
           * - noOrder：存在しない発注番号
           * - noAuthority：権限がない発注番号
           * - orderStatusUnApproved：承認キャンセル済み
           * - orderStatusShipped：出荷済み
           *
           * ### HTTPレスポンス
           * - 200 正常
           * - 400 パラメータ不正
           * - 401 未ログイン
           * - 403 権限なし
           * - 404 存在しない発注番号
           * @param option.body - 発注番号を指定する
           * @returns 正常処理
           */
          $delete: (option: { body: Methods54['delete']['reqBody'], config?: T | undefined }) =>
            fetch<Methods54['delete']['resBody'], BasicHeaders, Methods54['delete']['status']>(prefix, PATH46, DELETE, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH46}`
        },
        unapproved: {
          /**
           * ### 概要
           * 未承認の発注一覧を取得する。
           *
           * ### HTTPレスポンス
           * - 200 正常
           * - 401 未ログイン
           * @returns 正常処理
           */
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods55['get']['resBody'], BasicHeaders, Methods55['get']['status']>(prefix, PATH47, GET, option).json(),
          /**
           * ### 概要
           * 未承認の発注一覧を取得する。
           *
           * ### HTTPレスポンス
           * - 200 正常
           * - 401 未ログイン
           * @returns 正常処理
           */
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods55['get']['resBody'], BasicHeaders, Methods55['get']['status']>(prefix, PATH47, GET, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH47}`
        },
        /**
         * ### 概要
         * 指定した複数の発注に対して発注キャンセル処理を行う。<br>
         * 発注キャンセルは処理ステータスが正常である発注に対して発注単位または一括でキャンセルが可能となる。<br/>
         * 指定した発注番号に紐づく発注情報に対して権限がない場合、ステータス：noAuthority を返す。<br/>
         * なお、入金親店舗に紐づくユーザーの場合、自店舗と紐づく子店舗の発注をキャンセル可能。<br/>
         * 子店舗に紐づくユーザーの場合、自店舗の発注のみキャンセル可能。<br/>
         *
         * ### 処理ステータス
         * - ok：正常
         * - noOrder：存在しない発注番号
         * - noAuthority：権限が発注番号
         * - orderStatusShipped：出荷済み
         *
         * ### HTTPレスポンス
         * - 200 正常
         * - 400 パラメータ不正
         * - 401 未ログイン
         * @returns 正常処理
         */
        delete: (option: { body: Methods51['delete']['reqBody'], config?: T | undefined }) =>
          fetch<Methods51['delete']['resBody'], BasicHeaders, Methods51['delete']['status']>(prefix, PATH45, DELETE, option).json(),
        /**
         * ### 概要
         * 指定した複数の発注に対して発注キャンセル処理を行う。<br>
         * 発注キャンセルは処理ステータスが正常である発注に対して発注単位または一括でキャンセルが可能となる。<br/>
         * 指定した発注番号に紐づく発注情報に対して権限がない場合、ステータス：noAuthority を返す。<br/>
         * なお、入金親店舗に紐づくユーザーの場合、自店舗と紐づく子店舗の発注をキャンセル可能。<br/>
         * 子店舗に紐づくユーザーの場合、自店舗の発注のみキャンセル可能。<br/>
         *
         * ### 処理ステータス
         * - ok：正常
         * - noOrder：存在しない発注番号
         * - noAuthority：権限が発注番号
         * - orderStatusShipped：出荷済み
         *
         * ### HTTPレスポンス
         * - 200 正常
         * - 400 パラメータ不正
         * - 401 未ログイン
         * @returns 正常処理
         */
        $delete: (option: { body: Methods51['delete']['reqBody'], config?: T | undefined }) =>
          fetch<Methods51['delete']['resBody'], BasicHeaders, Methods51['delete']['status']>(prefix, PATH45, DELETE, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH45}`
      },
      purchases: {
        _downloadId: (val2: string) => {
          const prefix2 = `${PATH48}/${val2}`

          return {
            download: {
              /**
               * ### 概要
               * - ダウンロード受付した、CSVファイルの作成処理終了確認とダウンロードを行う。
               * ### 処理仕様
               * - ダウンロード受付し、作成されたCSVファイルをローカルにダウンロードする。
               * - 処理中の場合、ファイル名と内容は空を返す。
               * - 処理が完了した場合、ファイル名と内容が返る。
               * - 処理中エラーが発生した場合はエラーが返りファイル内容等は返却されない。
               * @returns 正常処理
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods56['get']['resBody'], BasicHeaders, Methods56['get']['status']>(prefix, `${prefix2}${PATH24}`, GET, option).json(),
              /**
               * ### 概要
               * - ダウンロード受付した、CSVファイルの作成処理終了確認とダウンロードを行う。
               * ### 処理仕様
               * - ダウンロード受付し、作成されたCSVファイルをローカルにダウンロードする。
               * - 処理中の場合、ファイル名と内容は空を返す。
               * - 処理が完了した場合、ファイル名と内容が返る。
               * - 処理中エラーが発生した場合はエラーが返りファイル内容等は返却されない。
               * @returns 正常処理
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods56['get']['resBody'], BasicHeaders, Methods56['get']['status']>(prefix, `${prefix2}${PATH24}`, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH24}`
            }
          }
        },
        ec_site_orders: {
          _systemType: (val3: number | string) => {
            const prefix3 = `${PATH49}/${val3}`

            return {
              /**
               * EC注文番号を指定して、EC注文取込結果詳細を取得します。
               * @returns 正常処理
               */
              get: (option: { query: Methods58['get']['query'], config?: T | undefined }) =>
                fetch<Methods58['get']['resBody'], BasicHeaders, Methods58['get']['status']>(prefix, prefix3, GET, option).json(),
              /**
               * EC注文番号を指定して、EC注文取込結果詳細を取得します。
               * @returns 正常処理
               */
              $get: (option: { query: Methods58['get']['query'], config?: T | undefined }) =>
                fetch<Methods58['get']['resBody'], BasicHeaders, Methods58['get']['status']>(prefix, prefix3, GET, option).json().then(r => r.body),
              $path: (option?: { method?: 'get' | undefined; query: Methods58['get']['query'] } | undefined) =>
                `${prefix}${prefix3}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
            }
          },
          download: {
            /**
             * ### 処理概要
             *
             * - 仕入情報のEC注文取込結果で一覧をCSVファイルダウンロード受付する。
             * - ダウンロードのための、ダウンロードIDが返却される。
             *
             * ### 仕様
             *
             * - 検索条件に合致する EC注文取込結果を抽出し、抽出結果をCSVファイルとしてS3に保存する。
             * - ただし、大量データの処理を複数同時に行うことでDBへの負荷が大きくなることを避けるため、以下のように処理を行う。
             *     - リクエストを受け付けた後、バリデーションエラーがない場合には、ダウンロードIDを発番し、即座にAPIのレスポンスとして返す。
             *     - ダウンロードIDの単位でリクエストをキューに格納し、キューから取り出して行う処理の同時実行数はシステム全体で1とする。
             *     - キューから取り出した後は、リクエスト時の検索条件に合致するデータを抽出し、抽出結果をCSVファイルとしてS3に保存する。
             * - 処理終了の確認、及びファイルのダウンロードは、仕入情報CSVダウンロードAPIにて行う。
             * - ダウンロードする場合のファイル名は「仕入情報_EC注文取込結果_yyyyMMddHHmmssfff.csv」とする。
             *
             * ### CSVカラム
             *
             * - EC注文番号,取込日時,出荷ステータス,システム種別,送付先ID,お届先都道府県,お届先名,出荷日,送り状番号,ファイル名,配送希望日,配送希望時間,出荷予定日,着荷予定日,出荷倉庫,運送会社,運送会社コード,受注番号,売上番号,行番号,JANコード,商品名,数量
             * @returns 正常処理
             */
            post: (option: { body: Methods59['post']['reqBody'], config?: T | undefined }) =>
              fetch<Methods59['post']['resBody'], Methods59['post']['resHeaders'], Methods59['post']['status']>(prefix, PATH50, POST, option).json(),
            /**
             * ### 処理概要
             *
             * - 仕入情報のEC注文取込結果で一覧をCSVファイルダウンロード受付する。
             * - ダウンロードのための、ダウンロードIDが返却される。
             *
             * ### 仕様
             *
             * - 検索条件に合致する EC注文取込結果を抽出し、抽出結果をCSVファイルとしてS3に保存する。
             * - ただし、大量データの処理を複数同時に行うことでDBへの負荷が大きくなることを避けるため、以下のように処理を行う。
             *     - リクエストを受け付けた後、バリデーションエラーがない場合には、ダウンロードIDを発番し、即座にAPIのレスポンスとして返す。
             *     - ダウンロードIDの単位でリクエストをキューに格納し、キューから取り出して行う処理の同時実行数はシステム全体で1とする。
             *     - キューから取り出した後は、リクエスト時の検索条件に合致するデータを抽出し、抽出結果をCSVファイルとしてS3に保存する。
             * - 処理終了の確認、及びファイルのダウンロードは、仕入情報CSVダウンロードAPIにて行う。
             * - ダウンロードする場合のファイル名は「仕入情報_EC注文取込結果_yyyyMMddHHmmssfff.csv」とする。
             *
             * ### CSVカラム
             *
             * - EC注文番号,取込日時,出荷ステータス,システム種別,送付先ID,お届先都道府県,お届先名,出荷日,送り状番号,ファイル名,配送希望日,配送希望時間,出荷予定日,着荷予定日,出荷倉庫,運送会社,運送会社コード,受注番号,売上番号,行番号,JANコード,商品名,数量
             * @returns 正常処理
             */
            $post: (option: { body: Methods59['post']['reqBody'], config?: T | undefined }) =>
              fetch<Methods59['post']['resBody'], Methods59['post']['resHeaders'], Methods59['post']['status']>(prefix, PATH50, POST, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH50}`
          },
          /**
           * EC注文取込結果の検索を行います。<br/>
           * 検索結果の並び順は、取込日時の降順、EC注文番号の昇順になります。
           * @returns 正常処理
           */
          get: (option: { query: Methods57['get']['query'], config?: T | undefined }) =>
            fetch<Methods57['get']['resBody'], BasicHeaders, Methods57['get']['status']>(prefix, PATH49, GET, option).json(),
          /**
           * EC注文取込結果の検索を行います。<br/>
           * 検索結果の並び順は、取込日時の降順、EC注文番号の昇順になります。
           * @returns 正常処理
           */
          $get: (option: { query: Methods57['get']['query'], config?: T | undefined }) =>
            fetch<Methods57['get']['resBody'], BasicHeaders, Methods57['get']['status']>(prefix, PATH49, GET, option).json().then(r => r.body),
          $path: (option?: { method?: 'get' | undefined; query: Methods57['get']['query'] } | undefined) =>
            `${prefix}${PATH49}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
        },
        makers: {
          download: {
            /**
             * ### 概要
             * - 仕入情報のメーカー別で一覧をCSVファイルダウンロード受付する。
             * - ダウンロードのための、ダウンロードIDが返却される。
             * ### 処理仕様
             *
             * - 検索条件に合致するメーカー別結果を抽出し、抽出結果をCSVファイルとしてS3に保存する。
             * - ただし、大量データの処理を複数同時に行うことでDBへの負荷が大きくなることを避けるため、以下のように処理を行う。
             *     - リクエストを受け付けた後、バリデーションエラーがない場合には、ダウンロードIDを発番し、即座にAPIのレスポンスとして返す。
             *     - ダウンロードIDの単位でリクエストをキューに格納し、キューから取り出して行う処理の同時実行数はシステム全体で1とする。
             *     - キューから取り出した後は、リクエスト時の検索条件に合致するデータを抽出し、抽出結果をCSVファイルとしてS3に保存する。
             * - 処理終了の確認、及びファイルのダウンロードは、仕入情報CSVダウンロードAPIにて行う。
             * - ダウンロードする場合のファイル名は「仕入情報_メーカー別_yyyyMMddHHmmssfff.csv」とする。
             *
             * - パラメータから一覧取得条件を作成する。
             * - 集計単位でユーザに対して対応していない集計単位を指定した場合は400を返す。
             * - 店舗コードの指定がない場合、すべて対象の店舗が検索される。
             * - 集計対象に注文を指定した場合、注文日、または、出荷日が必須で抽出を行う。
             * - 集計対象に請求を指定した場合、請求月で抽出を行う。
             * - 請求月の指定がない場合当月とする。
             * - 値引き価格表示ありの場合は、値引き後の価格とする。
             * - DBから結果を取得する。
             * - UTF-8 BOM形式CSVに整形しbase64でエンコードする。
             * - 上記の内容で処理を開始する。
             *
             * ### 並び順
             * - メーカーコード
             *
             * ### HTTPレスポンス
             * - 200 正常
             * - 400 パラメータ不正
             * - 401 未ログイン
             * - 403 権限のない集計単位の指定
             *
             * ### CSVカラム
             * 1. メーカー
             * 1. メーカーコード
             * 1. 数量
             * 1. サロン価格
             * @returns 正常処理
             */
            post: (option: { body: Methods61['post']['reqBody'], config?: T | undefined }) =>
              fetch<Methods61['post']['resBody'], BasicHeaders, Methods61['post']['status']>(prefix, PATH52, POST, option).json(),
            /**
             * ### 概要
             * - 仕入情報のメーカー別で一覧をCSVファイルダウンロード受付する。
             * - ダウンロードのための、ダウンロードIDが返却される。
             * ### 処理仕様
             *
             * - 検索条件に合致するメーカー別結果を抽出し、抽出結果をCSVファイルとしてS3に保存する。
             * - ただし、大量データの処理を複数同時に行うことでDBへの負荷が大きくなることを避けるため、以下のように処理を行う。
             *     - リクエストを受け付けた後、バリデーションエラーがない場合には、ダウンロードIDを発番し、即座にAPIのレスポンスとして返す。
             *     - ダウンロードIDの単位でリクエストをキューに格納し、キューから取り出して行う処理の同時実行数はシステム全体で1とする。
             *     - キューから取り出した後は、リクエスト時の検索条件に合致するデータを抽出し、抽出結果をCSVファイルとしてS3に保存する。
             * - 処理終了の確認、及びファイルのダウンロードは、仕入情報CSVダウンロードAPIにて行う。
             * - ダウンロードする場合のファイル名は「仕入情報_メーカー別_yyyyMMddHHmmssfff.csv」とする。
             *
             * - パラメータから一覧取得条件を作成する。
             * - 集計単位でユーザに対して対応していない集計単位を指定した場合は400を返す。
             * - 店舗コードの指定がない場合、すべて対象の店舗が検索される。
             * - 集計対象に注文を指定した場合、注文日、または、出荷日が必須で抽出を行う。
             * - 集計対象に請求を指定した場合、請求月で抽出を行う。
             * - 請求月の指定がない場合当月とする。
             * - 値引き価格表示ありの場合は、値引き後の価格とする。
             * - DBから結果を取得する。
             * - UTF-8 BOM形式CSVに整形しbase64でエンコードする。
             * - 上記の内容で処理を開始する。
             *
             * ### 並び順
             * - メーカーコード
             *
             * ### HTTPレスポンス
             * - 200 正常
             * - 400 パラメータ不正
             * - 401 未ログイン
             * - 403 権限のない集計単位の指定
             *
             * ### CSVカラム
             * 1. メーカー
             * 1. メーカーコード
             * 1. 数量
             * 1. サロン価格
             * @returns 正常処理
             */
            $post: (option: { body: Methods61['post']['reqBody'], config?: T | undefined }) =>
              fetch<Methods61['post']['resBody'], BasicHeaders, Methods61['post']['status']>(prefix, PATH52, POST, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH52}`
          },
          stores: {
            download: {
              /**
               * ### 概要
               * - 仕入情報のメーカー別店舗別で一覧をCSVファイルダウンロード処理受付する。
               * - ダウンロードのための、ダウンロードIDが返却される。
               *
               * ### 処理仕様
               *
               * - 検索条件に合致するメーカ別店舗別結果を抽出し、抽出結果をCSVファイルとしてS3に保存する。
               * - ただし、大量データの処理を複数同時に行うことでDBへの負荷が大きくなることを避けるため、以下のように処理を行う。
               *     - リクエストを受け付けた後、バリデーションエラーがない場合には、ダウンロードIDを発番し、即座にAPIのレスポンスとして返す。
               *     - ダウンロードIDの単位でリクエストをキューに格納し、キューから取り出して行う処理の同時実行数はシステム全体で1とする。
               *     - キューから取り出した後は、リクエスト時の検索条件に合致するデータを抽出し、抽出結果をCSVファイルとしてS3に保存する。
               * - 処理終了の確認、及びファイルのダウンロードは、仕入情報CSVダウンロードAPIにて行う。
               * - ダウンロードする場合のファイル名は「仕入情報_メーカ別店舗別_yyyyMMddHHmmssfff.csv」とする。
               *
               * - パラメータから一覧取得条件を作成する。
               * - 集計単位でユーザに対して対応していない集計単位を指定した場合は400を返す。
               * - 店舗コードの指定がない場合、すべて対象の店舗が検索される。
               * - 集計対象に注文を指定した場合、注文日、または、出荷日が必須で抽出を行う。
               * - 集計対象に請求を指定した場合、請求月で抽出を行う。
               * - 請求月の指定がない場合当月とする。
               * - 値引き価格表示ありの場合は、値引き後の価格とする。
               * - DBから結果を取得する。
               * - UTF-8 BOM形式CSVに整形しbase64でエンコードする。
               * - 上記の内容で処理を開始する。
               *
               * ### 並び順
               * - 店舗コード
               * - メーカーコード
               *
               * ### HTTPレスポンス
               * - 200 正常
               * - 400 パラメータ不正
               * - 401 未ログイン
               * - 403 権限のない集計単位の指定
               *
               * ### CSVカラム
               * 1. 店舗コード
               * 1. 店舗名
               * 1. メーカー名:金額(複数あり)
               * @returns 正常処理
               */
              post: (option: { body: Methods63['post']['reqBody'], config?: T | undefined }) =>
                fetch<Methods63['post']['resBody'], BasicHeaders, Methods63['post']['status']>(prefix, PATH54, POST, option).json(),
              /**
               * ### 概要
               * - 仕入情報のメーカー別店舗別で一覧をCSVファイルダウンロード処理受付する。
               * - ダウンロードのための、ダウンロードIDが返却される。
               *
               * ### 処理仕様
               *
               * - 検索条件に合致するメーカ別店舗別結果を抽出し、抽出結果をCSVファイルとしてS3に保存する。
               * - ただし、大量データの処理を複数同時に行うことでDBへの負荷が大きくなることを避けるため、以下のように処理を行う。
               *     - リクエストを受け付けた後、バリデーションエラーがない場合には、ダウンロードIDを発番し、即座にAPIのレスポンスとして返す。
               *     - ダウンロードIDの単位でリクエストをキューに格納し、キューから取り出して行う処理の同時実行数はシステム全体で1とする。
               *     - キューから取り出した後は、リクエスト時の検索条件に合致するデータを抽出し、抽出結果をCSVファイルとしてS3に保存する。
               * - 処理終了の確認、及びファイルのダウンロードは、仕入情報CSVダウンロードAPIにて行う。
               * - ダウンロードする場合のファイル名は「仕入情報_メーカ別店舗別_yyyyMMddHHmmssfff.csv」とする。
               *
               * - パラメータから一覧取得条件を作成する。
               * - 集計単位でユーザに対して対応していない集計単位を指定した場合は400を返す。
               * - 店舗コードの指定がない場合、すべて対象の店舗が検索される。
               * - 集計対象に注文を指定した場合、注文日、または、出荷日が必須で抽出を行う。
               * - 集計対象に請求を指定した場合、請求月で抽出を行う。
               * - 請求月の指定がない場合当月とする。
               * - 値引き価格表示ありの場合は、値引き後の価格とする。
               * - DBから結果を取得する。
               * - UTF-8 BOM形式CSVに整形しbase64でエンコードする。
               * - 上記の内容で処理を開始する。
               *
               * ### 並び順
               * - 店舗コード
               * - メーカーコード
               *
               * ### HTTPレスポンス
               * - 200 正常
               * - 400 パラメータ不正
               * - 401 未ログイン
               * - 403 権限のない集計単位の指定
               *
               * ### CSVカラム
               * 1. 店舗コード
               * 1. 店舗名
               * 1. メーカー名:金額(複数あり)
               * @returns 正常処理
               */
              $post: (option: { body: Methods63['post']['reqBody'], config?: T | undefined }) =>
                fetch<Methods63['post']['resBody'], BasicHeaders, Methods63['post']['status']>(prefix, PATH54, POST, option).json().then(r => r.body),
              $path: () => `${prefix}${PATH54}`
            },
            /**
             * ### 概要
             * 仕入情報のメーカー別店舗別で一覧を取得する。
             * ### 処理仕様
             * - パラメータから一覧取得条件を作成する。
             * - 集計単位でユーザに対して対応していない集計単位を指定した場合は400を返す。
             * - 店舗コードの指定がない場合、すべて対象の店舗が検索される。
             * - 集計対象に注文を指定した場合、注文日、または、出荷日が必須で抽出を行う。
             * - 集計対象に請求を指定した場合、請求月で抽出を行う。
             * - 請求月の指定がない場合当月とする。
             * - 値引き価格表示ありの場合は、値引き後の価格とする。
             * - DBから結果を取得する。
             * - 結果を返す。
             *
             * ### 並び順
             * - 店舗コード
             * - メーカーコード
             *
             * ### HTTPレスポンス
             * - 200 正常
             * - 400 パラメータ不正
             * - 401 未ログイン
             * - 403 権限のない集計単位の指定
             *
             * ### カラム
             * | No. | dataField | headerName | type |
             * |-----|-----------|------------|------|
             * | 1 | storeCode | 店舗コード | string |
             * | 2 | storeName | 店舗名 | string |
             * | 3 | price | メーカー名ごとに金額(複数あり) | integer |
             * @returns 正常処理
             */
            get: (option: { query: Methods62['get']['query'], config?: T | undefined }) =>
              fetch<Methods62['get']['resBody'], BasicHeaders, Methods62['get']['status']>(prefix, PATH53, GET, option).json(),
            /**
             * ### 概要
             * 仕入情報のメーカー別店舗別で一覧を取得する。
             * ### 処理仕様
             * - パラメータから一覧取得条件を作成する。
             * - 集計単位でユーザに対して対応していない集計単位を指定した場合は400を返す。
             * - 店舗コードの指定がない場合、すべて対象の店舗が検索される。
             * - 集計対象に注文を指定した場合、注文日、または、出荷日が必須で抽出を行う。
             * - 集計対象に請求を指定した場合、請求月で抽出を行う。
             * - 請求月の指定がない場合当月とする。
             * - 値引き価格表示ありの場合は、値引き後の価格とする。
             * - DBから結果を取得する。
             * - 結果を返す。
             *
             * ### 並び順
             * - 店舗コード
             * - メーカーコード
             *
             * ### HTTPレスポンス
             * - 200 正常
             * - 400 パラメータ不正
             * - 401 未ログイン
             * - 403 権限のない集計単位の指定
             *
             * ### カラム
             * | No. | dataField | headerName | type |
             * |-----|-----------|------------|------|
             * | 1 | storeCode | 店舗コード | string |
             * | 2 | storeName | 店舗名 | string |
             * | 3 | price | メーカー名ごとに金額(複数あり) | integer |
             * @returns 正常処理
             */
            $get: (option: { query: Methods62['get']['query'], config?: T | undefined }) =>
              fetch<Methods62['get']['resBody'], BasicHeaders, Methods62['get']['status']>(prefix, PATH53, GET, option).json().then(r => r.body),
            $path: (option?: { method?: 'get' | undefined; query: Methods62['get']['query'] } | undefined) =>
              `${prefix}${PATH53}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
          },
          /**
           * ### 概要
           * 仕入情報のメーカー別で一覧を取得する。
           * ### 処理仕様
           *
           * - パラメータから一覧取得条件を作成する。
           * - 集計単位でユーザに対して対応していない集計単位を指定した場合は400を返す。
           * - 店舗コードの指定がない場合、すべて対象の店舗が検索される。
           * - 集計対象に注文を指定した場合、注文日、または、出荷日が必須で抽出を行う。
           * - 集計対象に請求を指定した場合、請求月で抽出を行う。
           * - 請求月の指定がない場合当月とする。
           * - DBから結果を取得する。
           * - 結果を返す。
           *
           * ### 並び順
           * - メーカーコード
           *
           * ### HTTPレスポンス
           * - 200 正常
           * - 400 パラメータ不正
           * - 401 未ログイン
           * - 403 権限のない集計単位の指定
           *
           * ### カラム
           * | No. | dataField | headerName | type |
           * |-----|-----------|------------|------|
           * | 1 | makerName | メーカー | string |
           * | 2 | makerCode | メーカーコード | string |
           * | 3 | quantity | 数量 | integer |
           * | 4 | salonPrice | サロン価格 | integer |
           * @returns 正常処理
           */
          get: (option: { query: Methods60['get']['query'], config?: T | undefined }) =>
            fetch<Methods60['get']['resBody'], BasicHeaders, Methods60['get']['status']>(prefix, PATH51, GET, option).json(),
          /**
           * ### 概要
           * 仕入情報のメーカー別で一覧を取得する。
           * ### 処理仕様
           *
           * - パラメータから一覧取得条件を作成する。
           * - 集計単位でユーザに対して対応していない集計単位を指定した場合は400を返す。
           * - 店舗コードの指定がない場合、すべて対象の店舗が検索される。
           * - 集計対象に注文を指定した場合、注文日、または、出荷日が必須で抽出を行う。
           * - 集計対象に請求を指定した場合、請求月で抽出を行う。
           * - 請求月の指定がない場合当月とする。
           * - DBから結果を取得する。
           * - 結果を返す。
           *
           * ### 並び順
           * - メーカーコード
           *
           * ### HTTPレスポンス
           * - 200 正常
           * - 400 パラメータ不正
           * - 401 未ログイン
           * - 403 権限のない集計単位の指定
           *
           * ### カラム
           * | No. | dataField | headerName | type |
           * |-----|-----------|------------|------|
           * | 1 | makerName | メーカー | string |
           * | 2 | makerCode | メーカーコード | string |
           * | 3 | quantity | 数量 | integer |
           * | 4 | salonPrice | サロン価格 | integer |
           * @returns 正常処理
           */
          $get: (option: { query: Methods60['get']['query'], config?: T | undefined }) =>
            fetch<Methods60['get']['resBody'], BasicHeaders, Methods60['get']['status']>(prefix, PATH51, GET, option).json().then(r => r.body),
          $path: (option?: { method?: 'get' | undefined; query: Methods60['get']['query'] } | undefined) =>
            `${prefix}${PATH51}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
        },
        stores: {
          download: {
            /**
             * ### 概要
             * - 仕入情報の店舗別で一覧をCSVファイルダウンロード受付する。
             * - ダウンロードのための、ダウンロードIDが返却される。
             *
             * ### 処理仕様
             *
             * - 検索条件に合致する店舗別結果を抽出し、抽出結果をCSVファイルとしてS3に保存する。
             * - ただし、大量データの処理を複数同時に行うことでDBへの負荷が大きくなることを避けるため、以下のように処理を行う。
             *     - リクエストを受け付けた後、バリデーションエラーがない場合には、ダウンロードIDを発番し、即座にAPIのレスポンスとして返す。
             *     - ダウンロードIDの単位でリクエストをキューに格納し、キューから取り出して行う処理の同時実行数はシステム全体で1とする。
             *     - キューから取り出した後は、リクエスト時の検索条件に合致するデータを抽出し、抽出結果をCSVファイルとしてS3に保存する。
             * - 処理終了の確認、及びファイルのダウンロードは、仕入情報CSVダウンロードAPIにて行う。
             * - ダウンロードする場合のファイル名は「仕入情報_店舗別_yyyyMMddHHmmssfff.csv」とする。
             *
             * - パラメータから一覧取得条件を作成する。
             * - 集計単位でユーザに対して対応していない集計単位を指定した場合は400を返す。
             * - 店舗コードの指定がない場合、すべて対象の店舗が検索される。
             * - 集計対象に注文を指定した場合、注文日、または、出荷日が必須で抽出を行う。
             * - 集計対象に請求を指定した場合、請求月で抽出を行う。
             * - 請求月の指定がない場合当月とする。
             * - DBから結果を取得する。
             * - UTF-8 BOM形式CSVに整形しbase64でエンコードする。
             * - 上記の内容で処理を開始する。
             *
             * ### 並び順
             * - 店舗コード
             *
             * ### HTTPレスポンス
             * - 200 正常
             * - 400 パラメータ不正
             * - 401 未ログイン
             * - 403 権限のない集計単位の指定
             *
             * ### CSVカラム
             * 1. 店舗コード
             * 1. 店舗名
             * 1. 数量
             * 1. サロン価格
             * @returns 正常処理
             */
            post: (option: { body: Methods65['post']['reqBody'], config?: T | undefined }) =>
              fetch<Methods65['post']['resBody'], BasicHeaders, Methods65['post']['status']>(prefix, PATH56, POST, option).json(),
            /**
             * ### 概要
             * - 仕入情報の店舗別で一覧をCSVファイルダウンロード受付する。
             * - ダウンロードのための、ダウンロードIDが返却される。
             *
             * ### 処理仕様
             *
             * - 検索条件に合致する店舗別結果を抽出し、抽出結果をCSVファイルとしてS3に保存する。
             * - ただし、大量データの処理を複数同時に行うことでDBへの負荷が大きくなることを避けるため、以下のように処理を行う。
             *     - リクエストを受け付けた後、バリデーションエラーがない場合には、ダウンロードIDを発番し、即座にAPIのレスポンスとして返す。
             *     - ダウンロードIDの単位でリクエストをキューに格納し、キューから取り出して行う処理の同時実行数はシステム全体で1とする。
             *     - キューから取り出した後は、リクエスト時の検索条件に合致するデータを抽出し、抽出結果をCSVファイルとしてS3に保存する。
             * - 処理終了の確認、及びファイルのダウンロードは、仕入情報CSVダウンロードAPIにて行う。
             * - ダウンロードする場合のファイル名は「仕入情報_店舗別_yyyyMMddHHmmssfff.csv」とする。
             *
             * - パラメータから一覧取得条件を作成する。
             * - 集計単位でユーザに対して対応していない集計単位を指定した場合は400を返す。
             * - 店舗コードの指定がない場合、すべて対象の店舗が検索される。
             * - 集計対象に注文を指定した場合、注文日、または、出荷日が必須で抽出を行う。
             * - 集計対象に請求を指定した場合、請求月で抽出を行う。
             * - 請求月の指定がない場合当月とする。
             * - DBから結果を取得する。
             * - UTF-8 BOM形式CSVに整形しbase64でエンコードする。
             * - 上記の内容で処理を開始する。
             *
             * ### 並び順
             * - 店舗コード
             *
             * ### HTTPレスポンス
             * - 200 正常
             * - 400 パラメータ不正
             * - 401 未ログイン
             * - 403 権限のない集計単位の指定
             *
             * ### CSVカラム
             * 1. 店舗コード
             * 1. 店舗名
             * 1. 数量
             * 1. サロン価格
             * @returns 正常処理
             */
            $post: (option: { body: Methods65['post']['reqBody'], config?: T | undefined }) =>
              fetch<Methods65['post']['resBody'], BasicHeaders, Methods65['post']['status']>(prefix, PATH56, POST, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH56}`
          },
          makers: {
            sales_types: {
              download: {
                /**
                 * ### 概要
                 * - 仕入情報の店舗別メーカー別売上種別別で金額一覧をCSVファイルダウンロード処理受付する。
                 * - ダウンロードのための、ダウンロードIDが返却される。
                 * ### 処理仕様
                 *
                 * - 検索条件に合致する店舗別メーカー別売上種別別結果を抽出し、抽出結果をCSVファイルとしてS3に保存する。
                 * - ただし、大量データの処理を複数同時に行うことでDBへの負荷が大きくなることを避けるため、以下のように処理を行う。
                 *     - リクエストを受け付けた後、バリデーションエラーがない場合には、ダウンロードIDを発番し、即座にAPIのレスポンスとして返す。
                 *     - ダウンロードIDの単位でリクエストをキューに格納し、キューから取り出して行う処理の同時実行数はシステム全体で1とする。
                 *     - キューから取り出した後は、リクエスト時の検索条件に合致するデータを抽出し、抽出結果をCSVファイルとしてS3に保存する。
                 * - 処理終了の確認、及びファイルのダウンロードは、仕入情報CSVダウンロードAPIにて行う。
                 * - ダウンロードする場合のファイル名は「仕入情報_店舗別メーカー別売上種別別_yyyyMMddHHmmssfff.csv」とする。
                 *
                 * - パラメータから一覧取得条件を作成する。
                 * - 集計単位でユーザに対して対応していない集計単位を指定した場合は400を返す。
                 * - 店舗コードの指定がない場合、すべて対象の店舗が検索される。
                 * - 集計対象に注文を指定した場合、注文日、または、出荷日が必須で抽出を行う。
                 * - 集計対象に請求を指定した場合、請求月で抽出を行う。
                 * - 請求月の指定がない場合当月とする。
                 * - 値引き価格表示ありの場合は、値引き後の価格とする。
                 * - DBから結果を取得する。
                 * - UTF-8 BOM形式CSVに整形しbase64でエンコードする。
                 * - 上記の内容で処理を開始する。
                 *
                 * ### 並び順
                 * - 店舗コード
                 * - メーカーコード
                 * - 売上種別コード
                 *
                 * ### HTTPレスポンス
                 * - 200 正常
                 * - 400 パラメータ不正
                 * - 401 未ログイン
                 * - 403 権限のない集計単位の指定
                 *
                 * ### CSVカラム
                 * 1. 店舗コード
                 * 1. 店舗名
                 * 1. メーカー名(複数あり)
                 * 1. 売上種別(複数あり)
                 * @returns 正常処理
                 */
                post: (option: { body: Methods67['post']['reqBody'], config?: T | undefined }) =>
                  fetch<Methods67['post']['resBody'], BasicHeaders, Methods67['post']['status']>(prefix, PATH58, POST, option).json(),
                /**
                 * ### 概要
                 * - 仕入情報の店舗別メーカー別売上種別別で金額一覧をCSVファイルダウンロード処理受付する。
                 * - ダウンロードのための、ダウンロードIDが返却される。
                 * ### 処理仕様
                 *
                 * - 検索条件に合致する店舗別メーカー別売上種別別結果を抽出し、抽出結果をCSVファイルとしてS3に保存する。
                 * - ただし、大量データの処理を複数同時に行うことでDBへの負荷が大きくなることを避けるため、以下のように処理を行う。
                 *     - リクエストを受け付けた後、バリデーションエラーがない場合には、ダウンロードIDを発番し、即座にAPIのレスポンスとして返す。
                 *     - ダウンロードIDの単位でリクエストをキューに格納し、キューから取り出して行う処理の同時実行数はシステム全体で1とする。
                 *     - キューから取り出した後は、リクエスト時の検索条件に合致するデータを抽出し、抽出結果をCSVファイルとしてS3に保存する。
                 * - 処理終了の確認、及びファイルのダウンロードは、仕入情報CSVダウンロードAPIにて行う。
                 * - ダウンロードする場合のファイル名は「仕入情報_店舗別メーカー別売上種別別_yyyyMMddHHmmssfff.csv」とする。
                 *
                 * - パラメータから一覧取得条件を作成する。
                 * - 集計単位でユーザに対して対応していない集計単位を指定した場合は400を返す。
                 * - 店舗コードの指定がない場合、すべて対象の店舗が検索される。
                 * - 集計対象に注文を指定した場合、注文日、または、出荷日が必須で抽出を行う。
                 * - 集計対象に請求を指定した場合、請求月で抽出を行う。
                 * - 請求月の指定がない場合当月とする。
                 * - 値引き価格表示ありの場合は、値引き後の価格とする。
                 * - DBから結果を取得する。
                 * - UTF-8 BOM形式CSVに整形しbase64でエンコードする。
                 * - 上記の内容で処理を開始する。
                 *
                 * ### 並び順
                 * - 店舗コード
                 * - メーカーコード
                 * - 売上種別コード
                 *
                 * ### HTTPレスポンス
                 * - 200 正常
                 * - 400 パラメータ不正
                 * - 401 未ログイン
                 * - 403 権限のない集計単位の指定
                 *
                 * ### CSVカラム
                 * 1. 店舗コード
                 * 1. 店舗名
                 * 1. メーカー名(複数あり)
                 * 1. 売上種別(複数あり)
                 * @returns 正常処理
                 */
                $post: (option: { body: Methods67['post']['reqBody'], config?: T | undefined }) =>
                  fetch<Methods67['post']['resBody'], BasicHeaders, Methods67['post']['status']>(prefix, PATH58, POST, option).json().then(r => r.body),
                $path: () => `${prefix}${PATH58}`
              },
              /**
               * ### 概要
               * 仕入情報の店舗別メーカー別売上種別別で一覧を取得する。
               * ### 処理仕様
               * - パラメータから一覧取得条件を作成する。
               * - 集計単位でユーザに対して対応していない集計単位を指定した場合は400を返す。
               * - 店舗コードの指定がない場合、すべて対象の店舗が検索される。
               * - 集計対象に注文を指定した場合、注文日、または、出荷日が必須で抽出を行う。
               * - 集計対象に請求を指定した場合、請求月で抽出を行う。
               * - 請求月の指定がない場合当月とする。
               * - 値引き価格表示ありの場合は、値引き後の価格とする。
               * - DBから結果を取得する。
               * - 結果を返す。
               *
               * ### 並び順
               * - 店舗コード
               * - メーカーコード
               * - 売上種別コード
               *
               * ### HTTPレスポンス
               * - 200 正常
               * - 400 パラメータ不正
               * - 401 未ログイン
               * - 403 権限のない集計単位の指定
               *
               * ### カラム
               * | No. | dataField | headerName | type |
               * |-----|-----------|------------|------|
               * | 1 | storeCode | 店舗コード | string |
               * | 2 | storeName | 店舗名 | string |
               * | 3 | makerName | メーカー名(複数あり) | string |
               * | 4 | price | 売上種別ごとの金額(複数あり) | integer |
               * @returns 正常処理
               */
              get: (option: { query: Methods66['get']['query'], config?: T | undefined }) =>
                fetch<Methods66['get']['resBody'], BasicHeaders, Methods66['get']['status']>(prefix, PATH57, GET, option).json(),
              /**
               * ### 概要
               * 仕入情報の店舗別メーカー別売上種別別で一覧を取得する。
               * ### 処理仕様
               * - パラメータから一覧取得条件を作成する。
               * - 集計単位でユーザに対して対応していない集計単位を指定した場合は400を返す。
               * - 店舗コードの指定がない場合、すべて対象の店舗が検索される。
               * - 集計対象に注文を指定した場合、注文日、または、出荷日が必須で抽出を行う。
               * - 集計対象に請求を指定した場合、請求月で抽出を行う。
               * - 請求月の指定がない場合当月とする。
               * - 値引き価格表示ありの場合は、値引き後の価格とする。
               * - DBから結果を取得する。
               * - 結果を返す。
               *
               * ### 並び順
               * - 店舗コード
               * - メーカーコード
               * - 売上種別コード
               *
               * ### HTTPレスポンス
               * - 200 正常
               * - 400 パラメータ不正
               * - 401 未ログイン
               * - 403 権限のない集計単位の指定
               *
               * ### カラム
               * | No. | dataField | headerName | type |
               * |-----|-----------|------------|------|
               * | 1 | storeCode | 店舗コード | string |
               * | 2 | storeName | 店舗名 | string |
               * | 3 | makerName | メーカー名(複数あり) | string |
               * | 4 | price | 売上種別ごとの金額(複数あり) | integer |
               * @returns 正常処理
               */
              $get: (option: { query: Methods66['get']['query'], config?: T | undefined }) =>
                fetch<Methods66['get']['resBody'], BasicHeaders, Methods66['get']['status']>(prefix, PATH57, GET, option).json().then(r => r.body),
              $path: (option?: { method?: 'get' | undefined; query: Methods66['get']['query'] } | undefined) =>
                `${prefix}${PATH57}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
            }
          },
          sales_types: {
            download: {
              /**
               * ### 概要
               * - 仕入情報の店舗別売上種別別で一覧をCSVファイルダウンロード処理受付する。
               * - ダウンロードのための、ダウンロードIDが返却される。
               *
               * ### 処理仕様
               *
               * - 検索条件に合致する店舗別売上種別別結果を抽出し、抽出結果をCSVファイルとしてS3に保存する。
               * - ただし、大量データの処理を複数同時に行うことでDBへの負荷が大きくなることを避けるため、以下のように処理を行う。
               *     - リクエストを受け付けた後、バリデーションエラーがない場合には、ダウンロードIDを発番し、即座にAPIのレスポンスとして返す。
               *     - ダウンロードIDの単位でリクエストをキューに格納し、キューから取り出して行う処理の同時実行数はシステム全体で1とする。
               *     - キューから取り出した後は、リクエスト時の検索条件に合致するデータを抽出し、抽出結果をCSVファイルとしてS3に保存する。
               * - 処理終了の確認、及びファイルのダウンロードは、仕入情報CSVダウンロードAPIにて行う。
               * - ダウンロードする場合のファイル名は「仕入情報_店舗別売上種別別_yyyyMMddHHmmssfff.csv」とする。
               *
               * - パラメータから一覧取得条件を作成する。
               * - 集計単位でユーザに対して対応していない集計単位を指定した場合は400を返す。
               * - 店舗コードの指定がない場合、すべて対象の店舗が検索される。
               * - 集計対象に注文を指定した場合、注文日、または、出荷日が必須で抽出を行う。
               * - 集計対象に請求を指定した場合、請求月で抽出を行う。
               * - 請求月の指定がない場合当月とする。
               * - 値引き価格表示ありの場合は、値引き後の価格とする。
               * - DBから結果を取得する。
               * - UTF-8 BOM形式CSVに整形しbase64でエンコードする。
               * - 上記の内容で処理を開始する。
               * ### 並び順
               * - 店舗コード
               * - 売上種別コード
               *
               * ### HTTPレスポンス
               * - 200 正常
               * - 400 パラメータ不正
               * - 401 未ログイン
               * - 403 権限のない集計単位の指定
               *
               * ### CSVカラム
               * 1. 店舗コード
               * 1. 店舗名
               * 1. 売上種別(複数あり)
               * @returns 正常処理
               */
              post: (option: { body: Methods69['post']['reqBody'], config?: T | undefined }) =>
                fetch<Methods69['post']['resBody'], BasicHeaders, Methods69['post']['status']>(prefix, PATH60, POST, option).json(),
              /**
               * ### 概要
               * - 仕入情報の店舗別売上種別別で一覧をCSVファイルダウンロード処理受付する。
               * - ダウンロードのための、ダウンロードIDが返却される。
               *
               * ### 処理仕様
               *
               * - 検索条件に合致する店舗別売上種別別結果を抽出し、抽出結果をCSVファイルとしてS3に保存する。
               * - ただし、大量データの処理を複数同時に行うことでDBへの負荷が大きくなることを避けるため、以下のように処理を行う。
               *     - リクエストを受け付けた後、バリデーションエラーがない場合には、ダウンロードIDを発番し、即座にAPIのレスポンスとして返す。
               *     - ダウンロードIDの単位でリクエストをキューに格納し、キューから取り出して行う処理の同時実行数はシステム全体で1とする。
               *     - キューから取り出した後は、リクエスト時の検索条件に合致するデータを抽出し、抽出結果をCSVファイルとしてS3に保存する。
               * - 処理終了の確認、及びファイルのダウンロードは、仕入情報CSVダウンロードAPIにて行う。
               * - ダウンロードする場合のファイル名は「仕入情報_店舗別売上種別別_yyyyMMddHHmmssfff.csv」とする。
               *
               * - パラメータから一覧取得条件を作成する。
               * - 集計単位でユーザに対して対応していない集計単位を指定した場合は400を返す。
               * - 店舗コードの指定がない場合、すべて対象の店舗が検索される。
               * - 集計対象に注文を指定した場合、注文日、または、出荷日が必須で抽出を行う。
               * - 集計対象に請求を指定した場合、請求月で抽出を行う。
               * - 請求月の指定がない場合当月とする。
               * - 値引き価格表示ありの場合は、値引き後の価格とする。
               * - DBから結果を取得する。
               * - UTF-8 BOM形式CSVに整形しbase64でエンコードする。
               * - 上記の内容で処理を開始する。
               * ### 並び順
               * - 店舗コード
               * - 売上種別コード
               *
               * ### HTTPレスポンス
               * - 200 正常
               * - 400 パラメータ不正
               * - 401 未ログイン
               * - 403 権限のない集計単位の指定
               *
               * ### CSVカラム
               * 1. 店舗コード
               * 1. 店舗名
               * 1. 売上種別(複数あり)
               * @returns 正常処理
               */
              $post: (option: { body: Methods69['post']['reqBody'], config?: T | undefined }) =>
                fetch<Methods69['post']['resBody'], BasicHeaders, Methods69['post']['status']>(prefix, PATH60, POST, option).json().then(r => r.body),
              $path: () => `${prefix}${PATH60}`
            },
            /**
             * ### 概要
             * 仕入情報の店舗別売上種別別で一覧を取得する。
             * ### 処理仕様
             * - パラメータから一覧取得条件を作成する。
             * - 集計単位でユーザに対して対応していない集計単位を指定した場合は400を返す。
             * - 店舗コードの指定がない場合、すべて対象の店舗が検索される。
             * - 集計対象に注文を指定した場合、注文日、または、出荷日が必須で抽出を行う。
             * - 集計対象に請求を指定した場合、請求月で抽出を行う。
             * - 請求月の指定がない場合当月とする。
             * - 値引き価格表示ありの場合は、値引き後の価格とする。
             * - DBから結果を取得する。
             * - 結果を返す。
             *
             * ### 並び順
             * - 店舗コード
             * - 売上種別コード
             *
             * ### HTTPレスポンス
             * - 200 正常
             * - 400 パラメータ不正
             * - 401 未ログイン
             * - 403 権限のない集計単位の指定
             *
             * ### カラム
             * | No. | dataField | headerName | type |
             * |-----|-----------|------------|------|
             * | 1 | storeCode | 店舗コード | string |
             * | 2 | storeName | 店舗名 | string |
             * | 3 | price | 売上種別ごとの金額(複数あり) | integer |
             * @returns 正常処理
             */
            get: (option: { query: Methods68['get']['query'], config?: T | undefined }) =>
              fetch<Methods68['get']['resBody'], BasicHeaders, Methods68['get']['status']>(prefix, PATH59, GET, option).json(),
            /**
             * ### 概要
             * 仕入情報の店舗別売上種別別で一覧を取得する。
             * ### 処理仕様
             * - パラメータから一覧取得条件を作成する。
             * - 集計単位でユーザに対して対応していない集計単位を指定した場合は400を返す。
             * - 店舗コードの指定がない場合、すべて対象の店舗が検索される。
             * - 集計対象に注文を指定した場合、注文日、または、出荷日が必須で抽出を行う。
             * - 集計対象に請求を指定した場合、請求月で抽出を行う。
             * - 請求月の指定がない場合当月とする。
             * - 値引き価格表示ありの場合は、値引き後の価格とする。
             * - DBから結果を取得する。
             * - 結果を返す。
             *
             * ### 並び順
             * - 店舗コード
             * - 売上種別コード
             *
             * ### HTTPレスポンス
             * - 200 正常
             * - 400 パラメータ不正
             * - 401 未ログイン
             * - 403 権限のない集計単位の指定
             *
             * ### カラム
             * | No. | dataField | headerName | type |
             * |-----|-----------|------------|------|
             * | 1 | storeCode | 店舗コード | string |
             * | 2 | storeName | 店舗名 | string |
             * | 3 | price | 売上種別ごとの金額(複数あり) | integer |
             * @returns 正常処理
             */
            $get: (option: { query: Methods68['get']['query'], config?: T | undefined }) =>
              fetch<Methods68['get']['resBody'], BasicHeaders, Methods68['get']['status']>(prefix, PATH59, GET, option).json().then(r => r.body),
            $path: (option?: { method?: 'get' | undefined; query: Methods68['get']['query'] } | undefined) =>
              `${prefix}${PATH59}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
          },
          /**
           * ### 概要
           * 仕入情報の店舗別で一覧を取得する。
           * ### 処理仕様
           * - パラメータから一覧取得条件を作成する。
           * - 集計単位でユーザに対して対応していない集計単位を指定した場合は400を返す。
           * - 店舗コードの指定がない場合、すべて対象の店舗が検索される。
           * - 集計対象に注文を指定した場合、注文日、または、出荷日が必須で抽出を行う。
           * - 集計対象に請求を指定した場合、請求月で抽出を行う。
           * - 請求月の指定がない場合当月とする。
           * - 値引き価格表示ありの場合は、値引き後の価格とする。
           * - DBから結果を取得する。
           * - 結果を返す。
           *
           * ### 並び順
           * - 店舗コード
           *
           * ### HTTPレスポンス
           * - 200 正常
           * - 400 パラメータ不正
           * - 401 未ログイン
           * - 403 権限のない集計単位の指定
           *
           * ### カラム
           * | No. | dataField | headerName | type |
           * |-----|-----------|------------|------|
           * | 1 | storeCode | 店舗コード | string |
           * | 2 | storeName | 店舗名 | string |
           * | 3 | quantity | 数量 | integer |
           * | 4 | salonPrice | サロン価格 | integer |
           * @returns 正常処理
           */
          get: (option: { query: Methods64['get']['query'], config?: T | undefined }) =>
            fetch<Methods64['get']['resBody'], BasicHeaders, Methods64['get']['status']>(prefix, PATH55, GET, option).json(),
          /**
           * ### 概要
           * 仕入情報の店舗別で一覧を取得する。
           * ### 処理仕様
           * - パラメータから一覧取得条件を作成する。
           * - 集計単位でユーザに対して対応していない集計単位を指定した場合は400を返す。
           * - 店舗コードの指定がない場合、すべて対象の店舗が検索される。
           * - 集計対象に注文を指定した場合、注文日、または、出荷日が必須で抽出を行う。
           * - 集計対象に請求を指定した場合、請求月で抽出を行う。
           * - 請求月の指定がない場合当月とする。
           * - 値引き価格表示ありの場合は、値引き後の価格とする。
           * - DBから結果を取得する。
           * - 結果を返す。
           *
           * ### 並び順
           * - 店舗コード
           *
           * ### HTTPレスポンス
           * - 200 正常
           * - 400 パラメータ不正
           * - 401 未ログイン
           * - 403 権限のない集計単位の指定
           *
           * ### カラム
           * | No. | dataField | headerName | type |
           * |-----|-----------|------------|------|
           * | 1 | storeCode | 店舗コード | string |
           * | 2 | storeName | 店舗名 | string |
           * | 3 | quantity | 数量 | integer |
           * | 4 | salonPrice | サロン価格 | integer |
           * @returns 正常処理
           */
          $get: (option: { query: Methods64['get']['query'], config?: T | undefined }) =>
            fetch<Methods64['get']['resBody'], BasicHeaders, Methods64['get']['status']>(prefix, PATH55, GET, option).json().then(r => r.body),
          $path: (option?: { method?: 'get' | undefined; query: Methods64['get']['query'] } | undefined) =>
            `${prefix}${PATH55}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
        }
      },
      receive_email: {
        /**
         * ログインユーザメール受信取得
         * @returns 正常処理
         */
        get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods70['get']['resBody'], BasicHeaders, Methods70['get']['status']>(prefix, PATH61, GET, option).json(),
        /**
         * ログインユーザメール受信取得
         * @returns 正常処理
         */
        $get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods70['get']['resBody'], BasicHeaders, Methods70['get']['status']>(prefix, PATH61, GET, option).json().then(r => r.body),
        /**
         * ユーザメール受信設定更新
         * @returns 正常処理
         */
        put: (option: { body: Methods70['put']['reqBody'], config?: T | undefined }) =>
          fetch<Methods70['put']['resBody'], BasicHeaders, Methods70['put']['status']>(prefix, PATH61, PUT, option).json(),
        /**
         * ユーザメール受信設定更新
         * @returns 正常処理
         */
        $put: (option: { body: Methods70['put']['reqBody'], config?: T | undefined }) =>
          fetch<Methods70['put']['resBody'], BasicHeaders, Methods70['put']['status']>(prefix, PATH61, PUT, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH61}`
      },
      sales_details: {
        download: {
          /**
           * ### 概要
           * - 売上明細の一覧を取得しCSVをダウンロード処理受付する。
           * - 検索結果が0件の場合、ヘッダのみを返却する。
           * - ダウンロードのための、ダウンロードIDが返却される。
           * ### 処理仕様
           *
           * - 検索条件に合致する売上明細結果を抽出し、抽出結果をCSVファイルとしてS3に保存する。
           * - ただし、大量データの処理を複数同時に行うことでDBへの負荷が大きくなることを避けるため、以下のように処理を行う。
           *     - リクエストを受け付けた後、バリデーションエラーがない場合には、ダウンロードIDを発番し、即座にAPIのレスポンスとして返す。
           *     - ダウンロードIDの単位でリクエストをキューに格納し、キューから取り出して行う処理の同時実行数はシステム全体で1とする。
           *     - キューから取り出した後は、リクエスト時の検索条件に合致するデータを抽出し、抽出結果をCSVファイルとしてS3に保存する。
           * - 処理終了の確認、及びファイルのダウンロードは、仕入情報CSVダウンロードAPIにて行う。
           * - ダウンロードする場合のファイル名は「仕入情報_売上明細_yyyyMMddHHmmssfff.csv」とする。
           *
           * - パラメータから検索条件を作成する。
           * - 指定された集計単位の店舗コードを対象に検索する。店舗コードが指定されている場合、指定された店舗コードで検索する。
           * - 集計対象に注文を指定した場合、注文日、または、出荷日で抽出する。注文日、出荷日の指定がない場合、当月分の注文日を指定する。
           * - 商品コードは前方一致で絞り込みを行う。
           * - 集計対象に請求を指定した場合、請求月で抽出を行する。請求月の指定がない場合、当月の請求月を指定する。
           * - DBから売上明細情報を取得する。
           * - UTF-8 BOM形式CSVに整形しbase64でエンコードする。
           * - 結果を返す。
           * ### 並び順
           * 1. 受注日の降順
           * 1. 売上番号
           * 1. 売上行番号
           * ### HTTPレスポンス
           * - 200 正常
           * - 400 パラメータ不正
           * - 401 未ログイン
           * - 403 権限のない集計単位の指定
           * ### CSVカラム
           * 1. 受注日
           * 1. 出荷日
           * 1. 売上番号
           * 1. 売上行番号
           * 1. 店舗コード
           * 1. 店舗名
           * 1. 売上種別
           * 1. メーカー
           * 1. 商品コード
           * 1. 商品名
           * 1. JANコード
           * 1. カテゴリ
           * 1. 数量
           * 1. 単価
           * 1. 金額
           * 1. 備考
           * 1. サロン価格
           * 1. メーカー希望小売価格
           * 1. 取り込み番号
           * @returns 正常処理
           */
          post: (option: { body: Methods72['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods72['post']['resBody'], BasicHeaders, Methods72['post']['status']>(prefix, PATH63, POST, option).json(),
          /**
           * ### 概要
           * - 売上明細の一覧を取得しCSVをダウンロード処理受付する。
           * - 検索結果が0件の場合、ヘッダのみを返却する。
           * - ダウンロードのための、ダウンロードIDが返却される。
           * ### 処理仕様
           *
           * - 検索条件に合致する売上明細結果を抽出し、抽出結果をCSVファイルとしてS3に保存する。
           * - ただし、大量データの処理を複数同時に行うことでDBへの負荷が大きくなることを避けるため、以下のように処理を行う。
           *     - リクエストを受け付けた後、バリデーションエラーがない場合には、ダウンロードIDを発番し、即座にAPIのレスポンスとして返す。
           *     - ダウンロードIDの単位でリクエストをキューに格納し、キューから取り出して行う処理の同時実行数はシステム全体で1とする。
           *     - キューから取り出した後は、リクエスト時の検索条件に合致するデータを抽出し、抽出結果をCSVファイルとしてS3に保存する。
           * - 処理終了の確認、及びファイルのダウンロードは、仕入情報CSVダウンロードAPIにて行う。
           * - ダウンロードする場合のファイル名は「仕入情報_売上明細_yyyyMMddHHmmssfff.csv」とする。
           *
           * - パラメータから検索条件を作成する。
           * - 指定された集計単位の店舗コードを対象に検索する。店舗コードが指定されている場合、指定された店舗コードで検索する。
           * - 集計対象に注文を指定した場合、注文日、または、出荷日で抽出する。注文日、出荷日の指定がない場合、当月分の注文日を指定する。
           * - 商品コードは前方一致で絞り込みを行う。
           * - 集計対象に請求を指定した場合、請求月で抽出を行する。請求月の指定がない場合、当月の請求月を指定する。
           * - DBから売上明細情報を取得する。
           * - UTF-8 BOM形式CSVに整形しbase64でエンコードする。
           * - 結果を返す。
           * ### 並び順
           * 1. 受注日の降順
           * 1. 売上番号
           * 1. 売上行番号
           * ### HTTPレスポンス
           * - 200 正常
           * - 400 パラメータ不正
           * - 401 未ログイン
           * - 403 権限のない集計単位の指定
           * ### CSVカラム
           * 1. 受注日
           * 1. 出荷日
           * 1. 売上番号
           * 1. 売上行番号
           * 1. 店舗コード
           * 1. 店舗名
           * 1. 売上種別
           * 1. メーカー
           * 1. 商品コード
           * 1. 商品名
           * 1. JANコード
           * 1. カテゴリ
           * 1. 数量
           * 1. 単価
           * 1. 金額
           * 1. 備考
           * 1. サロン価格
           * 1. メーカー希望小売価格
           * 1. 取り込み番号
           * @returns 正常処理
           */
          $post: (option: { body: Methods72['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods72['post']['resBody'], BasicHeaders, Methods72['post']['status']>(prefix, PATH63, POST, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH63}`
        },
        /**
         * ### 概要
         * - 売上明細の一覧を取得する。
         * ### 処理仕様
         * - パラメータから検索条件を作成する。
         * - 指定された集計単位の店舗コードを対象に検索する。店舗コードが指定されている場合、指定された店舗コードで検索する。
         * - 集計対象に注文を指定した場合、注文日、または、出荷日で抽出する。注文日、出荷日の指定がない場合、当月分の注文日を指定する。
         * - 商品コードは前方一致で絞り込みを行う。
         * - 集計対象に請求を指定した場合、請求月で抽出を行する。請求月の指定がない場合、当月の請求月を指定する。
         * - DBから明細情報を取得し返却する。
         * ### 並び順
         * 1. 受注日の降順
         * 1. 売上番号
         * 1. 売上行番号
         * ### HTTPレスポンス
         * - 200 正常
         * - 400 パラメータ不正
         * - 401 未ログイン
         * - 403 権限のない集計単位の指定
         *
         * ### カラム
         * | No. | dataField | headerName | type |
         * |-----|-----------|------------|------|
         * | 1 | orderDate | 受注日 | string |
         * | 2 | shippingDate | 出荷日 | string |
         * | 3 | salesNo | 売上番号 | string |
         * | 4 | salesRowNo | 売上行番号 | string |
         * | 5 | storeCode | 店舗コード | string |
         * | 6 | storeName | 店舗名 | string |
         * | 7 | salesType | 売上種別 | string |
         * | 8 | makerName | メーカー | string |
         * | 9 | itemCode | 商品コード | string |
         * | 10 | itemName | 商品名 | string |
         * | 11 | janCode | JANコード | string |
         * | 12 | categoryName | カテゴリ | string |
         * | 13 | quantity | 数量 | integer |
         * | 14 | discountPrice| 単価 | integer |
         * | 15 | totalPrice | 金額 | integer |
         * | 16 | note | 備考 | string |
         * | 17 | salonPrice | サロン価格 | integer |
         * | 18 | retailPrice | メーカー希望小売価格 | integer |
         * | 19 | importNo | 取り込み番号 | string |
         * @returns 正常処理
         */
        get: (option: { query: Methods71['get']['query'], config?: T | undefined }) =>
          fetch<Methods71['get']['resBody'], BasicHeaders, Methods71['get']['status']>(prefix, PATH62, GET, option).json(),
        /**
         * ### 概要
         * - 売上明細の一覧を取得する。
         * ### 処理仕様
         * - パラメータから検索条件を作成する。
         * - 指定された集計単位の店舗コードを対象に検索する。店舗コードが指定されている場合、指定された店舗コードで検索する。
         * - 集計対象に注文を指定した場合、注文日、または、出荷日で抽出する。注文日、出荷日の指定がない場合、当月分の注文日を指定する。
         * - 商品コードは前方一致で絞り込みを行う。
         * - 集計対象に請求を指定した場合、請求月で抽出を行する。請求月の指定がない場合、当月の請求月を指定する。
         * - DBから明細情報を取得し返却する。
         * ### 並び順
         * 1. 受注日の降順
         * 1. 売上番号
         * 1. 売上行番号
         * ### HTTPレスポンス
         * - 200 正常
         * - 400 パラメータ不正
         * - 401 未ログイン
         * - 403 権限のない集計単位の指定
         *
         * ### カラム
         * | No. | dataField | headerName | type |
         * |-----|-----------|------------|------|
         * | 1 | orderDate | 受注日 | string |
         * | 2 | shippingDate | 出荷日 | string |
         * | 3 | salesNo | 売上番号 | string |
         * | 4 | salesRowNo | 売上行番号 | string |
         * | 5 | storeCode | 店舗コード | string |
         * | 6 | storeName | 店舗名 | string |
         * | 7 | salesType | 売上種別 | string |
         * | 8 | makerName | メーカー | string |
         * | 9 | itemCode | 商品コード | string |
         * | 10 | itemName | 商品名 | string |
         * | 11 | janCode | JANコード | string |
         * | 12 | categoryName | カテゴリ | string |
         * | 13 | quantity | 数量 | integer |
         * | 14 | discountPrice| 単価 | integer |
         * | 15 | totalPrice | 金額 | integer |
         * | 16 | note | 備考 | string |
         * | 17 | salonPrice | サロン価格 | integer |
         * | 18 | retailPrice | メーカー希望小売価格 | integer |
         * | 19 | importNo | 取り込み番号 | string |
         * @returns 正常処理
         */
        $get: (option: { query: Methods71['get']['query'], config?: T | undefined }) =>
          fetch<Methods71['get']['resBody'], BasicHeaders, Methods71['get']['status']>(prefix, PATH62, GET, option).json().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods71['get']['query'] } | undefined) =>
          `${prefix}${PATH62}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
      },
      sales_types: {
        /**
         * 売上種別一覧を取得します。
         * 取得結果の並び順は、売上種別コードの昇順になります。
         * @returns 正常処理
         */
        get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods73['get']['resBody'], BasicHeaders, Methods73['get']['status']>(prefix, PATH64, GET, option).json(),
        /**
         * 売上種別一覧を取得します。
         * 取得結果の並び順は、売上種別コードの昇順になります。
         * @returns 正常処理
         */
        $get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods73['get']['resBody'], BasicHeaders, Methods73['get']['status']>(prefix, PATH64, GET, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH64}`
      },
      stores: {
        me: {
          /**
           * ログイン中の店舗情報を取得します。
           * @returns 正常処理
           */
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods75['get']['resBody'], BasicHeaders, Methods75['get']['status']>(prefix, PATH66, GET, option).json(),
          /**
           * ログイン中の店舗情報を取得します。
           * @returns 正常処理
           */
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods75['get']['resBody'], BasicHeaders, Methods75['get']['status']>(prefix, PATH66, GET, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH66}`
        },
        /**
         * 店舗情報一覧取得
         * 店舗コードで昇順
         * @returns 正常処理
         */
        get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods74['get']['resBody'], BasicHeaders, Methods74['get']['status']>(prefix, PATH65, GET, option).json(),
        /**
         * 店舗情報一覧取得
         * 店舗コードで昇順
         * @returns 正常処理
         */
        $get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods74['get']['resBody'], BasicHeaders, Methods74['get']['status']>(prefix, PATH65, GET, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH65}`
      },
      stores_for_invoice: {
        /**
         * ### 概要
         * ログインユーザーの選択可能な請求先店舗を取得する。
         *
         * ### 処理仕様
         *
         * - ログインユーザの所属店舗を確認する。
         * - 入金親の場合、紐づく請求先の店舗情報を取得する。
         * - 請求先店舗の場合、自店舗の店舗情報を取得する。
         * - 結果を返す。
         *
         * ### 店舗並び順
         * - 店舗名の昇順
         *
         * ### HTTPレスポンス
         * - 200 正常
         * - 401 未ログイン
         * @returns 正常処理
         */
        get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods76['get']['resBody'], BasicHeaders, Methods76['get']['status']>(prefix, PATH67, GET, option).json(),
        /**
         * ### 概要
         * ログインユーザーの選択可能な請求先店舗を取得する。
         *
         * ### 処理仕様
         *
         * - ログインユーザの所属店舗を確認する。
         * - 入金親の場合、紐づく請求先の店舗情報を取得する。
         * - 請求先店舗の場合、自店舗の店舗情報を取得する。
         * - 結果を返す。
         *
         * ### 店舗並び順
         * - 店舗名の昇順
         *
         * ### HTTPレスポンス
         * - 200 正常
         * - 401 未ログイン
         * @returns 正常処理
         */
        $get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods76['get']['resBody'], BasicHeaders, Methods76['get']['status']>(prefix, PATH67, GET, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH67}`
      },
      tests: {
        cart: {
          delivery_date: {
            one_day_ago: {
              /**
               * このAPIはテスト用です。<br/>
               * ご希望納品日が最短納品日より過去の場合でもエラーにならないように<br/>
               * ログインユーザーのご希望納品日をAPI実行日の前日(1日前)に更新します。<br/>
               * カート用にご希望納品日の設定が出来ます。<br/>
               * 処理成功時は、設定したカート情報を返却します。<br/>
               * <br/>
               * このAPIで設定したカートは10分で揮発します。<br/>
               * <br/>
               * 以下のレスポンスはHTTPステータスのみの返却となります。<br/>
               *
               * <ul>
               * <li>401：認証に失敗した場合。</li>
               * </ul>
               * @returns 正常処理
               */
              put: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods77['put']['resBody'], BasicHeaders, Methods77['put']['status']>(prefix, PATH68, PUT, option).json(),
              /**
               * このAPIはテスト用です。<br/>
               * ご希望納品日が最短納品日より過去の場合でもエラーにならないように<br/>
               * ログインユーザーのご希望納品日をAPI実行日の前日(1日前)に更新します。<br/>
               * カート用にご希望納品日の設定が出来ます。<br/>
               * 処理成功時は、設定したカート情報を返却します。<br/>
               * <br/>
               * このAPIで設定したカートは10分で揮発します。<br/>
               * <br/>
               * 以下のレスポンスはHTTPステータスのみの返却となります。<br/>
               *
               * <ul>
               * <li>401：認証に失敗した場合。</li>
               * </ul>
               * @returns 正常処理
               */
              $put: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods77['put']['resBody'], BasicHeaders, Methods77['put']['status']>(prefix, PATH68, PUT, option).json().then(r => r.body),
              $path: () => `${prefix}${PATH68}`
            }
          }
        }
      },
      user: {
        me: {
          /**
           * ユーザ情報を取得します。
           * @returns 正常処理
           */
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods78['get']['resBody'], BasicHeaders, Methods78['get']['status']>(prefix, PATH69, GET, option).json(),
          /**
           * ユーザ情報を取得します。
           * @returns 正常処理
           */
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods78['get']['resBody'], BasicHeaders, Methods78['get']['status']>(prefix, PATH69, GET, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH69}`
        }
      }
    }
  }
}

// prettier-ignore
export type ApiInstance = ReturnType<typeof api>
// prettier-ignore
export default api
