import { AccessTime, SearchOutlined, Menu } from '@mui/icons-material'
import clsx from 'clsx'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { useCallback, useMemo } from 'react'

import { CartWithCount } from '@/components/features/cart'
import { FavoriteIcon } from '@/components/icons'
import { GlobalMenu, IconWithLabel, Image } from '@/components/ui'
import {
  ROUTE,
  ORDER_HISTORY_TITLE,
  FAVORITE_TITLE,
  ITEM_SEARCH_TITLE,
} from '@/const'
import { IS_PRODUCTION } from '@/const/env'
import { useLimitedMode, useLogout } from '@/hooks/features/auth'
import { useMyStore } from '@/hooks/features/store'
import { useMe } from '@/hooks/features/user'
import { useModal } from '@/hooks/util'

import { MaxWidthLayout } from '../MaxWidthLayout'
import { HeaderColumn } from './HeaderColumn'
import { HeaderIconLink } from './HeaderIconLink'
import { HeaderProps } from './type'

export const Header = ({
  withSearch = true,
  withMenu = true,
  withLinks = true,
  disableLogoLink = false,
}: HeaderProps) => {
  const router = useRouter()
  const { mode, isLimitedTerm } = useLimitedMode()

  const { data: me } = useMe({})

  const { data: myStore } = useMyStore({})

  const logoutMutation = useLogout({})

  const globalMenu = useModal()

  const handleLogout = useCallback(() => {
    logoutMutation.mutate(undefined, {
      onSuccess: () => {
        router.push(ROUTE.login)
        globalMenu.close()
      },
    })
  }, [globalMenu, logoutMutation, router])

  const isLoading = useMemo(
    () => logoutMutation.isLoading,
    [logoutMutation.isLoading]
  )

  const showRightLinks = withLinks && (mode === 'normal' || !isLimitedTerm)
  return (
    <>
      <div
        className={clsx(
          'tw-sticky tw-top-0',
          IS_PRODUCTION ? 'tw-bg-white' : 'tw-bg-accentYellow-50',
          'tw-z-header',
          'tw-border-b tw-border-gray-300'
        )}
      >
        <MaxWidthLayout>
          <div
            className={clsx(
              'tw-h-14 tw-w-full lg:tw-h-20',
              'tw-flex tw-items-center tw-justify-between',
              'tw-px-3'
            )}
          >
            {/* left */}
            <HeaderColumn isFlex={true} justify={'start'}>
              {withMenu && (
                <button
                  className={clsx(
                    'tw-flex tw-items-center',
                    'hover:tw-opacity-hover',
                    'tw-transition tw-duration-stateChange'
                  )}
                  onClick={globalMenu.open}
                >
                  <IconWithLabel label="メニュー" showLabelSp={false}>
                    <Menu
                      sx={{ fontSize: 32 }}
                      className={clsx('tw-text-primary-500')}
                    />
                  </IconWithLabel>
                </button>
              )}
            </HeaderColumn>
            {/* center */}
            <HeaderColumn isFlex={true} justify={'center'}>
              <div className={clsx('tw-h-[49px] tw-w-[141px]')}>
                {disableLogoLink ? (
                  <p>
                    <Image src="/altair.svg" alt="altair-logo" fit="contain" />
                  </p>
                ) : (
                  <NextLink href={ROUTE.top} passHref>
                    <a>
                      <Image
                        src="/altair.svg"
                        alt="altair-logo"
                        fit="contain"
                      />
                    </a>
                  </NextLink>
                )}
              </div>
              {!IS_PRODUCTION && (
                <span
                  className={clsx(
                    'tw-block tw-text-sm tw-font-bold lg:tw-text-base',
                    'tw-w-24'
                  )}
                >
                  【開発環境】
                </span>
              )}
            </HeaderColumn>
            {/* right */}
            <HeaderColumn isFlex={true} justify={'end'}>
              {showRightLinks && (
                <>
                  <HeaderIconLink
                    href={ROUTE.orderHistories}
                    label={ORDER_HISTORY_TITLE}
                    showSp={false}
                  >
                    <AccessTime
                      sx={{ fontSize: 32 }}
                      className={clsx('tw-text-primary-500')}
                    />
                  </HeaderIconLink>
                  <HeaderIconLink
                    href={ROUTE.favorites}
                    label={FAVORITE_TITLE}
                    showSp={false}
                  >
                    <FavoriteIcon size={32} type="empty" />
                  </HeaderIconLink>
                  {withSearch && (
                    <HeaderIconLink
                      href={ROUTE.search}
                      label={ITEM_SEARCH_TITLE}
                      showSp={false}
                    >
                      <SearchOutlined
                        sx={{ fontSize: 32 }}
                        className={clsx('tw-text-primary-500')}
                      />
                    </HeaderIconLink>
                  )}
                  <NextLink href={ROUTE.cart} passHref>
                    <a
                      className={clsx(
                        'tw-inline-flex tw-items-center',
                        'tw-transition tw-duration-stateChange',
                        'hover:tw-opacity-hover'
                      )}
                    >
                      <CartWithCount />
                    </a>
                  </NextLink>
                </>
              )}
            </HeaderColumn>
          </div>
        </MaxWidthLayout>
      </div>
      <GlobalMenu
        isOpen={globalMenu.isOpen}
        isLoading={isLoading}
        isRequirePasswordReset={me?.requirePasswordChange || false}
        showUploadOrder={!!me?.uploadOrder}
        showImportOrder={!!myStore?.importOrder}
        showInvoice={!!me?.invoice}
        showOrder={mode !== 'limit-order' || !isLimitedTerm}
        showOrderHistory={mode !== 'limit-order' || !isLimitedTerm}
        storeName={myStore?.storeName}
        onClickLogout={handleLogout}
        onClickClose={globalMenu.close}
      />
    </>
  )
}
