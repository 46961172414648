import { RadioGroup } from '@headlessui/react'
import clsx from 'clsx'
import * as React from 'react'

import type { InputRadioGroupProps } from './type'

import type { InputValue } from '@/types'

import { GAP_CLASS_NAME } from './const'
import { InputRadioGroupItem } from './InputRadioGroupItem'

export const InputRadioGroup = <T extends InputValue = InputValue>({
  ref,
  id,
  name,
  selected,
  options,
  disabled,
  direction = 'row',
  gap = 'base',
  handleChange,
  handleFocus,
  handleBlur,
}: InputRadioGroupProps<T>) => {
  return (
    <RadioGroup
      ref={ref}
      id={id}
      as="div"
      value={selected}
      onChange={disabled ? () => {} : handleChange}
      onFocus={disabled ? () => {} : handleFocus}
      onBlur={disabled ? () => {} : handleBlur}
      disabled={disabled}
      className={clsx(disabled && 'tw-opacity-30')}
    >
      <RadioGroup.Label className={clsx(['tw-sr-only'])}>
        {name}
      </RadioGroup.Label>
      <div
        className={clsx([
          'tw-flex',
          direction === 'col' && 'tw-flex-col',
          direction === 'row' && 'tw-flex-row tw-flex-wrap tw-items-center',
          direction === 'spCol' &&
            'tw-flex-col lg:tw-flex-row lg:tw-items-center',
          GAP_CLASS_NAME({ direction })[gap],
        ])}
      >
        {options.map((option, index) => (
          <InputRadioGroupItem
            key={`radio-group-item_${name}_${index}_${option.label}`}
            option={option}
          />
        ))}
      </div>
    </RadioGroup>
  )
}

InputRadioGroup.displayName = 'InputRadioGroup'
