import clsx from 'clsx'
import React from 'react'

import type { IconWrapperProps } from './type'

export const IconWrapper = ({ children }: IconWrapperProps) => {
  return (
    <span className={clsx('tw-inline-flex tw-items-center tw-justify-center')}>
      {children}
    </span>
  )
}
