import type { PurchasesResponse } from '@/api/@types'
import type { KeyLabel } from '@/types'

export const PURCHASE_TITLE = 'EC注文取込結果' // NOTE: 'EC注文取込結果' は暫定タイトルのため後に本来の '仕入情報' に戻す想定

export const PURCHASE_FIELDS: KeyLabel<PurchasesResponse> = {
  ecSalesOrderNo: 'EC注文番号',
  importDateTime: '取込日時',
  systemType: 'システム種別',
  shippingDate: '出荷日',
  trackingNos: '送り状番号',
  fileName: 'ファイル名',
  deliveryId: '送付先ID',
  deliveryName: 'お届先名称',
  deliveryAddress: 'お届先都道府県',
  desiredDeliveryDate: '配送希望日',
  desiredDeliveryTime: '配送希望時間',
  scheduledShippingDate: '出荷予定日',
  scheduledArrivalDate: '着荷予定日',
  shippingWarehouse: '出荷倉庫',
  shippingCompany: '運送会社',
  salesOrderNo: '受注番号',
  salesNos: '売上番号',
}
