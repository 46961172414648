import { InputOption } from '@/types'

export const createYearOption: (option?: {
  selectableYearFrom?: number
  selectableYearTo?: number
}) => InputOption<string>[] = (option) => {
  const yearFrom = option?.selectableYearFrom || new Date().getFullYear() - 50
  const yearTo = option?.selectableYearTo || new Date().getFullYear()
  const years = new Array(yearTo - yearFrom + 1).fill(1).map((_, i) => {
    return yearTo - i
  })
  return years.map((year) => {
    return {
      label: year.toString(),
      value: year.toString(),
    }
  })
}

export const MONTH_OPTION: InputOption<string>[] = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,
].map((month) => {
  return {
    label: month.toString(),
    value: month.toString(),
  }
})

export const getDefaultYearMonth = () => {
  const defaultYear = new Date().getFullYear()
  const defaultMonth = new Date().getMonth() + 1
  return {
    defaultYear: defaultYear.toString(),
    defaultMonth: defaultMonth.toString(),
  }
}
