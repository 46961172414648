import { zodResolver } from '@hookform/resolvers/zod'
import clsx from 'clsx'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { ZodType, ZodTypeDef } from 'zod'

import { FormProps } from './type'

export const Form = <
  TFormValues extends Record<string, unknown> = Record<string, unknown>,
  Schema extends ZodType<unknown, ZodTypeDef, unknown> = ZodType<
    unknown,
    ZodTypeDef,
    unknown
  >
>({
  onSubmit,
  children,
  className,
  options,
  id,
  schema,
}: FormProps<TFormValues, Schema>) => {
  const methods = useForm<TFormValues>({
    ...options,
    resolver: schema && zodResolver(schema),
  })
  return (
    <form
      className={clsx(className)}
      onSubmit={methods.handleSubmit(onSubmit)}
      id={id}
    >
      {children(methods)}
    </form>
  )
}
