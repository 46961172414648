import clsx from 'clsx'
import React from 'react'

import type { FavoriteCheckboxListProps } from './type'

import type { FavoriteUniqueString } from '@/utils/features/favorite'

import { FavoriteCheckbox } from '../FavoriteCheckbox'

export const FavoriteCheckboxList = ({
  options = [],
  selected = [],
  isLoading,
  handleChange,
}: FavoriteCheckboxListProps) => {
  const onChange = ({
    favoriteUniqueString,
    value,
  }: {
    favoriteUniqueString: FavoriteUniqueString
    value: boolean
  }) => {
    handleChange({ favoriteUniqueString, checked: value })
  }

  return (
    <ul className={clsx('tw-divide-y tw-divide-gray-300')}>
      {options.map((option) => (
        <li key={`favorite-checkbox-list-item_${option.value}`}>
          <FavoriteCheckbox
            checked={selected.includes(option.value)}
            label={option.label}
            isLoading={isLoading}
            handleChange={(value) =>
              onChange({
                favoriteUniqueString: option.value,
                value,
              })
            }
          />
        </li>
      ))}
    </ul>
  )
}
