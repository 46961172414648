import { useController } from 'react-hook-form'

import type { FormFieldDateRangeProps } from './type'
import type { FieldError } from 'react-hook-form'

import { FormFieldWrapper, InputDateRange } from '@/components/ui'

export const FormFieldDateRange = ({
  label,
  name,
  control,
  error,
  showError,
  showLabel,
  height,
  placeholder,
}: FormFieldDateRangeProps) => {
  const {
    field: { onChange, onBlur, value },
  } = useController({ name, control })

  const errors = [error?.from, error?.to].filter(
    (item): item is FieldError => item !== undefined
  )

  return (
    <FormFieldWrapper
      label={label}
      error={errors}
      showError={showError}
      showLabel={showLabel}
    >
      <InputDateRange
        value={value || {}}
        height={height}
        isError={errors.length > 0}
        placeholder={placeholder}
        handleChange={onChange}
        handleBlur={onBlur}
      />
    </FormFieldWrapper>
  )
}
