import { Add } from '@mui/icons-material'
import clsx from 'clsx'
import React, { useEffect } from 'react'
import { useFieldArray, useController } from 'react-hook-form'

import type { ItemOrderUnitFormFieldProps } from './type'

import { TextButton } from '@/components/ui'
import { useSalesTypesMaster } from '@/stores'

import { ItemOrderUnitFormFieldItem } from './ItemOrderUnitFormFieldItem'
import { extractError, getErrorMessage } from './util'

export const ItemOrderUnitFormField = ({
  control,
  itemIndex,
  disabled,
  isVisiblePurchasePrice = false,
  canRemoveAll = false,
  cartItem,
  register,
  onAfterChange,
  onFocusItemCount,
}: ItemOrderUnitFormFieldProps) => {
  const { salesTypesMaster } = useSalesTypesMaster()

  const { fields, append, remove, replace } = useFieldArray({
    name: `items.${itemIndex}.salesTypeQuantities`,
    control,
  })

  // 再フェッチなどにより cartItem が更新された時に fields に反映する
  useEffect(() => {
    if (!cartItem) return
    // console.log('フィールド更新')
    const items = cartItem.salesTypeQuantities.map((salesTypeQuantity) => ({
      salesType: salesTypeQuantity.salesType,
      quantity: salesTypeQuantity.quantity,
      note: salesTypeQuantity.note,
    }))
    replace(items)
  }, [cartItem, replace])

  const { formState } = useController({
    name: `items.${itemIndex}.salesTypeQuantities`,
    control,
  })

  /** 入力fieldの追加 */
  const appendField = () => {
    append({
      note: '',
      salesType: salesTypesMaster[0].salesTypeCode,
      quantity: 0,
    })
    onAfterChange && onAfterChange()
  }

  /** 入力fieldの削除 */
  const removeField = (unitIndex: number) => {
    remove(unitIndex)
    onAfterChange && onAfterChange()
  }

  return (
    <>
      {fields.map((field, unitIndex) => {
        const error = extractError(formState.errors, itemIndex, unitIndex)
        return (
          <div key={field.id} className={clsx('tw-mb-2')}>
            <ItemOrderUnitFormFieldItem
              itemIndex={itemIndex}
              unitIndex={unitIndex}
              field={field}
              error={extractError(formState.errors, itemIndex, unitIndex)}
              control={control}
              register={register}
              showRemove={canRemoveAll || unitIndex > 0}
              disabled={disabled}
              purchasePrice={
                isVisiblePurchasePrice && cartItem
                  ? cartItem.salesTypeQuantities.find(
                      (s) => s.salesType === field.salesType
                    )?.purchasePrice || undefined
                  : undefined
              }
              onClickRemove={() => removeField(unitIndex)}
              onAfterChange={onAfterChange}
              onFocusItemCount={onFocusItemCount}
            />
            {error && (
              <p
                className={clsx(
                  'tw-min-h-[4px]',
                  'tw-mb-4 tw-mt-1',
                  'tw-whitespace-pre-wrap tw-text-sm tw-font-bold tw-text-alert-500'
                )}
              >
                {getErrorMessage(error)}
              </p>
            )}
          </div>
        )
      })}

      <div
        className={clsx('tw-flex tw-items-center tw-justify-end', 'tw-my-2')}
      >
        <TextButton
          variant={'primary'}
          size={'xs'}
          startIcon={<Add sx={{ fontSize: '14px' }} />}
          onClick={appendField}
          tabIndex={-1}
        >
          売上種別を追加する
        </TextButton>
      </div>
    </>
  )
}
