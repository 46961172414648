import clsx from 'clsx'

import type { BreadcrumbItemContentProps } from './type'

export const BreadcrumbItemContent = ({
  label,
  isLink = false,
}: BreadcrumbItemContentProps) => {
  return (
    <span
      className={clsx(
        'tw-max-w-[160px]',
        'tw-px-1',
        'tw-text-2xs tw-leading-normal',
        'tw-truncate',
        isLink && 'tw-underline'
      )}
    >
      {label}
    </span>
  )
}

BreadcrumbItemContent.displayName = 'BreadcrumbItemContent'
