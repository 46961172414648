import clsx from 'clsx'
import { MouseEventHandler } from 'react'

export type TProps = {
  onClick?: MouseEventHandler<HTMLButtonElement>
}

export const ClearButton = ({ onClick }: TProps) => {
  return (
    <button
      className={clsx(
        'tw-vertical-align-middle',
        'tw-absolute tw-top-0 tw-right-0 tw-flex tw-h-full tw-items-center tw-justify-center',
        'tw-cursor-pointer tw-border-none tw-bg-transparent tw-pr-2 tw-outline-none'
      )}
      onClick={onClick}
    >
      <span
        className={clsx(
          'tw-align-center tw-vertical-align-middle',
          'tw-block tw-h-4 tw-w-4',
          'tw-cursor-pointer tw-rounded-full tw-bg-gray-400 tw-text-sm tw-leading-none tw-text-white'
        )}
      >
        ×
      </span>
    </button>
  )
}
