import clsx from 'clsx'
import { useCallback } from 'react'

import type { CategoryPanelProps } from './type'

import type { Category } from '@/api/@types'
import { CategoryDisclosure } from '@/components/features/category'

export const CategoryPanel = ({
  categories,
  handleSelect,
}: CategoryPanelProps) => {
  const handleClick = useCallback(
    (categoryId: Category['categoryId']) => handleSelect(categoryId),
    [handleSelect]
  )
  return (
    <div>
      <ul>
        {categories.map((category) => (
          <li
            key={category.categoryId}
            className={clsx('tw-border-b tw-border-gray-300')}
          >
            <CategoryDisclosure category={category} onClick={handleClick} />
          </li>
        ))}
      </ul>
    </div>
  )
}
