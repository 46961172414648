import type { InputOption } from '@/types'

export const isSelected = <T extends string | number = string | number>(
  target: InputOption<T>,
  options?: InputOption<T>[]
) => {
  return !!options && !!options.find((option) => option.value === target.value)
}

export const getButtonLabel = (
  optionsLength: number,
  selectedLength: number,
  defaultLabel: string
) => {
  if (optionsLength === 0) {
    return '-'
  }

  if (optionsLength !== 0 && selectedLength === 0) {
    return defaultLabel
  }

  return optionsLength - 1 === selectedLength
    ? 'すべて'
    : `${selectedLength}件選択中`
}
