import clsx from 'clsx'

export const variants = {
  primary: clsx(
    'tw-text-primary-500',
    'hover:tw-text-primary-400',
    'focus:tw-text-primary-700',
    'disabled:tw-text-primary-200'
  ),
  tertiary: clsx(
    'tw-text-accentBlue-500',
    'hover:tw-text-accentBlue-400',
    'focus:tw-text-accentBlue-700',
    'disabled:tw-text-accentBlue-500 disabled:tw-opacity-30'
  ),
  alert: clsx(
    'tw-text-alert-500',
    'hover:tw-text-alert-400',
    'focus:tw-text-alert-700',
    'disabled:tw-text-alert-50'
  ),
}

export const sizes = {
  md: 'tw-text-base',
  sm: 'tw-text-sm',
  xs: 'tw-text-xs',
}
