import clsx from 'clsx'
import * as React from 'react'

import type { ButtonProps } from './type'

import { Spinner } from '@/components/ui'

import { variants, sizes, round } from './const'

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      name,
      type = 'button',
      disabled = false,
      variant = 'primary',
      size = 'lg',
      roundType = 'md',
      roundDirection = {
        topLeft: true,
        topRight: true,
        bottomLeft: true,
        bottomRight: true,
      },
      isLoading = false,
      isBlock = true,
      isCircle = false,
      startIcon,
      endIcon,
      tabIndex,
      onClick,
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        name={name}
        type={type}
        disabled={disabled}
        className={clsx(
          'tw-font-bold',
          'tw-transition-all tw-duration-300',
          'focus:tw-outline-none',
          'disabled:tw-cursor-not-allowed',
          'tw-relative',
          isBlock && 'tw-w-full',
          variants[variant],
          sizes[size].height,
          isCircle
            ? ['tw-overflow-hidden tw-rounded-full', sizes[size].width]
            : ['tw-px-2', round(roundType, roundDirection)]
        )}
        onClick={onClick}
        tabIndex={tabIndex}
      >
        {isLoading && (
          <>
            <span
              className={clsx(
                'tw-absolute',
                'tw-left-1/2 tw-top-0 -tw-translate-x-1/2',
                'tw-transition-all',
                'tw-grid tw-h-full tw-place-items-center'
              )}
            >
              <Spinner variant={variant} size="sm" />
            </span>
          </>
        )}
        <div
          className={clsx(
            'tw-gap-2',
            'tw-flex tw-items-center tw-justify-center',
            isLoading && 'tw-pointer-events-none tw-opacity-0'
          )}
        >
          {startIcon}
          <span
            className={clsx(
              'tw-inline-flex tw-items-center',
              sizes[size].fontSize
            )}
          >
            {children}
          </span>
          {endIcon}
        </div>
      </button>
    )
  }
)

Button.displayName = 'Button'
