import clsx from 'clsx'

import { InputToggleProps } from './type'

export const InputToggle = ({
  checked,
  disabled = false,
  onChange,
}: InputToggleProps) => {
  const handleToggle = () => onChange(!checked)
  return (
    <button
      className={clsx(
        'tw-h-5 tw-w-11',
        'tw-rounded-full',
        'disabled:tw-opacity-disabled',
        disabled ? 'tw-cursor-not-allowed' : 'tw-cursor-pointer',
        checked ? 'tw-bg-primary-100' : 'tw-bg-gray-300'
      )}
      disabled={disabled}
      onClick={handleToggle}
    >
      <div
        className={clsx(
          'tw-h-5 tw-w-5',
          'tw-rounded-full',
          'tw-shadow',
          'tw-transition-all',
          checked ? 'tw-translate-x-6' : 'tw-translate-x-0',
          checked ? 'tw-bg-primary-500' : 'tw-bg-white'
        )}
      />
    </button>
  )
}
