import { zodResolver } from '@hookform/resolvers/zod'
import { Delete } from '@mui/icons-material'
import clsx from 'clsx'
import { useForm, useWatch } from 'react-hook-form'

import {
  ItemOrderUnitFormField,
  ItemPrice,
  ItemThumbnailBannar,
} from '@/components/features/item'
import { Button, TextButton } from '@/components/ui'

import { EDIT_MODAL_FORM_ITEM_INDEX, schema } from './const'
import { CartItemFormVO, FormProps } from './type'
import { convertCartItemToFormValues } from './util'

export const Form = ({
  cartItem,
  isVisiblePurchasePrice,
  disabled,
  isSubmittingEdit,
  isSubmittingDelete,
  onSubmit,
  onDelete,
  onClose,
}: FormProps) => {
  const { register, control, formState, handleSubmit } =
    useForm<CartItemFormVO>({
      defaultValues: convertCartItemToFormValues(cartItem),
      resolver: zodResolver(schema),
      mode: 'onChange',
    })

  const salesTypeQuantities = useWatch({
    control,
    name: `items.${EDIT_MODAL_FORM_ITEM_INDEX}.salesTypeQuantities`,
  })

  const handleUpdate = (data: CartItemFormVO) => {
    onSubmit(data.items)
  }

  const handleDelete = () => {
    onDelete(cartItem)
  }

  return (
    <>
      <ItemThumbnailBannar item={cartItem.item} />
      <div className={clsx('tw-mt-2 tw-w-full')}>
        <ItemPrice
          item={cartItem.item}
          cartSalesTypeQuantities={salesTypeQuantities}
          direction={'vertical'}
        />
        <TextButton
          variant={'alert'}
          size={'xs'}
          startIcon={<Delete sx={{ fontSize: '16px' }} />}
          isBlock={false}
          isLoading={isSubmittingDelete}
          onClick={handleDelete}
          disabled={disabled || isSubmittingEdit}
        >
          削除
        </TextButton>
      </div>
      <div
        className={clsx('tw-mt-2 tw-pt-4', 'tw-border-t tw-border-gray-300')}
      >
        <ItemOrderUnitFormField
          control={control}
          register={register}
          itemIndex={EDIT_MODAL_FORM_ITEM_INDEX}
          isVisiblePurchasePrice={isVisiblePurchasePrice}
          canRemoveAll
          disabled={disabled}
          cartItem={cartItem}
        />
      </div>
      <div
        className={clsx(
          'tw-mt-1 tw-flex tw-items-center',
          'tw-space-x-2 tw-py-4'
        )}
      >
        <Button type="button" variant="normal" onClick={onClose}>
          閉じる
        </Button>
        <Button
          type="button"
          onClick={handleSubmit(handleUpdate)}
          isLoading={formState.isSubmitting || isSubmittingEdit}
          disabled={!formState.isValid || isSubmittingDelete}
        >
          保存する
        </Button>
      </div>
    </>
  )
}
