import { useMutation } from 'react-query'

import { Methods } from '@/api/v1/favorites'
import { aspidaClient } from '@/lib/aspida'
import { MutationConfig } from '@/lib/react-query'

export type CreateFavoriteDTO = {
  data: Methods['post']['reqBody']
}

export const createFavorite = ({ data }: CreateFavoriteDTO) => {
  return aspidaClient.v1.favorites.$post({ body: data })
}

type UseCreateFavoriteOptions = {
  config?: MutationConfig<typeof createFavorite>
}

export const useCreateFavorite = ({ config }: UseCreateFavoriteOptions) => {
  return useMutation({
    ...config,
    mutationFn: createFavorite,
  })
}
