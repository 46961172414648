import '../styles/globals.css'
import Head from 'next/head'
import React from 'react'

import type { AppProps } from 'next/app'

import { MaintenanceDetection } from '@/components/layout'
import { queryClient } from '@/lib/react-query'
import { AppProvider } from '@/providers/app'
import { GoogleAnalyticsProvider } from '@/providers/ga'

if (process.env.NEXT_PUBLIC_API_MOCKING === 'true') {
  require('../test/server/setup')
}

function MyApp({ Component, pageProps }: AppProps) {
  if (typeof window === 'undefined') return <></>
  return (
    <>
      <Head>
        <meta name="robots" content="noindex" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1.0,maximum-scale=1.0"
        />
        <title>Altair</title>
        <link rel="apple-touch-icon" href="/apple-touch-icon.png"></link>
      </Head>
      <AppProvider queryClient={queryClient}>
        <GoogleAnalyticsProvider>
          <MaintenanceDetection>
            <Component {...pageProps} />
          </MaintenanceDetection>
        </GoogleAnalyticsProvider>
      </AppProvider>
    </>
  )
}

export default MyApp
