import clsx from 'clsx'

import { Spinner } from '@/components/ui'

import { LoadingPartialProps } from './type'

export const LoadingPartial = ({ isLoading = true }: LoadingPartialProps) => {
  return isLoading ? (
    <div
      className={clsx(
        'tw-text-gray-200 tw-z-loadingOverlay tw-flex tw-items-center tw-justify-center'
      )}
    >
      <Spinner variant="normal" />
    </div>
  ) : null
}
