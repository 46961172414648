import * as z from 'zod'

export const MODES = ['both', 'sales-type', 'quantity'] as const

export const modeOptions = [
  { label: '両方', value: MODES[0] },
  { label: '販売種別', value: MODES[1] },
  { label: '数量', value: MODES[2] },
]

const MIN_LENGTH = {
  quantity: 0,
}
const MAX_LENGTH = {
  quantity: 999,
  note: 20,
}

export const schema = z.object({
  salesType: z.string().nonempty(),
  quantity: z
    .number({ invalid_type_error: '必須項目です' })
    .min(MIN_LENGTH.quantity, `${MIN_LENGTH.quantity}未満です`)
    .max(MAX_LENGTH.quantity, `${MAX_LENGTH.quantity}を超えています`),
})
