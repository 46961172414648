import { useMutation } from 'react-query'

import { Methods } from '@/api/v1/change-email'
import { userKeyFactory } from '@/hooks/features/user/key'
import { aspidaClient } from '@/lib/aspida'
import { MutationConfig, queryClient } from '@/lib/react-query'

export type ChangeEmailDTO = {
  data: Methods['put']['reqBody']
}

export const changeEmail = ({ data }: ChangeEmailDTO) => {
  return aspidaClient.v1.change_email.$put({ body: data })
}

type UseChangeEmailOptions = {
  config?: MutationConfig<typeof changeEmail>
}

export const useChangeEmail = ({ config }: UseChangeEmailOptions) => {
  return useMutation({
    ...config,
    mutationFn: changeEmail,
    onSuccess: () => {
      queryClient.invalidateQueries(userKeyFactory.me())
    },
  })
}
