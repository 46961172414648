import { Delete } from '@mui/icons-material'
import clsx from 'clsx'
import { useMemo } from 'react'

import type { ItemOrderCardProps } from './type'

import { ItemErrorLabel, ItemThumbnailBannar } from '@/components/features/item'
import { Button, InputCheckbox, TextButton } from '@/components/ui'
import { ITEM_CARD_STATUS_LABEL } from '@/const'

import { ItemOrderCartPrice } from './ItemOrderCardPrice'

export const ItemOrderCard = ({
  item,
  salesTypeQuantities,
  selectable = false,
  selected = false,
  disableEdit = false,
  disableDelete = false,
  isLoadingDelete = false,
  isVisiblePurchasePrice = false,
  onClickDetail = () => {},
  onClickEdit = () => {},
  onClickDelete = () => {},
  onSelect = () => {},
}: ItemOrderCardProps) => {
  const itemStatus = useMemo(() => {
    return {
      label: ITEM_CARD_STATUS_LABEL[item.itemStatus],
      available: item.itemStatus === 'available',
    }
  }, [item.itemStatus])

  return (
    <>
      <div
        className={clsx(
          'tw-px-2 tw-py-1',
          'tw-rounded tw-border tw-border-gray-300'
        )}
      >
        <div className={clsx('tw-w-full', 'tw-flex', 'tw-gap-2')}>
          {selectable && (
            <div
              className={clsx(
                'tw-flex-none',
                'tw-px-2',
                'tw-flex tw-items-center'
              )}
            >
              <InputCheckbox
                checked={!!selected}
                label={item.itemName}
                handleChange={onSelect}
              />
            </div>
          )}
          <div
            className={clsx(
              'tw-w-full',
              'tw-flex tw-flex-col',
              'lg:tw-flex-row lg:tw-justify-between',
              'tw-gap-2',
              'tw-py-1'
            )}
          >
            <div className={clsx('tw-w-full lg:tw-w-1/2')}>
              <ItemThumbnailBannar
                item={item}
                nameFooter={
                  <>
                    <ItemErrorLabel status={item.itemStatus} />
                  </>
                }
              />
            </div>
            <div className={clsx('tw-flex', 'tw-flex-1', 'tw-gap-x-3')}>
              <div className={clsx('tw-flex-1')}>
                <ItemOrderCartPrice
                  item={item}
                  cartSalesTypeQuantities={
                    selectable ? undefined : salesTypeQuantities
                  }
                  direction={'vertical'}
                  showCount={!selectable}
                  showPurchasePrice={isVisiblePurchasePrice}
                />
                {/** Note: 選択モードの時、各ボタンを見せない */}
                {!selectable && (
                  <>
                    <TextButton
                      variant={'alert'}
                      /**
                       * Note:
                       * 商品が利用不可の場合、いずれの場合も削除可能とする
                       * 利用可能な場合は全体の編集フラグによって出し分ける
                       */
                      disabled={disableDelete && itemStatus.available}
                      onClick={onClickDelete}
                      isLoading={isLoadingDelete}
                    >
                      <Delete sx={{ fontSize: 14 }} />
                      <p>削除</p>
                    </TextButton>
                  </>
                )}
              </div>
              {/** Note: 選択モードもしくは商品が利用不可の場合、各ボタンを見せない */}
              {!selectable && itemStatus.available && (
                <div
                  className={clsx('tw-flex tw-flex-col', 'tw-gap-1', 'tw-w-20')}
                >
                  <Button
                    size={'sm'}
                    variant={'secondary'}
                    onClick={onClickDetail}
                  >
                    詳細
                  </Button>
                  <Button
                    size={'sm'}
                    variant={'secondary'}
                    onClick={onClickEdit}
                    disabled={disableEdit}
                  >
                    編集
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
