import { useMutation } from 'react-query'

import { Methods } from '@/api/v1/favorites/items'
import { aspidaClient } from '@/lib/aspida'
import { queryClient } from '@/lib/react-query'
import { MutationConfig } from '@/lib/react-query'

import { favoriteKeyFactory } from './key'

export type UpdateFavoritesDTO = {
  data: Methods['put']['reqBody']
}

export const updateFavorites = ({ data }: UpdateFavoritesDTO) => {
  return aspidaClient.v1.favorites.items.$put({ body: data })
}

type UseUpdateFavoritesOptions = {
  config?: MutationConfig<typeof updateFavorites>
}

export const useUpdateFavorites = ({ config }: UseUpdateFavoritesOptions) => {
  return useMutation({
    onSuccess: () => queryClient.invalidateQueries(favoriteKeyFactory.list()),
    ...config,
    mutationFn: updateFavorites,
  })
}
