export const formatCurrency = (value?: string) => {
  if (!value) {
    return ''
  }
  const converted = value
    .replace(/[０-９．]/g, (s) => String.fromCharCode(s.charCodeAt(0) - 0xfee0)) // 半角の数字とドットに置換
    .replace(/[−ー]/, '-') // 半角ハイフンに置換
    .replace(/^0+(?!\.|$)/, '') // 接頭の0埋めを削除

  const nonNumericRegExp = /[^0-9.-]/g

  const numeric = converted.replace(nonNumericRegExp, '') // 非数値・ドット・ハイフンを削除
  const [integerPart, decimalPart] = numeric.split('.')
  const commaed = integerPart.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') // 3桁ごとにカンマ挿入
  if (!decimalPart) {
    return commaed
  }
  const excludedEndZero = decimalPart.replace(/0+$/, '')
  if (excludedEndZero.length === 0) {
    return commaed
  }
  return [commaed, excludedEndZero].join('.')
}
