import { Switch } from '@headlessui/react'
import { AddCircle, RemoveCircleOutline } from '@mui/icons-material'
import clsx from 'clsx'
import * as React from 'react'

import { InputPlusStyleCheckboxProps } from './type'

export const InputPlusStyleCheckbox = React.forwardRef<
  HTMLDivElement,
  InputPlusStyleCheckboxProps
>(
  (
    {
      id,
      disabled,
      checked,
      label,
      showLabel,
      handleChange,
      handleFocus,
      handleBlur,
    },
    ref
  ) => {
    return (
      <Switch.Group
        ref={ref}
        id={id}
        as="div"
        className={clsx(['tw-flex tw-items-center tw-justify-start'])}
      >
        <Switch
          as="div"
          checked={checked}
          onChange={disabled ? () => {} : handleChange}
          onFocus={disabled ? () => {} : handleFocus}
          onBlur={disabled ? () => {} : handleBlur}
          className={clsx([
            'tw-flex tw-items-center',
            'tw-text-primary-500 hover:tw-text-primary-400 focus:tw-text-primary-700',
            disabled
              ? ['tw-cursor-not-allowed tw-opacity-70']
              : ['tw-cursor-pointer'],
          ])}
        >
          <div
            className={clsx(
              'tw-h-5 tw-w-5',
              'tw-inline-flex tw-items-center tw-justify-center',
              'tw-outline-none'
            )}
          >
            <span
              className={clsx(
                'tw-h-full tw-w-full',
                'tw-inline-flex tw-items-center tw-justify-center',
                'tw-rounded-full'
              )}
            >
              {checked ? (
                <RemoveCircleOutline
                  sx={{ fontSize: 24 }}
                  className={clsx('tw-h-full tw-w-full')}
                />
              ) : (
                <AddCircle
                  sx={{ fontSize: 24 }}
                  className={clsx('tw-h-full tw-w-full')}
                />
              )}
            </span>
          </div>

          <p
            className={clsx([
              showLabel ? ['tw-ml-1', 'tw-text-sm'] : ['tw-sr-only'],
            ])}
          >
            {label}
          </p>
        </Switch>
      </Switch.Group>
    )
  }
)

InputPlusStyleCheckbox.displayName = 'InputPlusStyleCheckbox'
