import { useCallback, useEffect, useState } from 'react'

import { range } from '@/utils/array'

import { useCache, useChildren, useResizeObserver } from './hooks'
import { ListItem } from './ListItem'
import { WindowVirtualizerProps } from './type'

const useRerender = () => {
  const [_, rerender] = useState(0)
  return () => rerender((prev) => prev + 1)
}

export const WindowVirtualizer = ({
  children,
  total = 100,
  defaultSize = 100,
  spacer = 400,
  overscan = 4,
  onChangeRange,
}: WindowVirtualizerProps) => {
  const rerender = useRerender()
  const [getElement] = useChildren(children, total)
  const {
    init,
    setItemSize,
    getItemOffset,
    getTotalSize,
    getRange,
    getDirection,
    isOver,
    isCached,
  } = useCache()
  const { start, end } = getRange()
  const { totalSize, diff } = getTotalSize()
  //console.log({ start, end })
  const resizer = useResizeObserver({
    onResize: (map) => {
      Array.from(map.values()).forEach(({ index, rect }) => {
        if (rect.height === 0) {
          return
        }
        //console.log(`setSize: ${index} ${rect.height}`)
        setItemSize(index, rect.height)
        rerender()
      })
    },
  })

  const handleScroll = useCallback(() => {
    if (isOver(start)) {
      //console.log('over')
      rerender()
    }
  }, [start])

  useEffect(() => {
    init({
      total,
      defaultSize,
      spacer,
      overscan,
    })
    rerender()

    return () => {
      resizer.dispose()
    }
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  if (onChangeRange) {
    onChangeRange(start, end)
  }

  /**
   * 全体サイズが変わった場合にスクロール位置を調整する
   * 上側にスクロールしている場合のみ調整する
   * 下側にスクロールしている場合は、調整せずとも位置が補正されるため、何もしない
   */
  if (diff !== 0 && getDirection() === 'up') {
    window.scrollBy(0, diff)
  }

  return (
    <div style={{ height: totalSize, width: '100%', position: 'relative' }}>
      {range(start, end + 1).map((i) => (
        <ListItem
          key={`virtualizer-${i}`}
          index={i}
          resizer={resizer}
          visible={isCached(i)}
          top={getItemOffset(i)}
        >
          {getElement(i)}
        </ListItem>
      ))}
    </div>
  )
}
