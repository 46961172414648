import clsx from 'clsx'

import type { FavoriteListFloatingFooterProps } from './type'

import { ActionFloatingFooter } from '@/components/layout'
import { Button } from '@/components/ui'
import { FAVORITE_TITLE } from '@/const'

export const FavoriteListFloatingFooter = ({
  isLoading,
  onClickUpdate,
  onCancel,
}: FavoriteListFloatingFooterProps) => {
  return (
    <ActionFloatingFooter>
      <div
        className={clsx(
          'tw-w-full',
          'tw-grid tw-grid-cols-5 tw-items-center lg:tw-grid-cols-2',
          'tw-gap-2 lg:tw-gap-4'
        )}
      >
        <div className={clsx('tw-col-span-2 lg:tw-col-span-1')}>
          <Button
            variant={'secondary'}
            isBlock={true}
            disabled={isLoading}
            onClick={onCancel}
          >
            {'閉じる'}
          </Button>
        </div>
        <div className={clsx('tw-col-span-3 lg:tw-col-span-1')}>
          <Button
            variant={'primary'}
            isBlock={true}
            isLoading={isLoading}
            onClick={onClickUpdate}
          >
            {`${FAVORITE_TITLE}を更新する`}
          </Button>
        </div>
      </div>
    </ActionFloatingFooter>
  )
}
