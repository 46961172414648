import { ChevronRightOutlined } from '@mui/icons-material'
import clsx from 'clsx'
import NextLink from 'next/link'

import type { RowProps } from './type'

const RowContent = ({ title, value, href, onClick, error }: RowProps) => {
  return (
    <>
      <div
        className={clsx(
          'tw-flex tw-min-h-[44px] tw-items-center tw-justify-between',
          (href !== undefined || onClick !== undefined) &&
            'tw-cursor-pointer tw-transition tw-duration-stateChange hover:tw-opacity-hover'
        )}
        onClick={onClick}
      >
        <p className={clsx('tw-text-sm')}>{title}</p>
        <div className={clsx('tw-flex tw-items-center tw-justify-center')}>
          <span
            className={clsx('tw-text-right tw-text-sm', 'tw-flex tw-flex-col')}
          >
            {value}
          </span>
          {href !== undefined && (
            <ChevronRightOutlined
              sx={{ fontSize: 24 }}
              className={clsx('tw--mr-2 tw-text-gray-700')}
            />
          )}
        </div>
      </div>
      {error && (
        <div
          className={clsx('tw-pb-2 tw-text-right tw-text-sm tw-text-alert-500')}
        >
          {error}
        </div>
      )}
    </>
  )
}

export const Row = ({ title, value, href, onClick, error }: RowProps) => {
  return href !== undefined ? (
    <NextLink href={href} passHref>
      <a>
        <RowContent
          title={title}
          value={value}
          href={href}
          error={error}
          onClick={onClick}
        />
      </a>
    </NextLink>
  ) : (
    <RowContent title={title} value={value} onClick={onClick} error={error} />
  )
}
