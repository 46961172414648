import clsx from 'clsx'

import type { FavoriteCardOperationListItemProps } from './type'

import { IconWrapper } from '@/components/ui'

export const FavoriteCardOperationListItem = ({
  icon,
  text,
  onClick,
}: FavoriteCardOperationListItemProps) => {
  return (
    <li>
      <button
        className={clsx(
          'tw-h-8 tw-w-full',
          'tw-flex tw-items-center',
          'tw-gap-2',
          'tw-px-2',
          'tw-bg-white hover:tw-bg-gray-100',
          'tw-transition tw-duration-stateChange'
        )}
        onClick={onClick}
      >
        <span
          className={clsx(
            'tw-inline-flex tw-justify-center',
            'tw-text-gray-600'
          )}
        >
          <IconWrapper>{icon}</IconWrapper>
        </span>
        <span className={clsx('tw-text-xs')}>{text}</span>
      </button>
    </li>
  )
}
