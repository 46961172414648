import { useQuery } from 'react-query'

import { aspidaClient } from '@/lib/aspida'
import { QueryConfig } from '@/lib/react-query'

import { itemKeyFactory } from './key'

export const getItemsCount = ({
  queryParams,
}: Parameters<typeof itemKeyFactory.itemsCount>[0]) => {
  return aspidaClient.v1.items.count.$get({
    query: queryParams,
  })
}

export type UseItemsCountOptions = Parameters<
  typeof itemKeyFactory.itemsCount
>[0] & {
  config?: QueryConfig<typeof getItemsCount>
}

export const useItemsCount = ({
  config,
  queryParams,
}: UseItemsCountOptions) => {
  const queryConfig: QueryConfig<typeof getItemsCount> = {
    ...config,
    queryKey: itemKeyFactory.itemsCount({ queryParams }),
    queryFn: () => getItemsCount({ queryParams }),
  }
  return useQuery(queryConfig)
}
