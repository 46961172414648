import clsx from 'clsx'
import { forwardRef } from 'react'

import type { InputTextareaProps } from './type'

import {
  baseClassNames,
  errorClassNames,
  widths,
  texts,
  rounds,
} from '../const'

export const InputTextarea = forwardRef<
  HTMLTextAreaElement,
  InputTextareaProps
>(
  (
    {
      id,
      name,
      value,
      disabled = false,
      placeholder,
      rounded = 'all',
      rows,
      minLength,
      maxLength,
      tabIndex,
      isError = false,
      onChange,
      onClick,
      onFocus,
      onBlur,
    },
    ref
  ) => {
    return (
      <textarea
        ref={ref}
        id={id}
        name={name}
        value={value}
        rows={rows}
        minLength={minLength}
        maxLength={maxLength}
        disabled={disabled}
        placeholder={placeholder}
        className={clsx(
          baseClassNames,
          'tw-resize-none tw-py-2',
          isError && !disabled && errorClassNames,
          widths['full'],
          texts.lg,
          rounds[rounded]
        )}
        tabIndex={disabled ? -1 : tabIndex}
        onChange={onChange}
        onClick={onClick}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    )
  }
)

InputTextarea.displayName = 'InputTextarea'
