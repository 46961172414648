import { useMutation } from 'react-query'

import { Methods } from '@/api/v1/cart'
import { aspidaClient } from '@/lib/aspida'
import { queryClient } from '@/lib/react-query'
import { MutationConfig } from '@/lib/react-query'

import { cartKeyFactory } from './key'

export type UpdateCartDTO = {
  data: Methods['put']['reqBody']
}

export const updateCart = ({ data }: UpdateCartDTO) => {
  return aspidaClient.v1.cart.$put({ body: data })
}

type UseUpdateCartOptions = {
  config?: MutationConfig<typeof updateCart>
}

export const useUpdateCart = ({ config }: UseUpdateCartOptions) => {
  return useMutation({
    onSuccess: () => queryClient.invalidateQueries(cartKeyFactory.itemCount()),
    ...config,
    mutationFn: updateCart,
  })
}
