import { KeyboardArrowRight } from '@mui/icons-material'
import clsx from 'clsx'
import React from 'react'

import type { CategoryDisclosureProps } from './type'

import { Disclosure, IconWrapper } from '@/components/ui'
import { useDisclosure } from '@/hooks/util'
import { getMargedCategoryItems } from '@/utils/features/category'

export const CategoryDisclosure = ({
  category,
  onClick,
}: CategoryDisclosureProps) => {
  const { isOpen, toggle } = useDisclosure()

  // NOTE: サブカテゴリーだけでなく、大分類の「すべて」も表示する
  const subCategories = getMargedCategoryItems(category)

  return (
    <Disclosure
      isOpen={isOpen}
      headerClassName={'tw-bg-primary-50 tw-text-sm tw-font-bold'}
      withSpace={{
        title: true,
        content: false,
      }}
      toggle={toggle}
      heading={
        <span className={clsx('text-sm tw-font-bold')}>
          {category.categoryName}
        </span>
      }
    >
      <ul className={clsx('tw-ml-4')}>
        {subCategories.map((subCategory) => (
          <li
            key={`${category.categoryId}-${subCategory.categoryId}`}
            className={clsx('tw-h-11', 'tw-border-b tw-border-gray-300')}
          >
            <button
              className={clsx(
                'tw-h-full tw-w-full',
                'tw-flex tw-items-center tw-justify-between',
                'tw-pr-4',
                'tw-cursor-pointer',
                'tw-transition tw-duration-stateChange',
                'hover:tw-opacity-hover'
              )}
              onClick={() => onClick(subCategory.categoryId)}
            >
              <span
                className={clsx('tw-text-left tw-text-sm', 'tw-line-clamp-2')}
              >
                {subCategory.categoryName}
              </span>
              <IconWrapper>
                <KeyboardArrowRight
                  sx={{ size: 24 }}
                  className={clsx('tw-text-gray-700')}
                />
              </IconWrapper>
            </button>
          </li>
        ))}
      </ul>
    </Disclosure>
  )
}
