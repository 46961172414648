import * as z from 'zod'

import type { UpdateFavoriteListTitleFormVO } from './type'

import type { InputOption } from '@/types'

const MAX_LENGTH = {
  name: 50,
  salesTypeCodes: 10,
}

export const schema = z.object({
  name: z
    .string()
    .nonempty('必須入力です')
    .max(MAX_LENGTH.name, `最大文字数は${MAX_LENGTH.name}文字です`),
  isShared: z.boolean(),
})

export const formLabels: Record<keyof UpdateFavoriteListTitleFormVO, string> = {
  name: 'リストの名称',
  isShared: '他のサロンへの共有',
}

export const DEFAULT_SELECTED_SALES_TYPE = '1'

export const formDefaultValues: Partial<UpdateFavoriteListTitleFormVO> = {
  name: undefined,
  isShared: false,
}

export const isSharedOptions: InputOption<boolean>[] = [
  {
    value: true,
    label: '共有する',
  },
  {
    value: false,
    label: '共有しない',
  },
]
