import { Listbox } from '@headlessui/react'
import clsx from 'clsx'
import * as React from 'react'

import type { InputSelectLabelProps } from './type'

export const InputSelectLabel = React.forwardRef<
  HTMLLabelElement,
  InputSelectLabelProps
>(({ label }, ref) => {
  return (
    <Listbox.Label
      ref={ref}
      className={clsx(['tw-invisible tw-absolute tw-h-0 tw-w-0'])}
    >
      {label}
    </Listbox.Label>
  )
})

InputSelectLabel.displayName = 'InputSelectLabel'
