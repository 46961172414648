import {
  CART_TITLE,
  SHIPPING_ADDRESS_TITLE,
  CART_FIELDS,
  FAVORITE_TITLE,
  FAVORITE_TITLE_ALIAS,
  ITEM_TITLE,
  ORDER_TITLE,
} from '@/const/label'

const ACTIONS = {
  CREATE: '作成',
  ADD: '追加',
  DELETE: '削除',
  UPDATE: '更新',
  COPY: '複製',
  MOVE: '移動',
  APPROVE: '承認',
  REVOKE: '取消',
  CANCEL: 'キャンセル',
}

export const NOTIFICATION_MESSAGE = {
  AUTH: {
    UPDATE_PASSWORD: `パスワードを${ACTIONS.UPDATE}しました`,
  },
  FAVORITE: {
    CREATE: `${FAVORITE_TITLE_ALIAS}を${ACTIONS.CREATE}しました`,
    UPDATE_SETTING: `${FAVORITE_TITLE_ALIAS}の設定を${ACTIONS.UPDATE}しました`,
    DELETE: `${FAVORITE_TITLE_ALIAS}を${ACTIONS.DELETE}しました`,
    COPY: `${FAVORITE_TITLE_ALIAS}を${ACTIONS.COPY}しました`,
    SORT: `${FAVORITE_TITLE_ALIAS}の並び順を${ACTIONS.UPDATE}しました`,
  },
  FAVORITE_ITEMS: {
    SORT: `${FAVORITE_TITLE}${ITEM_TITLE}の並び順を${ACTIONS.UPDATE}しました`,
    COPY: `選択された${FAVORITE_TITLE}${ITEM_TITLE}を${ACTIONS.COPY}しました`,
    MOVE: `選択された${FAVORITE_TITLE}${ITEM_TITLE}を${ACTIONS.MOVE}しました`,
    DELETE: `選択された${FAVORITE_TITLE}${ITEM_TITLE}を${ACTIONS.DELETE}しました`,
    ADD_CART: `選択された${FAVORITE_TITLE}${ITEM_TITLE}を${CART_TITLE}に${ACTIONS.ADD}しました`,
  },
  CART: {
    UPDATE_SHIPPING_INFO: `${SHIPPING_ADDRESS_TITLE}を${ACTIONS.UPDATE}しました`,
    UPDATE_DELIVERY_DATE: `${CART_FIELDS.desiredDeliveryDate}を${ACTIONS.UPDATE}しました`,
    CLEAR: `${CART_TITLE}を空にしました`,
    REMOVE_ITEM: `${CART_TITLE}から${ITEM_TITLE}を${ACTIONS.DELETE}しました`,
    OVER_DISIRED_DERIVERY_DATE:
      'お届け可能な納品日が更新されました。カートを再表示します',
  },
  USER: {
    UPDATE_EMAIL_SUBSCRIPTION: `メール受信設定を${ACTIONS.UPDATE}しました`,
  },
  ORDER: {
    APPROVE: `${ORDER_TITLE}を${ACTIONS.APPROVE}しました`,
    BULK_APPROVE: `${ORDER_TITLE}を一括${ACTIONS.APPROVE}しました`,
    REVOKE_APPROVE: `${ORDER_TITLE}の${ACTIONS.APPROVE}を${ACTIONS.REVOKE}しました`,
    BULK_REVOKE_APPROVE: `${ORDER_TITLE}の${ACTIONS.APPROVE}を一括${ACTIONS.REVOKE}しました`,
    CANCEL: `${ORDER_TITLE}を${ACTIONS.CANCEL}しました`,
    BULK_CANCEL: `${ORDER_TITLE}を一括${ACTIONS.CANCEL}しました`,
    RETURN_TO_CART: `${ORDER_TITLE}を${CART_TITLE}に戻しました`,
    UPDATE_DELIVERY_DATE: `${CART_FIELDS.desiredDeliveryDate}を${ACTIONS.UPDATE}しました`,
    UPDATE_SHIPPING_INFO: `${SHIPPING_ADDRESS_TITLE}を${ACTIONS.UPDATE}しました`,
    UPDATE_DELIVERY_DATE_FAILED: `${CART_FIELDS.desiredDeliveryDate}を${ACTIONS.UPDATE}できませんでした`,
    UPDATE_ITEM_FAILED: (orderNo: string) =>
      ` 発注番号（${orderNo}）の商品を${ACTIONS.UPDATE}できませんでした`,
    BULK_CANCEL_FAILED: (orderNos: string[]) =>
      `発注番号（${orderNos.join('・')}）の発注をキャンセルできませんでした`,
  },
  ORDER_IMPORT: {
    DETAIL_FAILED_TITLE: '詳細を取得できませんでした',
    DOWNLOAD_FAILED_TITLE: 'ダウンロードできませんでした',
  },
  PURCHASED_INFO: {
    DOWNLOAD_FAILED_TITLE: 'ダウンロードできませんでした',
  },
  DOWNLOAD: {
    DOWNLOAD_FAILED_TITLE: 'ダウンロードできませんでした',
  },
}
