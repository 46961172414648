import { useMemo } from 'react'

import type { AddFavoritesProps } from './type'

import { ERROR_FIELD_NAMES } from '@/const'
import { useUpdateFavoriteItems } from '@/hooks/features/favorite'
import type { FavoriteUniqueString } from '@/utils/features/favorite'
import {
  getFavoriteUniqueString,
  getFavoriteUniqueValues,
} from '@/utils/features/favorite'
import { getErrorMessages } from '@/utils/text'

import { FavoriteCheckboxList } from './FavoriteCheckboxList'

export const AddFavorites = ({
  favorites,
  selectedItemCodes,
  handleChange,
  handleErrorMessages,
}: AddFavoritesProps) => {
  const { isLoading, mutateAsync } = useUpdateFavoriteItems({})

  const selectedFavorites = useMemo(() => {
    // お気に入りリストに選択した商品が1つでも追加されていなかったら対象外
    return favorites
      .filter(
        (favorite) =>
          !selectedItemCodes.some(
            (itemCode) => !favorite.itemCodes.includes(itemCode)
          )
      )
      .map((favorite) =>
        getFavoriteUniqueString({
          setNo: favorite.setNo,
          storeCode: favorite.storeCode,
        })
      )
  }, [favorites, selectedItemCodes])

  const favoriteOptions = useMemo(() => {
    return favorites.map((favorite) => ({
      value: getFavoriteUniqueString({
        setNo: favorite.setNo,
        storeCode: favorite.storeCode,
      }),
      label: favorite.name,
    }))
  }, [favorites])

  const onChange = async ({
    favoriteUniqueString,
    checked,
  }: {
    favoriteUniqueString: FavoriteUniqueString
    checked: boolean
  }) => {
    const { setNo, storeCode } = getFavoriteUniqueValues(favoriteUniqueString)
    const currentItemCodes =
      favorites.find(
        (favorite) =>
          favorite.setNo === setNo && favorite.storeCode === storeCode
      )?.itemCodes || []
    handleErrorMessages([])
    await mutateAsync(
      {
        setNo,
        storeCode,
        data: {
          itemCodes: checked
            ? [
                ...currentItemCodes,
                // すでに追加済みのitemは除外
                ...selectedItemCodes.filter(
                  (itemCode) => !currentItemCodes.includes(itemCode)
                ),
              ]
            : currentItemCodes.filter(
                (itemCode) => !selectedItemCodes.includes(itemCode)
              ),
        },
      },
      {
        onSuccess: () => handleChange(),
        onError: (error) => {
          if (error.response?.status === 400) {
            handleErrorMessages(
              getErrorMessages({
                fields: error.response.data.fields,
                fieldNames: ERROR_FIELD_NAMES,
              })
            )
          }
        },
      }
    )
  }

  return (
    <FavoriteCheckboxList
      options={favoriteOptions}
      selected={selectedFavorites}
      isLoading={isLoading}
      handleChange={onChange}
    />
  )
}
