import clsx from 'clsx'

import type { DialogWarningProps } from './type'

import { Dialog, Button, WarningText } from '@/components/ui'

export const DialogWarning = ({
  isOpen,
  title,
  bodyText,
  confirmButtonText = '実行する',
  cancelButtonText = '閉じる',
  isLoading = false,
  onConfirm,
  onCancel,
}: DialogWarningProps) => {
  return (
    <Dialog title={title} isOpen={isOpen} variant="alert">
      <div className={clsx('tw-px-2 tw-pb-4')}>
        <div className={clsx('tw-text-xs')}>
          <WarningText>{bodyText}</WarningText>
        </div>

        <div className={clsx('tw-mt-10 tw-flex tw-items-center tw-space-x-4')}>
          <Button variant="normal" disabled={isLoading} onClick={onCancel}>
            {cancelButtonText}
          </Button>
          <Button
            variant="alert"
            disabled={isLoading}
            isLoading={isLoading}
            onClick={onConfirm}
          >
            {confirmButtonText}
          </Button>
        </div>
      </div>
    </Dialog>
  )
}
