import { useQuery } from 'react-query'

import { aspidaClient } from '@/lib/aspida'
import { QueryConfig } from '@/lib/react-query'

import { itemKeyFactory } from './key'

export const getItems = ({
  queryParams,
}: Parameters<typeof itemKeyFactory.items>[0]) => {
  return aspidaClient.v1.items.$get({
    query: queryParams,
  })
}

export type UseItemsOptions = Parameters<typeof itemKeyFactory.items>[0] & {
  config?: QueryConfig<typeof getItems>
}

export const useItems = ({ config, queryParams }: UseItemsOptions) => {
  const queryConfig: QueryConfig<typeof getItems> = {
    ...config,
    queryKey: itemKeyFactory.items({
      queryParams,
    }),
    queryFn: () =>
      getItems({
        queryParams,
      }),
  }
  return useQuery(queryConfig)
}
