import { InputSelectSingle } from '../select'
import { monthOptions } from './const'
import { InputMonthOnlyProps } from './type'

export const InputMonthOnly = ({
  value,
  disabled,
  onChange,
}: InputMonthOnlyProps) => {
  const options = monthOptions()
  return (
    <InputSelectSingle
      buttonLabel={`年`}
      options={options}
      height="lg"
      disabled={disabled}
      selected={options.find((year) => year.value === value) ?? null}
      handleChange={(selected) => {
        onChange(selected.value)
      }}
    />
  )
}
