import clsx from 'clsx'
import React from 'react'

import { PaginationItemProps } from './type'

export const PaginationItem = ({
  content,
  isCurrent = false,
  clickable = true,
  disabled = false,
  onClick,
}: PaginationItemProps) => {
  return (
    <li
      className={clsx(
        'tw-flex tw-items-center tw-justify-center',
        'tw-h-6 tw-w-6',
        isCurrent
          ? 'tw-rounded-full tw-bg-primary-500 tw-text-white'
          : 'tw-text-primary-500',
        clickable && 'tw-cursor-pointer',
        disabled && 'tw-cursor-not-allowed tw-text-gray-400'
      )}
      onClick={!disabled ? onClick : undefined}
    >
      {content}
    </li>
  )
}
