import clsx from 'clsx'

import type { Props } from './type'

import { sizes, variants } from './const'

export const Spinner = ({ size = 'md', variant = 'primary' }: Props) => {
  return (
    <>
      <div className={clsx('tw-flex tw-justify-center')}>
        <div
          className={clsx(
            'tw-animate-spin tw-rounded-full',
            sizes[size],
            variants[variant]
          )}
        ></div>
      </div>
      <span className="tw-sr-only">Loading</span>
    </>
  )
}
