import clsx from 'clsx'

import type { ButtonProps } from './type'

export const variants = {
  primary: clsx(
    'tw-bg-primary-500 tw-text-white tw-text-base',
    'hover:tw-bg-primary-400',
    'focus:tw-bg-primary-700',
    'disabled:tw-bg-primary-50'
  ),
  secondary: clsx(
    'tw-bg-white tw-text-primary-500 tw-border tw-border-primary-500 tw-text-base',
    'hover:tw-bg-gray-100 hover:tw-border-primary-400 hover:tw-text-primary-400',
    'focus:tw-bg-gray-300 focus:tw-border-primary-700 focus:tw-text-primary-700',
    'disabled:tw-bg-white disabled:tw-bg-opacity-50 disabled:tw-border-primary-200 disabled:tw-text-primary-200'
  ),
  tertiary: clsx(
    'tw-bg-white tw-text-accentBlue-500 tw-border tw-border-accentBlue-500 tw-text-sm',
    'hover:tw-border-accentBlue-400 hover:tw-text-accentBlue-400',
    'focus:tw-border-accentBlue-700 focus:tw-text-accentBlue-700',
    'disabled:tw-bg-white disabled:tw-border-accentBlue-500 disabled:tw-text-accentBlue-500 disabled:tw-opacity-30'
  ),
  notice: clsx(
    'tw-bg-accentYellow-50 tw-text-primary-500 tw-border tw-border-primary-500 tw-text-base',
    'hover:tw-bg-gray-100 hover:tw-border-primary-400 hover:tw-text-primary-400',
    'focus:tw-bg-gray-300 focus:tw-border-primary-700 focus:tw-text-primary-700',
    'disabled:tw-bg-accentYellow-50 disabled:tw-border-primary-50 disabled:tw-text-primary-50'
  ),
  alert: clsx(
    'tw-bg-alert-500 tw-text-white tw-text-base',
    'hover:tw-bg-alert-400',
    'focus:tw-bg-alert-700',
    'disabled:tw-bg-alert-50'
  ),
  'alert-secondary': clsx(
    'tw-bg-white tw-text-alert-500 tw-border tw-border-alert-500 tw-text-base',
    'hover:tw-bg-gray-100 hover:tw-border-alert-400 hover:tw-text-alert-400',
    'focus:tw-bg-gray-300 focus:tw-border-alert-700 focus:tw-text-alert-700',
    'disabled:tw-bg-white disabled:tw-bg-opacity-50 disabled:tw-border-alert-50 disabled:tw-text-alert-50'
  ),
  normal: clsx(
    'tw-bg-white tw-text-gray-700 tw-border tw-border-gray-700 tw-text-base',
    'hover:tw-bg-gray-100',
    'focus:tw-bg-gray-300',
    'disabled:tw-bg-white disabled:tw-opacity-30'
  ),
}

export const sizes = {
  sm: {
    height: 'tw-h-7',
    width: 'tw-w-7', // for circle
    fontSize: 'tw-text-sm',
  },
  md: {
    height: 'tw-h-8',
    width: 'tw-w-8', // for circle
    fontSize: 'tw-text-base',
  },
  lg: {
    height: 'tw-h-11',
    width: 'tw-w-11', // for circle
    fontSize: 'tw-text-base',
  },
  xl: {
    height: 'tw-h-[4.25rem]',
    width: 'tw-w-[4.25rem]', // for circle
    fontSize: 'tw-text-base',
  },
}

export const round = (
  roundType: NonNullable<ButtonProps['roundType']>,
  roundDirection: NonNullable<ButtonProps['roundDirection']>
) => {
  let roundedClassNames: string[] = []
  if (roundDirection.topLeft) {
    switch (roundType) {
      case 'full':
        roundedClassNames = [...roundedClassNames, 'tw-rounded-tl-full']
        break
      case 'md':
        roundedClassNames = [...roundedClassNames, 'tw-rounded-tl']
        break
    }
  }
  if (roundDirection.topRight) {
    switch (roundType) {
      case 'full':
        roundedClassNames = [...roundedClassNames, 'tw-rounded-tr-full']
        break
      case 'md':
        roundedClassNames = [...roundedClassNames, 'tw-rounded-tr']
        break
    }
  }
  if (roundDirection.bottomLeft) {
    switch (roundType) {
      case 'full':
        roundedClassNames = [...roundedClassNames, 'tw-rounded-bl-full']
        break
      case 'md':
        roundedClassNames = [...roundedClassNames, 'tw-rounded-bl']
        break
    }
  }
  if (roundDirection.bottomRight) {
    switch (roundType) {
      case 'full':
        roundedClassNames = [...roundedClassNames, 'tw-rounded-br-full']
        break
      case 'md':
        roundedClassNames = [...roundedClassNames, 'tw-rounded-br']
        break
    }
  }
  return roundedClassNames
}
