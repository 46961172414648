import type { Methods as EcSiteOrdersMethods } from '@/api/v1/ec-site-orders'
import type { Methods as EcSiteOrdersSystemTypesMethods } from '@/api/v1/ec-site-orders/systemTypes'

export const ecSiteOrdersKeyFactory = {
  all: [{ scope: 'ec-site-orders' }] as const,

  getEcSiteOrders: ({
    queryParams,
  }: {
    queryParams: EcSiteOrdersMethods['get']['query']
  }) =>
    [
      {
        ...ecSiteOrdersKeyFactory.all[0],
        entity: 'getEcSiteOrders',
        queryParams,
      },
    ] as const,

  getEcSiteOrdersFileId: ({ pathParams }: { pathParams: { fileId: string } }) =>
    [
      {
        ...ecSiteOrdersKeyFactory.all[0],
        entity: 'getEcSiteOrdersFileId',
        pathParams,
      },
    ] as const,

  getEcSiteOrdersFileIdDownload: ({
    pathParams,
  }: {
    pathParams: { fileId: string }
  }) =>
    [
      {
        ...ecSiteOrdersKeyFactory.all[0],
        entity: 'getEcSiteOrdersFileIdDownload',
        pathParams,
      },
    ] as const,

  getEcSiteOrdersSystemTypes: ({
    queryParams,
  }: {
    queryParams: EcSiteOrdersSystemTypesMethods['get']['query']
  }) =>
    [
      {
        ...ecSiteOrdersKeyFactory.all[0],
        entity: 'getEcSiteOrdersSystemTypes',
        queryParams,
      },
    ] as const,
}
