import { useMutation } from 'react-query'

import { Methods } from '@/api/v1/cart/delivery-date'
import { aspidaClient } from '@/lib/aspida'
import { queryClient } from '@/lib/react-query'
import { MutationConfig } from '@/lib/react-query'

import { cartKeyFactory } from './key'

export type UpdateCartDeliveryDateDTO = {
  data: Methods['put']['reqBody']
}

export const updateCartDeliveryDate = ({ data }: UpdateCartDeliveryDateDTO) => {
  return aspidaClient.v1.cart.delivery_date.$put({ body: data })
}

type UseUpdateCartOptions = {
  config?: Omit<MutationConfig<typeof updateCartDeliveryDate>, 'onSuccess'>
}

export const useUpdateCartDeliveryDate = ({ config }: UseUpdateCartOptions) => {
  return useMutation({
    ...config,
    mutationFn: updateCartDeliveryDate,
    onSuccess: () => queryClient.invalidateQueries(cartKeyFactory.detail()),
  })
}
