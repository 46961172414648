import { useMutation } from 'react-query'

import { Methods } from '@/api/v1/receive-email'
import { aspidaClient } from '@/lib/aspida'
import { MutationConfig } from '@/lib/react-query'
import { queryClient } from '@/lib/react-query'

import { userKeyFactory } from './key'

export type UpdateEmailSubscriptionDTO = {
  data: Methods['put']['reqBody']
}

export const updateEmailSubscription = ({
  data,
}: UpdateEmailSubscriptionDTO) => {
  return aspidaClient.v1.receive_email.$put({ body: data })
}

type UseUpdateEmailSubscriptionOptions = {
  config?: Omit<MutationConfig<typeof updateEmailSubscription>, 'onSuccess'>
}

export const useUpdateEmailSubscription = ({
  config,
}: UseUpdateEmailSubscriptionOptions) => {
  return useMutation({
    ...config,
    mutationFn: updateEmailSubscription,
    onSuccess: () =>
      queryClient.invalidateQueries(userKeyFactory.emailSubscription()),
  })
}
