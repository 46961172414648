import { useMutation } from 'react-query'

import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from '@/config'
import { aspidaClient } from '@/lib/aspida'
import { MutationConfig } from '@/lib/react-query'
import { queryClient } from '@/lib/react-query'
import { storage } from '@/utils/storage'

import { resetUser } from '../user'

const logout = () => {
  return aspidaClient.v1.logout.$put()
}

type UseLogoutOptions = {
  config?: Omit<MutationConfig<typeof logout>, 'onSuccess'>
}

export const useLogout = ({ config }: UseLogoutOptions) => {
  return useMutation({
    ...config,
    mutationFn: logout,
    onSuccess: async () => {
      storage.remove(ACCESS_TOKEN_KEY)
      storage.remove(REFRESH_TOKEN_KEY)
      await resetUser()
    },
    onSettled: (_data, error) => {
      if (error === null) {
        queryClient.invalidateQueries()
      }
    },
  })
}
