import clsx from 'clsx'
import { useMemo } from 'react'

import type { CategorySelectProps } from './type'

import { MultiSelectButton, MultiSelectButtonTag } from '@/components/ui'
import { FormFieldWrapper } from '@/components/ui'
import { CATEGORY_TITLE } from '@/const'
import { getMargedCategoryItems } from '@/utils/features/category'

export const CategorySelect = ({
  categories,
  categoryIds,
  errors,
  handleOpenPanel,
  handleUnselect,
}: CategorySelectProps) => {
  const subCategories = useMemo(
    () =>
      categories
        .map((category) => {
          // NOTE: サブカテゴリーだけでなく、大分類の「すべて」も表示する
          return getMargedCategoryItems(category)
        })
        .flat(),
    [categories]
  )

  return (
    <FormFieldWrapper id="categories" label={CATEGORY_TITLE} error={errors}>
      <MultiSelectButton
        placeholder={'未選択'}
        isSelected={!!categoryIds?.length}
        items={
          !!categoryIds?.length && (
            <ul className={clsx('tw-flex tw-flex-1 tw-flex-wrap tw-gap-2')}>
              {categoryIds.map((categoryId) => {
                const category = subCategories.find(
                  (category) => category?.categoryId === categoryId
                )
                return category ? (
                  <li key={categoryId}>
                    <MultiSelectButtonTag
                      onUnselect={() => handleUnselect(categoryId)}
                    >
                      {category.categoryName}
                    </MultiSelectButtonTag>
                  </li>
                ) : null
              })}
            </ul>
          )
        }
        onClick={handleOpenPanel}
      />
    </FormFieldWrapper>
  )
}
