import type { Item } from '@/api/@types'
import type { KeyLabel } from '@/types'

export const ITEM_TITLE = '商品'

export const ITEM_SEARCH_TITLE = '検索'

export const ITEM_FIELDS: KeyLabel<Item> & {
  brandStartDate: string
} = {
  brandName: 'ブランド名',
  retailPrice: 'メーカー希望小売価格',
  salonPrice: 'サロン価格',
  volume: '容量',
  janCode: 'JANコード',
  itemCode: '商品コード',
  itemName: '商品名',
  // isNonReturnable: '返品可否',
  latestOrderDate: '前回到着予定日',
  salesStartDate: '販売開始日',
  brandStartDate: 'ブランド取扱日',
} as const

export const ITEM_FIELDS_WITH_BREAK = {
  retailPrice: ['メーカー', '希望小売価格'],
} as const

// export const NON_RETURNABLE_FLG_VALUE = {
//   true: '不可',
//   false: '可',
// }

export const ITEM_CARD_STATUS_LABEL = {
  available: '',
  unavailable: '一時注文停止中（営業担当にお問合せください）',
  expired: '注文期間外',
  forbidden: '担当営業へお問合せ下さい',
  noItemCode: '存在しない商品コード', // NOTE: このステータスのitemの事例は未確認
}

export const KEYWORD_SEARCH_PLACEHOLDER =
  '商品名・JANコード・カタログ番号で検索'
