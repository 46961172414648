import clsx from 'clsx'

import type { BrandPanelProps } from './type'

import { BrandDisclosure } from '@/components/features/brand'

export const BrandPanel = ({ brandGroups, handleSelect }: BrandPanelProps) => {
  return (
    <div>
      <ul>
        {brandGroups.map((brandGroup) => (
          <li
            key={brandGroup.groupName}
            className={clsx('tw-border-b tw-border-gray-300')}
          >
            <BrandDisclosure brandGroup={brandGroup} onClick={handleSelect} />
          </li>
        ))}
      </ul>
    </div>
  )
}
