import { useController } from 'react-hook-form'

import type { FormFieldNumberProps } from './type'

import { FormFieldWrapper, InputNumber } from '@/components/ui'

export const FormFieldNumber = ({
  label,
  name,
  control,
  disabled,
  error,
  showLabel,
  showError,
  height,
  min,
  max,
  tabIndex,
  enterIndex,
  placeholder,
  zeroAttention,
  onAfterChange,
  onFocus,
}: FormFieldNumberProps) => {
  const {
    field: { onChange, value },
  } = useController({ name, control })

  const handleChange = (value: number | '') => {
    onChange(value)
    onAfterChange && onAfterChange()
  }

  return (
    <FormFieldWrapper
      label={label}
      error={error}
      showLabel={showLabel}
      showError={showError}
    >
      <InputNumber
        value={!!value || value === 0 ? value : ''}
        min={min}
        max={max}
        height={height}
        tabIndex={tabIndex}
        isError={!!error}
        disabled={disabled}
        placeholder={placeholder}
        zeroAttention={zeroAttention}
        enterIndex={enterIndex}
        onChange={handleChange}
        onFocus={onFocus}
      />
    </FormFieldWrapper>
  )
}
