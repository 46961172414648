import { ERROR_MESSAGE } from '@/const'
import type { Field } from '@/types/error'

export const conditionText = ({ title }: { title?: string }) => {
  const withTitle = () => {
    return title ? `${title}: ` : ''
  }

  return {
    text: (value?: string) => {
      return value ? [`${withTitle()}${value}`] : []
    },
    currencyRange: ({
      valueFrom,
      valueTo,
    }: {
      valueFrom?: number
      valueTo?: number
    }) => {
      return valueFrom || valueTo
        ? [
            `${withTitle()}${
              valueFrom ? `${valueFrom.toLocaleString()}円` : ''
            }〜${valueTo ? `${valueTo.toLocaleString()}円` : ''}`,
          ]
        : []
    },
    dateRange: ({
      valueFrom,
      valueTo,
    }: {
      valueFrom?: string
      valueTo?: string
    }) => {
      return valueFrom || valueTo
        ? [`${withTitle()}${valueFrom || ''}〜${valueTo || ''}`]
        : []
    },
  }
}

/**
 * @returns `${price.toLocaleString()} 円`
 */
export const priceToLocalString = (price: number) =>
  `${price.toLocaleString()} 円`

/**
 * 数字（文字列）を３桁区切りにして返す
 */
export const formatComma3keta = (integer: string) => {
  const commaed = integer.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') // 3桁ごとにカンマ挿入
  return commaed
}

export const getErrorMessages = <T extends { [x in string]: unknown }>({
  fields,
  fieldNames,
  errorMessages = ERROR_MESSAGE,
}: {
  fields: Field<T>[]
  fieldNames?: { [key in keyof T]: string }
  errorMessages?: Partial<{
    [key in Field<T>['reasons'][number]['type']]: string
  }>
}) => {
  return fields
    .map((field) =>
      field.reasons.map((reason) => {
        const errorMessage = { ...ERROR_MESSAGE, ...errorMessages }[reason.type]
        if (fieldNames) {
          const parameterKey = field.name
          const key = fieldNames[parameterKey] || parameterKey.toString()
          return `${key}：${errorMessage}`
        } else {
          return errorMessage
        }
      })
    )
    .flat()
}

export const zeroPadding = (num: string | number, length: number) => {
  return num.toString().length > length
    ? num.toString()
    : (Array(length).join('0') + num).slice(-length)
}
