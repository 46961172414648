import { InvoiceSearchDownloadState } from '@/components/features/invoice/InvoiceSearch/InvoiceSearchDownload/type'
import { InputOption } from '@/types'

export const INVOICE_TITLE = '請求書'
export const INVOICE_FILE_PREVIEW_DIALOG_TITLE = 'プレビュー'
export const INVOICE_FILE_BULK_DIALOG_TITLE = '一括ダウンロード'
export const INVOICE_FILE_HISTORY_DIALOG_TITLE = '更新履歴'
export const INVOICE_FILTER_DIALOG_TITLE = '請求書検索'
export const INVOICE_PDF_DUMMY_SRC = 'http://localhost:3000/pdf/default.pdf'
export const INVOICE_PDF_DOWNLOAD_STATUS = {
  downloaded: 'ダウンロード済みです',
  notDownloaded: 'ダウンロードが必要です',
  mailed: '郵送済み',
  invalid: '無効になったバージョン',
  noInvoice: '請求なし',
}
export const INVOICE_SEARCH_DOWNLOAD_STATUS: InputOption<InvoiceSearchDownloadState>[] =
  [
    { label: 'すべて表示する', value: 'all' },
    { label: '未ダウンロードの請求先', value: 'notDownloaded' },
    { label: 'ダウンロード済み/郵送の請求先', value: 'downloadedAndMailed' },
  ]

export const INVOICE_NOT_FOUND = `検索条件に合う請求書がありません。\n条件を変更して再度検索してください。`
export const INVOICE_NOT_FOUND_IN_FILTER = `フィルターの条件に合う請求書がありません。\n条件を変更して再度検索してください。`
export const INVOICE_HISTORY_NOT_FOUND = `条件に合う履歴が見つかりませんでした。\n条件を変更して再度検索してください。`
export const INVOICE_PREVIEW_NOT_FOUND = `条件に合うプレビューが見つかりませんでした。\n条件を変更して再度検索してください。`
