export const sizes = {
  sm: 'tw-h-4 tw-w-4 tw-border',
  md: 'tw-h-8 tw-w-8 tw-border-2',
  lg: 'tw-h-16 tw-w-16 tw-border-4',
}

export const variants = {
  primary: 'tw-border-primary-50 tw-border-t-transparent',
  secondary: 'tw-border-primary-500 tw-border-t-white',
  tertiary: 'tw-border-accentBlue-500 tw-border-t-white',
  notice: 'tw-border-primary-500 tw-border-t-white',
  alert: 'tw-border-alert-50 tw-border-t-transparent',
  'alert-secondary': 'tw-border-alert-500 tw-border-t-white',
  normal: 'tw-border-gray-400 tw-border-t-transparent',
}
