import { zodResolver } from '@hookform/resolvers/zod'
import clsx from 'clsx'
import { useCallback, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

import type {
  UpdateFavoriteListTitleFormProps,
  UpdateFavoriteListTitleFormVO,
} from './type'

import {
  Button,
  FormFieldText,
  FormFieldRadioGroup,
  ErrorMessageList,
} from '@/components/ui'
import { NOTIFICATION_MESSAGE, ERROR_FIELD_NAMES } from '@/const'
import { useCreateFavorite, useUpdateFavorite } from '@/hooks/features/favorite'
import { favoriteKeyFactory } from '@/hooks/features/favorite/key'
import { useNotification } from '@/hooks/util'
import { queryClient } from '@/lib/react-query'
import { getErrorMessages } from '@/utils/text'

import { schema, formLabels, formDefaultValues, isSharedOptions } from './const'

export const UpdateFavoriteListTitleForm = ({
  favorite,
  isDepositStore = false,
  onClickConfirm,
  onClickCancel,
}: UpdateFavoriteListTitleFormProps) => {
  const { notify } = useNotification()

  const [errorMessages, setErrorMessages] = useState<string[]>([])

  const createFavorite = useCreateFavorite({})
  const updateFavorite = useUpdateFavorite({})

  const isLoading = useMemo(() => {
    return createFavorite.isLoading || updateFavorite.isLoading
  }, [createFavorite.isLoading, updateFavorite.isLoading])

  const { control, register, formState, handleSubmit } =
    useForm<UpdateFavoriteListTitleFormVO>({
      resolver: schema && zodResolver(schema),
      defaultValues: favorite
        ? {
            name: favorite.name,
            isShared: favorite.isShared,
          }
        : formDefaultValues,
    })

  const submitHandler = useCallback(
    async ({ name, isShared }: UpdateFavoriteListTitleFormVO) => {
      setErrorMessages([])
      updateFavorite.mutate(
        {
          pathParams: [favorite.setNo, favorite.storeCode],
          data: {
            name,
            isShared,
            salesTypeCodes: favorite.salesTypes.map((obj) => obj.salesTypeCode),
          },
        },
        {
          onSuccess: () => {
            onClickConfirm && onClickConfirm()
            notify({
              id: 'NOTIFICATION_MESSAGE.FAVORITE.UPDATE_SETTING',
              type: 'success',
              message: NOTIFICATION_MESSAGE.FAVORITE.UPDATE_SETTING,
            })
            queryClient.invalidateQueries(favoriteKeyFactory.list())
          },
          onError: (error) => {
            if (error.response?.status === 400) {
              setErrorMessages(
                getErrorMessages({
                  fields: error.response.data.fields,
                  fieldNames: { ...ERROR_FIELD_NAMES, ...formLabels },
                })
              )
            }
          },
        }
      )
    },
    [favorite, updateFavorite, onClickConfirm, notify]
  )

  return (
    <div className={clsx('tw-px-1 tw-pb-4')}>
      {errorMessages.length > 0 && (
        <div className={clsx('tw-mt-4')}>
          <ErrorMessageList errorMessages={errorMessages} />
        </div>
      )}

      <form
        id="favorite-list-Update-form"
        onSubmit={handleSubmit(submitHandler)}
      >
        <div className={clsx('tw-mt-4')}>
          <FormFieldText
            label={formLabels.name}
            placeholder={formLabels.name}
            disabled={isLoading}
            required
            error={formState.errors['name']}
            registration={register('name')}
          />
        </div>

        {isDepositStore && (
          <div className={clsx('tw-mt-2')}>
            <div
              className={clsx(
                'tw-w-full',
                'tw-flex tw-flex-col tw-items-center',
                'tw-p-4',
                'tw-rounded',
                'tw-bg-primary-50'
              )}
            >
              <p className={clsx('tw-text-center tw-text-sm')}>
                各店舗への共有
              </p>
              <div className={clsx('tw-mt-1')}>
                <FormFieldRadioGroup
                  showLabel={false}
                  label={formLabels.isShared}
                  error={formState.errors['isShared']}
                  name="isShared"
                  control={control}
                  options={isSharedOptions}
                  formName="isShared"
                  direction={'row'}
                  gap={'lg'}
                  showError={false}
                />
              </div>
            </div>
          </div>
        )}
        <div
          className={clsx(
            isDepositStore ? 'tw-mt-7' : 'tw-mt-4', // バランスを取る
            'tw-flex tw-items-center',
            'tw-gap-4'
          )}
        >
          <Button
            type={'button'}
            disabled={isLoading}
            variant={'normal'}
            onClick={() => onClickCancel()}
          >
            閉じる
          </Button>
          <Button type={'submit'} disabled={isLoading} isLoading={isLoading}>
            保存する
          </Button>
        </div>
      </form>
    </div>
  )
}
