import { atom, selector, useRecoilState, useRecoilValue } from 'recoil'

import type { SystemType } from '@/api/@types'
import type { InputOption } from '@/types'

type TSystemTypeListItem = {
  systemType: SystemType // ex: "rakuten"
  systemTypeName: string // ex: "楽天"
}

const SYSTEM_TYPE_NONE_NAME = '指定なし'

// state

type TSystemTypeMasterState = {
  // 注文取込向けシステム種別
  forImport: TSystemTypeListItem[]
  // 仕入情報向けシステム種別
  forPurchase: TSystemTypeListItem[]
}

export const systemTypesMasterState = atom<TSystemTypeMasterState>({
  key: 'systemTypesMasterState',
  default: {
    forImport: [],
    forPurchase: [],
  },
})

// selector

const systemTypesDictionaries = selector({
  key: 'systemTypesDictionary',
  get: ({ get }) => {
    const master = get(systemTypesMasterState)

    function createDict(items: TSystemTypeListItem[]) {
      const dict = {} as {
        [key in TSystemTypeListItem['systemType']]: TSystemTypeListItem['systemTypeName']
      }
      items.forEach((item) => {
        dict[item.systemType] = item.systemTypeName
      })
      return dict
    }

    return {
      forImport: createDict(master.forImport),
      forPurchase: createDict(master.forPurchase),
    }
  },
})

const systemTypesInputOptions = selector({
  key: 'systemTypesOptions',
  get: ({ get }) => {
    const master = get(systemTypesMasterState)

    function createOptions(items: TSystemTypeListItem[]) {
      const options: InputOption<SystemType | ''>[] = [
        {
          label: SYSTEM_TYPE_NONE_NAME,
          value: '',
        },
        ...items.map((item) => ({
          label: item.systemTypeName,
          value: item.systemType,
        })),
      ]
      return options
    }

    return {
      forImport: createOptions(master.forImport),
      forPurchase: createOptions(master.forPurchase),
    }
  },
})

// hooks - state

export const useSystemTypesMaster = () => {
  const [systemTypesMaster, setSystemTypesMaster] = useRecoilState(
    systemTypesMasterState
  )
  return { systemTypesMaster, setSystemTypesMaster }
}

// hooks - selector

export const useSystemTypesDictionaries = () => {
  return useRecoilValue(systemTypesDictionaries)
}

export const useSystemTypesInputOptions = () => {
  return useRecoilValue(systemTypesInputOptions)
}
