import { useQuery } from 'react-query'

import { aspidaClient } from '@/lib/aspida'
import { QueryConfig } from '@/lib/react-query'

import { cartKeyFactory } from './key'

export const getCart = () => {
  return aspidaClient.v1.cart.$get()
}

type UseCartOptions = {
  config?: QueryConfig<typeof getCart>
}

export const useCart = ({ config }: UseCartOptions) => {
  const queryConfig: QueryConfig<typeof getCart> = {
    ...config,
    queryKey: cartKeyFactory.detail(),
    queryFn: getCart,
  }
  return useQuery(queryConfig)
}
