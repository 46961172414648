import { useRouter } from 'next/router'

import { ROUTE } from '@/const'
import { useLimitedMode } from '@/hooks/features/auth'

import { LIMITED_MODE_ALLOWED_PATHS } from './const'
import { WithLimitedModeProps } from './type'

/**
 * ログインユーザーのみアクセスを許可
 * 非ログインユーザーがアクセスを試みた場合はログインページへリダイレクト
 */
export const withLimitedMode = (PageComponent: WithLimitedModeProps) => {
  const LimitedPage = ({ ...props }: any) => {
    const { mode, isLimitedTerm } = useLimitedMode()
    const router = useRouter()

    if (
      isLimitedTerm &&
      mode !== 'normal' &&
      !LIMITED_MODE_ALLOWED_PATHS[mode].includes(router.pathname)
    ) {
      router.replace(ROUTE.top)
      return <></>
    }

    return <PageComponent {...props} />
  }

  return LimitedPage
}
