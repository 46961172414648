import { parseNumberToHalfWidth, parseToNumber } from '@/utils/number'

export const formatNumber = (value: string, min: number, max?: number) => {
  if (value === '') {
    return min
  }

  const halfWidthNumber = parseNumberToHalfWidth(value)
  const parsedValue = parseToNumber(halfWidthNumber, null)

  if (parsedValue === null) {
    return null
  }
  if (!!max && parsedValue > max) {
    return max
  }
  if (!!min && parsedValue < min) {
    return min
  }
  return parsedValue
}
