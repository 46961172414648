import { IMaintenancePage } from '@/types/generated/contentful'

export const contentfulMaintenanceKeyFactory = {
  all: [{ scope: 'contentfulMaintenance' }] as const,
  maintenance: () =>
    [
      {
        ...contentfulMaintenanceKeyFactory.all[0],
        entity: 'maintenance',
      },
    ] as const,
}

export const contentfulPreviewMaintenanceKeyFactory = {
  all: [{ scope: 'contentfulPreviewMaintenance' }] as const,
  maintenance: ({ id }: { id: IMaintenancePage['sys']['id'] }) =>
    [
      {
        ...contentfulPreviewMaintenanceKeyFactory.all[0],
        entity: 'maintenance',
        id,
      },
    ] as const,
}
