import { QuantityTableProps } from './type'

export const CART_ITEM_DETAIL_TABLE_LABEL: {
  [key in keyof QuantityTableProps['salesTypeQuantity']]: string
} = {
  salesTypeName: '種別',
  quantity: '数量',
  note: 'メモ',
  price: '購入価格',
  summaryPrice: '小計',
} as const

export const CART_ITEM_DETAIL_TABLE_KEYS: (keyof typeof CART_ITEM_DETAIL_TABLE_LABEL)[] =
  ['salesTypeName', 'quantity', 'note', 'price', 'summaryPrice']
