import { Search } from '@mui/icons-material'
import clsx from 'clsx'
import React, { useEffect } from 'react'

import type { InputSearchProps } from './type'

import { Button, InputText } from '@/components/ui'

import { widths } from '../const'

export const InputSearch = ({
  name,
  disabled = false,
  allowEmpty = true,
  placeholder = '検索',
  width = 'full',
  enterKeyHint = 'search',
  defaultValue,
  onSearch,
}: InputSearchProps) => {
  const [value, setValue] = React.useState<string>(defaultValue || '')

  const handleSearch = (event?: React.FormEvent<HTMLFormElement>) => {
    if (onSearch) {
      onSearch(value)
    }
    //Note: ページ遷移を防ぐ
    event?.preventDefault()
  }

  useEffect(() => {
    setValue(defaultValue || '')
  }, [defaultValue])

  return (
    <form
      className={clsx('tw-flex tw-items-center', widths[width])}
      onSubmit={handleSearch}
    >
      <InputText
        id={`input-search_${name}_input`}
        type={'text'}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        rounded={'left'}
        enterKeyHint={enterKeyHint}
        onChange={(v) => setValue(v.target.value)}
      />
      <div className={'tw-w-11'}>
        <Button
          isBlock={true}
          disabled={disabled || (!allowEmpty && !value)}
          roundDirection={{
            topLeft: false,
            topRight: true,
            bottomLeft: false,
            bottomRight: true,
          }}
          onClick={() => handleSearch()}
        >
          <Search
            className={clsx('tw-text-white')}
            sx={{
              fontSize: 24,
            }}
          />
        </Button>
      </div>
    </form>
  )
}
