import clsx from 'clsx'

import type { ErrorMessageListProps } from './type'

export const ErrorMessageList = ({ errorMessages }: ErrorMessageListProps) => {
  return (
    <ul className={clsx('tw-space-y-1 tw-text-sm tw-text-alert-500')}>
      {errorMessages.map((message, index) => (
        <li key={index}>{message}</li>
      ))}
    </ul>
  )
}
