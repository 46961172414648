import {} from 'react'
import clsx from 'clsx'

import type { SimpleKvListProps } from './type'

import { Row } from './Row'

export const SimpleKvList = ({
  title,
  rows,
  withBorderTop,
}: SimpleKvListProps) => {
  return (
    <div>
      <div
        className={clsx(
          'tw-flex tw-h-8 tw-items-center tw-px-4',
          'tw-bg-primary-50 tw-text-sm tw-font-bold',
          withBorderTop && 'tw-border-t tw-border-gray-300'
        )}
      >
        {title}
      </div>
      {rows.length && (
        <ul
          className={clsx(
            'tw-border-y tw-border-gray-300',
            'tw-pl-4',
            'tw-divide-y tw-divide-gray-300'
          )}
        >
          {rows.map((row, i) =>
            row.hidden ? (
              <></>
            ) : (
              <li key={i} className={clsx('tw-pr-4')}>
                <Row {...row} />
              </li>
            )
          )}
        </ul>
      )}
    </div>
  )
}
