import clsx from 'clsx'

import { LabelProps } from './type'

export const Label = ({ title, children }: LabelProps) => {
  return (
    <div
      className={clsx(
        'tw-bg-primary-50 tw-px-4 tw-py-[6px]',
        'tw-border-t tw-border-b tw-border-gray-300',
        'tw-flex tw-items-center tw-justify-between'
      )}
    >
      <div className={clsx('tw-text-sm tw-font-semibold')}>{title}</div>
      {children}
    </div>
  )
}
