import clsx from 'clsx'

import {
  baseClassNames,
  errorClassNames,
  attentionClassNames,
  widths,
  heights,
  texts,
  rounds,
} from '../const'
import style from './style.module.css'
import { InputNumberProps } from './type'
import { formatNumber } from './util'

/**
 * 数量（正の整数）を入力するコンポーネント
 */
export const InputNumber = ({
  id,
  name,
  value,
  placeholder,
  disabled = false,
  min = 0,
  max,
  height = 'lg',
  rounded = 'all',
  isError = false,
  selectOnFocus = true,
  zeroAttention = false,
  tabIndex,
  enterIndex,
  onChange,
  onBlur,
  onFocus = () => {},
}: InputNumberProps) => {
  const isValueZero = value === 0

  return (
    <div>
      <input
        id={id}
        name={name}
        placeholder={placeholder}
        className={clsx(
          style.hiddenArrow,
          baseClassNames,
          isError && !disabled && errorClassNames,
          widths['full'],
          heights[height],
          texts[height],
          rounds[rounded],
          isValueZero && zeroAttention && attentionClassNames,
          disabled &&
            'tw-pointer-events-none tw-cursor-not-allowed tw-bg-gray-100 tw-text-gray-600'
        )}
        value={value}
        min={min}
        max={max}
        tabIndex={disabled ? -1 : tabIndex}
        /**
         * Note:
         * どのOS・主要ブラウザでも強制的に半角数字が入力される仕様を流用したいため、
         * text ではなく tel を指定（他にいい方法があれば採用したい）
         * text の場合 windows にて enter を２回押さないと確定できないらしく、その対策を主として採用
         */
        type="tel"
        inputMode="numeric"
        onChange={(event) => {
          const num = formatNumber(event.currentTarget.value, min, max)
          if (num !== null && onChange) {
            onChange(Number(num))
          }
        }}
        onBlur={(event) => {
          const num = formatNumber(event.currentTarget.value, min, max)
          if (num !== null && onBlur) {
            onBlur(num)
          }
        }}
        onFocus={(event) => {
          if (selectOnFocus) {
            event.currentTarget.select()
          }
          onFocus()
        }}
        data-enterindex={enterIndex ? (disabled ? -1 : tabIndex ?? 0) : null}
      />
    </div>
  )
}
