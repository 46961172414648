import { compareDesc } from 'date-fns'

import type { CurrencyRange } from '@/components/ui/input/InputCurrencyRange/type'
import type { DateRange } from '@/components/ui/input/InputDateRange/type'

/** 価格範囲のcustom validation rule */
export const currencyRangeValidation = ({
  lower,
  upper,
}: Partial<CurrencyRange>) => {
  if (lower && upper) {
    return lower <= upper
  }
  return true
}

/** 日付範囲のcustom validation rule */
export const dateRangeValidation = ({ from, to }: Partial<DateRange>) => {
  if (from && to) {
    return compareDesc(new Date(from), new Date(to)) !== -1
  }
  return true
}

/** 配列要素をカンマで結合した文字列長さのcustom validation rule */
export const arrayStringLengthValidation =
  (maxLength: number) => (arr: unknown[]) => {
    return arr.join(',').length <= maxLength
  }
