import { useMemo } from 'react'

import type { UpdateFavoriteListTitleModalProps } from './type'

import { Dialog } from '@/components/ui'
import { useMyStore } from '@/hooks/features/store'

import { UpdateFavoriteListTitleForm } from './Form'

export const UpdateFavoriteListTitleModal = ({
  isOpen,
  favorite,
  onClickConfirm,
  onClickCancel,
}: UpdateFavoriteListTitleModalProps) => {
  const myStore = useMyStore({})

  const isDepositStore = useMemo(() => {
    if (!myStore.data?.isDepositStore) {
      return false
    }
    // create
    if (!favorite) {
      return true
    }
    // update
    return favorite.isEditableByMe
  }, [myStore, favorite])

  const title = useMemo(
    () => (isDepositStore ? 'タイトル編集・共有設定' : 'タイトル編集'),
    [isDepositStore]
  )

  return (
    <Dialog title={title} isOpen={isOpen}>
      <UpdateFavoriteListTitleForm
        favorite={favorite}
        isDepositStore={isDepositStore}
        onClickConfirm={onClickConfirm}
        onClickCancel={onClickCancel}
      />
    </Dialog>
  )
}
