import clsx from 'clsx'
import * as React from 'react'

import type { InputPlusStyleCheckboxGroupProps } from './type'

import type { InputOption } from '@/types'

import { InputPlusStyleCheckbox } from '../InputPlusStyleCheckbox'

const isSelected = (
  selected: InputPlusStyleCheckboxGroupProps['selected'],
  target: InputOption['value']
) => {
  return selected.includes(target)
}

export const InputPlusStyleCheckboxGroup = React.forwardRef<
  HTMLFieldSetElement,
  InputPlusStyleCheckboxGroupProps
>(
  (
    {
      id,
      label,
      options,
      selected,
      disabled = false,
      direction = 'row',
      handleChange,
      handleFocus,
      handleBlur,
    },
    ref
  ) => {
    return (
      <fieldset
        ref={ref}
        id={id}
        className={clsx(
          'tw-flex',
          direction === 'row'
            ? 'tw-flex-row tw-space-x-4'
            : 'tw-flex-col tw-space-y-2'
        )}
      >
        {options.map((option, index) => (
          <InputPlusStyleCheckbox
            key={`input-checkbox_${label}_option-${index}-${option.label}`}
            checked={isSelected(selected, option.value)}
            label={option.label}
            disabled={disabled || option.disabled}
            showLabel={true}
            handleChange={(value) =>
              handleChange(
                value
                  ? [...selected, option.value]
                  : selected.filter((v) => v !== option.value)
              )
            }
            handleFocus={handleFocus}
            handleBlur={handleBlur}
          />
        ))}
      </fieldset>
    )
  }
)

InputPlusStyleCheckboxGroup.displayName = 'InputPlusStyleCheckboxGroup'
