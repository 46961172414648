import { useMemo } from 'react'
import { useController } from 'react-hook-form'

import { FormFieldWrapper, InputSelectSingle } from '@/components/ui'
import type { InputSelectSingleProps } from '@/components/ui/input/select/InputSelectSingle/type'

import { FormFieldSelectSingleProps } from './type'

export const FormFieldSelect = ({
  buttonLabel,
  options,
  disabled,
  showLabel,
  label,
  required,
  height,
  name,
  control,
  error,
  tabIndex,
  showError,
  showErrorOnlyOccered,
  onAfterChange,
}: FormFieldSelectSingleProps) => {
  const {
    field: { onChange, onBlur, value },
  } = useController({ name, control })
  const selectedOption = useMemo(
    () => options.find((option) => option.value === value),
    [options, value]
  )

  const handleChange: InputSelectSingleProps['handleChange'] = (option) => {
    onChange(option.value)
    onAfterChange && onAfterChange()
  }

  const handleBlur = () => {
    onBlur && onBlur()
  }

  return (
    <FormFieldWrapper
      label={label}
      required={required}
      showLabel={showLabel}
      error={error}
      showError={showError}
      showErrorOnlyOccered={showErrorOnlyOccered}
    >
      <InputSelectSingle
        label={label}
        buttonLabel={`${buttonLabel}`}
        options={options}
        height={height}
        disabled={disabled}
        selected={selectedOption ?? null}
        tabIndex={tabIndex}
        isError={error !== undefined}
        handleChange={handleChange}
        handleBlur={handleBlur}
      />
    </FormFieldWrapper>
  )
}
