import { Close } from '@mui/icons-material'
import clsx from 'clsx'
import { useRef } from 'react'

import { DialogBase } from '@/components/ui'

import { DialogFullScreenProps } from './type'

export const DialogFullScreen = ({
  title,
  children,
  isOpen,
  initialFocus,
  showClose = true,
  onClose,
}: DialogFullScreenProps) => {
  const closeButtonRef = useRef(null)

  return (
    <DialogBase
      isOpen={isOpen}
      onClose={onClose}
      initialFocus={initialFocus || closeButtonRef}
    >
      <div
        className={clsx(
          'tw-inline-block tw-transform tw-overflow-hidden tw-bg-white tw-text-left tw-transition-all',
          'tw-w-full lg:tw-w-96',
          'tw-h-screen lg:tw-max-h-[80vh] lg:tw-rounded'
        )}
      >
        <div
          className={clsx(
            'tw-flex tw-h-11 tw-items-center tw-px-4',
            'tw-bg-primary-500 tw-text-sm tw-font-bold tw-text-white',
            showClose ? 'tw-justify-between' : 'tw-justify-center'
          )}
        >
          {/* title center寄せ用 */}
          {showClose && <span className={clsx('tw-w-6')} />}
          <span>{title}</span>
          {showClose && (
            <Close
              ref={closeButtonRef}
              sx={{ fontSize: 24 }}
              onClick={onClose}
              className={clsx('tw-cursor-pointer hover:tw-opacity-hover')}
            />
          )}
        </div>
        <div className={clsx('tw-h-[calc(100%-44px)] tw-overflow-y-auto')}>
          {children}
        </div>
      </div>
    </DialogBase>
  )
}
