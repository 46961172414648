import clsx from 'clsx'

import type { FavoriteCheckboxProps } from './type'

import { InputPlusStyleCheckbox } from '@/components/ui'

export const FavoriteCheckbox = ({
  label,
  checked = false,
  isLoading = false,
  handleChange,
}: FavoriteCheckboxProps) => {
  return (
    <div
      className={clsx(
        'tw-flex tw-items-center tw-justify-between',
        'tw-gap-2 tw-px-2',
        'tw-h-11'
      )}
    >
      <div className={clsx('tw-flex-1', 'tw-overflow-hidden')}>
        <p className={clsx('tw-block', 'tw-text-sm', 'tw-truncate')}>{label}</p>
      </div>
      <div
        className={clsx(
          'tw-flex-none',
          'tw-flex tw-items-center tw-justify-end',
          'tw-gap-1'
        )}
      >
        <InputPlusStyleCheckbox
          checked={checked}
          label={checked ? '削除する' : '追加する'}
          showLabel={true}
          disabled={isLoading}
          handleChange={handleChange}
        />
      </div>
    </div>
  )
}
