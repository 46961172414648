import { Switch } from '@headlessui/react'
import { CheckBox, IndeterminateCheckBox } from '@mui/icons-material'
import clsx from 'clsx'
import * as React from 'react'

import { InputCheckboxProps } from './type'

export const InputCheckbox = React.forwardRef<
  HTMLDivElement,
  InputCheckboxProps
>(
  (
    {
      id,
      disabled,
      checked,
      isIndeterminate,
      label,
      showLabel,
      enterIndex,
      tabIndex,
      handleChange,
      handleFocus,
      handleBlur,
    },
    ref
  ) => {
    return (
      <Switch.Group
        ref={ref}
        id={id}
        as="div"
        className={clsx([
          'tw-flex tw-items-center tw-justify-start',
          'tw-cursor-pointer',
        ])}
      >
        <Switch
          as="div"
          checked={isIndeterminate ? false : checked}
          onChange={disabled ? () => {} : handleChange}
          onFocus={disabled ? () => {} : handleFocus}
          onBlur={disabled ? () => {} : handleBlur}
          className={clsx([
            'tw-h-5 tw-w-5',
            'tw-inline-flex tw-items-center tw-justify-center',
            'tw-rounded tw-bg-white',
            'tw-border-2 tw-border-solid ',
            'tw-outline-none',
            disabled
              ? [
                  'tw-cursor-not-allowed tw-opacity-70',
                  'tw-border-gray-400 focus:tw-ring-0',
                ]
              : [
                  'tw-cursor-pointer',
                  'tw-border-primary-500 focus:tw-outline-none',
                ],
          ])}
          data-enterindex={enterIndex ? (disabled ? -1 : tabIndex ?? 0) : null}
        >
          {checked && !disabled && (
            <CheckBox className={clsx('tw-text-primary-500')} />
          )}
          {isIndeterminate && !disabled && (
            <IndeterminateCheckBox className={clsx('tw-text-primary-500')} />
          )}
          {disabled && (
            <IndeterminateCheckBox className={clsx('tw-text-gray-400')} />
          )}
        </Switch>
        <Switch.Label
          as="span"
          className={clsx([
            showLabel ? ['tw-ml-2', 'tw-text-sm'] : ['tw-sr-only'],
          ])}
        >
          {label}
        </Switch.Label>
      </Switch.Group>
    )
  }
)

InputCheckbox.displayName = 'InputCheckbox'
