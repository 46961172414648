import type { WithMaintenanceProps } from './type'

import { MaintenanceScreen } from '@/components/features/maintenance/MaintenanceScreen'
import { useSelectorMaintenanceMode } from '@/stores'

export const MaintenanceDetection = ({ children }: WithMaintenanceProps) => {
  const isMaintenanceMode = useSelectorMaintenanceMode()

  if (isMaintenanceMode) {
    return <MaintenanceScreen />
  } else {
    return <>{children}</>
  }
}
