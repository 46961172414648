import { Category } from '@/api/@types'

export const getMargedCategoryItems = (category: Category) => {
  // 「すべて」の件数
  const total = {
    categoryId: category.categoryId,
    categoryName: `${category.categoryName}すべて`,
    numberOfProducts: category.numberOfProducts,
  }
  // NOTE: サブカテゴリーだけでなく、大分類の「すべて」も表示する
  return [total, ...(category.childrenCategories || [])]
}
