export const isAccept = ({
  files,
  acceptList,
}: {
  files: FileList
  acceptList?: string[]
}) => {
  if (!acceptList) {
    return true
  }

  for (let i = 0; i < files.length; i++) {
    if (!acceptList.includes(files[i].type)) {
      return false
    }
  }

  return true
}
