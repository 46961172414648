import clsx from 'clsx'
import NextLink from 'next/link'
import React from 'react'

import type { LinkButtonProps } from './type'

import { variants, sizes, round } from './const'

export const LinkButton = ({
  onClick,
  children,
  variant = 'primary',
  size = 'md',
  roundType = 'md',
  roundDirection = {
    topLeft: true,
    topRight: true,
    bottomLeft: true,
    bottomRight: true,
  },
  isBlock = true,
  ...props
}: LinkButtonProps) => {
  return (
    <NextLink {...props} passHref>
      <a
        onClick={onClick}
        className={clsx(
          'tw-gap-2',
          'tw-flex tw-items-center tw-justify-center',
          'tw-font-bold',
          'tw-transition-all tw-duration-300',
          'focus:tw-outline-none',
          'disabled:tw-cursor-not-allowed',
          isBlock && 'tw-w-full',
          variants[variant],
          sizes[size].height,
          round(roundType, roundDirection)
        )}
      >
        {children}
      </a>
    </NextLink>
  )
}
