import { useQuery } from 'react-query'

import { aspidaClient } from '@/lib/aspida'
import { QueryConfig, queryClient } from '@/lib/react-query'

import { userKeyFactory } from './key'

const getMe = () => {
  return aspidaClient.v1.user.me.$get()
}

export type UseMeOptions = {
  config?: QueryConfig<typeof getMe>
}

export const useMe = ({ config }: UseMeOptions) => {
  const queryConfig: QueryConfig<typeof getMe> = {
    ...config,
    queryKey: userKeyFactory.me(),
    queryFn: () => getMe(),
  }
  return useQuery(queryConfig)
}

export const invalidateUser = async () => {
  const key = userKeyFactory.me()
  await queryClient.invalidateQueries(key)
}

export const resetUser = async () => {
  const key = userKeyFactory.me()
  await queryClient.resetQueries(key)
}
