import Cookie from 'js-cookie'

export const storage = {
  get: (key: string) => {
    return Cookie.get(key)
  },
  set: (key: string, value: string) => {
    Cookie.set(key, value)
  },
  remove: (key: string) => {
    Cookie.remove(key)
  },
}
