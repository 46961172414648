import { atom, useRecoilState } from 'recoil'

import type { SalesType } from '@/api/@types'

export const salesTypesMasterState = atom<SalesType[]>({
  key: 'sales-type-master',
  default: [],
})

export const useSalesTypesMaster = () => {
  const [salesTypesMaster, setSalesTypesMaster] = useRecoilState(
    salesTypesMasterState
  )

  return { salesTypesMaster, setSalesTypesMaster }
}
