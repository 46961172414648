import { useController } from 'react-hook-form'

import type { FormFieldCurrencyRangeProps } from './type'
import type { FieldError } from 'react-hook-form'

import { FormFieldWrapper, InputCurrencyRange } from '@/components/ui'

export const FormFieldCurrencyRange = ({
  label,
  name,
  control,
  error,
  showError,
  height,
}: FormFieldCurrencyRangeProps) => {
  const {
    field: { onChange, onBlur, value },
  } = useController({ name, control })

  const errors = [error?.lower, error?.upper].filter(
    (item): item is FieldError => item !== undefined
  )

  return (
    <FormFieldWrapper label={label} error={errors} showError={showError}>
      <InputCurrencyRange
        value={value}
        height={height}
        isError={errors.length > 0}
        handleChange={onChange}
        handleBlur={onBlur}
      />
    </FormFieldWrapper>
  )
}
