import { unflatten } from 'flat'
import { useRouter } from 'next/router'

import type { ParsedUrlQuery } from 'querystring'

/**
 * @param converter query stringの型付け処理関数
 */
export const useQueryString = <T>(converter: (query: ParsedUrlQuery) => T) => {
  let router = useRouter()

  if (!router.isReady) {
    return { query: {} as T, isReady: router.isReady, isValidQuery: false } // HACK
  }

  try {
    const typedQuery = converter(unflatten(router.query))

    return { query: typedQuery, isReady: router.isReady, isValidQuery: true }
  } catch (error) {
    return { query: {} as T, isReady: router.isReady, isValidQuery: false } // HACK
  }
}
