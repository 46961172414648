import { Add, ShoppingCart } from '@mui/icons-material'
import clsx from 'clsx'

import { IconWithLabel, IconWrapper } from '@/components/ui'
import { CART_TITLE } from '@/const'
import { useCartItemCount } from '@/hooks/features/cart'
import { useCartCount } from '@/stores'

export const CartWithCount = () => {
  const { cartCount, setCartCount } = useCartCount()

  useCartItemCount({
    config: {
      onSuccess: (data) => {
        if (cartCount.count !== data.count) {
          setCartCount({
            count: data.count,
          })
        }
      },
    },
  })

  return (
    <span className={clsx('tw-relative tw-inline-block', 'tw-pt-2 lg:tw-pt-0')}>
      <IconWithLabel label={CART_TITLE} showLabelSp={false}>
        <ShoppingCart
          className={clsx('tw-text-primary-500')}
          sx={{ fontSize: 32 }}
        />
      </IconWithLabel>
      <span
        className={clsx(
          'tw-absolute',
          'tw-top-0 tw-right-0 lg:tw--top-2',
          'tw-h-5 tw-w-5 tw-rounded-full',
          'tw-flex tw-items-center tw-justify-center',
          'tw-bg-accentYellow-500',
          'tw-text-2xs tw-font-bold tw-text-primary-500'
        )}
      >
        {cartCount.count < 100 ? cartCount.count : 99}
      </span>
      {cartCount.count >= 100 && (
        <span
          className={clsx(
            'tw-absolute tw-top-0 tw--right-0.5',
            'tw-flex tw-items-start tw-justify-end'
          )}
        >
          <IconWrapper>
            <Add className={clsx('tw-text-primary-500')} sx={{ fontSize: 8 }} />
          </IconWrapper>
        </span>
      )}
    </span>
  )
}
