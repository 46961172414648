import { useQuery } from 'react-query'

import { contentfulPreviewClient } from '@/lib/contentful'
import { QueryConfig } from '@/lib/react-query'
import type { IMaintenancePageFields } from '@/types/generated/contentful'

import { contentfulPreviewMaintenanceKeyFactory } from './key'

export const getContentfulMaintenancePreview = ({
  id,
}: Parameters<
  typeof contentfulPreviewMaintenanceKeyFactory.maintenance
>[0]) => {
  return contentfulPreviewClient.getEntry<IMaintenancePageFields>(id)
}

export type UseContentfulPreviewMaintenanceOptions = Parameters<
  typeof contentfulPreviewMaintenanceKeyFactory.maintenance
>[0] & {
  config?: QueryConfig<typeof getContentfulMaintenancePreview>
}

export const useContentfulPreviewMaintenance = ({
  config,
  id,
}: UseContentfulPreviewMaintenanceOptions) => {
  const queryConfig: QueryConfig<typeof getContentfulMaintenancePreview> = {
    ...config,
    queryKey: contentfulPreviewMaintenanceKeyFactory.maintenance({
      id,
    }),
    queryFn: () =>
      getContentfulMaintenancePreview({
        id,
      }),
  }
  return useQuery(queryConfig)
}
