import { useQuery } from 'react-query'

import type { Item } from '@/api/@types'
import { aspidaClient } from '@/lib/aspida'
import { QueryConfig } from '@/lib/react-query'

import { itemKeyFactory } from './key'

export const getItemDetail = (itemCode: Item['itemCode']) => {
  return aspidaClient.v1.items._itemCode(itemCode).$get()
}

export type UseItemDetailOptions = {
  itemCode: Item['itemCode']
  config?: QueryConfig<typeof getItemDetail>
}

export const useItemDetail = ({ itemCode, config }: UseItemDetailOptions) => {
  const queryConfig: QueryConfig<typeof getItemDetail> = {
    ...config,
    queryKey: itemKeyFactory.detail(itemCode),
    queryFn: () => getItemDetail(itemCode),
  }
  return useQuery(queryConfig)
}
