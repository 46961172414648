import clsx from 'clsx'

import type { FavoriteIconProps } from './type'

export const FavoriteIcon = ({ size, type = 'empty' }: FavoriteIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || 26}
      height={size || 26}
      viewBox="0 0 26 26"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        d="M19 5.20833V19.7917H5V5.20833H19ZM20.1 3.125H3.9C3.4 3.125 3 3.54167 3 4.0625V20.9375C3 21.3542 3.4 21.875 3.9 21.875H20.1C20.5 21.875 21 21.3542 21 20.9375V4.0625C21 3.54167 20.5 3.125 20.1 3.125ZM11 7.29167H17V9.375H11V7.29167ZM11 11.4583H17V13.5417H11V11.4583ZM7 7.29167H9V9.375H7V7.29167ZM7 11.4583H9V13.5417H7V11.4583Z"
        className={clsx('tw-fill-primary-500')}
      />
      <path
        d="M18.4839 25.2057L18.998 25.6932L19.514 25.2076L20.239 24.5253L20.2406 24.5238L20.2949 24.4725C21.545 23.2891 22.6406 22.252 23.4182 21.2612C24.2164 20.2439 24.75 19.1896 24.75 17.9687C24.75 15.9795 23.2328 14.3542 21.25 14.3542C20.4219 14.3542 19.6304 14.6519 19 15.1484C18.3696 14.6519 17.5781 14.3542 16.75 14.3542C14.7672 14.3542 13.25 15.9795 13.25 17.9687C13.25 19.1896 13.7836 20.244 14.582 21.2607C15.3622 22.2543 16.4625 23.2936 17.7179 24.4794L17.7589 24.5182L17.76 24.5192L18.4839 25.2057Z"
        fill="white"
        stroke="white"
        strokeWidth="1.5"
      />
      <path
        d="M19 24.6614L18.275 23.9739C15.7 21.5417 14 19.9375 14 17.9687C14 16.3646 15.21 15.1042 16.75 15.1042C17.62 15.1042 18.455 15.526 19 16.1927C19.545 15.526 20.38 15.1042 21.25 15.1042C22.79 15.1042 24 16.3646 24 17.9687C24 19.9375 22.3 21.5417 19.725 23.9792L19 24.6614Z"
        strokeWidth="1.5"
        className={clsx(
          type === 'empty' ? 'tw-fill-white' : 'tw-fill-accentYellow-500',
          'tw-stroke-primary-500'
        )}
      />
      <path d="M19 5V19H5V5H19ZM20.1 3H3.9C3.4 3 3 3.4 3 3.9V20.1C3 20.5 3.4 21 3.9 21H20.1C20.5 21 21 20.5 21 20.1V3.9C21 3.4 20.5 3 20.1 3ZM11 7H17V9H11V7ZM11 11H17V13H11V11ZM7 7H9V9H7V7ZM7 11H9V13H7V11Z" />
    </svg>
  )
}
