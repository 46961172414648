import { ChevronRightOutlined } from '@mui/icons-material'
import clsx from 'clsx'

import type { MultiSelectButtonProps } from './type'

export const MultiSelectButton = ({
  placeholder,
  items,
  isSelected,
  onClick,
}: MultiSelectButtonProps) => {
  return (
    <button
      type="button"
      className={clsx(
        'tw-w-full',
        'tw-flex tw-items-center tw-justify-between',
        'tw-rounded tw-border tw-border-gray-300',
        'tw-transition tw-duration-stateChange',
        isSelected ? 'tw-cursor-default' : 'hover:tw-opacity-hover'
      )}
      onClick={isSelected ? undefined : onClick}
    >
      <div
        className={clsx(
          'tw-flex-1',
          'tw-min-h-[42px]', // 親のborderとあわせて44pxにする
          'tw-flex tw-items-center',
          'tw-px-2 tw-py-[7px]' // 親のborderとあわせて8pxにする
        )}
      >
        {isSelected ? (
          items
        ) : (
          <p className={clsx('tw-text-sm tw-text-gray-600')}>{placeholder}</p>
        )}
      </div>
      {isSelected ? (
        <button
          type="button"
          className={clsx(
            'tw-transition tw-duration-stateChange',
            'hover:tw-opacity-hover'
          )}
          onClick={onClick}
        >
          <ChevronRightOutlined
            sx={{ fontSize: 24 }}
            className={clsx('tw-text-gray-600')}
          />
        </button>
      ) : (
        <ChevronRightOutlined
          sx={{ fontSize: 24 }}
          className={clsx('tw-text-gray-600')}
        />
      )}
    </button>
  )
}
