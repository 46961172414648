export const CONTENTFUL_SPACE = process.env.NEXT_PUBLIC_CONTENTFUL_SPACE ?? ''
export const CONTENTFUL_ENVIRONMENT =
  process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT ?? ''
export const CONTENTFUL_ACCESS_TOKEN =
  process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN ?? ''
export const CONTENTFUL_PREVIEW_ACCESS_TOKEN =
  process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW_ACCESS_TOKEN ?? ''
export const CONTENTFUL_PREVIEW_SECRET =
  process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW_SECRET ?? ''

export const CONTENTFUL_API_BASE = `https://cdn.contentful.com/spaces/${CONTENTFUL_SPACE}/environments/${CONTENTFUL_ENVIRONMENT}`
export const CONTENTFUL_PREVIEW_HOST = 'preview.contentful.com'

/** @type { [key: string]: @/types/generated/contentful/CONTENT_TYPE } */
export const CONTENT_TYPES = {
  post: 'post',
  category: 'category',
  privacyPolicy: 'privacyPolicy',
  termsOfService: 'termsOfService',
  tokushoho: 'tokushoho',
  maintenancePage: 'maintenancePage',
  announcement: 'announcement',
  help: 'alshainHelp',
} as const

export const SORT_KEY = {
  posts: 'fields.category.sys.id,fields.sort',
  categories: 'fields.sort',
  privacyPolicy: '-sys.updatedAt',
  termsOfService: '-sys.updatedAt',
  tokushoho: '-sys.updatedAt',
  maintenancePage: '-sys.updatedAt',
  announcement: '-fields.announcementDate',
  help: '-sys.updatedAt',
}

export const isContentfulEntryNotFoundError = (e: unknown) => {
  return (e as any)?.sys?.id === 'NotFound'
}
