import clsx from 'clsx'
import React from 'react'

import type { ItemCardListProps } from './type'

import { ItemCard } from '@/components/features/item'

export const ItemCardList = ({
  name,
  listItems,
  selectable = false,
  isOutsideModal,
  handleChange,
  handleChangeFavorite,
}: ItemCardListProps) => {
  return (
    <ul className={clsx('tw-grid tw-grid-cols-1', 'tw-gap-2')}>
      {listItems.map((listItem) => (
        <li key={`item-list-card-item_${name}_${listItem.item.itemCode}`}>
          <ItemCard
            item={listItem.item}
            selectable={selectable}
            selected={listItem.selected}
            isOutsideModal={isOutsideModal}
            handleFavorite={handleChangeFavorite}
            onChangeSelected={handleChange}
          />
        </li>
      ))}
    </ul>
  )
}
