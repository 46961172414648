import {
  // ORDER_TITLE,
  ITEM_TITLE,
  FAVORITE_TITLE_ALIAS,
  CART_TITLE,
  APPROVE_TITLE,
  SHIPPING_INFORMATION_TITLE_SHORT,
  ORDER_HISTORY_FIELDS,
  ORDER_SHIPPING_INFO_FIELDS,
  CART_FIELDS,
} from '@/const/label'

export const ERROR_MESSAGE = {
  required: '必須です',
  invalid: '不正です',
  incorrect: '正しくありません',
  noItem: '商品がありません',
  null: '存在しません',
  status: '不正なステータスです',
  over: '規定の値を超えています',
  sameAsNow: '現在使用されている値です',
} as const

/** 400エラーのカスタムメッセージ */
export const OPERATIONAL_ERROR_MESSAGE = {
  ADDRESS: {
    SEARCH_BY_ZIP_CODE: `利用できない${ORDER_SHIPPING_INFO_FIELDS.zipCode}です。`,
  },
  FAVORITE: {
    COPY: {
      ...ERROR_MESSAGE,
      expired: `販売終了${ITEM_TITLE}です`,
      unavailable: `販売期間外${ITEM_TITLE}です`,
      forbidden: `扱えない${ITEM_TITLE}です`,
    },
  },
  CART: {
    DELIVERY_DATE: {
      ...ERROR_MESSAGE,
      invalid: '存在しない日付です',
      incorrect: `最短納品日以降を選択してください`,
    },
    ORDER: {
      ...ERROR_MESSAGE,
      noItem: `${CART_TITLE}に${ITEM_TITLE}が追加されていません`,
      null: `${CART_TITLE}が存在しません`,
    },
  },
  ORDER: {
    // RETURN_TO_CART: {
    //   ...ERROR_MESSAGE,
    //   status: `${ORDER_TITLE}の${SHIPPING_INFORMATION_TITLE_SHORT}ステータスが未${SHIPPING_INFORMATION_TITLE_SHORT}でありません`,
    //   invalid: `${ORDER_HISTORY_FIELDS.orderNo}が不正な値です`,
    // },
    DELIVERY_DATE: {
      ...ERROR_MESSAGE,
      over: '指定したご希望納品日の注文は締切時間を過ぎてしまったので変更が出来ません',
    },
  },
  USER: {
    CHANGE_EMAIL: {
      sameAsNow: '現在と同じメールアドレスには変更できません',
    },
  },
}

export const ERROR_FIELD_NAMES = {
  // 商品関連
  itemCode: `${ITEM_TITLE}コード`,
  groupId: 'グループID',
  salonPriceLower: 'サロン価格下限',
  salonPriceUpper: 'サロン価格上限',
  retailPriceLower: 'メーカー希望小売価格下限',
  retailPriceUpper: 'メーカー希望小売価格上限',
  brandCodes: 'ブランド',
  categoryIds: 'カテゴリー',
  salesStartFrom: '販売開始範囲開始',
  salesStartTo: '販売開始範囲終了',
  keywords: '検索クエリワード',
  lineupCode: 'ラインアップコード',
  // 発注関連
  orderNo: ORDER_HISTORY_FIELDS.orderNo,
  deliveryDateFrom: '納品開始日',
  deliveryDateTo: '納品終了日',
  shipmentStatus: `${SHIPPING_INFORMATION_TITLE_SHORT}ステータス`,
  approvalStatus: `${APPROVE_TITLE}ステータス`,
  salesOrderNo: ORDER_HISTORY_FIELDS.salesOrderNo,
  // お気に入り関連
  setNo: `${FAVORITE_TITLE_ALIAS}番号`,
  // カート関連
  address1: '住所1',
  address2: '住所2',
  deliveryDate: '納品日',
  zipCode: ORDER_SHIPPING_INFO_FIELDS.zipCode,
  tel: ORDER_SHIPPING_INFO_FIELDS.tel,
  deliveryName: ORDER_SHIPPING_INFO_FIELDS.deliveryName,
  desiredDeliveryDate: CART_FIELDS.desiredDeliveryDate,
  // 注文取込関連
  systemType: '取込種別',
  orderDateFrom: '取込日（開始日）',
  fileContent: 'ファイル内容',
  fileName: 'ファイル名',
  fileId: 'ファイルID',
  // 請求書関連
  invoiceId: '請求書ID',
  // 共通
  storeCode: '店舗コード',
  page: 'ページ番号',
  pageSize: '表示件数',
}
