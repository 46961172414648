import { ChevronRightOutlined } from '@mui/icons-material'
import clsx from 'clsx'

import type { BreadcrumbProps } from './type'

import { BreadcrumbItem } from './BreadcrumbItem'

export const Breadcrumb = ({ items }: BreadcrumbProps) => {
  return (
    <div className={clsx('tw-flex tw-items-center', 'tw-space-x-2')}>
      {items.map((item, index) => {
        return (
          <div
            key={`breadcrumb-item_${index}_${item.label}`}
            className={clsx('tw-flex tw-items-center', 'tw-space-x-3')}
          >
            <BreadcrumbItem label={item.label} href={item.href} />
            {index < items.length - 1 && (
              <p className={clsx('tw-flex tw-items-center')}>
                <ChevronRightOutlined sx={{ fontSize: 16 }} />
              </p>
            )}
          </div>
        )
      })}
    </div>
  )
}

Breadcrumb.displayName = 'Breadcrumb'
