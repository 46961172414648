import { zodResolver } from '@hookform/resolvers/zod'
import { ChevronLeftOutlined } from '@mui/icons-material'
import clsx from 'clsx'
import { useCallback, useEffect, useState } from 'react'
import { useForm, useController } from 'react-hook-form'

import type {
  ItemDetailedSearchModalProps,
  ItemDetailedSearchModalFormVO,
  Panel,
} from './type'

import {
  DialogFullScreen,
  Button,
  FormFieldText,
  FormFieldCurrencyRange,
  FormFieldDateRange,
  LoadingOverlay,
  Spinner,
} from '@/components/ui'
import { KEYWORD_SEARCH_PLACEHOLDER } from '@/const'
import { useBrandGroups } from '@/hooks/features/brand'
import { useCategories } from '@/hooks/features/category'
import { useItemsCount } from '@/hooks/features/item'
import { useDebounce } from '@/hooks/util'

import { BrandPanel } from './BrandPanel'
import { BrandSelect } from './BrandSelect'
import { CategoryPanel } from './CategoryPanel'
import { CategorySelect } from './CategorySelect'
import {
  schema,
  formDefaultValues,
  panels,
  UPDATE_COUNT_INTERVAL_MILLISECOND,
} from './const'

export const ItemDetailedSearchModal = ({
  defaultSearchCondition,
  isOpen,
  onClose,
  onSubmit,
}: ItemDetailedSearchModalProps) => {
  const updateDebounceItemsCount = useDebounce(
    UPDATE_COUNT_INTERVAL_MILLISECOND
  )

  const [currentPanel, setCurrentPanel] = useState<Panel>('main')
  const [itemsCountSearchParams, setItemsCountSearchParams] = useState<
    Parameters<typeof useItemsCount>[0]['queryParams']
  >({})

  const brandGroups = useBrandGroups({})
  const categories = useCategories({})

  const { register, formState, control, handleSubmit, getValues } =
    useForm<ItemDetailedSearchModalFormVO>({
      resolver: schema && zodResolver(schema),
      defaultValues: defaultSearchCondition || formDefaultValues,
    })

  useEffect(() => {
    updateDebounceItemsCount(() => {
      const conditions = getValues()
      setItemsCountSearchParams({
        retailPriceLower: conditions.retailPrice?.lower || undefined,
        retailPriceUpper: conditions.retailPrice?.upper || undefined,
        brandCodes:
          conditions.brandCodes && !!conditions.brandCodes.length
            ? conditions.brandCodes
            : undefined,
        categoryIds:
          conditions.categoryIds && !!conditions.categoryIds.length
            ? conditions.categoryIds
            : undefined,
        salesStartFrom: conditions.salesStart?.from || undefined,
        salesStartTo: conditions.salesStart?.to || undefined,
        keywords: conditions.keywords || undefined,
        salonPriceLower: conditions.salonPrice?.lower || undefined,
        salonPriceUpper: conditions.salonPrice?.upper || undefined,
      })
    })
  }, [
    formState,
    getValues,
    setItemsCountSearchParams,
    updateDebounceItemsCount,
  ])

  /** 検索条件にhitする商品件数 */
  const itemsCountQuery = useItemsCount({
    // NOTE: 初期入力を引数で受け取っているorフォームに変更が入った場合に初回fetch
    config: {
      enabled: !!defaultSearchCondition || formState.isDirty,
      keepPreviousData: true,
    },
    queryParams: itemsCountSearchParams,
  })

  const categoriesController = useController({
    name: 'categoryIds',
    control,
  })

  const brandsController = useController({
    name: 'brandCodes',
    control,
  })

  /** カテゴリ追加 */
  const handleSelectCategories = useCallback(
    (
      selectedId: NonNullable<
        ItemDetailedSearchModalFormVO['categoryIds']
      >[number]
    ) => {
      const { value, onChange } = categoriesController.field
      if (!value) {
        onChange([selectedId])
      } else {
        onChange([...new Set([...value, selectedId])])
      }
      setCurrentPanel('main')
    },
    [categoriesController]
  )

  /** カテゴリ削除 */
  const handleUnselectCategories = useCallback(
    (
      selectedId: NonNullable<
        ItemDetailedSearchModalFormVO['categoryIds']
      >[number]
    ) => {
      const { value, onChange } = categoriesController.field
      onChange(value?.filter((categoryId) => categoryId !== selectedId))
    },
    [categoriesController]
  )

  /** ブランド追加 */
  const handleSelectBrands = useCallback(
    (
      selectedCode: NonNullable<
        ItemDetailedSearchModalFormVO['brandCodes']
      >[number]
    ) => {
      const { value, onChange } = brandsController.field
      if (!value) {
        onChange([selectedCode])
      } else {
        onChange([...new Set([...value, selectedCode])])
      }
      setCurrentPanel('main')
    },
    [brandsController]
  )

  /** ブランド削除 */
  const handleUnselectBrands = useCallback(
    (
      selectedCode: NonNullable<
        ItemDetailedSearchModalFormVO['brandCodes']
      >[number]
    ) => {
      const { value, onChange } = brandsController.field
      onChange(value?.filter((brandId) => brandId !== selectedCode))
    },
    [brandsController]
  )

  /** モーダル閉処理 */
  const handleClose = () => {
    onClose()
    setCurrentPanel('main')
  }

  return (
    <DialogFullScreen
      isOpen={isOpen}
      title={
        <div className={clsx('tw-flex tw-items-center tw-space-x-2')}>
          {panels[currentPanel].showBack && (
            <ChevronLeftOutlined
              sx={{ fontSize: 20 }}
              className={clsx('tw-cursor-pointer hover:tw-opacity-hover')}
              onClick={() => setCurrentPanel('main')}
            />
          )}
          <span>{panels[currentPanel].title}</span>
        </div>
      }
      showClose={panels[currentPanel].showClose}
      onClose={handleClose}
    >
      <LoadingOverlay
        isLoading={brandGroups.isFetching || categories.isFetching}
      />
      {brandGroups.data && categories.data && (
        <>
          {/* メインパネル */}
          <div
            className={clsx(currentPanel !== 'main' && 'tw-hidden', 'tw-p-4')}
          >
            <form
              onSubmit={handleSubmit((values) => {
                onSubmit(values)
                handleClose()
              })}
              id="detailed-search-condition-form"
              className={clsx('tw-space-y-4')}
            >
              <FormFieldText
                label="キーワード"
                placeholder={KEYWORD_SEARCH_PLACEHOLDER}
                error={formState.errors['keywords']}
                type="text"
                registration={register('keywords')}
              />

              <CategorySelect
                categories={categories.data.categories}
                categoryIds={categoriesController.field.value}
                errors={categoriesController.fieldState.error}
                handleOpenPanel={() => setCurrentPanel('category')}
                handleUnselect={handleUnselectCategories}
              />

              <BrandSelect
                brandGroups={brandGroups.data.brandsIndex}
                brandCodes={brandsController.field.value}
                errors={brandsController.fieldState.error}
                handleOpenPanel={() => setCurrentPanel('brand')}
                handleUnselect={handleUnselectBrands}
              />

              <FormFieldCurrencyRange
                label="サロン価格"
                error={formState.errors['salonPrice']}
                name={'salonPrice'}
                control={control}
              />

              <FormFieldCurrencyRange
                label="メーカー希望小売価格"
                error={formState.errors['retailPrice']}
                name={'retailPrice'}
                control={control}
              />

              <FormFieldDateRange
                label="販売開始日"
                error={formState.errors['salesStart']}
                name={'salesStart'}
                control={control}
              />

              <div className={clsx('tw-flex tw-items-center')}>
                <p className={clsx('tw-text-sm')}>{'検索結果：'}</p>
                {itemsCountQuery.isFetching ? (
                  <div className={clsx('tw-mx-2')}>
                    <Spinner size={'sm'} variant="secondary" />
                  </div>
                ) : (
                  <p className={clsx('tw-text-sm')}>
                    {itemsCountQuery.data?.count ?? 0}件
                  </p>
                )}
              </div>

              <div className={clsx('tw-mt-4')}>
                <Button type="submit" size="lg">
                  検索する
                </Button>
              </div>
            </form>
          </div>

          {/* カテゴリ選択パネル */}
          <div className={clsx(currentPanel !== 'category' && 'tw-hidden')}>
            <CategoryPanel
              categories={categories.data.categories}
              handleSelect={handleSelectCategories}
            />
          </div>

          {/* ブランド選択パネル */}
          <div className={clsx(currentPanel !== 'brand' && 'tw-hidden')}>
            <BrandPanel
              brandGroups={brandGroups.data.brandsIndex}
              handleSelect={handleSelectBrands}
            />
          </div>
        </>
      )}
    </DialogFullScreen>
  )
}
