import clsx from 'clsx'

import type { CartShippingAddressProps } from './type'

import { CartShippingAddressModal } from '@/components/features/cart'
import { TextButton } from '@/components/ui'
import { useModal } from '@/hooks/util'

export const CartShippingAddress = ({
  shippingInfo,
}: CartShippingAddressProps) => {
  const { isOpen, open, close } = useModal()

  return (
    <div
      className={clsx(
        'tw-border tw-border-gray-300',
        'tw-rounded-sm',
        'tw-px-2 tw-py-3',
        'tw-flex tw-items-stretch tw-justify-between'
      )}
    >
      <div className={clsx('tw-flex tw-flex-col tw-gap-1', 'tw-text-sm')}>
        <p className={clsx('tw-text-sm tw-font-bold')}>
          {shippingInfo.deliveryName}
        </p>
        <p>{`〒${shippingInfo.zipCode}`}</p>
        <p>{`${shippingInfo.address1} ${shippingInfo.address2}`}</p>
        <p>{`☎︎${shippingInfo.tel}`}</p>
      </div>
      <div
        className={clsx(
          'tw-ml-2 tw-pl-3',
          'tw-flex-shrink-0',
          'tw-border-l tw-border-gray-300',
          'tw-flex tw-items-center'
        )}
      >
        <TextButton onClick={open} tabIndex={-1}>
          変更
        </TextButton>
        <CartShippingAddressModal
          isOpen={isOpen}
          defaultValues={shippingInfo}
          onClose={close}
        />
      </div>
    </div>
  )
}
