export const useUa = () => {
  const isChorme = window.navigator.userAgent.match(/Chrome/)
  const isSafari = window.navigator.userAgent.match(/Safari/)

  const isIOS = window.navigator.userAgent.match(/iPhone OS/)
  const isMacOS = window.navigator.userAgent.match(/Mac OS/)
  const isIPadOS =
    window.navigator.userAgent.match(/Mac OS/) &&
    document.ontouchstart !== undefined
  const isWindows = window.navigator.userAgent.match(/Windows/)
  const isAndroid = window.navigator.userAgent.match(/Android/)

  return {
    isChorme,
    isSafari,
    isIOS,
    isIPadOS,
    isMacOS,
    isWindows,
    isAndroid,
  }
}
