import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import clsx from 'clsx'

import { DndItemProps } from './type'

export const DndItem = <T,>({ item, children }: DndItemProps<T>) => {
  const {
    isDragging,
    // 並び替えのつまみ部分に設定するプロパティ
    setActivatorNodeRef,
    attributes,
    listeners,
    // DOM全体に対して設定するプロパティ
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id: item.id,
    animateLayoutChanges: () => false,
  })
  return (
    <div
      ref={setNodeRef}
      style={{
        transform: CSS.Transform.toString(
          // アイテム間でサイズ違いがあると縮むため scaleY を調整
          transform ? { ...transform, scaleY: 1 } : null
        ),
        transition,
      }}
    >
      <div
        className={clsx(
          isDragging ? 'tw-cursor-grabbing tw-touch-none' : 'tw-cursor-grab'
        )}
        ref={setActivatorNodeRef}
        {...attributes}
        {...listeners}
      >
        {children}
      </div>
    </div>
  )
}
