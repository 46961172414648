import { Delete, DriveFileMove, Edit } from '@mui/icons-material'
import clsx from 'clsx'
import { useState, useMemo, useCallback } from 'react'

import {
  CopyFavoriteItemsModal,
  DeleteFavoriteItemsModal,
} from '@/components/features/favorite'
import {
  IconWrapper,
  InputCheckbox,
  ListOperationTitleSection,
  TextButton,
  WindowVirtualizer,
} from '@/components/ui'
import { NOTIFICATION_MESSAGE, ERROR_FIELD_NAMES } from '@/const'
import {
  useCopyFavoriteItems,
  useMoveFavoriteItems,
  useUpdateFavoriteItems,
} from '@/hooks/features/favorite'
import { useMyStore } from '@/hooks/features/store'
import { useModal, useNotification } from '@/hooks/util'
import { getErrorMessages } from '@/utils/text'

import { ItemCardWithAddCartForm } from '../ItemCardWithAddCartForm'
import { OperationColumn } from '../OperationColumn'
import { ListSelectableProps } from './type'

export const ListSelectable = ({
  control,
  register,
  items,
  allFavorites,
  currentFavorite,
  onSelectItem,
  onSelectAllItem,
  onRefetchItems,
  onClickEditTitle,
}: ListSelectableProps) => {
  const deleteModal = useModal()
  const copyModal = useModal()

  const { notify } = useNotification()

  const [errorMessages, setErrorMessages] = useState<string[]>([])

  const myStore = useMyStore({})
  const copyFavoriteItemsMutation = useCopyFavoriteItems({})
  const moveFavoriteItemsMutation = useMoveFavoriteItems({})
  const updateFavoriteItemsMutation = useUpdateFavoriteItems({})

  const isDepositStore = useMemo(() => {
    if (!myStore.data?.isDepositStore) {
      return false
    }
    // update
    return currentFavorite.isEditableByMe
  }, [myStore, currentFavorite])

  /** 選択中の商品数 */
  const selectedItemCount = useMemo(
    () =>
      items.reduce(
        (sum, current) => (sum += Number(current.selected ?? false)),
        0
      ),
    [items]
  )

  const handleItemDelete = useCallback(() => {
    setErrorMessages([])
    updateFavoriteItemsMutation.mutate(
      {
        setNo: currentFavorite.setNo,
        storeCode: currentFavorite.storeCode,
        data: {
          itemCodes: [
            ...items
              .filter((item) => !item.selected)
              .map((item) => item.itemCode),
          ],
        },
      },
      {
        onSuccess: () => {
          notify({
            id: 'NOTIFICATION_MESSAGE.FAVORITE_ITEMS.DELETE',
            type: 'success',
            message: NOTIFICATION_MESSAGE.FAVORITE_ITEMS.DELETE,
          })
          onRefetchItems && onRefetchItems()
          deleteModal.close()
        },
        onError: (error) => {
          if (error.response?.status === 400) {
            setErrorMessages(
              getErrorMessages({
                fields: error.response.data.fields,
                fieldNames: ERROR_FIELD_NAMES,
              })
            )
          }
        },
      }
    )
  }, [
    updateFavoriteItemsMutation,
    currentFavorite.setNo,
    currentFavorite.storeCode,
    items,
    notify,
    onRefetchItems,
    deleteModal,
  ])

  const handleItemCopy = useCallback(
    (toFavoriteSetNo: number) => {
      setErrorMessages([])
      copyFavoriteItemsMutation.mutate(
        {
          setNo: currentFavorite.setNo,
          storeCode: currentFavorite.storeCode,
          data: {
            itemCodes: items
              .filter((item) => item.selected)
              .map((item) => item.itemCode),
            toFavoriteSetNo,
          },
        },
        {
          onSuccess: () => {
            notify({
              id: 'NOTIFICATION_MESSAGE.FAVORITE_ITEMS.COPY',
              type: 'success',
              message: NOTIFICATION_MESSAGE.FAVORITE_ITEMS.COPY,
            })
            copyModal.close()
          },
          onError: (error) => {
            if (error.response?.status === 400) {
              setErrorMessages(
                getErrorMessages({
                  fields: error.response.data.fields,
                  fieldNames: ERROR_FIELD_NAMES,
                })
              )
            }
          },
        }
      )
    },
    [
      copyFavoriteItemsMutation,
      currentFavorite.setNo,
      currentFavorite.storeCode,
      items,
      notify,
      copyModal,
    ]
  )

  const handleItemMove = useCallback(
    (toFavoriteSetNo: number) => {
      setErrorMessages([])
      moveFavoriteItemsMutation.mutate(
        {
          setNo: currentFavorite.setNo,
          storeCode: currentFavorite.storeCode,
          data: {
            itemCodes: items
              .filter((item) => item.selected)
              .map((item) => item.itemCode),
            toFavoriteSetNo,
          },
        },
        {
          onSuccess: () => {
            notify({
              id: 'NOTIFICATION_MESSAGE.FAVORITE_ITEMS.MOVE',
              type: 'success',
              message: NOTIFICATION_MESSAGE.FAVORITE_ITEMS.MOVE,
            })
            onRefetchItems && onRefetchItems()
            copyModal.close()
          },
          onError: (error) => {
            if (error.response?.status === 400) {
              setErrorMessages(
                getErrorMessages({
                  fields: error.response.data.fields,
                  fieldNames: ERROR_FIELD_NAMES,
                })
              )
            }
          },
        }
      )
    },
    [
      moveFavoriteItemsMutation,
      currentFavorite.setNo,
      currentFavorite.storeCode,
      items,
      notify,
      onRefetchItems,
      copyModal,
    ]
  )

  /** 商品の複製・移動モーダル閉処理 */
  const handleCloseCopyMoveModal = () => {
    setErrorMessages([])
    copyModal.close()
  }

  /** すべて選択チェックボックスの状態 */
  const allSelectState = useMemo(() => {
    const selectedItemCount = items.filter((item) => item.selected).length
    if (selectedItemCount === items.length) {
      return 'all'
    } else if (selectedItemCount === 0) {
      return 'none'
    } else {
      return 'indeterminate'
    }
  }, [items])

  /** 商品の削除警告モーダル閉処理 */
  const handleCloseDeleteModal = () => {
    setErrorMessages([])
    deleteModal.close()
  }

  return (
    <>
      <div className={clsx('tw-mb-3')}>
        <TextButton variant="tertiary" onClick={onClickEditTitle}>
          <span className={clsx('tw-inline-flex tw-items-center tw-gap-1')}>
            <IconWrapper>
              <Edit sx={{ fontSize: 24 }} />
            </IconWrapper>
            <span>
              {isDepositStore ? 'タイトル編集・共有設定' : 'タイトル編集'}
            </span>
          </span>
        </TextButton>
      </div>
      <ListOperationTitleSection>
        <div
          className={clsx(
            'tw-w-full',
            'tw-flex tw-items-center tw-justify-between'
          )}
        >
          <div>
            <p>{`${selectedItemCount}件選択中`}</p>
          </div>
          <div
            className={clsx(
              'tw-flex tw-items-center',
              'tw-gap-4',
              'tw-text-gray-600'
            )}
          >
            <TextButton
              type="button"
              variant="primary"
              disabled={!selectedItemCount}
              onClick={selectedItemCount ? copyModal.open : undefined}
            >
              <span
                className={clsx('tw-inline-flex tw-items-center', 'tw-gap-0.5')}
              >
                <IconWrapper>
                  <DriveFileMove sx={{ fontSize: 24 }} />
                </IconWrapper>
                <span>移動</span>
              </span>
            </TextButton>
            <CopyFavoriteItemsModal
              isOpen={copyModal.isOpen}
              currentFavorite={currentFavorite}
              favorites={allFavorites}
              isMutatingCopy={copyFavoriteItemsMutation.isLoading}
              isMutatingMove={moveFavoriteItemsMutation.isLoading}
              errorMessages={errorMessages}
              onClose={handleCloseCopyMoveModal}
              onCopy={handleItemCopy}
              onMove={handleItemMove}
            />

            {currentFavorite.isEditableByMe && (
              <>
                <TextButton
                  type="button"
                  variant="alert"
                  disabled={!selectedItemCount}
                  onClick={selectedItemCount ? deleteModal.open : undefined}
                >
                  <span
                    className={clsx(
                      'tw-inline-flex tw-items-center',
                      'tw-gap-0.5'
                    )}
                  >
                    <IconWrapper>
                      <Delete sx={{ fontSize: 24 }} />
                    </IconWrapper>
                    <span>削除</span>
                  </span>
                </TextButton>
                <DeleteFavoriteItemsModal
                  favoriteName={currentFavorite.name}
                  isOpen={deleteModal.isOpen}
                  isMutatingDelete={updateFavoriteItemsMutation.isLoading}
                  errorMessages={errorMessages}
                  onClose={handleCloseDeleteModal}
                  onDelete={handleItemDelete}
                />
              </>
            )}
          </div>
        </div>
      </ListOperationTitleSection>
      <div
        className={clsx(
          'tw-w-full',
          'tw-flex tw-items-center tw-justify-between',
          'tw-py-2 tw-px-2',
          'tw-border-b tw-border-gray-300'
        )}
      >
        <OperationColumn>
          <InputCheckbox
            checked={allSelectState === 'all'}
            isIndeterminate={allSelectState === 'indeterminate'}
            showLabel={true}
            label="すべて選択"
            enterIndex
            handleChange={() => onSelectAllItem(allSelectState !== 'all')}
          />
        </OperationColumn>
      </div>

      <ul className={clsx('tw-px-2')}>
        <WindowVirtualizer
          total={items.length}
          defaultSize={56}
          spacer={320}
          overscan={4}
          //onChangeRange={() => refreshElement()}
        >
          {items.map((item, index) => (
            <li
              key={item.itemCode}
              className={clsx(
                'tw-h-auto',
                'tw-flex tw-items-center',
                'tw-mt-2 tw-gap-x-1',
                'tw-rounded tw-border tw-border-gray-300'
              )}
            >
              <div
                className={clsx(
                  'tw-flex-none',
                  'tw-py-2 tw-pl-2',
                  'tw-flex tw-items-center'
                )}
              >
                <InputCheckbox
                  checked={!!item.selected}
                  label={item.itemName}
                  handleChange={(value) => onSelectItem(value, item)}
                />
              </div>

              <div className={clsx('tw-flex-1')}>
                <ItemCardWithAddCartForm
                  item={item}
                  itemIndex={index}
                  disabled={true}
                  control={control}
                  register={register}
                />
              </div>
            </li>
          ))}
        </WindowVirtualizer>
      </ul>
    </>
  )
}
