import clsx from 'clsx'
import { useMemo } from 'react'

import type { ErrorPageProps } from './type'

import { PageWithHeader, PageWrapper } from '@/components/layout'
import { LinkButton } from '@/components/ui'
import { ROUTE } from '@/const'

import { ERROR_MESSAGE } from './const'

const getErrorMessage = (statusCode: ErrorPageProps['statusCode']) => {
  if (statusCode === undefined) return 'エラーが発生しました'
  if ([401, 403, 404, 500].includes(statusCode)) {
    return ERROR_MESSAGE[statusCode as 401 | 403 | 404 | 500]
  }
  return 'エラーが発生しました'
}

export const ErrorPage = ({ statusCode }: ErrorPageProps) => {
  const showLinkButton = useMemo(
    () => statusCode === 401 || statusCode === 403,
    [statusCode]
  )

  return (
    <PageWithHeader withSearch={true}>
      <PageWrapper withBreadcrumb={false} spacingX={'sm'} pcWidth={'half'}>
        <div className={clsx('tw-py-2', 'tw-text-center')}>
          <p className={clsx('tw-text-sm')}>エラー</p>
          {statusCode && (
            <h1 className={clsx('tw-mt-1 tw-text-3xl tw-font-bold')}>
              {statusCode}
            </h1>
          )}
          <p
            className={clsx(
              'tw-mt-8 tw-whitespace-pre-wrap tw-text-sm tw-leading-8'
            )}
          >
            {getErrorMessage(statusCode)}
          </p>
          {showLinkButton && (
            <div className={clsx('tw-mt-20')}>
              <LinkButton size="lg" href={ROUTE.login}>
                ログイン画面へ
              </LinkButton>
            </div>
          )}
        </div>
      </PageWrapper>
    </PageWithHeader>
  )
}
