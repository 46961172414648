import clsx from 'clsx'

import type { PageWrapperProps } from './type'

import { SectionWrapper } from '@/components/layout'

export const PageWrapper = ({
  children,
  spacingX,
  withBreadcrumb = false,
  pcWidth = 'full',
}: PageWrapperProps) => {
  return (
    <div className={clsx('tw-flex tw-justify-center')}>
      <div
        className={clsx(
          'tw-w-full tw-max-w-pcMax lg:tw-max-w-none',
          pcWidth === 'half' ? 'lg:tw-w-pcHalf' : 'lg:tw-w-pcMax',
          withBreadcrumb && 'tw-pt-2 tw-pb-4'
        )}
      >
        <SectionWrapper
          spacing={{
            x: {
              sp: spacingX,
              pc: 'none',
            },
            y: {
              sp: withBreadcrumb ? 'none' : 'base',
              pc: withBreadcrumb ? 'none' : 'base',
            },
          }}
        >
          {children}
        </SectionWrapper>
      </div>
    </div>
  )
}
