import clsx from 'clsx'
import { useCallback, useMemo } from 'react'
import { useController } from 'react-hook-form'

import type { FormFieldSelectMonthProps } from './type'

import { FormFieldWrapper, InputSelectSingle } from '@/components/ui'
import { InputOption } from '@/types'

import { createYearOption, MONTH_OPTION } from './utils'

const DEFAULT_YEAR_OPTION = createYearOption()
const DEFAULT_MONTH_OPTION = [...MONTH_OPTION]

export const FormFieldSelectMonth = ({
  label,
  name,
  control,
  error,
  showLabel,
  showError,
  height,
  disabled = false,
  yearOption = DEFAULT_YEAR_OPTION,
  monthOption = DEFAULT_MONTH_OPTION,
}: FormFieldSelectMonthProps) => {
  const {
    field: { onChange, onBlur, value },
  } = useController({ name, control })

  // NOTE: ２つの入力欄のために値を分ける
  const { selectedY, selectedM } = useMemo(() => {
    if (!value) {
      return {
        selectedY: null,
        selectedM: null,
      }
    }
    let ym = value.split('-')
    if (ym.length !== 2) {
      ym = ['', '']
    }
    const year = ym[0]
    const month = ym[1] ? parseInt(ym[1]).toString() : ym[1] // NOTE: 一度数値に換えて頭の0を削除 '01' -> '1'
    return {
      selectedY: yearOption.find((opt) => opt.value === year) || null,
      selectedM: monthOption.find((opt) => opt.value === month) || null,
    }
  }, [value, yearOption, monthOption])

  const handleChangeYear = useCallback(
    (value: InputOption<string> | null) => {
      const year = value?.value || null
      const oldSelectedYear = selectedY?.value || null
      if (oldSelectedYear !== year) {
        const ym = year ? `${year}-` : ''
        onChange(ym)
      }
    },
    [selectedY, onChange]
  )

  const handleChangeMonth = useCallback(
    (value: InputOption<string> | null) => {
      const month = value?.value || null
      const oldSelectedMonth = selectedM?.value || null
      if (oldSelectedMonth !== month) {
        if (!selectedY) {
          throw new Error('年が選ばれていないと月は選べない')
        }
        const ym = month
          ? `${selectedY.value}-${month.length === 1 ? '0' + month : month}`
          : `${selectedY.value}-`
        onChange(ym)
      }
    },
    [selectedY, selectedM, onChange]
  )

  // NOTE: 年が選択されていたら月を選択できる
  const canSelectMonth = !!selectedY?.value

  return (
    <FormFieldWrapper
      label={label}
      error={error}
      showLabel={showLabel}
      showError={showError}
    >
      <div className={clsx('tw-flex tw-w-full')}>
        <div className={clsx('tw-flex tw-shrink-0 tw-grow tw-basis-[90px]')}>
          <InputSelectSingle
            // label={label}
            buttonLabel={'年'}
            name={`${name}-year`}
            options={yearOption}
            height={height}
            disabled={disabled}
            selected={selectedY}
            isError={error !== undefined}
            className={'tw-w-full'}
            handleChange={handleChangeYear}
            handleBlur={onBlur}
          />
        </div>

        <div
          className={clsx(
            'tw-flex tw-flex-none tw-basis-4 tw-items-center tw-justify-center'
          )}
        >
          {'/'}
        </div>

        <div className={clsx('tw-flex tw-shrink-0 tw-grow tw-basis-[70px]')}>
          <InputSelectSingle
            // label={label}
            buttonLabel={'月'}
            name={`${name}-month`}
            options={monthOption}
            height={height}
            disabled={disabled || !canSelectMonth}
            selected={selectedM}
            isError={error !== undefined}
            className={'tw-w-full'}
            handleChange={handleChangeMonth}
            handleBlur={onBlur}
          />
        </div>
      </div>
    </FormFieldWrapper>
  )
}
