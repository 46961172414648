import { useMemo } from 'react'

export const useResizeObserver = ({
  onResize,
}: {
  onResize: (map: { index: number; rect: DOMRectReadOnly }[]) => void
}) => {
  const indexes = useMemo(() => new Map<Element, number>(), [])
  const observer = useMemo(() => {
    return new ResizeObserver((entries) => {
      onResize(
        entries
          .map(({ target, contentRect }) => {
            return {
              index: indexes.get(target) ?? -1,
              rect: contentRect,
            }
          })
          .filter(({ index }) => index !== -1)
      )
    })
  }, [indexes, onResize])

  const subscribe = (el: Element, index: number) => {
    //console.log('sub')
    observer.observe(el)
    indexes.set(el, index)
  }

  const unsubscribe = (el: Element) => {
    //console.log('unsub')
    indexes.delete(el)
    observer.unobserve(el)
  }

  const dispose = () => {
    observer.disconnect()
  }

  return useMemo(
    () => ({
      subscribe,
      unsubscribe,
      dispose,
    }),
    []
  )
}
