import clsx from 'clsx'
import NextLink from 'next/link'

import type { HeaderIconLinkProps } from './type'

import { IconWithLabel } from '@/components/ui'

export const HeaderIconLink = ({
  children,
  href,
  label,
  showSp = false,
}: HeaderIconLinkProps) => {
  return (
    <NextLink href={href} passHref>
      <a
        className={clsx(
          'tw-transition tw-duration-stateChange',
          'hover:tw-opacity-hover',
          showSp
            ? 'tw-flex tw-items-center'
            : 'tw-hidden lg:tw-flex lg:tw-items-center'
        )}
      >
        <IconWithLabel label={label} showLabelSp={false}>
          {children}
        </IconWithLabel>
      </a>
    </NextLink>
  )
}
