import React from 'react'

import type { ItemListSortSelectProps } from './type'

import { InputSelectSingle } from '@/components/ui'

import { options } from './const'

export const ItemListSortSelect = (props: ItemListSortSelectProps) => {
  return (
    <InputSelectSingle<string>
      {...props}
      height="md"
      buttonLabel={'並び替え'}
      options={options}
    />
  )
}
