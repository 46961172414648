import clsx from 'clsx'
import { useCallback } from 'react'
import { toast, ToastOptions } from 'react-hot-toast'

import { NotificationToast } from '@/components/ui'

type Props = Pick<ToastOptions, 'id' | 'duration'> & {
  type: 'success' | 'error'
  message: string
  onClick?: () => void
}

const toastClassName = clsx('hover:tw-opacity-75 tw-cursor-pointer')

export const useNotification = () => {
  const notify = useCallback(
    ({ type, id, message, duration, onClick }: Props) => {
      switch (type) {
        case 'success': {
          duration = duration ? duration : 2000
          toast.custom(
            (t) =>
              t.visible ? (
                <NotificationToast
                  message={message}
                  onClick={() => {
                    toast.dismiss(t.id)
                  }}
                  key={t.id}
                />
              ) : null,
            { id, duration }
          )
          break
        }
        // form内でデータ取得エラーなどsubmit時以外のエラーが発生した場合に使用する
        case 'error': {
          toast.error(
            (t) =>
              t.visible ? (
                <span
                  onClick={() => {
                    toast.dismiss(t.id)
                    if (onClick) {
                      onClick()
                    }
                  }}
                  className={toastClassName}
                >
                  {message}
                </span>
              ) : null,
            { id, duration }
          )
          break
        }
      }
    },
    []
  )
  return { notify }
}
