import { Favorite } from '@mui/icons-material'
import clsx from 'clsx'

import { FavoriteTagProps } from './type'

export const FavoriteTag = ({ isFavorite }: FavoriteTagProps) => {
  return (
    <span
      className={clsx(
        'tw-h-[18px] tw-w-[18px]',
        'tw-flex tw-items-center tw-justify-center',
        'tw-rounded-sm',
        isFavorite ? 'tw-bg-accentYellow-50' : 'tw-bg-gray-100'
      )}
    >
      <Favorite
        className={clsx(
          'tw-align-sub',
          '!tw-text-sm',
          '!tw-stroke-gray-700',
          isFavorite ? '!tw-fill-accentYellow-500' : '!tw-fill-white'
        )}
      />
    </span>
  )
}
