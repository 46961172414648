import { aspidaClient } from '@/lib/aspida'
import { isAxiosError, isIncludedPath } from '@/lib/axios/util'
import { isContentfulEntryNotFoundError } from '@/lib/contentful'

/** status codeに依らずerror boundaryへerrorを投げるAPI pathリスト */
const THROW_ALL_STATUS_PATHS = [aspidaClient.v1.auth.token.$path()]

/** error boundaryに投げるべきか否か */
export const isForErrorBoundary = (error: unknown): boolean => {
  // contentful sdk
  if (isContentfulEntryNotFoundError(error)) return true

  // axios
  if (!isAxiosError(error)) return true

  if (error.response === undefined) return true

  if (isIncludedPath(error, THROW_ALL_STATUS_PATHS)) return true

  if (error.response.status !== 503 && error.response.status >= 402) return true

  return false
}

export const baseErrorHandler = (error: unknown): void => {
  if (!isAxiosError(error)) return

  if (error.response?.status === 400) {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }
}
