import clsx from 'clsx'
import { useRef } from 'react'

import type { AddCartModalProps } from './type'

import { Button, Dialog, ErrorMessageList } from '@/components/ui'
import { CART_TITLE } from '@/const'

const Row = ({
  title,
  value,
  unit,
}: {
  title: string
  value: string
  unit: string
}) => {
  return (
    <li className={clsx('tw-flex tw-items-center tw-justify-between')}>
      <p className={clsx('tw-text-xs tw-font-bold')}>{title}</p>
      <p className={clsx('tw-flex tw-items-baseline tw-gap-1', 'tw-font-bold')}>
        <span className={clsx('tw-text-2xl')}>{value}</span>
        <span className={clsx('tw-text-xs')}>{unit}</span>
      </p>
    </li>
  )
}

export const AddCartModal = ({
  isOpen = false,
  count,
  amount,
  disabledAddCart,
  isLoading,
  errorMessages = [],
  onClose,
  onAddCart,
}: AddCartModalProps) => {
  const addCardButtonRef = useRef(null)

  return (
    <Dialog
      title={`${CART_TITLE}に追加`}
      isOpen={isOpen}
      initialFocus={addCardButtonRef}
    >
      <div className={clsx('tw-mt-2 tw-px-2 tw-pb-4')}>
        {errorMessages.length > 0 && (
          <div className={clsx('tw-mb-2 tw-text-center')}>
            <ErrorMessageList errorMessages={errorMessages} />
          </div>
        )}

        <p className={clsx('tw-text-center tw-text-sm')}>
          {`選択した商品を${CART_TITLE}に追加します。`}
        </p>

        <ul
          className={clsx(
            'tw-mt-7 tw-inline-flex tw-w-full tw-flex-col tw-gap-4 tw-rounded tw-bg-primary-50 tw-px-8 tw-py-7'
          )}
        >
          <Row title={'商品数'} value={`${count}`} unit={'点'} />
          <Row
            title={'金額'}
            value={`${amount.toLocaleString()}`}
            unit={'円'}
          />
        </ul>

        <div
          className={clsx(
            'tw-mt-7',
            'tw-flex tw-items-center tw-justify-center',
            'tw-gap-2'
          )}
        >
          <Button variant="normal" disabled={isLoading} onClick={onClose}>
            キャンセル
          </Button>
          <Button
            ref={addCardButtonRef}
            isLoading={isLoading}
            disabled={disabledAddCart || isLoading}
            onClick={onAddCart}
          >
            {`${CART_TITLE}に追加する`}
          </Button>
        </div>
      </div>
    </Dialog>
  )
}
