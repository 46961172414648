import { useQuery } from 'react-query'

import { aspidaClient } from '@/lib/aspida'
import { QueryConfig } from '@/lib/react-query'

import { brandKeyFactory } from './key'

export const getBrandGroups = () => {
  return aspidaClient.v1.brands.$get()
}

export type UseBrandGroupOptions = {
  config?: QueryConfig<typeof getBrandGroups>
}

/**
 * ブランドグループ取得
 * groupNameがundefinedの場合は全てのブランドグループを取得
 */
export const useBrandGroups = ({ config }: UseBrandGroupOptions) => {
  const queryConfig: QueryConfig<typeof getBrandGroups> = {
    ...config,
    queryKey: brandKeyFactory.list(),
    queryFn: () => getBrandGroups(),
  }
  return useQuery(queryConfig)
}
