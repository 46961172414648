import clsx from 'clsx'

import { SectionTitleProps } from './type'

export const SectionTitle = ({
  index,
  right = <></>,
  children,
}: SectionTitleProps) => {
  return (
    <div
      className={clsx(
        'tw-h-11',
        'tw-flex tw-items-center tw-justify-between',
        'tw-px-2',
        'tw-mb-2',
        'tw-border-b tw-border-gray-300',
        'tw-bg-primary-50'
      )}
    >
      <h3 className={clsx('tw-text-xl tw-font-bold', 'tw-flex-shrink-0')}>
        {!!index &&
          index > 0 &&
          // Note: 全角に変換
          `${String.fromCharCode(index.toString().charCodeAt(0) + 0xfee0)}. `}
        {children}
      </h3>
      {right}
    </div>
  )
}
