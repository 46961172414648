import clsx from 'clsx'

export const alignHorizontals = {
  left: clsx('tw-justify-start'),
  center: clsx('tw-justify-center'),
  right: clsx('tw-justify-end'),
}

export const alignVerticals = {
  start: clsx('tw-items-start'),
  center: clsx('tw-items-center'),
  end: clsx('tw-items-end'),
}
