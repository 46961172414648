import clsx from 'clsx'
import { useMemo } from 'react'

import type { FavoriteCardListProps } from './type'

import { Dnd } from '@/components/ui'

import { FavoriteCardListItem } from './FavoriteCardListItem'

export const FavoriteCardList = ({
  favorites,
  sortable,
  isMyFavorites = false,
  isDepositStore = false,
  onClickCopy,
  onClickDelete,
  onClickEdit,
  onChangeSortOrder = () => {},
}: FavoriteCardListProps) => {
  const items = useMemo(() => {
    return favorites.map((f, i) => ({ ...f, id: i + 1 }))
  }, [favorites])

  return favorites.length ? (
    <Dnd items={items} onChange={onChangeSortOrder} disabled={!sortable}>
      {(item, index) => (
        <FavoriteCardListItem
          favorite={item}
          sortable={sortable}
          isMyFavorites={isMyFavorites}
          isDepositStore={isDepositStore}
          isOperationDirectionTop={
            favorites.length >= 4 && favorites.length - index <= 3
          }
          onClickCopy={onClickCopy}
          onClickDelete={onClickDelete}
          onClickEdit={onClickEdit}
        />
      )}
    </Dnd>
  ) : (
    <div
      className={clsx(
        'tw-h-11',
        'tw-flex tw-items-center',
        'tw-px-4',
        'tw-border-b tw-border-gray-300'
      )}
    >
      <p className={clsx('tw-text-sm')}>
        {/* 共有リストはコンポーネントごと非表示にするので使用されない想定 */}
        {isMyFavorites ? 'マイリストがありません' : 'リストがありません'}
      </p>
    </div>
  )
}
