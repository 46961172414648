import { useMemo } from 'react'

import type { FavoriteItemCardListProps } from './type'

import { List } from './List'
import { ListSelectable } from './ListSelectable/ListSelectable'
import { ListSortable } from './ListSortable'

export const FavoriteItemCardList = ({
  items,
  currentFavorite,
  allFavorites,
  control,
  selectable = false,
  sortable = false,
  onSelectItem,
  onSelectAllItem,
  onChangeItemSortOrder,
  onRefetchItems,
  onClickSortButton,
  onClickEditButton,
  onClickEditSalesTypeButton,
  onClickEditTitleButton,
  register,
}: FavoriteItemCardListProps) => {
  /** 商品を50個ずつの配列に分割 */
  const splittedItems = useMemo(() => {
    if (!items) return []
    const splittedItems = []
    let index = 0
    while (index < items.length) {
      splittedItems.push({
        items: items.slice(index, (index += 20)),
        visible: index === 0,
      })
    }
    return splittedItems
  }, [items])

  return (
    <>
      {!selectable && !sortable && (
        <List
          control={control}
          register={register}
          itemsArray={splittedItems}
          currentFavorite={currentFavorite}
          onClickSortButton={onClickSortButton}
          onClickEditButton={onClickEditButton}
          onClickSettingButton={onClickEditSalesTypeButton}
        />
      )}

      {selectable && (
        // 「リストの編集」モード
        <ListSelectable
          control={control}
          register={register}
          items={items}
          allFavorites={allFavorites}
          currentFavorite={currentFavorite}
          onSelectItem={onSelectItem}
          onSelectAllItem={onSelectAllItem}
          onRefetchItems={onRefetchItems}
          onClickEditTitle={onClickEditTitleButton}
        />
      )}

      {sortable && (
        // 「並び替え」モード
        <ListSortable
          control={control}
          register={register}
          items={items}
          onChangeItemSortOrder={onChangeItemSortOrder}
        />
      )}
    </>
  )
}
