import { useQuery } from 'react-query'

import { aspidaClient } from '@/lib/aspida'
import { QueryConfig } from '@/lib/react-query'

import { addressKeyFactory } from './key'

export const searchAddressByZipCode = (
  zipCode: UseSearchByZipCodeOptions['zipCode']
) => {
  return aspidaClient.v1.addresses.$get({ query: { zipCode } })
}

export type UseSearchByZipCodeOptions = {
  zipCode: string
  config?: QueryConfig<typeof searchAddressByZipCode>
}

export const useSearchAddressByZipCode = ({
  zipCode,
  config,
}: UseSearchByZipCodeOptions) => {
  const queryConfig: QueryConfig<typeof searchAddressByZipCode> = {
    ...config,
    queryKey: addressKeyFactory.searchByZipCode(zipCode),
    queryFn: () => searchAddressByZipCode(zipCode),
  }
  return useQuery(queryConfig)
}
