import clsx from 'clsx'
import { useMemo } from 'react'

import { ITEM_CARD_STATUS_LABEL } from '@/const'

import { ItemErrorLabelProps } from './type'

export const ItemErrorLabel = ({ status }: ItemErrorLabelProps) => {
  const itemStatusLabel = useMemo(() => {
    return ITEM_CARD_STATUS_LABEL[status]
  }, [status])
  return (
    <>
      {itemStatusLabel && (
        <div>
          <p className={clsx('tw-text-xs tw-text-alert-500')}>
            {itemStatusLabel}
          </p>
        </div>
      )}
    </>
  )
}
