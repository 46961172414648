import { Check } from '@mui/icons-material'
import clsx from 'clsx'
import * as React from 'react'

import type { InputSelectMultiOptionContentProps } from './type'

export const InputSelectMultiOptionContent = ({
  active,
  selected,
  option,
}: InputSelectMultiOptionContentProps) => {
  return (
    <div
      className={clsx([
        active ? 'tw-bg-accentBlue-500 tw-text-white' : 'tw-text-black',
        'tw-relative tw-cursor-default tw-select-none tw-py-1 tw-px-2 tw-pr-8',
        'tw-flex tw-gap-[10px]',
        'tw-transition-colors tw-duration-100',
        'hover:tw-cursor-pointer',
      ])}
    >
      <span
        className={clsx([
          selected ? 'tw-bg-accentBlue-500' : 'tw-text-white',
          'tw-block',
          'tw-rounded-[4px] tw-border-[2px] tw-border-accentBlue-500 tw-p-[1px]',
          'tw-inset-y-0 tw-right-0',
          'tw-flex tw-items-center',
        ])}
      >
        <Check
          className={clsx([
            'tw-text-white',
            selected ? 'tw-opacity-100' : 'tw-opacity-0',
          ])}
          sx={{ fontSize: 14, strokeWidth: '2px' }}
          aria-hidden="true"
        />
      </span>
      <div className="tw-flex tw-items-center">
        <span className={clsx(['tw-block tw-truncate tw-text-xs'])}>
          {option.label}
        </span>
      </div>
    </div>
  )
}

InputSelectMultiOptionContent.displayName = 'InputSelectOptionContent'
