/* eslint-disable */
// prettier-ignore
import type { AspidaClient, BasicHeaders } from 'aspida'
// prettier-ignore
import type { Methods as Methods0 } from '.'

// prettier-ignore
const api = <T>({ baseURL, fetch }: AspidaClient<T>) => {
  const prefix = (baseURL === undefined ? 'http://localhost:18081' : baseURL).replace(/\/$/, '')
  const PATH0 = '/v1/auth/token'
  const POST = 'POST'

  return {
    /**
     * リフレッシュトークンを渡して、アクセストークンを取得します。<br/>
     * <br/>
     * 以下のレスポンスはHTTPステータスのみの返却となります。<br/>
     *
     * <ul>
     * <li>400：必須項目が無いなどのリクエスト不正。</li>
     * <li>401：対象のリフレッシュトークンが未登録の場合。</li>
     * </ul>
     * @returns 処理成功
     */
    post: (option: { body: Methods0['post']['reqBody'], config?: T | undefined }) =>
      fetch<Methods0['post']['resBody'], BasicHeaders, Methods0['post']['status']>(prefix, PATH0, POST, option).json(),
    /**
     * リフレッシュトークンを渡して、アクセストークンを取得します。<br/>
     * <br/>
     * 以下のレスポンスはHTTPステータスのみの返却となります。<br/>
     *
     * <ul>
     * <li>400：必須項目が無いなどのリクエスト不正。</li>
     * <li>401：対象のリフレッシュトークンが未登録の場合。</li>
     * </ul>
     * @returns 処理成功
     */
    $post: (option: { body: Methods0['post']['reqBody'], config?: T | undefined }) =>
      fetch<Methods0['post']['resBody'], BasicHeaders, Methods0['post']['status']>(prefix, PATH0, POST, option).json().then(r => r.body),
    $path: () => `${prefix}${PATH0}`
  }
}

// prettier-ignore
export type ApiInstance = ReturnType<typeof api>
// prettier-ignore
export default api
