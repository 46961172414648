import * as React from 'react'
import { Toaster } from 'react-hot-toast'
import { QueryClientProvider, QueryClient } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { RecoilRoot } from 'recoil'

type AppProviderProps = {
  queryClient: QueryClient
  children: React.ReactNode
}

export const AppProvider = ({ queryClient, children }: AppProviderProps) => {
  return (
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        {process.env.NEXT_IS_TEST !== 'true' && <ReactQueryDevtools />}
        <Toaster />
        {children}
      </RecoilRoot>
    </QueryClientProvider>
  )
}
