import { Add } from '@mui/icons-material'
import clsx from 'clsx'
import { useEffect, useMemo, useState } from 'react'

import type { AddFavoriteModalProps } from './type'

import { UpsertFavoriteListForm } from '@/components/features/favorite'
import { AddFavorites } from '@/components/features/item'
import { Button, Dialog, TextButton, ErrorMessageList } from '@/components/ui'
import { FAVORITE_TITLE_ALIAS } from '@/const'
import { useFavorites } from '@/hooks/features/favorite'
import { useMyStore } from '@/hooks/features/store'

export const AddFavoriteModal = ({
  selectedItemCodes,
  isOpen,
  onChange,
  onClose,
}: AddFavoriteModalProps) => {
  // call API
  const { data, refetch } = useFavorites({})

  const [mode, setMode] = useState<'list' | 'create'>('list')
  const [errorMessages, setErrorMessages] = useState<string[]>([])

  // 入金親か否か
  const myStore = useMyStore({})
  const isDepositStore = useMemo(
    () => !!myStore.data?.isDepositStore,
    [myStore]
  )

  const onSubmitCreateFavorite = () => {
    setMode('list')
    refetch()
    onChange && onChange()
  }

  const onSubmitUpdateFavorite = () => {
    refetch()
    onChange && onChange()
  }

  /** 表示モード切替処理 */
  const handleSwitchMode = (selectedMode: typeof mode) => {
    setErrorMessages([])
    setMode(selectedMode)
  }

  const handleClose = () => {
    setErrorMessages([])
    onClose()
  }

  useEffect(() => {
    if (isOpen) {
      refetch()
    }
  }, [isOpen, refetch])

  return (
    <Dialog
      title={
        mode === 'create'
          ? `新しい${FAVORITE_TITLE_ALIAS}を作成する`
          : `${FAVORITE_TITLE_ALIAS}への追加・削除`
      }
      isOpen={isOpen}
    >
      {mode === 'create' ? (
        <UpsertFavoriteListForm
          favorite={undefined}
          isDepositStore={isDepositStore}
          onClickCancel={() => handleSwitchMode('list')}
          onClickConfirm={onSubmitCreateFavorite}
        />
      ) : (
        <>
          {errorMessages.length > 0 && (
            <div className={clsx('tw-my-2 tw-text-center')}>
              <ErrorMessageList errorMessages={errorMessages} />
            </div>
          )}

          <div className={clsx('tw-px-2')}>
            <Button
              variant="secondary"
              size="md"
              startIcon={<Add sx={{ fontSize: 24 }} />}
              onClick={() => handleSwitchMode('create')}
            >{`新しいリストを作成する`}</Button>
          </div>

          <div
            className={clsx(
              'tw--mx-2 tw-mt-4',
              'tw-max-h-[50vh]',
              'tw-overflow-y-auto',
              'tw-border-y tw-border-gray-300'
            )}
          >
            <AddFavorites
              favorites={data?.favorites || []}
              selectedItemCodes={selectedItemCodes}
              handleChange={() => onSubmitUpdateFavorite()}
              handleErrorMessages={setErrorMessages}
            />
          </div>

          <div className={clsx('tw-mt-6')}>
            <TextButton isBlock={true} isCenter={true} onClick={handleClose}>
              閉じる
            </TextButton>
          </div>
        </>
      )}
    </Dialog>
  )
}
