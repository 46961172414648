import { useState } from 'react'

export const useTimer = ({ duration }: { duration: number }) => {
  const [timeout, setTimeoutState] = useState(false)
  const [timerId, setTimerId] = useState<NodeJS.Timeout>()
  const [running, setRunningState] = useState(false)

  const start = () => {
    const id = setTimeout(() => {
      setTimeoutState(true)
      setRunningState(false)
    }, duration)
    setTimerId(id)
    setTimeoutState(false)
    setRunningState(true)
  }

  const clear = () => {
    clearTimeout(timerId)
    setTimeoutState(false)
    setRunningState(false)
  }

  return {
    timeout,
    running,
    start,
    clear,
  }
}
