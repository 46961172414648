import { useQuery } from 'react-query'

import { aspidaClient } from '@/lib/aspida'
import { QueryConfig } from '@/lib/react-query'

import { cartKeyFactory } from './key'

export const getCartItemCount = () => {
  return aspidaClient.v1.cart.count.$get()
}

type UseCartItemCountOptions = {
  config?: QueryConfig<typeof getCartItemCount>
}

export const useCartItemCount = ({ config }: UseCartItemCountOptions) => {
  const queryConfig: QueryConfig<typeof getCartItemCount> = {
    ...config,
    queryKey: cartKeyFactory.itemCount(),
    queryFn: getCartItemCount,
  }
  return useQuery(queryConfig)
}
