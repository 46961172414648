import aspida from '@aspida/axios'
import Axios, { AxiosRequestConfig } from 'axios'
import qs from 'qs'

import type { AxiosExtendError } from './type'

import { Status400Response } from '@/api/@types'
import refreshTokenApi from '@/api/v1/auth/token/$api'
import { API_URL, ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from '@/config'
import { appEmitter, APP_EVENT_NAMES } from '@/utils/appEmitter'
import { storage } from '@/utils/storage'

import { checkTokenRefreshAPI, checkNotRetryAPI } from './util'

const authRequestInterceptor = async (config: AxiosRequestConfig) => {
  const accessToken = storage.get(ACCESS_TOKEN_KEY)
  if (accessToken === undefined) return config

  // NOTE: accessTokenが日本語(非ISO8859-1文字列)を含む場合はcookieから削除してheaderは更新しない
  const isNoneISO8859_1String = /[^\u0000-\u00ff]/g.test(accessToken)
  if (isNoneISO8859_1String) {
    storage.remove(ACCESS_TOKEN_KEY)
    return config
  }

  return {
    ...config,
    headers: {
      ...config.headers,
      authorization: `Bearer ${accessToken}`,
    },
  }
}

export const axios = Axios.create({
  baseURL: API_URL,
  headers: {
    Accept: 'application/json',
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: 'comma' })
  },
})

axios.interceptors.request.use(authRequestInterceptor)
axios.interceptors.response.use(
  (response) => {
    return response
  },
  async (error: AxiosExtendError) => {
    if (error?.response) {
      if (error.response.status === 503) {
        appEmitter.emit(APP_EVENT_NAMES.CHANGE_MENTENANCE_MODE, true)
        return Promise.resolve(error)
      }

      if (error.response.status >= 402) {
        // captureException({
        //   error,
        //   level: error.response.status >= 500 ? 'error' : 'warning',
        //   tags: {
        //     api: true,
        //   },
        //   fingerPrints: [
        //     String(error.response.status),
        //     error?.config?.url ?? '',
        //   ],
        // })
        return Promise.reject(error)
      }
    }

    // MEMO: lib/aspidaからimportすると循環参照になるためrefreshToken用に個別定義
    const refreshTokenApiClient = refreshTokenApi(aspida(axios))

    // リトライリクエストでエラーの場合、ループするので再リトライはしない
    if (!!error.config.isRetry) {
      // captureException({
      //   error,
      //   level: 'error',
      //   tags: {
      //     api: true,
      //     retryError: true,
      //   },
      //   fingerPrints: ['retryError'],
      // })

      return Promise.reject(error)
    }

    // トークンリフレッシュでエラーの場合、ループするのでリトライしない
    if (checkTokenRefreshAPI(axios, error)) {
      storage.remove(REFRESH_TOKEN_KEY)

      // captureException({
      //   error,
      //   level: 'warning',
      //   tags: {
      //     api: true,
      //   },
      //   fingerPrints: ['token refresh error'],
      // })

      return Promise.reject(error)
    }

    // トークンリフレッシュ対象外の401を返すAPIの場合、リトライしない
    if (checkNotRetryAPI(axios, error)) {
      // captureException({
      //   error,
      //   level: 'error',
      //   tags: {
      //     api: true,
      //   },
      //   fingerPrints: [
      //     String(error?.response?.status),
      //     error?.config?.url ?? '',
      //   ],
      // })

      return Promise.reject(error)
    }

    // 401以外の場合トークンリフレッシュをしない
    if (error.response?.status !== 401) {
      // captureException({
      //   error,
      //   level:
      //     !error?.response || error.response?.status >= 500
      //       ? 'error'
      //       : 'warning',
      //   tags: {
      //     api: true,
      //   },
      //   fingerPrints: [
      //     String(error?.response?.status),
      //     error?.config?.url ?? '',
      //   ],
      // })

      return Promise.reject(error)
    }

    // 無効なアクセストークンをcookieから削除
    storage.remove(ACCESS_TOKEN_KEY)

    const refreshToken = storage.get(REFRESH_TOKEN_KEY)

    // リフレッシュトークンを保持していない場合トークンリフレッシュをしない
    if (!refreshToken) {
      // captureException({
      //   error,
      //   level: 'warning',
      //   tags: {
      //     api: true,
      //   },
      //   fingerPrints: ['no auth token'],
      // })

      return Promise.reject(error)
    }

    try {
      const { body } = await refreshTokenApiClient.post({
        body: {
          refreshToken,
        },
      })
      storage.set(ACCESS_TOKEN_KEY, body.accessToken)

      // 新しいaccessTokenで再リクエスト
      return axios.request({
        ...error.config,
        // 再リクエストフラグを付与する
        isRetry: true,
      } as AxiosExtendError['config'])
    } catch (e) {
      return Promise.reject(e)
    }
  }
)

export const status400Response = (e: unknown) => {
  if (Axios.isAxiosError(e) && e.response?.status === 400) {
    return e.response as Status400Response
  }
}
