export const parseToNumber = (
  value: string | number,
  fallback: number | null = null
) => {
  const num = Number(value)
  return isNaN(num) ? fallback : num
}

/** 数値を半角に変換する関数 */
export const parseNumberToHalfWidth = (value: number | string) => {
  return value.toString().replace(/[０-９]/g, (s) => {
    return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
  })
}
