import type { FormFieldTextareaProps } from './type'

import { FormFieldWrapper, InputTextarea } from '@/components/ui'

export const FormFieldTextarea = (props: FormFieldTextareaProps) => {
  const {
    label,
    required,
    registration: { onChange, onBlur, ...registration },
    error,
    showLabel,
    showError,
    onFocus,
    ...textareaProps
  } = props

  const handleChange: React.TextareaHTMLAttributes<HTMLTextAreaElement>['onChange'] =
    (e) => {
      onChange && onChange(e)
      props.onChange && props.onChange(e)
    }

  const handleFocus: React.TextareaHTMLAttributes<HTMLTextAreaElement>['onFocus'] =
    (e) => {
      onFocus && onFocus(e)
    }
  const handleBlur: React.TextareaHTMLAttributes<HTMLTextAreaElement>['onBlur'] =
    (e) => {
      onBlur && onBlur(e)
      props.onBlur && props.onBlur(e)
    }

  return (
    <FormFieldWrapper
      id={registration.name}
      label={label}
      required={required}
      error={error}
      showLabel={showLabel}
      showError={showError}
    >
      <InputTextarea
        data-testid={registration.name}
        {...textareaProps}
        {...registration}
        id={registration.name}
        isError={error !== undefined}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
    </FormFieldWrapper>
  )
}
