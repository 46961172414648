import { useMe } from '@/hooks/features/user'

export const useSession = () => {
  const meQuery = useMe({})

  if (meQuery.isLoading) {
    return {
      isAuthenticated: false,
      isPasswordResetRequired: false,
      isLoading: true,
    }
  }

  if (meQuery.isError || !meQuery.data) {
    return {
      isAuthenticated: false,
      isPasswordResetRequired: false,
      isLoading: false,
    }
  }

  return {
    isAuthenticated: !meQuery.data.requirePasswordChange ?? false,
    isPasswordResetRequired: meQuery.data.requirePasswordChange ?? false,
    isLoading: false,
  }
}
