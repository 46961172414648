import { useQuery } from 'react-query'

import { aspidaClient } from '@/lib/aspida'
import { QueryConfig } from '@/lib/react-query'

import { ecSiteOrdersKeyFactory } from './key'

export type TQueryParams = Parameters<
  typeof ecSiteOrdersKeyFactory.getEcSiteOrdersSystemTypes
>[0]['queryParams']

/**
 * システム種別一覧取得
 */
const getEcSiteOrdersSystemTypes = (queryParams: TQueryParams) => {
  return aspidaClient.v1.ec_site_orders.systemTypes.$get({ query: queryParams })
}

export type UseSystemTypesOptions = {
  config?: QueryConfig<typeof getEcSiteOrdersSystemTypes>
  queryParams?: TQueryParams
}

export const useGetEcSiteOrdersSystemTypes = ({
  config,
  queryParams,
}: UseSystemTypesOptions) => {
  const queryConfig: QueryConfig<typeof getEcSiteOrdersSystemTypes> = {
    ...config,
    queryKey: ecSiteOrdersKeyFactory.getEcSiteOrdersSystemTypes({
      queryParams,
    }),
    queryFn: () => getEcSiteOrdersSystemTypes(queryParams),
  }
  return useQuery(queryConfig)
}
