import clsx from 'clsx'

import { FavoriteTag } from '@/components/features/favorite'
import { Image } from '@/components/ui'

import { DetailLink } from './DetailLink'
import { ItemThumbnailBannarProps } from './type'

export const ItemThumbnailBannar = ({
  item,
  showFavorite = true,
  nameFooter = null,
  disabledLink,
}: ItemThumbnailBannarProps) => {
  return (
    <div className={clsx('tw-w-full', 'tw-flex', 'tw-gap-2')}>
      <div className={clsx('tw-flex-none', 'tw-relative', 'tw-h-10 tw-w-10')}>
        <Image src={item.itemImage ?? ''} alt={item.itemName ?? ''} />
      </div>
      <div
        className={clsx(
          'tw-w-full',
          'tw-flex tw-flex-col',
          'tw-gap-1',
          'tw-py-1'
        )}
      >
        <div className={clsx('tw-w-full', 'tw-items-top tw-flex', 'tw-gap-2')}>
          {showFavorite && (
            <FavoriteTag isFavorite={item.isFavorite ?? false} />
          )}

          <DetailLink
            item={item}
            disabled={item.itemStatus !== 'available' || disabledLink}
            tabIndex={-1}
          >
            {item.itemName || '-'}
          </DetailLink>
        </div>
        {nameFooter && nameFooter}
      </div>
    </div>
  )
}
