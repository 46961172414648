import clsx from 'clsx'

import {
  CART_ITEM_DETAIL_TABLE_KEYS,
  CART_ITEM_DETAIL_TABLE_LABEL,
} from './const'
import { QuantityTableProps } from './type'

export const QuantityTable = ({
  salesTypeQuantity,
  isVisiblePurchasePrice,
}: QuantityTableProps) => {
  const isRowVisible = (
    key: keyof typeof salesTypeQuantity,
    quantity: typeof salesTypeQuantity
  ) => {
    // Note: メモが空の場合、列を表示しない
    if (key === 'note' && quantity[key] === '') {
      return false
    }

    // Note: 購入価格が見れない場合、列を表示しない
    if (key === 'price' && !isVisiblePurchasePrice) {
      return false
    }

    // Note: 購入価格が見れない場合、列を表示しない
    if (key === 'summaryPrice' && !isVisiblePurchasePrice) {
      return false
    }

    return true
  }

  return (
    <table
      className={clsx('tw-border tw-border-gray-300', 'tw-w-full', 'tw-mt-2')}
    >
      {CART_ITEM_DETAIL_TABLE_KEYS.map((key, i) =>
        isRowVisible(key, salesTypeQuantity) ? (
          <tr
            key={`cart-detail-table-${key}-${i}-${salesTypeQuantity[key]}`}
            className={clsx('tw-border-b tw-border-gray-300', 'tw-text-sm')}
          >
            <td
              className={clsx('tw-bg-primary-50', 'tw-w-20', 'tw-py-1 tw-px-2')}
            >
              {CART_ITEM_DETAIL_TABLE_LABEL[key]}
            </td>
            <td className={clsx('tw-py-1 tw-px-2')}>
              {salesTypeQuantity[key]}
            </td>
          </tr>
        ) : (
          <></>
        )
      )}
      <tr className={clsx('tw-border-b tw-border-gray-300', 'tw-text-sm')}></tr>
    </table>
  )
}
