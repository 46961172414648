import { Cancel } from '@mui/icons-material'
import clsx from 'clsx'

import type { MultiSelectButtonTagProps } from './type'

export const MultiSelectButtonTag = ({
  children,
  onUnselect,
}: MultiSelectButtonTagProps) => {
  return (
    <div
      className={clsx(
        'tw-inline-flex tw-items-center tw-gap-2',
        'tw-h-7',
        'tw-px-3',
        'tw-bg-primary-50',
        'tw-rounded-full tw-border tw-border-primary-100'
      )}
    >
      <p
        className={clsx(
          'tw-text-sm tw-leading-none',
          'tw-max-w-[15rem]',
          'tw-truncate'
        )}
      >
        {children}
      </p>
      <button
        type="button"
        className={clsx(
          'tw-h-4 tw-w-4',
          'tw-flex tw-items-center tw-justify-center',
          'tw-transition tw-duration-stateChange',
          'hover:tw-opacity-hover'
        )}
        onClick={onUnselect}
      >
        <span className={clsx('tw-relative', 'tw-block', 'tw-h-3 tw-w-3')}>
          <span
            className={clsx(
              'tw-absolute',
              'tw-top-0.5 tw-left-0.5',
              'tw-h-2 tw-w-2',
              'tw-rounded-full',
              'tw-bg-gray-700'
            )}
          />
          <Cancel
            sx={{ fontSize: 12 }}
            className={clsx(
              'tw-absolute',
              'tw-top-0 tw-left-0',
              'tw-text-white'
            )}
          />
        </span>
      </button>
    </div>
  )
}
