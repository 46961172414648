import type { WithSalesTypesProps } from './type'

import { LoadingOverlay } from '@/components/ui'

import { useCheckSalesTypesMaster } from './util'

export const withSalesTypes = (PageComponent: WithSalesTypesProps) => {
  const SalesTypesRequiredPage = (props: any) => {
    const { isStored, isLoading } = useCheckSalesTypesMaster()

    if (isStored) {
      return <PageComponent {...props} />
    }

    if (isLoading) {
      return <LoadingOverlay isLoading={true} />
    }

    return null
  }

  return SalesTypesRequiredPage
}
