import clsx from 'clsx'

import { ClearButton } from './ClearButton'

export type TProps = {
  value?: string
  placeholder: string
  error?: string
  onChange?: (value: number | '') => void
  onClickClear?: () => void
}

export const FileNameField = ({
  value,
  placeholder,
  error = '',
  onClickClear,
}: TProps) => {
  const shownClearButton = !!value
  return (
    <div
      className={clsx(
        'tw-relative tw-w-full',
        'tw-h-11 tw-rounded tw-px-2 tw-text-sm',
        'tw-border tw-border-gray-300 tw-bg-gray-100',
        'focus:tw-border-accentBlue-500 focus:tw-outline-none',
        'tw-transition tw-duration-300',
        error && 'tw-border-alert-500 focus:tw-border-alert-500'
      )}
    >
      <div
        className={clsx(
          'tw-flex tw-h-full tw-w-full tw-items-center',
          'tw-pr-6'
        )}
      >
        <p
          className={clsx(
            'tw-w-full tw-text-sm',
            'tw-truncate',
            value ? 'tw-text-black' : 'tw-text-gray-400'
          )}
        >
          {value || placeholder}
        </p>
      </div>

      {shownClearButton && <ClearButton onClick={onClickClear} />}
    </div>
  )
}
