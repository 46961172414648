import { useMemo } from 'react'

const UNCACHED = -1

export const useCache = () => {
  const cache = useMemo(() => {
    return {
      sizes: [] as number[],
      offsets: [] as number[],
      compusedIndex: 0,
      length: 0,
    }
  }, [])

  const condition = useMemo(
    () => ({
      defaultSize: 0,
      spacer: window.innerHeight / 2,
      prevScroll: 0,
      prevTotalSize: 0,
      totalSizeDiff: 0,
      overscan: 4,
      direction: 'down' as 'down' | 'up' | 'stay',
    }),
    []
  )

  const init = ({
    total,
    defaultSize,
    spacer,
    overscan,
  }: {
    total: number
    defaultSize: number
    spacer: number
    overscan: number
  }) => {
    cache.sizes = Array(total).fill(UNCACHED)
    cache.offsets = Array(total)
      .fill(0)
      .map((_, i) => i * defaultSize)
    cache.compusedIndex = 0
    cache.length = total
    condition.defaultSize = defaultSize
    condition.spacer = spacer
    condition.overscan = overscan
    //console.log(cache)
  }

  const setItemSize = (index: number, size: number) => {
    cache.sizes[index] = size
    cache.compusedIndex = index
    //console.log(cache)
  }

  const getItemSize = (index: number) => {
    return cache.sizes[index] === UNCACHED
      ? condition.defaultSize
      : cache.sizes[index]
  }

  const getItemOffset = (index: number) => {
    if (cache.compusedIndex > index) {
      return cache.offsets[index]
    }

    let i = cache.compusedIndex
    let top = cache.offsets[i]
    while (i < index) {
      const size =
        cache.sizes[i] === UNCACHED ? condition.defaultSize : cache.sizes[i]
      top += size
      cache.offsets[i + 1] = top
      i++
    }

    cache.compusedIndex = index
    return cache.offsets[index]
  }

  const getTotalSize = () => {
    if (cache.sizes.length <= 0) {
      return {
        totalSize: 0,
        diff: 0,
      }
    }
    const totalSize =
      getItemOffset(cache.sizes.length - 1) +
      (cache.sizes[cache.sizes.length - 1] === UNCACHED
        ? condition.defaultSize
        : cache.sizes[cache.sizes.length - 1])
    condition.totalSizeDiff = totalSize - condition.prevTotalSize
    condition.prevTotalSize = totalSize
    return {
      totalSize,
      diff: condition.totalSizeDiff,
    }
  }

  const getTotalSizeDiff = () => {
    return condition.totalSizeDiff
  }

  /**
   *
   * @param offset 検索対象の位置
   * @param i 検索開始位置
   */
  const getIndex = (offset: number, i: number) => {
    if (offset < 0) {
      return 0
    }
    while (i >= 0 && i < cache.length) {
      const itemOffset = getItemOffset(i)
      //console.log(`itemOffset: ${itemOffset} offset: ${offset} index:${i}`)
      if (itemOffset <= offset) {
        if (itemOffset + getItemSize(i) > offset) {
          break
        } else {
          i++
        }
      } else {
        i--
      }
    }

    return i
  }

  const getRange = () => {
    if (cache.length === 0) {
      return { start: 0, end: 0 }
    }

    const start = getIndex(
      window.scrollY - condition.spacer,
      cache.compusedIndex
    )
    //console.log(`end: ${start}`)
    const end =
      getIndex(window.scrollY + window.innerHeight + condition.spacer, start) +
      condition.overscan

    return { start: Math.max(start, 0), end: Math.min(end, cache.length - 1) }
  }

  const setDirection = () => {
    if (condition.prevScroll === window.scrollY) {
      condition.direction = 'stay'
      return condition.direction
    }
    condition.direction = condition.prevScroll < window.scrollY ? 'down' : 'up'
    condition.prevScroll = window.scrollY
    return condition.direction
  }

  const getDirection = () => {
    return condition.direction
  }

  const isCached = (index: number) => {
    return cache.sizes.length > 0 && cache.sizes[index] !== UNCACHED
  }

  const isOver = (start: number) => {
    const direction = setDirection()

    if (window.scrollY - condition.spacer < 0) {
      return false
    }

    switch (direction) {
      case 'down': {
        const offset = getItemOffset(start + 1)
        // console.log(
        //   `scrollY: ${window.scrollY} start: ${start} offset: ${offset} spacer: ${condition.spacer}`
        // )
        // console.log(window.scrollY - condition.spacer, offset)
        return window.scrollY - condition.spacer > offset
      }
      case 'up': {
        const offset = getItemOffset(start)
        // console.log(
        //   `scrollY: ${window.scrollY} start: ${start} offset: ${offset} spacer: ${condition.spacer}`
        // )
        // console.log(window.scrollY - condition.spacer, offset)
        return window.scrollY - condition.spacer < offset
      }
    }
  }

  return {
    init,
    setItemSize,
    getItemOffset,
    getItemSize,
    getRange,
    getDirection,
    getTotalSize,
    getTotalSizeDiff,
    isCached,
    isOver,
  }
}
