import clsx from 'clsx'
import * as React from 'react'

import {
  baseClassNames,
  errorClassNames,
  widths,
  heights,
  texts,
  rounds,
} from '../const'
import { InputTextProps } from './type'

export const InputText = React.forwardRef<HTMLInputElement, InputTextProps>(
  (
    {
      id,
      name,
      type = 'text',
      value,
      disabled = false,
      placeholder,
      height = 'lg',
      rounded = 'all',
      enterKeyHint,
      tabIndex,
      isError = false,
      onChange,
      onClick,
      onFocus,
      onBlur,
    },
    ref
  ) => {
    return (
      <input
        ref={ref}
        id={id}
        name={name}
        type={type}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        enterKeyHint={enterKeyHint}
        className={clsx(
          baseClassNames,
          isError && !disabled && errorClassNames,
          widths['full'],
          heights[height],
          texts[height],
          rounds[rounded],
          type === 'password' && 'ms-reveal:tw-hidden'
        )}
        tabIndex={disabled ? -1 : tabIndex}
        onChange={onChange}
        onClick={onClick}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    )
  }
)

InputText.displayName = 'InputText'
