import clsx from 'clsx'
import * as React from 'react'

import type { TextButtonProps } from './type'

import { Spinner } from '../../Spinner'
import { sizes, variants } from './const'

export const TextButton = ({
  children,
  name,
  type = 'button',
  variant = 'primary',
  size = 'sm',
  disabled = false,
  isBlock = false,
  isCenter = false,
  isLoading = false,
  tabIndex,
  startIcon,
  endIcon,
  onClick,
}: TextButtonProps) => {
  return (
    <button
      name={name}
      type={type}
      disabled={disabled}
      className={clsx(
        'tw-gap-2',
        'tw-flex tw-items-center',
        'tw-py-1',
        'tw-transition-all tw-duration-300',
        'tw-relative',
        'focus:tw-outline-none',
        'disabled:tw-cursor-not-allowed',
        variants[variant],
        isBlock && 'tw-w-full',
        isCenter && 'tw-justify-center',
        isLoading && 'tw-opacity-disabled'
      )}
      onClick={onClick}
      tabIndex={tabIndex}
    >
      <span
        className={clsx(
          'tw-inline-flex tw-items-center',
          'tw-gap-1 tw-font-bold',
          sizes[size]
        )}
      >
        {startIcon}
        {children}
        {endIcon}
      </span>
      {isLoading && (
        <span
          className={clsx(
            'tw-flex tw-w-full tw-items-center tw-justify-center',
            'tw-absolute'
          )}
        >
          <Spinner size={'sm'} />
        </span>
      )}
    </button>
  )
}
