import { useQuery } from 'react-query'

import { aspidaClient } from '@/lib/aspida'
import { QueryConfig } from '@/lib/react-query'

import { storeKeyFactory } from './key'

const getMyStore = () => {
  return aspidaClient.v1.stores.me.$get()
}

export type UseMyStoreOptions = {
  config?: QueryConfig<typeof getMyStore>
}

export const useMyStore = ({ config }: UseMyStoreOptions) => {
  const queryConfig: QueryConfig<typeof getMyStore> = {
    ...config,
    queryKey: storeKeyFactory.myStore(),
    queryFn: () => getMyStore(),
  }
  return useQuery(queryConfig)
}
