import clsx from 'clsx'
import { useMemo } from 'react'

import { layoutClassName } from '../FormFieldWrapper/const'
import { FormFieldHorizontalWrapperProps } from './type'

export const FormFieldHorizontalWrapper = ({
  id,
  label,
  error,
  showLabel = true,
  showError = true,
  required = false,
  children,
}: FormFieldHorizontalWrapperProps) => {
  const displayError = useMemo(
    () => (Array.isArray(error) ? error[0] : error),
    [error]
  )

  return (
    <div>
      <div className={clsx('tw-flex tw-items-center tw-gap-3')}>
        <div>{children}</div>
        {showLabel && (
          <label htmlFor={id} className={clsx(layoutClassName.label)}>
            <span className={clsx('tw-text-xs tw-font-bold tw-text-black')}>
              {label}
            </span>
            {required && (
              <span
                className={clsx('tw-text-xs tw-font-bold tw-text-alert-500')}
              >
                ※必須
              </span>
            )}
          </label>
        )}
      </div>

      {showError && (
        <p
          role="alert"
          aria-label={displayError?.message}
          className={clsx(
            layoutClassName.error,
            'tw-text-sm tw-font-bold tw-text-alert-500'
          )}
        >
          {displayError?.message}
        </p>
      )}
    </div>
  )
}
