import { CurrencyYen } from '@mui/icons-material'
import clsx from 'clsx'

export const priceLabel = (
  price: number,
  iconStyle: {
    colorClassName: string
    fontSize: number
  } = {
    colorClassName: 'tw-text-black',
    fontSize: 16,
  }
) => {
  return (
    <span className={clsx('tw-flex tw-items-center')}>
      <CurrencyYen
        className={clsx(iconStyle.colorClassName)}
        sx={{
          fontSize: iconStyle.fontSize,
        }}
      />
      {price.toLocaleString()}
    </span>
  )
}

export const unsetScrollLock = () => {
  document.documentElement.style.overflow = ''
  document.documentElement.style.paddingRight = ''
}
