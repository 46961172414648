import { Delete } from '@mui/icons-material'
import clsx from 'clsx'
import React from 'react'

import type { CartItemListProps } from './type'

import { TextButton, DialogWarning, SectionTitle } from '@/components/ui'
import { CART_TITLE, NOTIFICATION_MESSAGE } from '@/const'
import { useClearCart } from '@/hooks/features/cart'
import { useEnterIndex, useModal, useNotification } from '@/hooks/util'

import { ItemCardWithAddCartForm } from './ItemCardWithAddCartForm'

export const CartItemList = ({
  cartItems,
  isVisiblePurchasePrice,
}: CartItemListProps) => {
  const { notify } = useNotification()

  const cartClearWarningModal = useModal()

  const clearCartMutation = useClearCart({})

  const handleClearCart = () => {
    clearCartMutation.mutate(undefined, {
      onSuccess: () => {
        notify({
          id: 'NOTIFICATION_MESSAGE.CART.CLEAR',
          type: 'success',
          message: NOTIFICATION_MESSAGE.CART.CLEAR,
        })
        cartClearWarningModal.close()
      },
    })
  }

  // Note: tabIndex の挙動を enter でも行えるようにする
  useEnterIndex()

  return (
    <div className={clsx('tw-mt-6')}>
      <SectionTitle
        right={
          <TextButton variant="alert" onClick={cartClearWarningModal.open}>
            <Delete sx={{ fontSize: 24 }} />
            <span
              className={clsx('tw-text-sm')}
            >{`${CART_TITLE}を空にする`}</span>
          </TextButton>
        }
      >
        購入商品
      </SectionTitle>
      <DialogWarning
        isOpen={cartClearWarningModal.isOpen}
        title={`${CART_TITLE}を空にする`}
        bodyText={
          <p>
            カートを空にします。
            <br />
            この操作は取り消すことができません。
          </p>
        }
        confirmButtonText="空にする"
        isLoading={clearCartMutation.isLoading}
        onCancel={cartClearWarningModal.close}
        onConfirm={handleClearCart}
      />

      <ul>
        {cartItems.map((cartItem) => (
          <li key={cartItem.item.itemCode} className={clsx('tw-mt-2')}>
            <ItemCardWithAddCartForm
              cartItem={cartItem}
              isVisiblePurchasePrice={isVisiblePurchasePrice}
            />
          </li>
        ))}
      </ul>
    </div>
  )
}
