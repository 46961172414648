import clsx from 'clsx'

import type { FloatingFooterProps } from './type'

export const FloatingFooter = ({ inputRef, children }: FloatingFooterProps) => {
  return (
    <div
      ref={inputRef}
      className={clsx([
        'tw-fixed',
        'tw-inset-x-0 tw-bottom-0',
        'tw-w-full',
        'tw--drop-shadow-300',
      ])}
    >
      {children}
    </div>
  )
}
