import { useMemo } from 'react'

import type { ItemCardDetailKvListItemProps } from './type'

import { CLASS_NAME } from './const'

export const ItemCardDetailKvListItem = ({
  keyLabel,
  valueLabel,
  pageType = 'list',
  isOutsideModal = false,
  titleWidthClass,
}: ItemCardDetailKvListItemProps) => {
  const className = useMemo(() => {
    return CLASS_NAME({ pageType, isOutsideModal, titleWidthClass })
  }, [pageType, isOutsideModal, titleWidthClass])

  return (
    <li className={className.wrapper}>
      <p className={className.key}>{keyLabel}</p>
      <p className={className.value}>{valueLabel}</p>
    </li>
  )
}
