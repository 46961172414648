import * as z from 'zod'

import type { UpsertFavoriteListFormVO } from './type'

import type { InputOption } from '@/types'

const MAX_LENGTH = {
  name: 50,
  salesTypeCodes: 10,
}

export const schema = z.object({
  name: z
    .string()
    .nonempty('必須入力です')
    .max(MAX_LENGTH.name, `最大文字数は${MAX_LENGTH.name}文字です`),
  isShared: z.boolean(),
  salesTypeCodes: z
    .array(z.string())
    .max(
      MAX_LENGTH.salesTypeCodes,
      `最大件数は${MAX_LENGTH.salesTypeCodes}件です`
    ),
})

export const formLabels: Record<keyof UpsertFavoriteListFormVO, string> = {
  name: 'リストの名称',
  isShared: '他のサロンへの共有',
  salesTypeCodes: 'このリストのデフォルトの売上種別を設定',
}

export const DEFAULT_SELECTED_SALES_TYPE = '1'

export const formDefaultValues: Partial<UpsertFavoriteListFormVO> = {
  name: undefined,
  isShared: false,
  salesTypeCodes: ['1', '2'],
}

export const isSharedOptions: InputOption<boolean>[] = [
  {
    value: true,
    label: '共有する',
  },
  {
    value: false,
    label: '共有しない',
  },
]
