import { atom, useRecoilState } from 'recoil'

type CartCountState = {
  count: number
}

export const cartCountState = atom<CartCountState>({
  key: 'cartCount',
  default: {
    count: 0,
  },
})

export const useCartCount = () => {
  const [cartCount, setCartCount] = useRecoilState(cartCountState)

  return { cartCount, setCartCount }
}
