import { ChevronRightOutlined } from '@mui/icons-material'
import clsx from 'clsx'
import NextLink from 'next/link'

import type { SubCategoryListProps } from './type'

import { ROUTE } from '@/const/route'
import { getMargedCategoryItems } from '@/utils/features/category'

const href = (categoryId: SubCategoryListProps['category']['categoryId']) => {
  return `${ROUTE.items}?categoryIds.0=${categoryId}`
}

export const SubCategoryList = ({ category }: SubCategoryListProps) => {
  // NOTE: サブカテゴリーだけでなく、大分類の「すべて」も表示する
  const subCategories = getMargedCategoryItems(category)

  return (
    <div className={clsx('tw-border-t tw-border-gray-300')}>
      <div
        className={clsx(
          'tw-h-11',
          'tw-flex tw-items-center',
          'tw-px-4',
          'tw-border-b tw-border-gray-300',
          'tw-text-sm tw-font-bold',
          'tw-bg-primary-50'
        )}
      >
        {category.categoryName}
      </div>
      <ul>
        {subCategories.map((subCategory) => (
          <li key={`${category.categoryId}-${subCategory.categoryId}`}>
            <NextLink href={href(subCategory.categoryId)}>
              <a
                className={clsx(
                  'tw-h-11',
                  'tw-flex tw-items-center tw-justify-between',
                  'tw-px-4',
                  'tw-border-b tw-border-gray-300',
                  'tw-text-sm',
                  'tw-cursor-pointer hover:tw-opacity-75',
                  'tw-transition tw-duration-stateChange'
                )}
              >
                <span className={clsx('tw-inline-flex', 'tw-truncate')}>
                  <span className={clsx('tw-truncate')}>
                    {subCategory.categoryName}
                  </span>
                  <span
                    className={clsx('tw-ml-1', 'tw-flex-none')}
                  >{`(${subCategory.numberOfProducts})`}</span>
                </span>
                <span className={clsx('tw-ml-1', 'tw-flex-none')}>
                  <ChevronRightOutlined
                    sx={{ fontSize: 24 }}
                    className={clsx('tw-text-gray-600')}
                  />
                </span>
              </a>
            </NextLink>
          </li>
        ))}
      </ul>
    </div>
  )
}
