import { ROUTE } from '@/const'

export const LIMIT_ORDER_ALLOWED_ROTUES = [
  ROUTE[401],
  ROUTE[403],
  ROUTE[404],
  ROUTE[500],
  ROUTE.top,
  ROUTE.login,
  ROUTE.forgetPassword,
  ROUTE.account,
  ROUTE.changeEmailAuth,
  ROUTE.orderImport,
  ROUTE.purchases,
  '/purchases/[systemType]', // ROUTE.purchaseDetail
  ROUTE.purchasedInfo,
  ROUTE.purchasedInfoBilledAmounts,
  ROUTE.purchasedInfoMakers,
  ROUTE.purchasedInfoMakersStores,
  ROUTE.purchasedInfoSalesDetail,
  ROUTE.purchasedInfoStores,
  ROUTE.purchasedInfoStoresMakersSalesTypes,
  ROUTE.purchasedInfoStoresSalesTypes,
  ROUTE.guides,
  ROUTE.guideDetail('[id]'),
  ROUTE.guidesSearch,
  ROUTE.contact,
  ROUTE.contactComplete,
  ROUTE.termsOfService,
  ROUTE.privacyPolicy,
  ROUTE.tokushoho,
  ROUTE.invoice,
] as const

export const LIMITED_MODE_ALLOWED_PATHS = {
  'limit-order': LIMIT_ORDER_ALLOWED_ROTUES,
  normal: Object.keys(ROUTE),
} as const
