import { useSalesTypes } from '@/hooks/features/salesType'
import { useSalesTypesMaster } from '@/stores'

export const useCheckSalesTypesMaster = () => {
  const { salesTypesMaster, setSalesTypesMaster } = useSalesTypesMaster()

  const salesTypesQuery = useSalesTypes({
    config: {
      enabled: salesTypesMaster.length === 0,
      onSuccess: ({ salesTypes }) => {
        setSalesTypesMaster(salesTypes)
      },
    },
  })

  if (salesTypesMaster.length > 0) {
    return { isStored: true, isLoading: false }
  }

  return salesTypesQuery.isLoading
    ? {
        isStored: false,
        isLoading: true,
      }
    : {
        isStored: salesTypesQuery.isSuccess,
        isLoading: false,
      }
}
