import { CloudUpload } from '@mui/icons-material'
import clsx from 'clsx'
import { MouseEventHandler, useMemo, useState } from 'react'

import { TextButton } from '@/components/ui'

export type TProps = {
  disabled: boolean
  onDrop: (files: FileList) => void
  onClickSelect: MouseEventHandler<HTMLButtonElement>
}

export const DropArea = ({ disabled, onDrop, onClickSelect }: TProps) => {
  // Drag File
  const [dragActive, setDragActive] = useState(false)
  const handleDrag = useMemo(
    () => (e: React.DragEvent<HTMLDivElement>) => {
      if (disabled) return
      e.preventDefault()
      e.stopPropagation()
      if (e.type === 'dragenter' || e.type === 'dragover') {
        setDragActive(true)
      } else if (e.type === 'dragleave') {
        setDragActive(false)
      }
    },
    [setDragActive, disabled]
  )

  // Drop File
  const handleDrop = useMemo(
    () => (e: React.DragEvent<HTMLDivElement>) => {
      if (disabled) return
      e.preventDefault()
      e.stopPropagation()
      setDragActive(false)
      onDrop(e.dataTransfer.files)
    },
    [setDragActive, disabled, onDrop]
  )

  return (
    <div className={clsx('tw-relative tw-h-20 tw-w-full')}>
      <div
        className={clsx(
          'tw-h-full tw-w-full',
          'tw-rounded',
          'tw-bg-primary-50',
          'tw-border-3 tw-border-dashed tw-border-primary-100',
          disabled && 'tw-opacity-disabled',
          dragActive && 'tw-border-2 tw-border-accentBlue-400 tw-opacity-hover'
        )}
        onDragEnter={handleDrag}
        onDragOver={handleDrag}
        onDragLeave={handleDrag}
        onDrop={handleDrop}
      >
        <div
          className={clsx(
            'tw-h-full tw-w-full',
            'tw-flex tw-items-center tw-justify-center tw-gap-2'
          )}
        >
          <CloudUpload
            className="tw-text-gray-700"
            style={{ fontSize: '32px' }}
          />

          <p className={clsx('tw-hidden lg:tw-block', 'tw-text-sm')}>
            csvファイルをドラッグ&ドロップ&nbsp;&nbsp;もしくは
          </p>

          <TextButton
            type={'button'}
            variant={'primary'}
            disabled={disabled}
            onClick={onClickSelect}
          >
            ファイルを選択
          </TextButton>
        </div>
      </div>
    </div>
  )
}
