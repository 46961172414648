import { useRouter } from 'next/router'
import Script from 'next/script'
import { useEffect } from 'react'

import { ENVIRONTMENT } from '@/const/app'
import { GA_MEASUREMENT_ID } from '@/const/ga'
import { gtag } from '@/lib/ga'

export const GoogleAnalyticsProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const router = useRouter()

  // Note: js経由でルーティングしていてGAのイベントが発火しないため、自前で実装
  useEffect(() => {
    if (ENVIRONTMENT !== 'prod') return

    router.events.on('routeChangeComplete', gtag.pageview)

    return () => {
      router.events.off('routeChangeComplete', gtag.pageview)
    }
  }, [router.events])

  return (
    <>
      {/** 本番のみGAを有効にする */}
      {ENVIRONTMENT === 'prod' ? (
        <>
          <Script
            strategy="afterInteractive"
            src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`}
          />
          <Script
            id="gtag-init"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
        
          gtag('config', '${GA_MEASUREMENT_ID}');
          `,
            }}
          />
        </>
      ) : (
        <></>
      )}
      {children}
    </>
  )
}
