import { Dialog, Transition } from '@headlessui/react'
import { ChevronLeftOutlined } from '@mui/icons-material'
import clsx from 'clsx'
import { Fragment } from 'react'

import type { GlobalMenuProps } from './type'

import { LoadingOverlay, SimpleKvList } from '@/components/ui'
import {
  CART_TITLE,
  CONTACT_TITLE,
  FAVORITE_TITLE_ALIAS,
  INVOICE_TITLE,
  ITEM_TITLE,
  ORDER_HISTORY_TITLE,
  ORDER_IMPORT_TITLE,
  ORDER_TITLE,
  PURCHASED_INFO_TITLE,
  PURCHASE_TITLE,
  ROUTE,
  USER_INFO_TITLE,
} from '@/const'
import { IS_PRODUCTION } from '@/const/env'

export const GlobalMenu = ({
  isOpen,
  isLoading,
  isRequirePasswordReset = true,
  showOrder = false,
  showOrderHistory = false,
  showUploadOrder = false,
  showImportOrder = false,
  showInvoice = false,
  storeName,
  onClickLogout,
  onClickClose,
}: GlobalMenuProps) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="tw-fixed tw-inset-0 tw-z-operationModal tw-overflow-hidden"
        open={isOpen}
        onClose={onClickClose}
      >
        <div className="tw-absolute tw-inset-0 tw-overflow-hidden">
          <Dialog.Overlay className="tw-fixed tw-inset-0 tw-bg-overlay tw-bg-opacity-75 tw-transition-opacity" />
          <div className="tw-fixed tw-inset-y-0 tw-left-0 tw-flex tw-max-w-full">
            <Transition.Child
              enter="tw-transform tw-transition tw-ease-in-out tw-duration-300"
              enterFrom="tw--translate-x-full"
              enterTo="tw-translate-x-0"
              leave="tw-transform tw-transition tw-ease-in-out tw-duration-300"
              leaveFrom="tw-translate-x-0"
              leaveTo="tw--translate-x-full"
            >
              <LoadingOverlay isLoading={isLoading} />
              <div className={clsx('tw-h-full tw-w-80 tw-bg-white')}>
                <div
                  className={clsx(
                    'tw-flex tw-items-center tw-justify-between tw-bg-primary-500 tw-px-4 tw-text-white'
                  )}
                  style={{ height: '44px' }}
                >
                  <ChevronLeftOutlined
                    sx={{ fontSize: 24 }}
                    onClick={onClickClose}
                    className={clsx(
                      'tw-cursor-pointer tw-transition tw-duration-stateChange hover:tw-opacity-hover'
                    )}
                  />
                  <span className={clsx('tw-font-bold')}>メニュー</span>
                  <span></span>
                </div>
                <div
                  className={clsx('tw-overflow-y-auto')}
                  style={{ maxHeight: 'calc(100% - 44px)' }}
                >
                  <div className={clsx('tw-p-4 tw-text-center tw-text-[15px]')}>
                    {`${storeName || '-'} 様`}
                  </div>
                  {showOrder && !isRequirePasswordReset && (
                    <SimpleKvList
                      title={`${ORDER_TITLE}する`}
                      rows={[
                        {
                          title: FAVORITE_TITLE_ALIAS,
                          value: '',
                          href: ROUTE.favorites,
                          onClick: onClickClose,
                        },
                        {
                          title: `${ITEM_TITLE}検索`,
                          value: '',
                          href: ROUTE.search,
                          onClick: onClickClose,
                        },
                        {
                          title: CART_TITLE,
                          value: '',
                          href: ROUTE.cart,
                          onClick: onClickClose,
                        },
                      ]}
                    />
                  )}
                  {(showOrderHistory || showUploadOrder) &&
                    !isRequirePasswordReset && (
                      <SimpleKvList
                        title={'注文を確認する'}
                        rows={[
                          ...(showOrderHistory
                            ? [
                                // 注文履歴
                                {
                                  title: ORDER_HISTORY_TITLE,
                                  value: '',
                                  href: ROUTE.orderHistories,
                                  onClick: onClickClose,
                                },
                              ]
                            : []),
                          ...(showUploadOrder
                            ? [
                                // 仕入情報
                                {
                                  title: PURCHASED_INFO_TITLE,
                                  value: '',
                                  href: ROUTE.purchasedInfo,
                                  onClick: onClickClose,
                                },
                              ]
                            : []),
                        ]}
                      />
                    )}

                  {showImportOrder && !isRequirePasswordReset && (
                    <SimpleKvList
                      title={'注文取込'}
                      rows={[
                        // 注文取込
                        {
                          title: ORDER_IMPORT_TITLE,
                          value: '',
                          href: ROUTE.orderImport,
                          onClick: onClickClose,
                        },
                        // EC注文取込結果
                        {
                          title: PURCHASE_TITLE,
                          value: '',
                          href: ROUTE.purchases,
                          onClick: onClickClose,
                        },
                      ]}
                    />
                  )}
                  {showInvoice && !isRequirePasswordReset && !IS_PRODUCTION && (
                    <SimpleKvList
                      title={'請求書を確認する'}
                      rows={[
                        // 請求書
                        {
                          title: INVOICE_TITLE,
                          value: '',
                          href: ROUTE.invoice,
                          onClick: onClickClose,
                        },
                      ]}
                    />
                  )}

                  <SimpleKvList
                    title="そのほか"
                    rows={[
                      {
                        title: USER_INFO_TITLE,
                        value: '',
                        href: ROUTE.account,
                        hidden: isRequirePasswordReset,
                        onClick: onClickClose,
                      },
                      {
                        title: CONTACT_TITLE,
                        value: '',
                        href: ROUTE.contact,
                        hidden: isRequirePasswordReset,
                        onClick: onClickClose,
                      },
                      {
                        title: 'ログアウト',
                        value: '',
                        onClick: () => {
                          onClickClose()
                          onClickLogout()
                        },
                      },
                    ]}
                  />
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
