import { useQuery } from 'react-query'

import { aspidaClient } from '@/lib/aspida'
import { QueryConfig } from '@/lib/react-query'

import { salesTypeKeyFactory } from './key'

const getSalesTypes = () => {
  return aspidaClient.v1.sales_types.$get()
}

export type UseSalesTypesOptions = {
  config?: QueryConfig<typeof getSalesTypes>
}

export const useSalesTypes = ({ config }: UseSalesTypesOptions) => {
  const queryConfig: QueryConfig<typeof getSalesTypes> = {
    ...config,
    queryKey: salesTypeKeyFactory.list(),
    queryFn: () => getSalesTypes(),
  }
  return useQuery(queryConfig)
}
