import * as z from 'zod'

import type { UpdateFavoriteListFormVO } from './type'

const MAX_LENGTH = {
  name: 50,
  salesTypeCodes: 10,
}

export const schema = z.object({
  salesTypeCodes: z
    .array(z.string())
    .max(
      MAX_LENGTH.salesTypeCodes,
      `最大件数は${MAX_LENGTH.salesTypeCodes}件です`
    ),
})

export const formLabels: Record<keyof UpdateFavoriteListFormVO, string> = {
  salesTypeCodes: 'このリストのデフォルトの売上種別を設定',
}

export const DEFAULT_SELECTED_SALES_TYPE = '1'

export const formDefaultValues: Partial<UpdateFavoriteListFormVO> = {
  salesTypeCodes: ['1', '2'],
}
