import type { CartUpdRequest } from '@/api/@types'
import type { Field } from '@/types/error'

/** 商品コードが同じ商品の売上種別情報を結合する */
export const cartItemReducer = (
  reducedCartItems: CartUpdRequest['cartItems'],
  cartItem: CartUpdRequest['cartItems'][number]
): CartUpdRequest['cartItems'] => {
  const isIncluded = reducedCartItems.some(
    (item) => item.itemCode === cartItem.itemCode
  )
  if (isIncluded) {
    return reducedCartItems.map((item) =>
      item.itemCode === cartItem.itemCode
        ? {
            itemCode: item.itemCode,
            salesTypeQuantities: [
              ...item.salesTypeQuantities,
              ...cartItem.salesTypeQuantities,
            ],
          }
        : item
    )
  } else {
    return [...reducedCartItems, cartItem]
  }
}

const getPositions = (fieldName: string) => {
  const positions = /cartItems\[(\d+)\].salesTypeQuantitys\[(\d+)\]/.exec(
    fieldName
  )
  if (positions === null || positions.length < 3) return undefined
  return {
    item: Number(positions[1]) + 1,
    salesType: Number(positions[2]) + 1,
  }
}

export const extendFieldNames = <T extends { [x in string]: unknown }>({
  fields,
  fieldNames,
}: {
  fields: Field<T>[]
  fieldNames?: { [key in keyof T]: string }
}) => {
  return fields
    .filter((field) => /cartItem/.test(String(field.name)))
    .reduce(
      (result, field) => {
        const fieldName = String(field.name)
        if (fieldName.includes('itemCode')) {
          return {
            ...result,
            [fieldName]: `商品コード「${field.value}」`,
          }
        } else if (fieldName.includes('quantity')) {
          const positions = getPositions(fieldName)
          if (positions === undefined) return result
          return {
            ...result,
            [fieldName]: `${positions.item}番目の商品の${positions.salesType}番目の数量`,
          }
        } else if (fieldName.includes('note')) {
          const positions = getPositions(fieldName)
          if (positions === undefined) return result
          return {
            ...result,
            [fieldName]: `${positions.item}番目の商品の${positions.salesType}番目の備考`,
          }
        } else if (fieldName.includes('salesType')) {
          const positions = getPositions(fieldName)
          if (positions === undefined) return result
          return {
            ...result,
            [fieldName]: `${positions.item}番目の商品の${positions.salesType}番目の売上種別`,
          }
        }
        return result
      },
      { ...fieldNames }
    )
}
