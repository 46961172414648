import { Listbox, Transition } from '@headlessui/react'
import clsx from 'clsx'
import * as React from 'react'

import type { InputSelectOptionsProps } from './type'

import { widths } from '@/components/ui/input/const'

export const InputSelectOptions = ({
  open,
  width = 'full',
  children,
}: InputSelectOptionsProps) => {
  return (
    <Transition
      show={open}
      as={React.Fragment}
      leave={clsx(['tw-transition tw-ease-in tw-duration-100'])}
      leaveFrom={clsx(['opacity-100'])}
      leaveTo={clsx(['opacity-0'])}
    >
      <Listbox.Options
        className={clsx([
          widths[width],
          'tw-absolute',
          'tw-z-operationModal',
          'tw-min-h-8 tw-max-h-56',
          'tw-py-1',
          'tw-rounded-md tw-bg-white tw-shadow-lg',
          'tw-ring-1 tw-ring-black tw-ring-opacity-5',
          'tw-overflow-auto',
          'tw-text-sm',
          'focus:tw-outline-none',
        ])}
      >
        {children}
      </Listbox.Options>
    </Transition>
  )
}

InputSelectOptions.displayName = 'InputSelectOptions'
