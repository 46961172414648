import { Delete } from '@mui/icons-material'
import clsx from 'clsx'
import { useMemo } from 'react'

import type { SalesTypeItemProps } from './type'

import { FormInputHorizontalLayout, FormFieldSelect } from '@/components/ui'
import { useSalesTypesMaster } from '@/stores'

export const SalesTypeItem = ({
  index,
  showRemove = true,
  control,
  onClickRemove,
  onAfterChange,
}: SalesTypeItemProps) => {
  const { salesTypesMaster } = useSalesTypesMaster()

  const salesTypeOptions = useMemo(
    () =>
      salesTypesMaster.map(({ salesTypeCode, salesTypeName }) => ({
        label: salesTypeName,
        value: salesTypeCode,
      })) ?? [],
    [salesTypesMaster]
  )

  return (
    <div className={clsx('tw-mt-1')}>
      <FormInputHorizontalLayout alignVertical="center">
        {showRemove ? (
          <Delete
            onClick={onClickRemove}
            sx={{ fontSize: 24 }}
            className={clsx(
              'tw-cursor-pointer tw-text-primary-500',
              'hover:tw-opacity-hover'
            )}
          />
        ) : (
          <span className={clsx('tw-w-6')} />
        )}

        <div className={clsx('tw-flex-1')}>
          <div className={clsx('tw-items-center tw-gap-x-2 lg:tw-flex')}>
            <div className={clsx('tw-flex tw-items-center tw-gap-x-2')}>
              <div className="tw-min-w-[106px] tw-flex-1">
                <FormFieldSelect
                  showLabel={false}
                  height="md"
                  showError={false}
                  name={`salesTypeCodes.${index}`}
                  control={control}
                  options={salesTypeOptions}
                  buttonLabel="選択してください"
                  onAfterChange={onAfterChange}
                />
              </div>
            </div>
          </div>
        </div>
      </FormInputHorizontalLayout>
    </div>
  )
}
