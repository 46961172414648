import DatePicker from 'react-datepicker'

import type { InputCalendarProps } from './type'

import { formatDate } from '@/utils/date'

export const InputCalendar = ({
  value,
  onChange,
  dateFormat = 'yyyy-MM-dd',
  minDate,
  maxDate,
  disabled,
  tabIndex,
}: InputCalendarProps) => {
  return (
    <DatePicker
      selected={value ? new Date(value) : undefined}
      onChange={(date) => onChange(formatDate(date, dateFormat))}
      disabled={disabled}
      locale="ja"
      dateFormat={dateFormat}
      minDate={minDate ? new Date(minDate) : undefined}
      maxDate={maxDate ? new Date(maxDate) : undefined}
      inline={true}
      tabIndex={tabIndex}
    />
  )
}
