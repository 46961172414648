import * as z from 'zod'

import type { ItemDetailedSearchModalFormVO } from './type'

import {
  currencyRangeValidation,
  dateRangeValidation,
  arrayStringLengthValidation,
} from '@/utils/validation'

export const panels = {
  main: {
    title: '詳細検索',
    showClose: true,
    showBack: false,
  },
  category: {
    title: 'カテゴリ選択',
    showClose: false,
    showBack: true,
  },
  brand: {
    title: 'ブランド選択',
    showClose: false,
    showBack: true,
  },
}

export const UPDATE_COUNT_INTERVAL_MILLISECOND = 1000

const MAX_LENGTH = {
  retailPrice: 999999999,
  salonPrice: 999999999,
  brandCodes: 1000,
  categoryIds: 1000,
  keywords: 1000,
}

export const schema = z.object({
  keywords: z
    .string()
    .max(MAX_LENGTH.keywords, `${MAX_LENGTH.keywords}文字を超えています`)
    .optional(),
  categoryIds: z
    .array(z.number())
    .refine(arrayStringLengthValidation(MAX_LENGTH.categoryIds), {
      message: 'カテゴリ指定数が最大数を超えています',
    })
    .optional(),
  brandCodes: z
    .array(z.string())
    .refine(arrayStringLengthValidation(MAX_LENGTH.brandCodes), {
      message: 'ブランド指定数が最大数を超えています',
    })
    .optional(),
  salonPrice: z
    .object({
      lower: z
        .number()
        .nonnegative()
        .max(MAX_LENGTH.salonPrice, `${MAX_LENGTH.salonPrice}を超えています`)
        .optional(),
      upper: z
        .number()
        .nonnegative()
        .max(MAX_LENGTH.salonPrice, `${MAX_LENGTH.salonPrice}を超えています`)
        .optional(),
    })
    .refine(currencyRangeValidation, {
      path: ['lower'],
      message: '下限は上限より小さい値を入力してください',
    })
    .optional(),
  retailPrice: z
    .object({
      lower: z
        .number()
        .nonnegative()
        .max(MAX_LENGTH.retailPrice, `${MAX_LENGTH.retailPrice}を超えています`)
        .optional(),
      upper: z
        .number()
        .nonnegative()
        .max(MAX_LENGTH.retailPrice, `${MAX_LENGTH.retailPrice}を超えています`)
        .optional(),
    })
    .refine(currencyRangeValidation, {
      path: ['lower'],
      message: '下限は上限より小さい値を入力してください',
    })
    .optional(),
  salesStart: z
    .object({
      from: z.string().optional(),
      to: z.string().optional(),
    })
    .refine(dateRangeValidation, {
      path: ['from'],
      message: '終了日は開始日より前の日付にしてください',
    })
    .optional(),
})

export const formDefaultValues: Partial<ItemDetailedSearchModalFormVO> = {
  keywords: undefined,
  salonPrice: { lower: undefined, upper: undefined },
  retailPrice: { lower: undefined, upper: undefined },
  salesStart: { from: undefined, to: undefined },
}
