import clsx from 'clsx'

import type { ItemCardProps } from './type'

import {
  AddCartButton,
  FavoriteButton,
  ItemCardHeader,
  ItemRelatedLinks,
} from '@/components/features/item'
import { InputCheckbox, Image } from '@/components/ui'

export const ItemCard = ({
  item,
  selectable = false, // 選択モードの時にtrue
  selected,
  withAction = true,
  withRelatedLinks = true,
  children,
  isOutsideModal,
  handleFavorite,
  onChangeSelected,
}: ItemCardProps) => {
  return (
    <div className={clsx('tw-rounded tw-border tw-border-gray-300')}>
      <div className={clsx('tw-flex tw-items-center', 'tw-py-2 tw-px-2')}>
        {selectable && (
          <div className={clsx('tw-flex-none')}>
            <InputCheckbox
              checked={!!selected}
              label={item.itemName}
              handleChange={(value) =>
                onChangeSelected && onChangeSelected(value, item)
              }
            />
          </div>
        )}
        <div className={clsx('tw-flex-1', 'tw-w-full')}>
          <div
            className={clsx(
              'tw-flex-none',
              'tw-flex tw-items-start',
              'tw-py-1'
            )}
            style={{
              minHeight: '100px',
            }}
          >
            <div
              className={clsx('tw-flex-none', 'tw-relative')}
              style={{ width: '100px', height: '100px' }}
            >
              <Image src={item.itemImage ?? ''} alt={item.itemName ?? ''} />
            </div>
            <div className={clsx('tw-ml-4', 'tw-w-full')}>
              <ItemCardHeader item={item} />
            </div>
          </div>
        </div>
      </div>

      <div className={clsx(selectable && 'tw-ml-6', 'tw-px-2')}>
        {withRelatedLinks && (
          <ItemRelatedLinks item={item} disabled={selectable} />
        )}
      </div>

      {children}

      {withAction && (
        <div
          className={clsx(
            'tw-flex tw-items-center tw-justify-center',
            'tw-gap-2',
            'tw-py-2 tw-px-2'
          )}
        >
          <div className={clsx('lg:tw-hidden')}>
            <FavoriteButton
              type={'iconButton'}
              isFavorite={item.isFavorite ?? false}
              selectedItemCodes={[item.itemCode]}
              disabled={selectable}
              onChange={handleFavorite}
            />
          </div>
          <div
            className={clsx(
              'tw-hidden lg:tw-block',
              isOutsideModal && 'tw-w-full tw-max-w-pcHalf'
            )}
          >
            <FavoriteButton
              type={isOutsideModal ? 'textButton' : 'iconButton'}
              isSpTypeIcon={true}
              isFavorite={item.isFavorite ?? false}
              selectedItemCodes={[item.itemCode]}
              disabled={selectable}
              onChange={handleFavorite}
            />
          </div>

          <div className={clsx('tw-w-full tw-max-w-pcHalf')}>
            <AddCartButton items={[item]} disabled={selectable} />
          </div>
        </div>
      )}
    </div>
  )
}
