export const baseClassNames = [
  'tw-px-2',
  'tw-border tw-border-gray-300',
  'focus:tw-outline-none focus:tw-border-accentBlue-500',
  'disabled:tw-bg-gray-100 disabled:tw-text-gray-600 disabled:tw-cursor-not-allowed',
  'tw-transition tw-duration-300',
]

export const errorClassNames = 'tw-border-alert-500 focus:tw-border-alert-500'

export const attentionClassNames = 'tw-bg-accentYellow-50'

export const widths = {
  sm: 'tw-w-40',
  md: 'tw-w-60',
  lg: 'tw-w-80',
  full: 'tw-w-full',
}

export const heights = {
  md: 'tw-h-8',
  lg: 'tw-h-11',
} as const

export const texts = {
  md: 'tw-text-xs',
  lg: 'tw-text-sm',
} as const

export const rounds = {
  all: 'tw-rounded',
  left: 'tw-rounded-l',
  right: 'tw-rounded-r',
}

export const INPUT_CHECK_ALL_VALUE = 'check-all'
