import { useMutation } from 'react-query'

import type { Favorite } from '@/api/@types'
import { aspidaClient } from '@/lib/aspida'
import { MutationConfig } from '@/lib/react-query'

export type DeleteFavoriteDTO = {
  setNo: Favorite['setNo']
  storeCode: Favorite['storeCode']
}

export const deleteFavorite = ({ setNo, storeCode }: DeleteFavoriteDTO) => {
  return aspidaClient.v1.favorites._setNo(setNo)._storeCode(storeCode).$delete()
}

type UseDeleteFavoriteOptions = {
  config?: MutationConfig<typeof deleteFavorite>
}

export const useDeleteFavorite = ({ config }: UseDeleteFavoriteOptions) => {
  return useMutation({
    ...config,
    mutationFn: deleteFavorite,
  })
}
