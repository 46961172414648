import { FormFieldWrapper, InputText } from '@/components/ui'

import { FormFieldTextProps } from './type'

export const FormFieldText = (props: FormFieldTextProps) => {
  const {
    label,
    required,
    registration: { onChange, onBlur, ...registration },
    error,
    showLabel,
    showError,
    onFocus,
    ...inputProps
  } = props

  const handleChange: React.InputHTMLAttributes<HTMLInputElement>['onChange'] =
    (e) => {
      onChange && onChange(e)
      props.onChange && props.onChange(e)
    }

  const handleFocus: React.InputHTMLAttributes<HTMLInputElement>['onFocus'] = (
    e
  ) => {
    onFocus && onFocus(e)
  }
  const handleBlur: React.InputHTMLAttributes<HTMLInputElement>['onBlur'] = (
    e
  ) => {
    onBlur && onBlur(e)
    props.onBlur && props.onBlur(e)
  }

  return (
    <FormFieldWrapper
      id={registration.name}
      label={label}
      required={required}
      error={error}
      showLabel={showLabel}
      showError={showError}
    >
      <InputText
        data-testid={registration.name}
        {...inputProps}
        {...registration}
        id={registration.name}
        isError={error !== undefined}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
    </FormFieldWrapper>
  )
}
