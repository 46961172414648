import { useQuery } from 'react-query'

import { aspidaClient } from '@/lib/aspida'
import { QueryConfig } from '@/lib/react-query'

import { ecSiteOrdersKeyFactory } from './key'

type TParams = Parameters<
  typeof ecSiteOrdersKeyFactory.getEcSiteOrdersFileId
>[0]

type TConfig = QueryConfig<typeof getEcSiteOrdersFileId>

/**
 * Ec注文アップロードファイル詳細取得
 */
export const getEcSiteOrdersFileId = ({ pathParams }: TParams) =>
  aspidaClient.v1.ec_site_orders._fileId(pathParams.fileId).$get()

export type UseGetEcSiteOrdersFileIdOptions = TParams & {
  config?: TConfig
}

export const useGetEcSiteOrdersFileId = ({
  config,
  pathParams,
}: UseGetEcSiteOrdersFileIdOptions) => {
  const queryConfig: TConfig = {
    ...config,
    queryKey: ecSiteOrdersKeyFactory.getEcSiteOrdersFileId({
      pathParams,
    }),
    queryFn: () =>
      getEcSiteOrdersFileId({
        pathParams,
      }),
  }
  return useQuery(queryConfig)
}
