import clsx from 'clsx'
import React, { useRef } from 'react'
import { useWatch } from 'react-hook-form'

import type { ItemCardWithAddCartFormProps } from './type'

import {
  ItemErrorLabel,
  ItemOrderUnitFormField,
  ItemPrice,
  ItemThumbnailBannar,
} from '@/components/features/item'
import { useMobileChecker } from '@/hooks/util'

export const ItemCardWithAddCartForm = ({
  item,
  control,
  itemIndex,
  showPrice = true,
  disabled,
  disabledLink,
  register,
  onAfterChange,
}: ItemCardWithAddCartFormProps) => {
  const salesTypeQuantities = useWatch({
    control,
    name: `items.${itemIndex}.salesTypeQuantities`,
  })
  const { isMobile } = useMobileChecker()
  const ref = useRef<HTMLDivElement>(null)

  // カートに追加する際に、フォームが画面外にある場合はスクロールする
  const handleScrollToVisibleArea = () => {
    // モバイルは見づらくなってしまうため、スクロールしない
    if (isMobile) return

    const HEADER_HEIGHT = 140
    const FOOTER_HEIGHT = 100
    const cardOffsetTop = ref.current?.getClientRects()[0].top ?? 0
    const cardOffsetHeight = ref.current?.offsetHeight ?? 0
    if (window.innerHeight - cardOffsetTop < FOOTER_HEIGHT + cardOffsetHeight) {
      window.scrollTo({
        top: window.scrollY + FOOTER_HEIGHT + cardOffsetHeight,
        behavior: 'smooth',
      })
      return
    }

    if (cardOffsetTop < HEADER_HEIGHT) {
      window.scrollTo({
        top: window.scrollY - HEADER_HEIGHT,
        behavior: 'smooth',
      })
      return
    }
  }

  return (
    <div
      className={clsx('tw-flex tw-flex-col tw-py-2 lg:tw-flex-row')}
      ref={ref}
    >
      <div className={'tw-w-full lg:tw-w-1/2'}>
        <ItemThumbnailBannar
          item={item}
          showFavorite={false}
          disabledLink={disabledLink}
          nameFooter={
            <>
              {showPrice && salesTypeQuantities && (
                <ItemPrice
                  item={item}
                  cartSalesTypeQuantities={salesTypeQuantities}
                  direction={'horizontal'}
                  showCount={false}
                />
              )}
              <ItemErrorLabel status={item.itemStatus} />
              <span
                className={clsx(
                  'tw-block',
                  'tw-flex tw-items-center tw-gap-1',
                  'tw-text-xs tw-text-gray-600'
                )}
              >
                <span className={clsx('tw-block tw-w-16')}>JANコード</span>
                <span className={clsx('tw-block')}>{item.janCode}</span>
              </span>
              <span
                className={clsx(
                  'tw-block',
                  'tw-flex tw-items-center tw-gap-1',
                  'tw-text-xs tw-text-gray-600'
                )}
              >
                <span className={clsx('tw-block tw-w-16')}>商品コード</span>
                <span className={clsx('tw-block')}>{item.itemCode}</span>
              </span>
            </>
          }
        />
      </div>
      {!disabled && (
        <div className={clsx('tw-w-full lg:tw-w-1/2', 'tw-mt-1 lg:tw-mt-0')}>
          <div className={clsx('tw-flex tw-justify-center', 'tw-px-2')}>
            <div className={clsx('tw-w-full')}>
              <ItemOrderUnitFormField
                control={control}
                itemIndex={itemIndex}
                disabled={item.itemStatus !== 'available'}
                register={register}
                onAfterChange={onAfterChange}
                onFocusItemCount={handleScrollToVisibleArea}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
