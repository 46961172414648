import type { PerPageInputOption } from './type'

export const options: PerPageInputOption[] = [
  {
    label: '30件',
    value: 30,
  },
  {
    label: '50件',
    value: 50,
  },
  {
    label: '100件',
    value: 100,
  },
]

export const DEFAULT_PER_PAGE = options[0].value
