import { LocalShipping } from '@mui/icons-material'
import clsx from 'clsx'
import { useMemo } from 'react'

import type { ItemCardHeaderProps } from './type'

import { ITEM_CARD_STATUS_LABEL } from '@/const'

export const ItemCardHeader = ({ item }: ItemCardHeaderProps) => {
  const categoryName = useMemo(() => {
    if (item.categories === null || item.categories.length === 0) return ''
    return item.categories[item.categories.length - 1].categoryName ?? ''
  }, [item.categories])

  const itemStatusLabel = useMemo(() => {
    return ITEM_CARD_STATUS_LABEL[item.itemStatus]
  }, [item.itemStatus])

  return (
    <div
      className={clsx(
        'tw-w-full',
        'tw-flex tw-flex-col tw-justify-between',
        'tw-overflow-hidden'
      )}
    >
      <p className={clsx('tw-text-xs', 'tw-truncate')}>{categoryName}</p>
      <p className={clsx('tw-text-xs', 'tw-mt-1 tw-truncate')}>
        {item.brandName}
      </p>
      <p
        className={clsx(
          'tw-text-sm tw-font-bold',
          'tw-mt-1 tw-break-all tw-line-clamp-2'
        )}
      >
        {item.itemName}
      </p>
      <p className={clsx('tw-text-xs', 'tw-mt-1 tw-truncate')}>
        {`サロン価格：${(item.salonPrice || 0).toLocaleString()}円`}
      </p>

      {item.isBackorder && (
        <p
          className={clsx(
            'tw-mt-1 tw-flex tw-items-center tw-justify-start tw-text-xs'
          )}
        >
          <LocalShipping sx={{ fontSize: 18 }} className={clsx('')} />
          <span className="tw-ml-1">取寄品</span>
        </p>
      )}

      {itemStatusLabel && (
        <div>
          <p className={clsx('tw-mt-1 tw-text-sm tw-text-alert-500')}>
            {itemStatusLabel}
          </p>
        </div>
      )}
    </div>
  )
}
