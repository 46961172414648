import { useMutation } from 'react-query'

import { aspidaClient } from '@/lib/aspida'
import { MutationConfig } from '@/lib/react-query'

export type ChangeEmailAuthDTO = {
  token: string
}

export const changeEmailAuth = ({ token }: ChangeEmailAuthDTO) => {
  return aspidaClient.v1.change_email_auth.$post({ body: { token } })
}

type UseChangeEmailAuthOptions = {
  config?: MutationConfig<typeof changeEmailAuth>
}

export const useChangeEmailAuth = ({ config }: UseChangeEmailAuthOptions) => {
  return useMutation({
    ...config,
    mutationFn: changeEmailAuth,
  })
}
