import { Visibility, VisibilityOff } from '@mui/icons-material'
import clsx from 'clsx'
import { useState } from 'react'

import type { InputPasswordProps } from './type'

import { InputText } from '@/components/ui'

export const InputPassword = (props: InputPasswordProps) => {
  const [isMasked, setIsMasked] = useState(true)

  return (
    <span className={clsx('tw-relative')}>
      <InputText type={isMasked ? 'password' : 'text'} {...props} />
      <button
        type="button"
        onClick={() => setIsMasked(!isMasked)}
        className={clsx(
          'tw-absolute tw-right-2 tw-top-1/2 -tw-translate-y-1/2',
          'tw-transition tw-duration-stateChange hover:tw-opacity-hover'
        )}
      >
        {isMasked ? (
          <Visibility sx={{ fontSize: 20 }} />
        ) : (
          <VisibilityOff sx={{ fontSize: 20 }} />
        )}
      </button>
    </span>
  )
}
