import { DragHandle } from '@mui/icons-material'
import clsx from 'clsx'

import { ItemCardWithAddCartForm } from '../../ItemCardWithAddCartForm'
import { SortableItemProps } from './type'

export const SortableItem = ({
  item,
  control,
  register,
  index,
}: SortableItemProps) => {
  return (
    <li
      className={clsx(
        'tw-h-auto',
        'tw-mt-2',
        'tw-rounded tw-border tw-border-gray-300',
        'tw-bg-white',
        'tw-list-none'
      )}
    >
      <div className={clsx('tw-flex tw-items-center', 'tw-gap-x-1')}>
        <div
          className={clsx(
            'tw-flex-none',
            'tw-py-2 tw-pl-2',
            'tw-flex tw-items-center'
          )}
        >
          <DragHandle
            sx={{ fontSize: 24 }}
            className={clsx('tw-text-primary-500')}
          />
        </div>

        <div className={clsx('tw-flex-1')}>
          <ItemCardWithAddCartForm
            item={item}
            itemIndex={index}
            disabled={true}
            disabledLink={true}
            control={control}
            register={register}
          />
        </div>
      </div>
    </li>
  )
}
